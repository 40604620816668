import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FlagIcon = forwardRef<SVGSVGElement, IconProps>(function FlagIcon(
  {
    'aria-label': ariaLabel = 'Flag Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M6.14973 20.3251V12.6127C6.83524 12.2206 7.60962 12.0103 8.3993 12.0017C9.55536 12.0639 10.6881 12.3512 11.7341 12.8475C12.9311 13.4596 14.2536 13.7861 15.598 13.8013C16.7976 13.8443 17.9761 13.4764 18.9382 12.7584C19.0189 12.6953 19.0842 12.6146 19.129 12.5224C19.174 12.4303 19.1973 12.3291 19.1973 12.2266V4.57809C19.1973 4.45754 19.1651 4.3392 19.1039 4.23533C19.0428 4.13147 18.9549 4.04588 18.8495 3.98747C18.7439 3.92905 18.6249 3.89995 18.5044 3.90316C18.3838 3.90639 18.2664 3.94182 18.1642 4.0058C17.4026 4.51134 16.512 4.78803 15.598 4.80304C14.4419 4.74087 13.3091 4.45355 12.2632 3.95721C11.0662 3.3451 9.74364 3.01862 8.3993 3.00338C7.19962 2.96039 6.02122 3.32833 5.05914 4.04629C4.97838 4.10943 4.91308 4.19013 4.86819 4.28228C4.82328 4.37441 4.79996 4.47558 4.79999 4.57809V20.3251C4.79999 20.5041 4.87109 20.6758 4.99764 20.8023C5.12421 20.9289 5.29587 21 5.47486 21C5.65385 21 5.8255 20.9289 5.95206 20.8023C6.07863 20.6758 6.14973 20.5041 6.14973 20.3251Z" />
    </Icon>
  );
}) as typeof Icon;
