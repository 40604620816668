import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const EmbedIcon = forwardRef<SVGSVGElement, IconProps>(
  function EmbedIcon(
    {
      'aria-label': ariaLabel = 'Embed Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M8.69253 15.9025L4.79253 12.0025L8.69253 8.10253C9.08253 7.71253 9.08253 7.09253 8.69253 6.70253C8.30253 6.31253 7.68253 6.31253 7.29253 6.70253L2.70253 11.2925C2.31253 11.6825 2.31253 12.3125 2.70253 12.7025L7.29253 17.3025C7.68253 17.6925 8.30253 17.6925 8.69253 17.3025C9.08253 16.9125 9.08253 16.2925 8.69253 15.9025ZM15.2925 15.9025L19.1925 12.0025L15.2925 8.10253C14.9025 7.71253 14.9025 7.09253 15.2925 6.70253C15.6825 6.31253 16.3025 6.31253 16.6925 6.70253L21.2825 11.2925C21.6725 11.6825 21.6725 12.3125 21.2825 12.7025L16.6925 17.3025C16.3025 17.6925 15.6825 17.6925 15.2925 17.3025C14.9025 16.9125 14.9025 16.2925 15.2925 15.9025Z" />
      </Icon>
    );
  },
) as typeof Icon;
