import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { type ComponentProps, type ReactNode, forwardRef } from 'react';
import type { SetRequired, Simplify } from 'type-fest';

import { styled } from '../../stitches.config.js';
import { ChevronRightIcon } from '../icons/chevron-right-icon.js';
import { contentStyles } from './menu.js';
import { itemStyles, RightSlot } from './menu-item.js';

const SubTrigger = styled(Dropdown.SubTrigger, {
  '&[data-state="open"]': {
    dark: { backgroundColor: '$gray-500' },
    light: { backgroundColor: '$gray-200' },
  },
  ...itemStyles,
});

SubTrigger.displayName = 'Menu.SubTrigger';

const SubContent = styled(Dropdown.SubContent, {
  ...contentStyles,
  zIndex: '$10',
  maxHeight: '50rem',
  overflowY: 'auto',
  minWidth: '15.6rem',
  '@small': {
    minWidth: '18.6rem',
  },
  '@medium': {
    minWidth: '20.6rem',
  },
  '@large': {
    minWidth: '20.8rem',
  },
});

SubContent.displayName = 'Menu.SubContent';

export type MenuSubProps = Simplify<
  SetRequired<Dropdown.DropdownMenuSubProps, 'children'> & {
    trigger: ReactNode;
    icon?: ReturnType<typeof ChevronRightIcon>;
    contentStyles?: ComponentProps<typeof SubContent>['css'];
  }
>;

export const Sub = forwardRef<HTMLDivElement, MenuSubProps>(function Sub(
  props: MenuSubProps,
  ref,
) {
  const {
    children,
    trigger,
    icon = <ChevronRightIcon />,
    ...restProps
  } = props;

  return (
    <Dropdown.Sub {...restProps}>
      <SubTrigger>
        {trigger}
        {icon ?
          <RightSlot>{icon}</RightSlot>
        : null}
      </SubTrigger>
      <Dropdown.Portal>
        <SubContent ref={ref} sideOffset={-2}>
          {children}
        </SubContent>
      </Dropdown.Portal>
    </Dropdown.Sub>
  );
});
