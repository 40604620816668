import { type ReactNode, forwardRef } from 'react';

import type { CSSProperties } from '../../types.js';
import { useFormatCss, useStyleProps } from '../box/index.js';
import { List as Primitive } from './tabs.primitive.js';

type ListProps = {
  children: ReactNode;
  css?: CSSProperties;
};

export const List = forwardRef<HTMLDivElement, ListProps>(function List(
  { children, css, ...props },
  ref,
) {
  const { cssProperties: cssShorthandProps, htmlAttributes } =
    useStyleProps(props);
  const mergedCss = useFormatCss(cssShorthandProps, css);
  return (
    <Primitive css={mergedCss} {...htmlAttributes} ref={ref}>
      {children}
    </Primitive>
  );
});
