import { Checkbox as Root } from './checkbox.js';
import { Checkbox as Input, Label } from './checkbox.primitive.js';

export const Checkbox = Object.assign(Root, {
  Input,
  Label,
}) as typeof Root & {
  Input: typeof Input;
  Label: typeof Label;
};

export type { CheckboxProps } from './checkbox.js';
