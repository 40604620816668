import { toHHMMSS } from '@iheartradio/web.utilities';
import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Badge } from '../badge/index.js';
import { Box } from '../box/index.js';
import { Flex } from '../flex/index.js';
import { type SliderProps, Slider } from '../slider/index.js';
import { Text } from '../text/index.js';

type PlayerTimeProps = {
  disabled?: SliderProps['disabled'];
  badge?: string;
  onChange?: SliderProps['onChange'];
  position: number;
  duration: number;
  interactive?: boolean;
};

/**
 * @deprecated
 */
export const PlayerTime = forwardRef<HTMLSpanElement, PlayerTimeProps>(
  function PlayerTime(
    {
      badge,
      position,
      disabled = false,
      duration,
      interactive,
      onChange,
      ...props
    },
    ref,
  ) {
    return (
      <Flex
        css={{
          height: 'initial',
          padding: '$0',

          [`@container ${media.large}`]: {
            padding: '$0 $16',
            height: '2.7rem',
            display: 'flex',
          },
          [`@container ${media.xlarge}`]: {
            padding: '$0 $32',
          },

          '[data-player-type="fullscreen"] &': {
            height: '2.7rem',
            padding: '$0 $16',

            [`@container ${media.large}`]: {
              display: 'none',
            },
          },
        }}
        gridArea="time"
        justifyContent="center"
        position="relative"
        width="100%"
        {...props}
      >
        <Flex
          alignItems="center"
          columnGap="$16"
          css={{
            transform: 'translateY(-1.4rem)',
            width: '100%',
            position: 'absolute',

            [`@container ${media.large}`]: {
              transform: 'translateY(0rem)',
              width: '30rem',
              position: 'relative',
            },
            [`@container ${media.xlarge}`]: { width: '60rem' },

            '[data-player-type="fullscreen"] &': {
              transform: 'translateY(0rem)',

              [`@container ${media.small}`]: { width: '80%' },
              [`@container ${media.medium}`]: { width: '70%' },
            },
          }}
          justifyContent="center"
          justifySelf="center"
        >
          {!badge && position > 0 && (
            <Text
              aria-label="position"
              css={{
                display: 'none',

                [`@container ${media.large}`]: {
                  display: 'block',
                },

                '[data-player-type="fullscreen"] &': {
                  display: 'block',

                  [`@container ${media.large}`]: {
                    display: 'none',
                  },
                },
              }}
              kind="caption-4"
              role="timer"
            >
              {toHHMMSS(position)}
            </Text>
          )}
          <Box
            css={{
              display: badge ? 'none' : 'contents',
              '[data-player-type="fullscreen"] &': {
                display: 'contents',
              },
            }}
          >
            <Slider
              data-test="progress-bar-player-control"
              disabled={disabled}
              interactive={interactive}
              max={duration}
              min={0}
              onChange={onChange}
              ref={ref}
              value={badge ? 0 : position}
            />
          </Box>
          {!badge && position > 0 && (
            <Text
              aria-label="duration"
              css={{
                display: 'none',

                [`@container ${media.large}`]: {
                  display: 'block',
                },

                '[data-player-type="fullscreen"] &': {
                  display: 'block',

                  [`@container ${media.large}`]: {
                    display: 'none',
                  },
                },
              }}
              kind="caption-4"
              role="timer"
            >
              {toHHMMSS(duration)}
            </Text>
          )}
          <Badge
            backgroundColor="$brand-red"
            color="$brand-white"
            css={{
              [`@container ${media.xsmall}`]: { display: 'none' },
              [`@container ${media.large}`]: { display: 'block' },
              '[data-player-type="fullscreen"] &': { display: 'block' },
            }}
            placeSelf="center"
            position="absolute"
            size="small"
          >
            {badge}
          </Badge>
        </Flex>
      </Flex>
    );
  },
);

PlayerTime.displayName = 'Player.Time';
