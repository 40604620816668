// Generated by ts-to-zod
import { z } from 'zod';

export const lotameSchema = z.object({
  clientId: z.number().optional(),
  enabled: z.boolean(),
  publisherId: z.number().optional(),
  threshold: z.number().optional(),
  tp: z.string().optional(),
  legacyLotame: z.boolean().optional(),
});

export type Lotame = z.infer<typeof lotameSchema>;
