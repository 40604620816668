import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Grid } from '../grid/index.js';

export const PlayerControls = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(function PlayerControls({ children, ...props }, ref) {
  return (
    <Grid
      alignItems="center"
      alignSelf={{
        [`@container ${media.xsmall}`]: 'center',
        [`@container ${media.large}`]: 'end',
      }}
      css={{
        padding: '$0 $8',
        display: 'grid',

        [`@container ${media.large}`]: {
          padding: '$0 $16',
        },

        [`@container ${media.xlarge}`]: {
          padding: '$0 $32',
        },

        '[data-player-type="fullscreen"] &': {
          padding: '$24 $0',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },

        '& > *': {
          padding: '$0 $8',

          '[data-player-type="fullscreen"] &': {
            padding: '$0 $6',
          },
        },
      }}
      gridArea="controls"
      height="min-content"
      justifyContent="center"
      ref={ref}
      templateAreas='"previous skipBack play skipForward next"'
      {...props}
    >
      {children}
    </Grid>
  );
});

PlayerControls.displayName = 'Player.Controls';
