import { styled } from '../../stitches.config.js';

const sharedStyles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  /**
   * This ensures clicking the extra included elements focuses the input.
   * Buttons override this on the element level so a Button in one of the below
   * Input Left/Right Elements should still receive pointer events.
   */
  pointerEvents: 'none',
};

export const InputLeftElement = styled('div', {
  left: 0,
  insetInlineStart: '0',
  padding: '0 $12',

  ...sharedStyles,
});

InputLeftElement.displayName = 'InputLeftElement';

export const InputRightElement = styled('div', {
  right: 0,
  insetInlineEnd: '0',
  paddingLeft: '$12',
  ...sharedStyles,
});

InputRightElement.displayName = 'InputRightElement';
