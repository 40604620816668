import { EmptyState } from '@iheartradio/web.companion';
import { useState } from 'react';

import { useErrorContext } from '../hooks/error-context.js';
import { ErrorCTA, getErrorCTA, getErrorIcon } from './app-error.utils.js';
import { ClearCookieDialog } from './clear-cookie-dialog.js';

const AppError = () => {
  const {
    errorSource: { title, description, icon, cta },
  } = useErrorContext();
  const [dialogOpenCount, setDialogOpenCount] = useState(0);

  const handleDialogClick = () =>
    setDialogOpenCount(dialogOpenCount => (dialogOpenCount += 1));

  return (
    <>
      <EmptyState
        cta={<ErrorCTA>{getErrorCTA(cta, handleDialogClick)}</ErrorCTA>}
        description={description}
        icon={getErrorIcon(icon)}
        title={title}
      />
      {!!dialogOpenCount && (
        <ClearCookieDialog key={dialogOpenCount} showDialog={true} />
      )}
    </>
  );
};

export default AppError;
