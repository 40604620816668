import { type TextProps, Text } from '../text/index.js';

type DialogBodyProps = Omit<TextProps, 'kind' | 'as'>;

export const DialogBody = ({ children, ...props }: DialogBodyProps) => (
  <Text
    as="p"
    kind={{ '@initial': 'body-3', '@medium': 'body-2' }}
    textAlign="center"
    {...props}
  >
    {children}
  </Text>
);
