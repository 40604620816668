import type { Permutive } from '../../../schemas/sdks/permutive.js';

export const stagingCA: Permutive = {
  apiKey: 'b1a4360a-3db9-4b39-b09d-c3e14666840a',
  projectId: '289d106c-df24-4cd9-a9fa-753e928c23ad',
};

export const productionCA: Permutive = {
  apiKey: 'b1a4360a-3db9-4b39-b09d-c3e14666840a',
  projectId: '289d106c-df24-4cd9-a9fa-753e928c23ad',
};
