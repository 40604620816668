export function toHHMMSS(time: number) {
  if (!Number.isFinite(time) || time < 0) return null;

  const date = new Date(0);

  date.setSeconds(time);

  return date
    .toISOString()
    .slice(11, 19)
    .replace(/^00:0?|^0/, '');
}
