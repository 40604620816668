import {
  type Dispatch,
  type MutableRefObject,
  useEffect,
  useId,
  useMemo,
  useReducer,
} from 'react';

declare global {
  interface EventTarget {
    height: number;
    width: number;
  }
}

type Img = JSX.IntrinsicElements['img'];

export type UseImageProps = Pick<Img, 'src'>;

type UseImageState = {
  height: number;
  id: string;
  status: 'pending' | 'failed' | 'succeeded';
  width: number;
};

type UseImageAction =
  | {
      type: 'SUCCEEDED';
      payload: {
        height: UseImageState['height'];
        width: UseImageState['width'];
      };
    }
  | {
      type: 'FAILED';
    };

const useImageReducer = (
  state: UseImageState,
  action: UseImageAction,
): UseImageState => {
  switch (action.type) {
    case 'SUCCEEDED': {
      return {
        ...state,
        status: 'succeeded',
        width: action.payload.width,
        height: action.payload.height,
      };
    }
    case 'FAILED': {
      return {
        ...state,
        status: 'failed',
      };
    }
    default: {
      throw new TypeError('Unknown useImageReducer action type');
    }
  }
};

// Make these handlers into factory functions so that `useCallback` is not needed in the body
// of the hook
const onErrorFactory = (dispatch: Dispatch<UseImageAction>) => () =>
  dispatch({ type: 'FAILED' });

const onLoadFactory =
  (
    dispatch: Dispatch<UseImageAction>,
    // pass in the `.current` value of the ref into the factory function so that...
    current: HTMLImageElement | null,
  ) =>
  (event: Event) => {
    // ... we can be sure the currentTarget of the event is in fact the correct `img` element
    if (event.currentTarget && event.currentTarget === current) {
      dispatch({
        type: 'SUCCEEDED',
        payload: {
          height: event.currentTarget.height,
          width: event.currentTarget.width,
        },
      });
    }
  };

export const useImage = (ref: MutableRefObject<HTMLImageElement | null>) => {
  // use the `useId` hook so that we can have `key` reciprocity between server/client loads
  const id = useId();
  const [state, dispatch] = useReducer(useImageReducer, {
    id,
    status: 'pending',
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { current } = ref;
    const onload = onLoadFactory(dispatch, current);
    const onerror = onErrorFactory(dispatch);

    if (current) {
      current.addEventListener('load', onload);
      current.addEventListener('error', onerror);

      return () => {
        current.removeEventListener('load', onload);
        current.removeEventListener('error', onerror);
      };
    }
  }, [ref]);

  return useMemo(
    () => ({
      height: state.height,
      status: state.status,
      width: state.width,
      id: state.id,
    }),
    [state.height, state.status, state.width, state.id],
  );
};
