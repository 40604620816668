import type { Snapchat } from '../../../schemas/sdks/snapchat.js';

export const stagingWW: Snapchat = {
  pixel: {
    enabled: false,
    id: '015fcbb1-38ea-41dc-ae3e-391a68f31a4b',
  },
};

export const productionWW: Snapchat = {
  pixel: {
    enabled: false,
    id: '015fcbb1-38ea-41dc-ae3e-391a68f31a4b',
  },
};
