import { styled } from '../../stitches.config.js';
import { ErrorFilledIcon } from '../icons/error-filled-icon.js';
import { kinds } from '../text/text.js';

const errorColor = {
  dark: '$orange-300',
  light: '$orange-650',
};

export const FieldLabel = styled('label', {
  paddingRight: '$4',

  dark: {
    color: '$gray-200',
  },
  light: {
    color: '$gray-500',
  },

  disabled: {
    dark: {
      color: '$gray-400',
    },
    light: {
      color: '$gray-300',
    },
  },

  '@xsmall': kinds['caption-3'],
  '@medium': kinds['caption-1'],
});

FieldLabel.displayName = 'FieldLabel';

export const FieldContainer = styled('div', {
  display: 'flex',
  width: '100%',

  defaultVariants: {
    inline: false,
    inlineLabel: false,
  },

  variants: {
    inline: {
      false: {
        width: '100%',
      },
      true: {
        width: 'auto',
      },
    },

    inlineLabel: {
      false: {
        alignItems: 'stretch',
        flexDirection: 'column',
        gap: '$4',
      },
      true: {
        alignItems: 'center',
        flexDirection: 'row',

        '@xsmall': {
          gap: '$8',
        },

        '@medium': {
          gap: '$12',
        },
      },
    },
  },
});

FieldContainer.displayName = 'FieldContainer';

export const ErrorIcon = styled(ErrorFilledIcon, {
  display: 'inline-block',
  paddingRight: '.4rem',
  dark: {
    fill: errorColor.dark,
  },
  light: {
    fill: errorColor.light,
  },
});

export const FieldMessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '100%',
  variants: {
    hasIcon: {
      true: {},
    },
    size: {
      large: {},
      small: {},
    },
  },
  compoundVariants: [
    {
      hasIcon: true,
      size: 'large',
      css: {
        width: 'calc(100% - ($space$18 + $space$4))',
      },
    },
    {
      hasIcon: true,
      size: 'small',
      css: {
        width: 'calc(100% - ($space$16 + $space$4))',
      },
    },
  ],
});

FieldMessageContainer.displayName = 'FieldMessageContainer';

export const FieldMessageContent = styled('div', {
  ...kinds['caption-4'],

  dark: {
    color: '$gray-250',
  },
  light: {
    color: '$gray-450',
  },

  disabled: {
    cursor: 'default',

    dark: {
      color: '$gray-400',
    },
    light: {
      color: '$gray-300',
    },
  },

  '@xsmall': kinds['caption-4'],
  '@medium': kinds['body-4'],

  variants: {
    level: {
      error: {
        dark: {
          color: errorColor.dark,
        },
        light: {
          color: errorColor.light,
        },
      },
      info: {},
      success: {},
    },
  },
});

FieldMessageContent.displayName = 'FieldMessageContent';
