import * as Dropdown from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { Simplify } from 'type-fest';
import { useMediaQuery } from 'usehooks-ts';

import { media } from '../../core/media.js';
import { keyframes } from '../../stitches.config.js';
import { type BoxProps, Box } from '../box/index.js';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-1rem)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeft = keyframes({
  '0%': { opacity: 0, transform: 'translateX(1rem)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideRight = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-1rem)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(1rem)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export type MenuProps = Simplify<
  Dropdown.DropdownMenuProps & {
    align?: Dropdown.DropdownMenuContentProps['align'];
    maxHeight?: string;
    onInteractOutside?: () => void;
    onChange?: Dropdown.DropdownMenuProps['onOpenChange'];
    side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
    trigger: ReactNode;
    zIndex?: BoxProps['zIndex'];
  }
>;

export const contentStyles = {
  animationDuration: '400ms',
  animationTimingFunction: 'ease',
  borderRadius: '$2',
  boxShadow: '$elevation-4',
  minWidth: '15.6rem',
  maxWidth: '15.6rem',
  overflow: 'hidden auto',
  willChange: 'transform, opacity',
  zIndex: '$10',

  '@small': {
    minWidth: '18.6rem',
    maxWidth: '100%',
  },

  '@medium': {
    minWidth: '20.6rem',
  },

  '@large': {
    minWidth: '20.8rem',
  },

  dark: {
    backgroundColor: '$gray-600',
    color: '$brand-white',
    scrollbarColor: 'initial $gray-600',
  },

  light: {
    backgroundColor: '$brand-white',
    color: '$gray-450',
    scrollbarColor: 'initial $gray-200',
  },

  '@motion': {
    '&[data-state:"open"]': {
      '&[data-side:"top"]': { animationName: slideDown },
      '&[data-side:"right"]': { animationName: slideLeft },
      '&[data-side:"bottom"]': { animationName: slideUp },
      '&[data-side:"left"]': { animationName: slideRight },
    },
  },
};

/**
 * @deprecated
 * @link Accessibility: https://www.w3.org/WAI/tutorials/menus/
 * @link Built With: https://www.radix-ui.com/docs/primitives/components/dropdown-menu
 *
 * @remarks The {@link Menu \<Menu \/\>} offers a list of choices to the user, such as a set of
 * actions or functions. A `<Menu />` is usually opened, or made visible, by activating a menu
 * button. When a user activates a choice in a menu, the menu usually closes.
 *
 * @props
 *
 * {@link MenuProps.children children} You can only nest `<Menu.Item />` components as children of a
 * `<Menu />`.
 *
 * {@link MenuProps.onChange onChange} When the menu opens/closes, this handler will fire.
 *
 * {@link MenuProps.trigger trigger} This is the element that will trigger the `<Menu />` to open
 * when clicked.
 *
 * @example
 *
 * ```tsx
 * <Menu
 *   trigger={
       <Button color="gray" kind="tertiary" size="icon">
         <MenuIcon />
       </Button>
     }
 * >
 *   <Menu.Item>Option 1</Menu.Item>
 *   <Menu.Item>
 *     <AddIcon />
 *     Option 2
 *   </Menu.Item>
 *   <Menu.Item>
 *     <AddIcon />
 *     Option 3
 *     <CheckIcon />
 *   </Menu.Item>
 *   <Menu.Item disabled>Option 4</Menu.Item>
 * </Menu>
 * ```
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(
  {
    align,
    children,
    defaultOpen,
    maxHeight = 'initial',
    modal = false,
    onChange,
    onInteractOutside,
    onOpenChange,
    open,
    side = 'top',
    trigger,
    zIndex = '$10',
  }: MenuProps,
  ref,
) {
  const matchesMediumBreakpoint = useMediaQuery(media.medium);

  const alignContent = align ?? (matchesMediumBreakpoint ? 'end' : 'center');

  return (
    <Dropdown.Root
      defaultOpen={defaultOpen}
      modal={modal}
      onOpenChange={onOpenChange ?? onChange}
      open={open}
    >
      <Dropdown.Trigger asChild>{trigger}</Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          align={alignContent}
          asChild
          avoidCollisions
          collisionPadding={5}
          hideWhenDetached
          onInteractOutside={onInteractOutside}
          ref={ref}
          side={side}
          sideOffset={5}
        >
          <Box css={contentStyles} maxHeight={maxHeight} zIndex={zIndex}>
            {children}
          </Box>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
});
