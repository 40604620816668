// Generated by ts-to-zod
import { z } from 'zod';

export const snapchatSchema = z.object({
  pixel: z.object({
    enabled: z.boolean(),
    id: z.string(),
  }),
});

export type Snapchat = z.infer<typeof snapchatSchema>;
