import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PauseFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function PauseFilledIcon(
    {
      'aria-label': ariaLabel = 'Pause Filled Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16C9.45 16 9 15.55 9 15V9C9 8.45 9.45 8 10 8C10.55 8 11 8.45 11 9V15C11 15.55 10.55 16 10 16ZM14 16C13.45 16 13 15.55 13 15V9C13 8.45 13.45 8 14 8C14.55 8 15 8.45 15 9V15C15 15.55 14.55 16 14 16Z" />
      </Icon>
    );
  },
) as typeof Icon;
