import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ChevronUpIcon = forwardRef<SVGSVGElement, IconProps>(
  function ChevronUpIcon(
    {
      'aria-label': ariaLabel = 'Chevron Up Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M8.11997 15.0025L12 11.1225L15.88 15.0025C16.27 15.3925 16.9 15.3925 17.29 15.0025C17.68 14.6125 17.68 13.9825 17.29 13.5925L12.7 9.00246C12.31 8.61246 11.68 8.61246 11.29 9.00246L6.69997 13.5925C6.30997 13.9825 6.30997 14.6125 6.69997 15.0025C7.08997 15.3825 7.72997 15.3925 8.11997 15.0025Z" />
      </Icon>
    );
  },
) as typeof Icon;
