import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RepeatAllIcon = forwardRef<SVGSVGElement, IconProps>(
  function ReorderIcon(
    { 'aria-label': ariaLabel = 'Repeat All Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M7.40337 19.2136V18.002L17.4065 18.002H17.9867C19.0567 18.002 19.7746 17.3452 20.0723 17.0156C20.5098 16.5312 20.5801 16.0736 20.5801 15.2656V8.55129V8.14062C20.5801 7.14062 20.4097 6.74104 19.8397 6.27713C19.1545 5.71946 18.2438 5.67188 17.2968 5.67188H7.4727H6.98638C6.04041 5.70994 5.67389 5.79688 4.95514 6.45312C4.50381 6.86521 4.11138 7.50852 4.11138 8.70312V11.6406V12.0547C4.11138 12.2109 4.13092 12.5156 4.24811 12.6953C4.3653 12.875 4.64191 13.1328 5.15045 13.1328C5.50983 13.1328 5.73117 13.0777 5.94873 12.8828C6.10691 12.7411 6.25041 12.5508 6.25041 12.1608L6.24468 8.55129L6.25041 8.45072C6.27972 8.19149 6.42115 7.95086 6.64713 7.77576C6.87311 7.60067 7.16743 7.50365 7.4727 7.50364H17.2968L17.4147 7.50852C17.7186 7.53353 18.0006 7.65419 18.2059 7.84698C18.4111 8.03977 18.5248 8.29086 18.5248 8.55129V14.2734V14.6177V14.9688C18.5248 14.9967 18.5252 15.0263 18.5256 15.0573C18.5288 15.3339 18.5334 15.7169 18.2989 15.9297C18.0381 16.1663 17.8853 16.1914 17.5801 16.1914H7.40337V15.9702V14.7044C7.40337 14.2639 6.89623 14.0434 6.59963 14.3548L4.45235 16.6094C4.26848 16.8025 4.26848 17.1155 4.45235 17.3086L6.59963 19.5632C6.89623 19.8746 7.40337 19.6541 7.40337 19.2136ZM12.8145 23.502C13.6395 23.502 14.3145 22.827 14.3145 22.002C14.3145 21.177 13.6395 20.502 12.8145 20.502C11.9895 20.502 11.3145 21.177 11.3145 22.002C11.3145 22.827 11.9895 23.502 12.8145 23.502Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
