import { type ReactNode, useState } from 'react';
import { isString } from 'remeda';

import { Button } from '../button/index.js';
import { Flex } from '../flex/index.js';
import { InfoOutlineIcon } from '../icons/info-outline-icon.js';
import { Popover } from '../popover/index.js';
import { Text } from '../text/index.js';

export type FieldHintProps = {
  hint?: ReactNode;
};

export const FieldHint = ({ hint, ...props }: FieldHintProps) => {
  const [open, setOpen] = useState(false);

  const HintComponent =
    isString(hint) ?
      <Flex>
        <Text kind="caption-4">{hint}</Text>
      </Flex>
    : hint;

  return hint ?
      <Popover
        open={open}
        trigger={
          <Button
            color={{ dark: 'gray', light: 'white' }}
            kind="tertiary"
            size="icon"
            {...props}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <InfoOutlineIcon size={16} />
          </Button>
        }
      >
        {HintComponent}
      </Popover>
    : null;
};
