import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { logUnknownProps } from '../../utilities/internal.js';

export interface BackgroundImageProps extends ElementProps<'div'> {
  children?: ReactNode;
  css?: RainbowSprinkles;
  src: string;
}

/**
 * The BackgroundImage component can be used to add any content on image. It is useful for hero headers and other similar sections
 */
export const BackgroundImage = forwardRef<HTMLDivElement, BackgroundImageProps>(
  function BackgroundImage(
    { children, css, src, ...props }: BackgroundImageProps,
    ref,
  ) {
    const result = rainbowSprinkles({
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      width: '100%',
      ...css,
    });

    const className = result.className;
    const style = result.style;
    logUnknownProps(result.otherProps);

    return (
      <div
        className={className}
        role="img"
        style={{ ...style, backgroundImage: `url(${src})` }}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
