import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const AccountSettingsIcon = forwardRef<SVGSVGElement, IconProps>(
  function AccountSettingsIcon(
    {
      'aria-label': ariaLabel = 'Account Settings Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C12.5486 21 13.0859 20.9508 13.6075 20.8566C13.2688 20.0998 13.0605 19.2718 13.0113 18.4013C12.6817 18.4531 12.3439 18.48 12 18.48C9.75 18.48 7.761 17.328 6.6 15.582C6.627 13.791 10.2 12.81 12 12.81C12.7501 12.81 13.8144 12.9821 14.8001 13.3137C16.0812 11.893 17.9363 11 20 11C20.3229 11 20.6406 11.0219 20.9519 11.0642C20.4833 6.53539 16.652 3 12 3ZM12 5.7C13.494 5.7 14.7 6.906 14.7 8.4C14.7 9.894 13.494 11.1 12 11.1C10.506 11.1 9.3 9.894 9.3 8.4C9.3 6.906 10.506 5.7 12 5.7ZM23.0851 18C23.0851 18.136 23.0727 18.264 23.0563 18.392L23.924 19.052C24.0021 19.112 24.0227 19.22 23.9733 19.308L23.1509 20.692C23.1015 20.78 22.9946 20.816 22.9 20.78L21.8761 20.38C21.6622 20.536 21.432 20.672 21.1811 20.772L21.0248 21.832C21.0125 21.928 20.9262 22 20.8234 22H19.1785C19.0757 22 18.9893 21.928 18.977 21.832L18.8207 20.772C18.5699 20.672 18.3396 20.54 18.1257 20.38L17.1018 20.78C17.0113 20.812 16.9003 20.78 16.851 20.692L16.0285 19.308C15.9792 19.22 15.9997 19.112 16.0779 19.052L16.9455 18.392C16.9291 18.264 16.9167 18.132 16.9167 18C16.9167 17.868 16.9291 17.736 16.9455 17.608L16.0779 16.948C15.9997 16.888 15.9751 16.78 16.0285 16.692L16.851 15.308C16.9003 15.22 17.0072 15.184 17.1018 15.22L18.1257 15.62C18.3396 15.464 18.5699 15.328 18.8207 15.228L18.977 14.168C18.9893 14.072 19.0757 14 19.1785 14H20.8234C20.9262 14 21.0125 14.072 21.0248 14.168L21.1811 15.228C21.432 15.328 21.6622 15.46 21.8761 15.62L22.9 15.22C22.9905 15.188 23.1015 15.22 23.1509 15.308L23.9733 16.692C24.0227 16.78 24.0021 16.888 23.924 16.948L23.0563 17.608C23.0727 17.736 23.0851 17.864 23.0851 18ZM18.5616 18C18.5616 18.772 19.2073 19.4 20.0009 19.4C20.7946 19.4 21.4402 18.772 21.4402 18C21.4402 17.228 20.7946 16.6 20.0009 16.6C19.2073 16.6 18.5616 17.228 18.5616 18Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
