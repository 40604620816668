import type { ReactNode } from 'react';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import { type BadgeVariants, badgeStyles } from './badge.css.js';

export type BadgeProps = BadgeVariants & {
  children?: ReactNode;
  css?: RainbowSprinkles;
};

/**
 * The Badge component is used to highlight an item's status for quick recognition.
 */
export function Badge({ children, css, ...props }: BadgeProps) {
  const { className, style, otherProps } = getRecipeStyleProps(
    badgeStyles,
    props,
    css,
  );

  return (
    <div className={className} data-test="badge" style={style} {...otherProps}>
      {children}
    </div>
  );
}
