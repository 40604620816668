import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RefreshIcon = forwardRef<SVGSVGElement, IconProps>(
  function RefreshIcon(
    {
      'aria-label': ariaLabel = 'Refresh Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M7.03856 6.82466L5.23301 5.01911C4.94179 4.72789 4.44995 4.93498 4.45642 5.34268L4.44995 10.2546C4.44995 10.5134 4.65057 10.714 4.90943 10.714L9.81484 10.714C10.2225 10.7076 10.4296 10.2157 10.1384 9.92452L8.33286 8.11896C10.7467 5.70509 14.8691 6.00925 16.8429 9.04439C18.0078 10.8176 18.0013 13.1926 16.8429 14.9594C15.0179 17.7551 11.3745 18.2275 8.92177 16.396C8.56583 16.1307 8.05458 16.1631 7.73748 16.4802C7.34919 16.8685 7.38155 17.5221 7.82161 17.8586C11.0638 20.2984 15.8722 19.6965 18.3378 16.0401C19.9622 13.6262 19.9622 10.3775 18.3378 7.96365C15.6586 4.00308 10.2355 3.62773 7.03856 6.82466Z" />
      </Icon>
    );
  },
) as typeof Icon;
