import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const AddOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function AddOutlineIcon(
    {
      'aria-label': ariaLabel = 'Add Outline Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.2857 12.7143H12.7143V16.2857C12.7143 16.6786 12.3929 17 12 17C11.6071 17 11.2857 16.6786 11.2857 16.2857V12.7143H7.71429C7.32143 12.7143 7 12.3929 7 12C7 11.6071 7.32143 11.2857 7.71429 11.2857H11.2857V7.71429C11.2857 7.32143 11.6071 7 12 7C12.3929 7 12.7143 7.32143 12.7143 7.71429V11.2857H16.2857C16.6786 11.2857 17 11.6071 17 12C17 12.3929 16.6786 12.7143 16.2857 12.7143Z" />
        <path
          clipRule="evenodd"
          d="M12.7143 12.7143H16.2857C16.6786 12.7143 17 12.3929 17 12C17 11.6071 16.6786 11.2857 16.2857 11.2857H12.7143V7.71429C12.7143 7.32143 12.3929 7 12 7C11.6071 7 11.2857 7.32143 11.2857 7.71429V11.2857H7.71429C7.32143 11.2857 7 11.6071 7 12C7 12.3929 7.32143 12.7143 7.71429 12.7143H11.2857V16.2857C11.2857 16.6786 11.6071 17 12 17C12.3929 17 12.7143 16.6786 12.7143 16.2857V12.7143ZM11.0357 12.9643H7.71429C7.18336 12.9643 6.75 12.5309 6.75 12C6.75 11.4691 7.18336 11.0357 7.71429 11.0357H11.0357V7.71429C11.0357 7.18336 11.4691 6.75 12 6.75C12.5309 6.75 12.9643 7.18336 12.9643 7.71429V11.0357H16.2857C16.8166 11.0357 17.25 11.4691 17.25 12C17.25 12.5309 16.8166 12.9643 16.2857 12.9643H12.9643V16.2857C12.9643 16.8166 12.5309 17.25 12 17.25C11.4691 17.25 11.0357 16.8166 11.0357 16.2857V12.9643Z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
