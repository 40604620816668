import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const AppleIcon = forwardRef<SVGSVGElement, IconProps>(
  function AppleIcon(
    {
      'aria-label': ariaLabel = 'Apple Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M20.4489 16.7603C19.9874 17.7938 19.7744 18.2571 19.1709 19.148C18.3544 20.4309 17.1829 21.9989 15.7274 21.9989C14.4494 21.9989 14.0944 21.1436 12.3549 21.1793C10.6154 21.1793 10.2604 22.0345 8.9469 21.9989C7.4914 21.9989 6.3909 20.5734 5.5744 19.2905C3.2669 15.7625 3.0184 11.593 4.4384 9.38349C5.4679 7.81547 7.0654 6.88892 8.5564 6.88892C10.0829 6.88892 11.0414 7.7442 12.3194 7.7442C13.5619 7.7442 14.3074 6.88892 16.0824 6.88892C17.4314 6.88892 18.8514 7.63729 19.8454 8.88458C16.5439 10.7377 17.0764 15.4774 20.4489 16.7603Z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M14.5879 5.28358C15.2123 4.44444 15.698 3.24046 15.5245 2C14.4838 2.07297 13.3044 2.76617 12.5759 3.67827C11.9515 4.48092 11.3964 5.6849 11.6046 6.88888C12.7493 6.88888 13.9288 6.19568 14.5879 5.28358Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
