import type { ComponentProps } from 'react';

import { styled } from '../../stitches.config.js';
import { kinds as textKinds } from '../text/text.js';

export type LinkProps = ComponentProps<typeof Link>;

export const Link = styled('a', {
  '&:focus-visible': {
    outline: '1px solid',
    outlineOffset: '1px',
    borderRadius: '2px',
    textDecoration: 'none',
  },

  hover: {
    cursor: 'pointer',
  },

  pressed: {
    outline: 'none',
  },

  disabled: {
    pointerEvents: 'none',
    textDecoration: 'none',

    dark: { color: '$gray-400' },
    light: { color: '$gray-300' },
  },

  variants: {
    color: {
      inherit: {
        color: 'inherit',
      },
      primary: {
        dark: { color: '$blue-400' },
        light: { color: '$blue-600' },

        focus: {
          dark: { outlineColor: '$blue-400' },
          light: { outlineColor: '$blue-600' },
        },
      },
      secondary: {
        dark: { color: '$gray-250' },
        light: { color: '$gray-450' },
      },
    },
    underline: {
      always: {
        textDecoration: 'underline',
      },
      hover: {
        hover: {
          textDecoration: 'underline',
        },
      },
      none: {
        textDecoration: 'none',
      },
    },
    kind: {
      ...textKinds,
    },
  },
  defaultVariants: {
    color: 'inherit',
    underline: 'always',
  },
});
