import {
  Range as SliderRange,
  Root as SliderRoot,
  Thumb as SliderThumb,
  Track as SliderTrack,
} from '@radix-ui/react-slider';

import { styled } from '../../stitches.config.js';

export const Track = styled(SliderTrack, {
  borderRadius: '$6',
  flexGrow: 1,
  position: 'relative',
  width: '100%',
  overflow: 'hidden',

  '&::before': {
    dark: {
      backgroundColor: '$gray-250',
    },
    light: {
      backgroundColor: '$gray-450',
    },
    borderRadius: '$6',
    content: '""',
    display: 'block',
    height: '100%',
    position: 'absolute',
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    height: '100%',
    width: '0.2rem',
  },

  '&[data-orientation="horizontal"]': {
    height: '0.2rem',
  },

  '@large': {
    '&[data-orientation="vertical"]': {
      width: '0.3rem',
    },

    '&[data-orientation="horizontal"]': {
      height: '0.3rem',
    },
  },

  dark: {
    backgroundColor: '$gray-450',
  },

  light: {
    backgroundColor: '$gray-250',
  },
});

export const Thumb = styled(SliderThumb, {
  all: 'unset',
  backgroundColor: '$red-550',
  borderRadius: '$999',
  boxShadow: '$elevation-1',
  display: 'none',
  height: '1.2rem',
  width: '1.2rem',

  '@large': {
    height: '1.4rem',
    width: '1.4rem',
  },
});

export const Range = styled(SliderRange, {
  backgroundColor: '$red-550',
  borderRadius: '$6',
  height: '100%',
  position: 'absolute',
  maxWidth: '100%',
});

const rangeHoverColors = {
  dark: {
    backgroundColor: '$red-400',
  },

  light: {
    backgroundColor: '$red-400',
  },
};

const rangePressedColors = {
  dark: {
    backgroundColor: '$red-650',
  },

  light: {
    backgroundColor: '$red-650',
  },
};

export const Root = styled(SliderRoot, {
  alignItems: 'center',
  borderRadius: '$6',
  display: 'flex',
  height: '100%',
  position: 'relative',
  touchAction: 'none',
  userSelect: 'none',
  width: '100%',
  padding: '$12 0',

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',

        [`${Track}`]: {
          dark: {
            backgroundColor: '$gray-450',
          },
          light: {
            backgroundColor: '$gray-250',
          },
        },
        [`${Range}`]: {
          dark: {
            backgroundColor: '$gray-400',
          },
          light: {
            backgroundColor: '$gray-300',
          },
        },
      },
      false: {
        cursor: 'pointer',

        hover: {
          [`${Thumb}`]: {
            display: 'block',
          },
          [`${Track}`]: {
            '&::before': {
              borderRadius: '$6',
              maxWidth: '100%',
              width: 'var(--x)',
            },
          },
        },
      },
    },
    pressed: {
      true: {
        [`${Thumb}`]: {
          display: 'block',
        },
      },
      false: {},
    },

    interactive: {
      true: {
        cursor: 'pointer',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
  compoundVariants: [
    {
      disabled: false,
      pressed: true,
      css: {
        [`${Range}`]: {
          ...rangePressedColors,
        },
        [`${Thumb}`]: {
          ...rangePressedColors,
        },
      },
    },
    {
      disabled: false,
      pressed: false,
      css: {
        hover: {
          [`${Range}`]: {
            ...rangeHoverColors,
          },
          [`${Thumb}`]: {
            ...rangeHoverColors,
          },
        },
      },
    },
  ],
});
