import { Content, Root, Trigger } from './tabs.primitive.js';
import { List } from './tabs-list.js';

export const Tabs = Object.assign(Root, {
  Trigger,
  List,
  Content,
}) as typeof Root & {
  Trigger: typeof Trigger;
  List: typeof List;
  Content: typeof Content;
};
