import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CheckboxCheckedIndeterminateIcon = forwardRef<
  SVGSVGElement,
  IconProps
>(function CheckboxCheckedIndeterminateIcon(
  {
    'aria-label': ariaLabel = 'Checkbox Checked Indeterminate Icon',

    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path
        clipRule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H6Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}) as typeof Icon;
