import { debounce } from '@iheartradio/web.utilities';
import {
  type ComponentProps,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isNumber, isPlainObject, isString } from 'remeda';
import smoothscroll from 'smoothscroll-polyfill';
import type { Merge } from 'type-fest';

import { breakpoints } from '../../core/media.js';
import { Button } from '../button/index.js';
import { Flex } from '../flex/index.js';
import { Grid } from '../grid/index.js';
import { ChevronLeftIcon } from '../icons/chevron-left-icon.js';
import { ChevronRightIcon } from '../icons/chevron-right-icon.js';
import { LoadingIcon } from '../icons/loading-icon.js';
import { Text } from '../text/index.js';
import type { SlideProps } from './carousel-slide.js';
import type { SlidesToShow } from './compute-slide-widths.js';
import { computeSlideWidths } from './compute-slide-widths.js';

/**
 * Smooth scrolling behavior is not currently supported in Safari, so we have to polyfill it for the
 * time being. This polyfill is small: https://bundlephobia.com/package/smoothscroll-polyfill@0.4.4.
 * Once https://bugs.webkit.org/show_bug.cgi?id=188043 is resolved, we can remove this.
 */
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

export type CarouselProps = Merge<
  ComponentProps<typeof Flex>,
  {
    buttons?: boolean;
    children: ReactNode;
    loading?: boolean;
    onChange?: (activeIndex: number) => void;
    slidesToScroll?: SlidesToShow;
    slidesToShow?: SlidesToShow;
    title?: ReactNode;
  }
>;

const defaultSlidesToShow: SlidesToShow = {
  '@initial': 3,
  '@xsmall': 4,
  '@medium': 5,
  '@large': 6,
  '@xlarge': 7,
};

/**
 * @link Accessibility: https://www.w3.org/TR/wai-aria-practices/#carousel
 *
 * @remarks The {@link Carousel \<Carousel \/\>} shows a collection of items one grouping at a time.
 * They are also known as "slideshows" or "sliders". Typical uses of carousels include scrolling
 * news headlines, featured articles on home pages, and image galleries.
 *
 * The algorithm for determining the active slide index is as follows:
 *   1. We set the active slide index to `0` and initialize a `new InterscrionObserver()`, which
 *      obeserves when an element enters or leaves from the left side of the carousel.
 *   2. When the carousel container is scrolled, the observer will set the active index equal to the
 *      child index of the element that is currently furthest left in the carousel viewport.
 *   3. We then use the active child to determine how far we should scroll to the left or right
 *      when the previous/next buttons are clicked.
 *   4. After the previous/next buttons are clicked, this will trigger the observer and repeat steps
 *      2-4.
 *
 * @props
 *
 * {@link CarouselProps.buttons buttons} Navigation buttons are optional because there may be some
 * environments where carousels are viewed using keyboards or remotes.
 *
 * {@link CarouselProps.children children} You can only nest <Carousel.Slide /> components as
 * children of the carousel.
 *
 * {@link CarouselProps.loading loading} You can set the loading state while you are waiting for
 * data to load.
 *
 * {@link CarouselProps.onChange onChange} When the active slide updates, this callback will fire.
 *
 * {@link CarouselProps.slidesToScroll slidesToScroll} This is how many slides to scroll at a time
 * when clicking the next/previous buttons.
 *
 * {@link CarouselProps.slidesToShow slidesToShow} This is how many slides to show when displaying
 * the carousel.
 *
 * {@link CarouselProps.title title} This is the text that is displayed in the upper-left corner of
 * the carousel. It can be a string or a React component.
 *
 * @example
 *
 * ```tsx
 * <Carousel
 *   buttons
 *   loading={false}
 *   onChange={console.log}
 *   slidesToScroll={4}
 *   slidesToShow={4}
 *   title="Carousel"
 * >
 *   <Carousel.Slide>1</Carousel.Slide>
 *   <Carousel.Slide>2</Carousel.Slide>
 *   <Carousel.Slide>3</Carousel.Slide>
 *   <Carousel.Slide>4</Carousel.Slide>
 * </Carousel>
 * ```
 */
export const Carousel = ({
  buttons = true,
  children,
  loading = false,
  onChange,
  slidesToShow = defaultSlidesToShow,
  slidesToScroll = slidesToShow ?? 1,
  title,
  ...props
}: CarouselProps) => {
  const [disabled, setDisabled] = useState({ previous: true, next: true });
  const active = useRef<number>(0);
  const track = useRef<HTMLDivElement | null>(null);

  const toScroll = useCallback(
    () =>
      Number(
        isPlainObject(slidesToScroll) ?
          (Object.entries(slidesToScroll)
            .reverse()
            .find(entry => {
              const key = entry[0].slice(1) as keyof typeof breakpoints;
              return window.matchMedia(breakpoints[key]).matches;
            })?.[1] ?? slidesToScroll['@initial'])
        : slidesToScroll,
      ),
    [slidesToScroll],
  );

  useEffect(() => {
    if (!track.current) return;

    track.current.scroll?.({ behavior: 'smooth', left: 0 });
  }, []);

  useEffect(() => {
    if (!track.current) return () => {};

    const debouncedOnChange = debounce(
      (activeIndex: number) => onChange?.(activeIndex),
      500,
    );

    const observer = new IntersectionObserver(
      ([slide]) => {
        if (
          !track.current ||
          !slide.rootBounds ||
          slide.boundingClientRect.x > slide.rootBounds.x
        ) {
          return;
        }

        const children = Array.from(track.current.children);

        active.current =
          children.indexOf(slide.target as HTMLDivElement) +
          (slide.isIntersecting ? 0 : 1);

        debouncedOnChange(active.current);

        setDisabled({
          next: active.current + toScroll() >= children.length,
          previous: active.current === 0,
        });
      },
      { root: track.current, threshold: 0.5 },
    );

    for (const target of Array.from(track.current.children)) {
      observer.observe(target);
    }

    return () => observer.disconnect();
  }, [onChange, toScroll]);

  // TODO: figure out way to add fsp to this use effect ^^

  const navigate = useCallback(
    (direction: -1 | 1) => (event: MouseEvent<HTMLButtonElement>) => {
      if (!track.current) return;

      event.preventDefault();
      event.currentTarget.focus();

      let child = track.current.children[
        active.current + toScroll() * direction
      ] as HTMLDivElement;

      if (child === undefined) {
        child = (
          direction === 1 ?
            track.current.lastElementChild
          : track.current.firstElementChild) as HTMLDivElement;
      }

      const left = child.offsetLeft - track.current.offsetLeft;

      track.current.scroll({ behavior: 'smooth', left });
    },
    [toScroll],
  );

  const styles = useMemo(
    () => ({
      ...computeSlideWidths(slidesToShow),
      overscrollBehaviorX: 'contain',
      scrollbarWidth: 'none',
      scrollBehavior: 'smooth',
      scrollSnapType: 'x mandatory',

      '-ms-overflow-style': 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
        height: 0,
        width: 0,
      },

      focusVisible: {
        outlineWidth: '0.2rem',
        outlineStyle: 'solid',
        outlineOffset: '-0.3rem',
        dark: {
          outlineColor: '$brand-white',
        },
        light: {
          outlineColor: '$brand-black',
        },
      },
    }),
    [slidesToShow],
  );

  const padding = useMemo(() => {
    const isNumeric = isNumber(slidesToShow);
    const hasLarge = !isNumeric && !!slidesToShow['@large'];
    const hasXLarge = !isNumeric && !!slidesToShow['@xlarge'];

    return {
      '@initial': '0 $16',
      '@large': hasLarge ? '0 $32' : undefined,
      '@xlarge': hasXLarge ? '0 $32' : undefined,
    };
  }, [slidesToShow]);

  return (
    <Flex direction="column" gap="$16">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        minHeight="3.2rem"
        padding={padding}
      >
        <Flex alignItems="baseline" gap="$8" width="100%">
          {isString(title) ?
            <Text as="h2" kind={{ '@initial': 'h4', '@large': 'h3' }}>
              {title}
            </Text>
          : title}
        </Flex>
        {buttons ?
          <Flex display={{ '@initial': 'flex', '@touch': 'none' }} gap="$8">
            <Button
              color={{ dark: 'white', light: 'gray' }}
              disabled={disabled.previous}
              kind="primary"
              onClick={navigate(-1)}
              size="icon"
            >
              <ChevronLeftIcon size={24} />
            </Button>
            <Button
              color={{ dark: 'white', light: 'gray' }}
              disabled={disabled.next}
              kind="primary"
              onClick={navigate(1)}
              size="icon"
            >
              <ChevronRightIcon size={24} />
            </Button>
          </Flex>
        : null}
      </Flex>
      <Flex
        pointerEvents={loading ? 'none' : 'initial'}
        position="relative"
        width="100%"
      >
        <Grid
          bottom={0}
          left={0}
          opacity={loading ? 1 : 0}
          placeItems="center"
          pointerEvents={loading ? 'initial' : 'none'}
          position="absolute"
          right={0}
          top={0}
          transition="opacity ease 300ms"
          zIndex="$1"
        >
          <LoadingIcon
            fill={{ dark: 'brand-white', light: 'gray-600' }}
            size={40}
          />
        </Grid>
        <Flex
          {...props}
          css={styles}
          overflowX="scroll"
          overflowY="hidden"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={track}
          tabIndex={0}
          width="100%"
          wrap="nowrap"
        >
          {Children.map(
            children,
            child =>
              isValidElement(child) &&
              cloneElement<SlideProps & { tabIndex: number }>(
                child as ReactElement<SlideProps & { tabIndex: number }>,
                { loading, tabIndex: 0 },
              ),
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
