import type { Features } from '../../schemas/features.js';
import type { PartialGlobalConfig } from '../../schemas/index.js';
import { featuresBase } from './base.js';

export const GlobalFeatures: PartialGlobalConfig<Features> = {
  AU: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
  CA: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
  MX: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
  NZ: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
  US: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
  WW: {
    staging: featuresBase,
    pr: featuresBase,
    production: featuresBase,
  },
};
