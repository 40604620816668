export const fontSizes = {
  6: '0.6rem',
  10: '1.0rem',
  11: '1.1rem',
  12: '1.2rem',
  13: '1.3rem',
  14: '1.4rem',
  15: '1.5rem',
  16: '1.6rem',
  17: '1.7rem',
  18: '1.8rem',
  20: '2.0rem',
  21: '2.1rem',
  24: '2.4rem',
  32: '3.2rem',
  40: '4.0rem',
} as const;
