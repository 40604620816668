import { useMemo } from 'react';
import { isNullish, isPlainObject, mergeDeep } from 'remeda';

import { breakpoints } from '../../core/media.js';
import type { CSSProperties, CSSProperty } from '../../types.js';

const keys = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'animation',
  'animationDelay',
  'animationDirection',
  'animationDuration',
  'animationFillMode',
  'animationIterationCount',
  'animationName',
  'animationPlayState',
  'animationTimingFunction',
  'aspectRatio',
  'background',
  'backgroundAttachment',
  'backgroundColor',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'border',
  'borderBottom',
  'borderColor',
  'borderLeft',
  'borderRadius',
  'borderRight',
  'borderStyle',
  'borderTop',
  'borderWidth',
  'bottom',
  'boxShadow',
  'boxSizing',
  'color',
  'columnGap',
  'cursor',
  'display',
  'fill',
  'filter',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'gap',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnGap',
  'gridGap',
  'gridRow',
  'gridRowGap',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'height',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'left',
  'letterSpacing',
  'lineHeight',
  'listStyle',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'objectFit',
  'opacity',
  'order',
  'outline',
  'overflow',
  'overflowX',
  'overflowY',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'placeContent',
  'placeItems',
  'placeSelf',
  'pointerEvents',
  'position',
  'right',
  'rowGap',
  'scrollbarColor',
  'textAlign',
  'textDecoration',
  'textOverflow',
  'textShadow',
  'textTransform',
  'top',
  'transform',
  'transformOrigin',
  'transition',
  'verticalAlign',
  'visibility',
  'whiteSpace',
  'width',
  'willChange',
  'wordBreak',
  'zIndex',
] as const;

export type PropertyKey = (typeof keys)[number];

export const properties = new Set(keys);

type CSSShorthandProps = {
  [Key in keyof CSSProperties]: CSSProperty<Key>;
};

export function useFormatCss(
  cssShorthandProps: CSSShorthandProps,
  css: CSSProperties = {},
): CSSProperties {
  if (css['@initial']) {
    throw new Error('The "@initial" key is not supported by the "css" prop.');
  }

  const result: CSSProperties = useMemo((): CSSProperties => {
    if (Object.keys(cssShorthandProps).length === 0) {
      return css;
    }

    const result: CSSProperties = {};

    for (const [parentKey, parentValue] of Object.entries(cssShorthandProps)) {
      if (isNullish(parentValue)) continue;

      /**
       * If the shorthand value is a primitive, then we can just assign the key to its value.
       */
      if (!isPlainObject(parentValue)) {
        result[parentKey] = parentValue;
        continue;
      }

      /**
       * If the shorthand value is an object, we loop through each key and invert the parent and
       * child keys.
       */
      for (const [childKey, childValue] of Object.entries(parentValue)) {
        if (isNullish(childValue)) continue;

        /**
         * This is a special check for "@initial" because the "css" prop does not support this in
         * in the same way shorthand props do. We need to take all the properties of the "@initial"
         * object and copy them over to "result".
         */
        if (childKey === '@initial') {
          result[parentKey] = childValue;
          continue;
        }

        const property = result[childKey];

        /**
         * Finally, we invert the child and parent keys and copy the value onto the object if it
         * already exists
         */
        result[childKey] = {
          ...(isPlainObject(property) ? property : {}),
          [parentKey]: childValue,
        };
      }
    }

    return sortCssPropKeys(mergeDeep(css, result) as any) as any;
  }, [css, cssShorthandProps]);

  return result as unknown as CSSProperties;
}

/**
 * Converts the specified breakpoints into an ordered array of strings as they will be used in
 * the style definitions:
 * ['@xsmall', '@small', '@medium', '@large', '@xlarge']
 */
const mediaQueryKeys = Object.keys(breakpoints).map(key => `@${key}`);

/**
 *  Stitches breakpoint handling is incorrect if the style breakpoint keys
 * (`@xsmall`, `@small`, etc.) are not in the proper order.
 *
 * This is needed to sort the keys in the style definition objects so that
 * - breakpoint keys are in smallest (xsmall) to largest (xlarge) order and are last in the object
 * - the rest of the keys are in alphabetical order and precede any breakpoint keys
 */
export const sortCssPropKeys = (
  cssProperties: CSSProperties,
): CSSProperties => {
  return Object.fromEntries(
    Object.entries(cssProperties).sort(([a], [b]) => {
      if (a.startsWith('@') && !b.startsWith('@')) {
        // a should come after b - it is a breakpoint definition
        return 1;
      } else if (!a.startsWith('@') && b.startsWith('@')) {
        // b should come after a - it is a breakpoint definition
        return -1;
      } else if (a.startsWith('@') && b.startsWith('@')) {
        // mediaQueryKeys should already be in the proper order (xsmall..xlarge) so we can sort
        // by finding the index of the key in the mediaQueryKeys array
        return mediaQueryKeys.indexOf(a) - mediaQueryKeys.indexOf(b);
      }

      // Sort non-breakpoint css keys alphabetically
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    }),
  );
};
