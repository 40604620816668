import { isEmpty } from 'remeda';

export function joinClassnames(classNames: (string | undefined | null)[]) {
  return classNames.filter(Boolean).join(' ');
}

export function logUnknownProps(
  props: Record<string, unknown>,
  componentName?: string,
) {
  if (isEmpty(props)) return;

  console.warn(
    `The following props are not handled by "rainbowSprinkles()"${
      componentName ? 'component: ' + componentName : ''
    }:`,
    props,
  );
}
