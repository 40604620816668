import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ShuffleIcon = forwardRef<SVGSVGElement, IconProps>(
  function ShuffleIcon(
    { 'aria-label': ariaLabel = 'Shuffle Off Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M17.9458 10.6353L20.2261 8.35489C20.4214 8.15963 20.4214 7.84305 20.2261 7.64779L17.9458 5.36742C17.6308 5.05244 17.0922 5.27552 17.0922 5.72097V7.05495C15.6255 7.2058 13.086 8.03959 10.7978 11.4718C8.65782 14.6818 5.45696 15.0265 4.62988 15.0265C4.0776 15.0265 3.62988 15.4742 3.62988 16.0265C3.62988 16.5788 4.0776 17.0265 4.62988 17.0265C5.80281 17.0265 9.80195 16.5712 12.4619 12.5812C14.254 9.89315 16.096 9.22557 17.0922 9.0705V10.2817C17.0922 10.7272 17.6308 10.9502 17.9458 10.6353Z" />
        <path d="M10.2386 9.89181C7.99473 7.41547 5.73374 7.02661 4.62988 7.02661C4.0776 7.02661 3.62988 7.47433 3.62988 8.02661C3.62988 8.5789 4.0776 9.02661 4.62988 9.02661C5.23888 9.02661 7.06461 9.22351 8.98864 11.5001L10.2386 9.89181Z" />
        <path d="M12.3431 15.2322L13.633 13.6908C14.9637 14.6013 16.2962 14.9036 17.0922 14.993V13.7211C17.0922 13.2756 17.6308 13.0526 17.9458 13.3675L20.2261 15.6479C20.4214 15.8432 20.4214 16.1598 20.2261 16.355L17.9458 18.6354C17.6308 18.9504 17.0922 18.7273 17.0922 18.2818V17.0016C16.0343 16.9108 14.1654 16.5323 12.3431 15.2322Z" />
      </Icon>
    );
  },
) as typeof Icon;
