import { Root } from '@radix-ui/react-checkbox';

import { styled } from '../../stitches.config.js';
import { CheckboxCheckedIcon as CheckboxChecked } from '../icons/checkbox-checked-icon.js';
import { CheckboxIcon as CheckboxUnchecked } from '../icons/checkbox-icon.js';
import { kinds } from '../text/text.js';

export const Label = styled('label', {
  cursor: 'pointer',
  padding: '0 $12 0 $4',

  dark: {
    color: '$gray-200',
  },

  light: {
    color: '$gray-500',
  },

  '@small': kinds['body-4'],
  '@large': kinds['body-3'],
});

export const CheckboxCheckedIcon = styled(CheckboxChecked, {
  display: 'inline',
  /**
   * The classnames Stitches generates for the checked/unchecked icons are the exact same which
   * causes issues. This is included as a way to have Stitches generate unique classnames for each
   * icon but has no other effect.
   */
  state: 'checked',
});

export const CheckboxUncheckedIcon = styled(CheckboxUnchecked, {
  display: 'inline',
  /**
   * The classnames Stitches generates for the checked/unchecked icons are the exact same which
   * causes issues. This is included as a way to have Stitches generate unique classnames for each
   * icon but has no other effect.
   */
  state: 'unchecked',
});

export const Checkbox = styled(Root, {
  borderRadius: '$999',
  display: 'flex',
  padding: '$4',
  transition: 'all 200ms ease 0s',

  pressed: {
    transform: 'scale(0.95)',
  },

  '&[data-state="checked"]': {
    [`& ${CheckboxCheckedIcon}`]: {
      display: 'block',
    },
    [`& ${CheckboxUncheckedIcon}`]: {
      display: 'none',
    },
  },

  '&[data-state="unchecked"]': {
    [`& ${CheckboxCheckedIcon}`]: {
      display: 'none',
    },
    [`& ${CheckboxUncheckedIcon}`]: {
      display: 'block',
    },
  },

  focus: {
    [`${CheckboxUncheckedIcon}, ${CheckboxCheckedIcon}`]: {
      dark: {
        fill: '$blue-400',
      },

      light: {
        fill: '$blue-500',
      },
    },
  },

  hover: {
    dark: {
      backgroundColor: '$gray-450',
    },
    light: {
      backgroundColor: '$gray-250',
    },
  },

  disabled: {
    backgroundColor: 'none',
    pointerEvents: 'none',

    [`& + ${Label}`]: {
      pointerEvents: 'none',

      dark: {
        color: '$gray-400',
      },

      light: {
        color: '$gray-300',
      },
    },

    [`${CheckboxUncheckedIcon}, ${CheckboxCheckedIcon}`]: {
      dark: {
        fill: '$gray-400',
      },

      light: {
        fill: '$gray-300',
      },
    },
  },
});
