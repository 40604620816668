import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CancelFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function CancelFilledIcon(
    {
      'aria-label': ariaLabel = 'Cancel Filled Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12 1.5C6.1935 1.5 1.5 6.1935 1.5 12C1.5 17.8065 6.1935 22.5 12 22.5C17.8065 22.5 22.5 17.8065 22.5 12C22.5 6.1935 17.8065 1.5 12 1.5ZM16.515 16.515C16.1055 16.9245 15.444 16.9245 15.0345 16.515L12 13.4805L8.9655 16.515C8.556 16.9245 7.8945 16.9245 7.485 16.515C7.0755 16.1055 7.0755 15.444 7.485 15.0345L10.5195 12L7.485 8.9655C7.0755 8.556 7.0755 7.8945 7.485 7.485C7.8945 7.0755 8.556 7.0755 8.9655 7.485L12 10.5195L15.0345 7.485C15.444 7.0755 16.1055 7.0755 16.515 7.485C16.9245 7.8945 16.9245 8.556 16.515 8.9655L13.4805 12L16.515 15.0345C16.914 15.4335 16.914 16.1055 16.515 16.515Z" />
      </Icon>
    );
  },
) as typeof Icon;
