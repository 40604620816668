// Generated by ts-to-zod
import { z } from 'zod';

export const adobeAnalyticsSchema = z.object({
  account: z.string(),
  dtmUrl: z.string(),
  enabled: z.boolean(),
  secureTrackingServer: z.string(),
  threshold: z.number(),
  trackingServer: z.string(),
  visitorNamespace: z.string(),
});

export type AdobeAnalytics = z.infer<typeof adobeAnalyticsSchema>;
