import { validation } from '@iheartradio/web.config';
import { isZeroLengthString } from '@iheartradio/web.utilities';
import { useState } from 'react';

export type PasswordValidationKey = keyof (typeof validation)['password'];
export type PasswordValidationResults = Record<
  PasswordValidationKey,
  boolean | null
>;

export default function usePasswordValidation() {
  const [passwordValidationResults, setPasswordValidationResults] =
    useState<PasswordValidationResults>({
      lengthTest: null,
      caseTest: null,
      digitTest: null,
    });

  const doPasswordChecks = (password: string) => {
    const results: PasswordValidationResults = {
      lengthTest: null,
      caseTest: null,
      digitTest: null,
    };
    for (const check of Object.keys(validation.password)) {
      if (!isZeroLengthString(password)) {
        results[check as PasswordValidationKey] =
          validation.password[check as PasswordValidationKey].safeParse(
            password,
          ).success;
      }
    }
    setPasswordValidationResults(results);
  };

  return { passwordValidationResults, doPasswordChecks };
}
