import { isPlainObject } from 'remeda';

import type { CSSPropertyValue } from '../types.js';

export function makeImportant(property: CSSPropertyValue<unknown>) {
  if (isPlainObject(property)) {
    return Object.entries(property).reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key]: `${String(value)} !important`,
      }),
      {},
    );
  }

  return `${String(property)} !important`;
}
