import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SearchIcon = forwardRef<SVGSVGElement, IconProps>(
  function SearchIcon(
    {
      'aria-label': ariaLabel = 'Search Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M14.2761 16.1655C13.1684 16.8176 11.8807 17.191 10.5067 17.191C6.36088 17.191 3 13.7904 3 9.59552C3 5.40063 6.36088 2 10.5067 2C14.6526 2 18.0135 5.40063 18.0135 9.59552C18.0135 11.5096 17.3137 13.2583 16.159 14.5941L20.7062 19.962C21.1448 20.4797 21.0855 21.259 20.5739 21.7028C20.0623 22.1465 19.292 22.0865 18.8534 21.5689L14.2761 16.1655ZM15.0852 9.59552C15.0852 12.154 13.0353 14.2281 10.5067 14.2281C7.97816 14.2281 5.92833 12.154 5.92833 9.59552C5.92833 7.03702 7.97816 4.96296 10.5067 4.96296C13.0353 4.96296 15.0852 7.03702 15.0852 9.59552Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
