import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CheckIcon = forwardRef<SVGSVGElement, IconProps>(
  function CheckIcon(
    {
      'aria-label': ariaLabel = 'Checkmark Icon',
      children: _children,
      ...props
    },
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M9.15975 16.7251L5.39025 12.9556C4.98075 12.5461 4.98075 11.8846 5.39025 11.4751C5.79975 11.0656 6.46126 11.0656 6.87076 11.4751L9.90525 14.4991L17.1293 7.27514C17.5388 6.86564 18.2003 6.86564 18.6098 7.27514C19.0193 7.68464 19.0193 8.34614 18.6098 8.75564L10.6403 16.7251C10.2413 17.1346 9.56925 17.1346 9.15975 16.7251Z" />
      </Icon>
    );
  },
) as typeof Icon;
