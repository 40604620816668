import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ErrorIcon = forwardRef<SVGSVGElement, IconProps>(
  function ErrorIcon(
    {
      'aria-label': ariaLabel = 'Error Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M10.75 12C10.75 12.6875 11.3125 13.25 12 13.25C12.6875 13.25 13.25 12.6875 13.25 12V7C13.25 6.3125 12.6875 5.75 12 5.75C11.3125 5.75 10.75 6.3125 10.75 7V12Z" />
        <path d="M10.75 17C10.75 17.6904 11.3096 18.25 12 18.25C12.6904 18.25 13.25 17.6904 13.25 17C13.25 16.3096 12.6904 15.75 12 15.75C11.3096 15.75 10.75 16.3096 10.75 17Z" />
      </Icon>
    );
  },
) as typeof Icon;
