import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const EqualizerIcon = forwardRef<SVGSVGElement, IconProps>(
  function EqualizerIcon(
    {
      'aria-label': ariaLabel = 'Equalizer Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12 21C13.2375 21 14.25 19.9875 14.25 18.75V5.25C14.25 4.0125 13.2375 3 12 3C10.7625 3 9.75 4.0125 9.75 5.25V18.75C9.75 19.9875 10.7625 21 12 21ZM5.25 21C6.4875 21 7.5 19.9875 7.5 18.75V14.25C7.5 13.0125 6.4875 12 5.25 12C4.0125 12 3 13.0125 3 14.25V18.75C3 19.9875 4.0125 21 5.25 21ZM16.5 10.875V18.75C16.5 19.9875 17.5125 21 18.75 21C19.9875 21 21 19.9875 21 18.75V10.875C21 9.6375 19.9875 8.625 18.75 8.625C17.5125 8.625 16.5 9.6375 16.5 10.875Z" />
      </Icon>
    );
  },
) as typeof Icon;
