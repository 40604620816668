import { forwardRef, useEffect } from 'react';

import { media } from '../../core/index.js';
import { type BoxProps, Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { ChevronDownIcon } from '../icons/chevron-down-icon.js';
import { ChevronUpIcon } from '../icons/chevron-up-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';
import { useFullScreen } from './player-root.js';

export const PlayerExpand = forwardRef<HTMLButtonElement, BoxProps>(
  function PlayerExpand({ ...props }, ref) {
    const [isFullScreen, setIsFullScreen] = useFullScreen();

    useEffect(() => {
      function toggle(event: globalThis.KeyboardEvent) {
        if (!isFullScreen && event.key === 'F' && event.shiftKey) {
          setIsFullScreen(true);
        } else if (isFullScreen && event.key === 'Escape') {
          setIsFullScreen(false);
        }
      }

      window.addEventListener('keyup', toggle);

      return function cleanup() {
        window.removeEventListener('keyup', toggle);
      };
    }, [isFullScreen, setIsFullScreen]);

    return (
      <Box
        css={{
          display: 'none',

          [`@container ${media.medium}`]: { display: 'block' },

          // We want to use this button in the FSP, but don't want it rendered if inside of Player.Actions
          '[data-container="actions"] &': {
            '[data-player-type="fullscreen"] &': {
              display: 'none',
            },
          },
        }}
        gridArea="expand"
        padding="$0 $8"
        {...props}
      >
        <Tooltip
          side="top"
          trigger={
            <Button
              color={{ dark: 'white', light: 'gray' }}
              kind="tertiary"
              onClick={() => setIsFullScreen(!isFullScreen)}
              ref={ref}
              size="icon"
            >
              {isFullScreen ?
                <ChevronDownIcon size={32} />
              : <ChevronUpIcon size={32} />}
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            {isFullScreen ? 'Minimize' : 'Expand'}
          </Text>
        </Tooltip>
      </Box>
    );
  },
);

PlayerExpand.displayName = 'Player.Expand';
