import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LyricsIcon = forwardRef<SVGSVGElement, IconProps>(
  function LyricsIcon(
    {
      'aria-label': ariaLabel = 'Lyrics Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M7.08947 11.0329V5.33324C7.08947 4.59996 7.70304 4 8.45296 4H9.81646C10.5664 4 11.18 4.59996 11.18 5.33324C11.18 6.06653 10.5664 6.66648 9.81646 6.66648H8.45296V13.2327C8.45296 14.5593 7.50533 15.7592 6.16911 15.9658C4.30794 16.2525 2.72628 14.6926 3.03989 12.8661C3.20351 11.9328 3.90571 11.1395 4.81925 10.8195C5.6578 10.5329 6.44863 10.6729 7.08947 11.0329ZM12.1815 10C11.6292 10 11.1815 10.4477 11.1815 11C11.1815 11.5523 11.6292 12 12.1815 12H20C20.5523 12 21 11.5523 21 11C21 10.4477 20.5523 10 20 10H12.1815ZM12.1815 14C11.6292 14 11.1815 14.4477 11.1815 15C11.1815 15.5523 11.6292 16 12.1815 16H20C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14H12.1815ZM6.06807 19C6.06807 18.4477 6.51579 18 7.06808 18H19.9994C20.5517 18 20.9994 18.4477 20.9994 19C20.9994 19.5523 20.5517 20 19.9994 20H7.06807C6.51579 20 6.06807 19.5523 6.06807 19Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
