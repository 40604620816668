import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RemoveIcon = forwardRef<SVGSVGElement, IconProps>(
  function RemoveIcon(
    {
      'aria-label': ariaLabel = 'Remove Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
