import { type ReactNode, useCallback, useEffect } from 'react';
import {
  type ToastOptions as ReactHotToastOptions,
  toast,
  useToasterStore,
} from 'react-hot-toast';
import { isFunction } from 'remeda';

import { Box } from '../box/index.js';

function ToastContainer({ children }: { children: ReactNode }) {
  return (
    <Box
      maxWidth="100vw"
      width={{
        '@initial': '30rem',
        '@medium': '50rem',
      }}
    >
      {children}
    </Box>
  );
}

export type ToastOptions = Pick<ReactHotToastOptions, 'duration' | 'position'>;

export function useToast({ maxToasts = 3 }: { maxToasts?: number } = {}) {
  const { toasts } = useToasterStore();

  useEffect(() => {
    if (toasts.length > maxToasts) {
      toast.dismiss(toasts.at(-1)?.id);
    }
  }, [toasts, maxToasts]);

  const openFunction = useCallback(
    (
      content: ReactNode | ((close: () => void) => ReactNode),
      options?: ToastOptions,
    ) => {
      const id = toast.custom(
        isFunction(content) ?
          t => (
            <ToastContainer>{content(() => toast.remove(t.id))}</ToastContainer>
          )
        : <ToastContainer>{content}</ToastContainer>,
        { duration: 5000, ...options },
      );

      return function close() {
        toast.remove(id);
      };
    },
    [],
  );

  return openFunction;
}
