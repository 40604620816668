import { type MouseEvent, type ReactNode, forwardRef } from 'react';
import type { SetOptional } from 'type-fest';

import { styled } from '../../stitches.config.js';
import type { Component } from '../../types.js';
import { type BoxProps, Box } from '../box/index.js';
import { Flex } from '../flex/index.js';
import { type ImageProps, Image as Image_ } from '../image/index.js';
import {
  type LinkProps as CompanionLinkProps,
  Link as CompanionLink,
} from '../link/index.js';
import { type TextProps, Text } from '../text/index.js';

export type CardImageProps = ImageProps;

export const ImageOverlay = styled(Box, {
  backgroundColor: '$brand-black',
  inset: 0,
  opacity: 0,
  position: 'absolute',
  transition: 'opacity ease-in 200ms',
  zIndex: '$8',

  '& ~ button': {
    transition: 'visibility ease 100ms',
    zIndex: '$10',
  },
});

ImageOverlay.displayName = 'Card.ImageOverlay';

export const Image = forwardRef<HTMLImageElement, CardImageProps>(
  (props: CardImageProps, ref) => {
    return (
      <>
        <ImageOverlay
          aria-hidden="true"
          borderRadius={props.borderRadius ?? 'inherit'}
          pointerEvents="none"
        />
        <Image_
          aspectRatio={1}
          backgroundColor={{ dark: '$gray-600', light: '$brand-white' }}
          borderRadius="inherit"
          boxShadow="$elevation-1"
          fill="fit"
          height="auto"
          maxWidth="100%"
          overflow="hidden"
          zIndex="$0"
          {...props}
          className="card-image"
          ref={ref}
        />
      </>
    );
  },
);

Image.toString = () => '.card-image';
Image.displayName = 'Card.Image';

export const ImageContainer = styled(Box, {
  display: 'inline-flex',
  flexGrow: 0,
  flexShrink: 0,
  position: 'relative',
});

ImageContainer.displayName = 'Image.Container';

export type ImageButtonProps = BoxProps & {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const ImageButton = forwardRef<HTMLDivElement, ImageButtonProps>(
  (props: ImageButtonProps, ref) => {
    const { css, children, onClick, ...restProps } = props;

    return (
      <Box
        {...restProps}
        className="card-image-button"
        css={{
          position: 'absolute',
          right: '$4',
          bottom: '$4',

          '@xsmall': {
            display: 'none',
          },

          '@large': {
            display: 'block',
          },

          '& button': { zIndex: '$10', boxShadow: '$elevation-1' },

          ...css,
        }}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

ImageButton.toString = () => '.card-image-button';
ImageButton.displayName = 'Card.ImageButton';

export const Body = styled(Flex, {
  flexDirection: 'column',
  gap: '$4',
});

Body.displayName = 'Card.Body';

export const Actions = styled(Flex, {
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '$8',
  justifyContent: 'flex-end',
});

Actions.displayName = 'Card.Actions';

export type CardTitleProps = SetOptional<TextProps, 'kind'>;

export const Title = forwardRef<HTMLParagraphElement, CardTitleProps>(
  (props: CardTitleProps, ref) => {
    return (
      <Text
        color={{ dark: '$brand-white', light: '$gray-600' }}
        lines={1}
        {...props}
        as="p"
        kind={{ '@initial': 'subtitle-4', '@large': 'subtitle-2' }}
        ref={ref}
      />
    );
  },
);

Title.displayName = 'Card.Title';

export type CardSubtitleProps = SetOptional<TextProps, 'kind'>;

export const Subtitle = forwardRef<HTMLParagraphElement, CardSubtitleProps>(
  (props: CardSubtitleProps, ref) => {
    return (
      <Text
        color={{ dark: '$gray-200', light: '$gray-450' }}
        lines={2}
        {...props}
        as="p"
        kind={{ '@initial': 'caption-4', '@large': 'caption-2' }}
        ref={ref}
      />
    );
  },
);

Subtitle.displayName = 'Card.Subtitle';

export const Root = styled(Flex, {
  alignItems: 'stretch',
  borderRadius: '$6',
  height: '100%',
  transition: 'all ease 300ms',
  width: '100%',

  focusVisible: {
    transition: 'none',
    outlineWidth: '0.2rem',
    outlineStyle: 'solid',
    outlineOffset: '-0.2rem',
    dark: {
      outlineColor: '$brand-white',
    },
    light: {
      outlineColor: '$brand-black',
    },
  },

  [`& ${Image}`]: {
    transition: 'filter ease 300ms',
  },

  '&:hover, &:focus, &:focus-visible': {
    dark: { '@large': { backgroundColor: '$gray-500' } },
    light: { '@large': { backgroundColor: '$gray-200' } },

    '@large': {
      boxShadow: '$elevation-1',
    },

    [`&  ${ImageOverlay}`]: {
      opacity: '0.2',
    },
  },

  '@touch': {
    pressed: {
      img: {
        filter: 'brightness(0.8)',
      },

      [`&  ${ImageOverlay}`]: {
        opacity: '0.2',
      },
    },
  },

  variants: {
    isActive: {
      true: {
        [`& ${Image}`]: {
          filter: 'brightness(0.8)',
        },

        [`& ${ImageButton}`]: {
          visibility: 'visible',
        },

        [`&  ${ImageOverlay}`]: {
          opacity: '0.2',
        },
      },

      false: {
        [`& ${Image}`]: {
          filter: 'brightness(1)',
        },

        [`& ${ImageButton}`]: {
          visibility: 'hidden',
        },

        '@large': {
          [`&:hover ${ImageButton}`]: {
            visibility: 'visible',
          },
        },
      },
    },

    direction: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
        gap: '$12',
      },
    },

    isLink: {
      true: {
        cursor: 'pointer',
      },

      false: {},
    },

    withBackground: {
      true: {
        boxShadow: '$elevation-1',

        dark: {
          backgroundColor: '$gray-600',
        },

        light: {
          backgroundColor: '$brand-white',
        },
      },

      false: {},
    },
  },

  defaultVariants: {
    isActive: false,
    direction: 'vertical',
    isLink: false,
    withBackground: false,
  },
});

Root.displayName = 'Card.Root';

export type LinkProps = CompanionLinkProps & {
  children: ReactNode;
  target?: '_blank' | '_self';
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { children, target = '_self', ...props }: LinkProps,
  ref,
) {
  return (
    <CompanionLink
      css={{
        '&:focus': { outline: 'none' },

        [`&:focus ${Root}`]: {
          outlineWidth: '0.2rem',
          outlineStyle: 'solid',
          outlineOffset: '-0.2rem',
          transition: 'none',

          dark: {
            backgroundColor: '$gray-500',
            outlineColor: '$brand-white',
          },

          light: {
            backgroundColor: '$gray-200',
            outlineColor: '$brand-black',
          },
        },
      }}
      target={target}
      {...props}
      ref={ref}
      underline="none"
    >
      {children}
    </CompanionLink>
  );
}) as Component<'a', LinkProps>;
