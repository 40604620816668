import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const TrendingDownIcon = forwardRef<SVGSVGElement, IconProps>(
  function TrendingDownIcon(
    {
      'aria-label': ariaLabel = 'Trending Down Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.7953 17.8056L18.2815 16.1159L13.245 10.3895L9.84952 14.2501C9.44701 14.7078 8.79681 14.7078 8.3943 14.2501L2.2019 7.19774C1.7994 6.74009 1.7994 6.00082 2.2019 5.54318C2.60441 5.08554 3.25461 5.08554 3.65712 5.54318L9.11675 11.7624L12.5122 7.9018C12.9148 7.44416 13.565 7.44416 13.9675 7.9018L19.7367 14.4496L21.2229 12.7598C21.5428 12.3961 22.1001 12.6542 22.1001 13.1706V18.2046C22.1001 18.5332 21.8731 18.7913 21.5841 18.7913H17.1565C16.7024 18.8031 16.4754 18.1694 16.7953 17.8056Z" />
      </Icon>
    );
  },
) as typeof Icon;
