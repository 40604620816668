import type { Facebook } from '../../../schemas/sdks/facebook.js';

export const stagingAU: Facebook = {
  appId: '214588375268980',
  pages: '55354819171',
  pixelEnabled: false,
  pixelId: '1126589700832689',
  threshold: 100,
};

export const productionAU: Facebook = {
  appId: '121897277851831',
  pages: '55354819171',
  pixelEnabled: false,
  pixelId: '1126589700832689',
  threshold: 100,
};
