import type { ComponentProps } from '@stitches/react';
import { type ButtonHTMLAttributes, type ReactNode, forwardRef } from 'react';
import { isPlainObject } from 'remeda';
import type { Simplify } from 'type-fest';

import type { Component, CSSProperties, VariantProps } from '../../types.js';
import { useTheme } from '../theme/index.js';
import { Button as Primitive } from './button.primitive.js';

type Color = VariantProps<typeof Primitive>['color'];

export type ButtonProps = Simplify<{
  children: ReactNode;
  color?: Color | { dark: Color; light: Color };
  css?: CSSProperties;
  disabled?: boolean;
  inline?: VariantProps<typeof Primitive>['inline'];
  onClick?: ComponentProps<typeof Primitive>['onClick'];
  kind?: VariantProps<typeof Primitive>['kind'];
  size?: VariantProps<typeof Primitive>['size'];
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      color,
      disabled = false,
      inline = true,
      type = 'button',
      ...props
    }: ButtonProps,
    ref,
  ) {
    const theme = useTheme();

    return (
      <Primitive
        {...props}
        aria-disabled={disabled}
        color={isPlainObject(color) ? color[theme] : color}
        disabled={disabled}
        inline={inline}
        ref={ref}
        type={type}
      >
        {children}
      </Primitive>
    );
  },
) as Component<'button', ButtonProps>;
