export const colors = {
  'brand-black': '#000000',
  'brand-gray': '#939697',
  'brand-red': '#C6002B',
  'brand-white': '#FFFFFF',
  'blue-100': '#9ADAFF',
  'blue-150': '#84D0FC',
  'blue-200': '#75C8F7',
  'blue-250': '#5FBBF0',
  'blue-300': '#51B2EA',
  'blue-350': '#42AAE6',
  'blue-400': '#3CA2DD',
  'blue-450': '#2A97D6',
  'blue-500': '#288BD3',
  'blue-550': '#2880D3',
  'blue-600': '#0055B7',
  'gray-100': '#F6F8F9',
  'gray-150': '#EDF1F3',
  'gray-200': '#E6EAED',
  'gray-250': '#DADFE3',
  'gray-300': '#A9AFB2',
  'gray-350': '#96979F',
  'gray-400': '#717277',
  'gray-450': '#55565B',
  'gray-500': '#3F4447',
  'gray-550': '#2D3134',
  'gray-600': '#27292D',
  'green-100': '#ACE7C0',
  'green-150': '#A6E4BB',
  'green-200': '#9CE2B4',
  'green-250': '#90E1AB',
  'green-300': '#8BDEA7',
  'green-350': '#80DB9F',
  'green-400': '#78D297',
  'green-450': '#6CC58B',
  'green-500': '#62AA7B',
  'green-550': '#519769',
  'green-600': '#46815A',
  'magenta-100': '#FCEBF5',
  'magenta-150': '#FBD7ED',
  'magenta-200': '#F9C4E4',
  'magenta-250': '#F7B0DC',
  'magenta-300': '#F59CD4',
  'magenta-350': '#F389CB',
  'magenta-400': '#F175C3',
  'magenta-450': '#F061BB',
  'magenta-500': '#EE4EB3',
  'magenta-550': '#ED39AB',
  'magenta-600': '#EC26A3',
  'magenta-650': '#C80E83',
  'orange-100': '#FBC8B3',
  'orange-150': '#F9B699',
  'orange-200': '#F8A480',
  'orange-250': '#F69166',
  'orange-300': '#F69166',
  'orange-350': '#F36D33',
  'orange-400': '#F65D1C',
  'orange-450': '#F84E1B',
  'orange-500': '#F14510',
  'orange-550': '#E44311',
  'orange-600': '#D03E11',
  'orange-650': '#A82700',
  'purple-100': '#C7A3F5',
  'purple-150': '#C19BF2',
  'purple-200': '#B895F2',
  'purple-250': '#AC8EEC',
  'purple-300': '#A586E5',
  'purple-350': '#9880DF',
  'purple-400': '#A071DC',
  'purple-450': '#9664D5',
  'purple-500': '#8D5BCD',
  'purple-550': '#8043BD',
  'purple-600': '#793CB7',
  'purple-650': '#6117AB',
  'red-100': '#F4ADB1',
  'red-150': '#F4A0A0',
  'red-200': '#F79096',
  'red-250': '#F9838A',
  'red-300': '#F4747C',
  'red-350': '#F94E5C',
  'red-400': '#E22C3A',
  'red-450': '#D62836',
  'red-500': '#D2252A',
  'red-550': '#CC032E',
  'red-600': '#C6002B',
  'red-650': '#B30027',
  'teal-100': '#A3E2EB',
  'teal-150': '#8EDFEA',
  'teal-200': '#84DAE5',
  'teal-250': '#77D4E0',
  'teal-300': '#68C8D5',
  'teal-350': '#5FC0CD',
  'teal-400': '#60BAC6',
  'teal-450': '#57B1BC',
  'teal-500': '#509DA7',
  'teal-550': '#3E919C',
  'teal-600': '#267883',
  'teal-650': '#186B76',
  transparent: 'transparent',
  'yellow-100': '#FFF8E9',
  'yellow-150': '#FEF2D4',
  'yellow-200': '#FEECBF',
  'yellow-250': '#FEE5AA',
  'yellow-300': '#FDDF96',
  'yellow-350': '#FDD982',
  'yellow-400': '#FDD36F',
  'yellow-450': '#FDCC5D',
  'yellow-500': '#FDC64C',
  'yellow-550': '#FDC040',
  'yellow-600': '#FCBA38',
  'yellow-650': '#CC8838',
  'gradient-bright-blue':
    'linear-gradient(147.65deg, #0055B7 0%, #2880D3 100%)',
  'gradient-evening-sky':
    'linear-gradient(147.65deg, #2A97D6 9.1%, #793CB7 99.36%)',
  'gradient-fog': 'linear-gradient(153.68deg, #F6F8F9 0%, #E6EAED 100%)',
  'gradient-key-lime': 'linear-gradient(153.68deg, #8BDEA7 0%, #60BAC6 100%)',
  'gradient-purple': 'linear-gradient(119.4deg, #793C8A 0%, #8071AF 91.41%)',
  'gradient-steel': 'linear-gradient(153.68deg, #C5CDD2 0%, #717277 100%)',
  'gradient-sunrise': 'linear-gradient(153.87deg, #F9B699 0%, #E22C3A 100%)',
  'gradient-sunset': 'linear-gradient(153.87deg, #8B6AE9 0%, #F4747C 100%)',
  'gradient-sunset-reversed':
    'linear-gradient(154.6deg, #F94E5C -27.55%, #793CB7 125.12%)',
  'gradient-plus-horizontal':
    'linear-gradient(134.77deg, #6117AB 21.2%, #0055B7 99.45%);',
  'gradient-all-access-horizontal':
    'linear-gradient(153.87deg, #C80E83 0.84%, #6117AB 100%);',
  'gradient-plus-dark-vertical':
    'linear-gradient(180deg, #AC8EEC 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  'gradient-all-access-dark-vertical':
    'linear-gradient(180deg, #F59CD4 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  'gradient-plus-light-vertical':
    'linear-gradient(180deg, #6117AB 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  'gradient-all-access-light-vertical':
    'linear-gradient(180deg, rgba(200, 14, 131, 0.50) 0%, rgba(246, 248, 249, 0.00) 97.52%)',
} as const;
