import type { Outbrain } from '../../../schemas/sdks/outbrain.js';

export const stagingUS: Outbrain = {
  enabled: true,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};

export const productionUS: Outbrain = {
  enabled: true,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};
