import { styled } from '../../stitches.config.js';
import { createThemeVariant } from '../../utilities/create-theme-variant.js';

export const Icon = styled('svg', {
  width: 'auto',

  variants: {
    fill: {
      ...createThemeVariant('colors', 'fill'),
      currentColor: {
        fill: 'currentColor',
      },
      none: {
        fill: 'none',
      },
    },
    size: {
      12: {
        height: '1.2rem',
      },
      16: {
        height: '1.6rem',
      },
      18: {
        height: '1.8rem',
      },
      24: {
        height: '2.4rem',
      },
      32: {
        height: '3.2rem',
      },
      36: {
        height: '3.6rem',
      },
      40: {
        height: '4.0rem',
      },
      48: {
        height: '4.8rem',
      },
      52: {
        height: '5.2rem',
      },
      56: {
        height: '5.6rem',
      },
      60: {
        height: '6.0rem',
      },
      72: {
        height: '7.2rem',
      },
      88: {
        height: '8.8rem',
      },
      96: {
        height: '9.6rem',
      },
      124: {
        height: '12.4rem',
      },
      164: {
        height: '16.4rem',
      },
      222: {
        height: '22.2rem',
      },
      736: {
        height: '73.6rem',
      },
    },
  },
});
