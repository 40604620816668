import type { ReactNode } from 'react';

import { type BoxProps, Box } from '../box/index.js';
import { CheckFilledIcon } from '../icons/check-filled-icon.js';
import { CheckIcon } from '../icons/check-icon.js';
import { ErrorFilledIcon } from '../icons/error-filled-icon.js';
import { HelperMessageText } from './helper-message.primitive.js';

export type Kind = 'disabled' | 'error' | 'neutral' | 'success';

export interface HelperMessageProps {
  kind: Kind;
  hasIcon?: boolean;
  children?: ReactNode;
  padding?: BoxProps['padding'];
}

export function HelperMessage({
  kind,
  hasIcon = false,
  children,
  padding = '$0 $4',
}: HelperMessageProps) {
  return (
    <>
      <HelperMessageText kind={kind}>
        {hasIcon ?
          <Box className="icon-wrapper" maxHeight="1.8rem" minWidth="1.8rem">
            {kind === 'error' && (
              <ErrorFilledIcon size={{ '@xsmall': 16, '@medium': 18 }} />
            )}
            {kind === 'success' && (
              <CheckFilledIcon size={{ '@xsmall': 16, '@medium': 18 }} />
            )}
            {(kind === 'neutral' || kind === 'disabled') && (
              <CheckIcon size={{ '@xsmall': 16, '@medium': 18 }} />
            )}
          </Box>
        : null}
        <Box padding={padding}>{children}</Box>
      </HelperMessageText>
    </>
  );
}
