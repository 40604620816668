import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const Back15Icon = forwardRef<SVGSVGElement, IconProps>(
  function Back15Icon(
    {
      'aria-label': ariaLabel = 'Back 15 Seconds Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M11.9593 5.41125V2.51977C11.9593 2.05341 11.3831 1.82541 11.0526 2.15705L7.00889 6.0849C6.79529 6.29217 6.79529 6.61344 7.00889 6.82072L11.0526 10.7486C11.3831 11.0698 11.9593 10.8418 11.9593 10.3858V7.484C15.9389 7.484 19.0863 11.0284 18.2116 15.0391C17.7102 17.3917 15.7469 19.2883 13.3357 19.7754C9.52668 20.5526 6.13393 18.0135 5.62183 14.5831C5.55775 14.0857 5.10973 13.7022 4.57627 13.7022C3.93601 13.7022 3.4239 14.2515 3.50934 14.8733C4.1707 19.423 8.63063 22.7912 13.6772 21.8377C17.0058 21.2056 19.6838 18.6043 20.3348 15.3708C21.391 10.0542 17.2405 5.41125 11.9593 5.41125Z" />
        <path d="M10.7858 16.8113H9.87885V13.4328L8.80125 13.754V13.0389L10.6897 12.386H10.7858V16.8113Z" />
        <path d="M12.6636 12.3964L12.3969 14.6453L13.1117 14.8111C13.1437 14.78 13.1757 14.7386 13.2182 14.7179C13.2396 14.7075 13.2609 14.6946 13.2823 14.6816C13.3036 14.6686 13.325 14.6557 13.3464 14.6453C13.3891 14.6246 13.4532 14.6039 13.5063 14.5935C13.5597 14.5831 13.6344 14.5728 13.7199 14.5728C13.8371 14.5728 13.9546 14.5935 14.0398 14.6246C14.1252 14.6557 14.1999 14.7075 14.264 14.78C14.3278 14.8526 14.3706 14.9251 14.4026 15.0288C14.4346 15.1324 14.4453 15.2361 14.4453 15.3501C14.4453 15.4641 14.4453 15.5677 14.4133 15.6713C14.3812 15.775 14.3385 15.8579 14.2958 15.9304C14.2533 16.003 14.1786 16.0651 14.1038 16.1066C14.0291 16.1481 13.9332 16.1688 13.8157 16.1688C13.6344 16.1688 13.4849 16.1066 13.3677 16.0133C13.2502 15.9201 13.1864 15.775 13.1651 15.5884H12.2688C12.2688 15.7957 12.3222 15.9822 12.4073 16.1377C12.4928 16.2932 12.5996 16.4382 12.7488 16.5419C12.8983 16.6455 13.0583 16.7388 13.2396 16.7906C13.4211 16.8424 13.6131 16.8735 13.8051 16.8735C14.0718 16.8735 14.2958 16.8217 14.488 16.7492C14.68 16.6766 14.8399 16.5626 14.9681 16.4279C15.096 16.2931 15.1921 16.1377 15.2562 15.9615C15.3203 15.7853 15.3521 15.5988 15.3521 15.4019C15.3521 15.1739 15.3096 14.9666 15.2562 14.78C15.2028 14.5935 15.1067 14.438 14.9895 14.3137C14.872 14.1893 14.7227 14.096 14.5519 14.0235C14.3812 13.9509 14.1893 13.9199 13.9653 13.9199C13.8905 13.9199 13.8157 13.9302 13.7517 13.9406C13.6878 13.9509 13.6238 13.9717 13.5597 13.982C13.4956 13.9924 13.4425 14.0131 13.3998 14.0339C13.382 14.0425 13.3643 14.0494 13.3471 14.0559C13.3234 14.065 13.301 14.0736 13.2823 14.0857L13.3998 13.1322H15.2135V12.3964H12.6636Z" />
      </Icon>
    );
  },
) as typeof Icon;
