import { type ReactNode, forwardRef } from 'react';

import { Flex } from '../flex/index.js';

export const PlayerMetadataText = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(function PlayerMetadataText({ children, ...props }, ref) {
  return (
    <Flex direction="column" gridArea="text" ref={ref} {...props}>
      {children}
    </Flex>
  );
});

PlayerMetadataText.displayName = 'Player.MetadataText';
