import type { AdobeAnalytics } from '../../../schemas/sdks/adobe-analytics.js';

export const stagingUS: AdobeAnalytics = {
  account: 'cciheartmuciccomdev,ccmarketdev,ccglobaldev',
  dtmUrl:
    '//assets.adobedtm.com/dba7b4ad2c8b/91f40365a420/launch-7f0176c0ef0a-staging.min.js',
  enabled: true,
  secureTrackingServer: 'smy.iheart.com',
  threshold: 100,
  trackingServer: 'my.iheart.com',
  visitorNamespace: 'clearchannel',
};

export const productionUS: AdobeAnalytics = {
  ...stagingUS,
  account: 'cccorporate01,cccorporate,ccnetwork',
  dtmUrl:
    '//assets.adobedtm.com/dba7b4ad2c8b/91f40365a420/launch-530fb1e26ecf.min.js',
};
