import { forwardRef } from 'react';
import type { Merge } from 'type-fest';

import type { Component } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export type SpacerProps = Merge<
  BoxProps,
  {
    bottom?: BoxProps['paddingBottom'];
    top?: BoxProps['paddingTop'];
    left?: BoxProps['paddingLeft'];
    right?: BoxProps['paddingRight'];
    x?: BoxProps['paddingLeft'];
    y?: BoxProps['paddingTop'];
  }
>;

export const Spacer = forwardRef<HTMLDivElement, SpacerProps>(function Spacer(
  { bottom, children, left, right, top, x, y, ...props },
  ref,
) {
  return (
    <Box
      {...props}
      paddingBottom={bottom ?? y}
      paddingLeft={left ?? x}
      paddingRight={right ?? x}
      paddingTop={top ?? y}
      ref={ref}
    >
      {children}
    </Box>
  );
}) as Component<'div', SpacerProps>;
