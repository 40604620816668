// Generated by ts-to-zod
import { z } from 'zod';

export const tritonSchema = z.object({
  desktop: z.object({
    custom: z.string(),
    talk: z.string(),
  }),
  enabled: z.boolean(),
  mobile: z.object({
    custom: z.string(),
    talk: z.string(),
  }),
  sid: z.string(),
  threshold: z.number(),
});

export type Triton = z.infer<typeof tritonSchema>;
