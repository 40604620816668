// Generated by ts-to-zod
import { z } from 'zod';

export const facebookSchema = z.object({
  appId: z.string(),
  pages: z.string(),
  pixelEnabled: z.boolean(),
  pixelId: z.string(),
  threshold: z.number(),
});

export type Facebook = z.infer<typeof facebookSchema>;
