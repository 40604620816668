import type {
  LoaderFunction,
  SerializeFrom,
  ServerRuntimeMetaArgs,
  ServerRuntimeMetaFunction,
} from '@remix-run/server-runtime';

/*
 * Helper for merging meta tags for nested routes, refs: https://remix.run/docs/en/1.15.0/route/meta-v2#meta-merging-helper
 * calls the exported `meta` function from each route, and merges the results, then appends any additional meta tags
 * @param leafMetaFn - the meta function that will override any parent meta tags
 * @returns merged meta tags
 * */
export const mergeMeta = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >,
>(
  leafMetaFn: ServerRuntimeMetaFunction<Loader, ParentsLoaders>,
): ServerRuntimeMetaFunction<Loader, ParentsLoaders> => {
  return argument => {
    const leafMeta = leafMetaFn(argument);

    const metaMap = new Map<string, Record<string, string>>();
    argument.matches.reduce((_accumulator, match) => {
      for (const parentMeta of match.meta as Record<string, string>[]) {
        const key =
          parentMeta.key ||
          parentMeta.name ||
          parentMeta.property ||
          parentMeta.content;
        metaMap.set(key, parentMeta);
      }
      return _accumulator;
    }, leafMeta);

    for (const meta of leafMeta as Record<string, string>[]) {
      const key = meta.key || meta.name || meta.property || meta.content;
      metaMap.set(key, meta);
    }
    return [...metaMap.values()];
  };
};

export const getRootLoaderData = <T>({
  matches,
  id,
}: {
  matches: ServerRuntimeMetaArgs['matches'];
  id: string;
}): SerializeFrom<T> => {
  return matches.find(match => match.id === id)?.data as SerializeFrom<T>;
};
