import type { ReactNode } from 'react';
import type { Simplify } from 'type-fest';

import { Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { CancelIcon } from '../icons/cancel-icon.js';
import { Spacer } from '../spacer/index.js';
import type { DialogProps } from './dialog.primitive.js';
import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from './dialog.primitive.js';

export type DialogRootProps = Simplify<
  DialogProps & {
    trigger?: ReactNode;
    onClose?: () => void;
    showClose?: boolean;
  }
>;

export const DialogRoot = (props: DialogRootProps) => {
  const {
    children,
    trigger,
    showClose = true,
    onClose,
    defaultOpen,
    open,
    onOpenChange,
    ...restProps
  } = props;

  return (
    <Root
      {...restProps}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      open={open}
    >
      <Trigger asChild data-test="dialog-trigger">
        {trigger}
      </Trigger>
      <Portal>
        <Overlay>
          <Content>
            <Spacer
              css={{
                position: 'relative',
                overflowY: 'auto',
                maxHeight: '85vh',

                '&:focus': { outline: 'none' },
              }}
              x={{ '@initial': '16', '@medium': '24' }}
              y={{ '@initial': '16', '@medium': '24' }}
            >
              <Box
                css={{
                  position: 'fixed',
                  top: '0',
                  right: '0',
                  zIndex: '$10',
                  height: '5rem',

                  dark: {
                    backgroundColor: '$gray-600',
                  },

                  light: {
                    backgroundColor: '$brand-white',
                  },
                }}
              >
                {showClose ?
                  <Close asChild {...restProps}>
                    <Button
                      color="white"
                      kind="tertiary"
                      onClick={onClose}
                      size="icon"
                    >
                      <CancelIcon size={32} />
                    </Button>
                  </Close>
                : null}
              </Box>
              {children}
            </Spacer>
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};

DialogRoot.displayName = 'DialogRoot';
