import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ShareIcon = forwardRef<SVGSVGElement, IconProps>(
  function ShareIcon(
    { 'aria-label': ariaLabel = 'Share Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12.272 3.13268C12.1091 2.95439 11.8211 2.95602 11.6602 3.13614L8.95432 6.16735C8.65022 6.50803 8.69009 7.02189 9.04358 7.31508C9.39687 7.60828 9.92987 7.56978 10.234 7.2291L11.1875 6.161V13.917C11.1875 14.3511 11.5513 14.7031 12 14.7031C12.4487 14.7031 12.8125 14.3511 12.8125 13.917V6.18601L13.7724 7.23627C14.0806 7.5735 14.614 7.60599 14.964 7.30883C15.3137 7.01168 15.3474 6.49741 15.0391 6.16018L12.272 3.13268Z" />
        <path d="M5.5 9.92427C5.5 9.11345 6.18317 8.46797 7.01154 8.46797H8.75C9.1987 8.46797 9.5625 8.8199 9.5625 9.25402C9.5625 9.68815 9.1987 10.0401 8.75 10.0401H7.125V19.3029H16.875V10.0401H15.25C14.8013 10.0401 14.4375 9.68815 14.4375 9.25402C14.4375 8.8199 14.8013 8.46797 15.25 8.46797H16.9889C17.817 8.46797 18.5 9.11374 18.5 9.92427V19.4191C18.5 20.2295 17.8168 20.875 16.9889 20.875H7.01154C6.18356 20.875 5.5 20.2298 5.5 19.4191V9.92427Z" />
      </Icon>
    );
  },
) as typeof Icon;
