import type { PropsWithChildren } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';

export interface SpacerProps extends PropsWithChildren<ElementProps<'div'>> {
  bottom?: RainbowSprinkles['paddingBottom'];
  top?: RainbowSprinkles['paddingTop'];
  left?: RainbowSprinkles['paddingLeft'];
  right?: RainbowSprinkles['paddingRight'];
  x?: RainbowSprinkles['paddingX'];
  y?: RainbowSprinkles['paddingY'];
  p?: RainbowSprinkles['padding'];
}

export function Spacer({
  bottom,
  children,
  left,
  right,
  top,
  x,
  y,
  p,
  ...props
}: SpacerProps) {
  const { className, style, otherProps } = rainbowSprinkles({
    paddingBottom: bottom,
    paddingTop: top,
    paddingLeft: left,
    paddingRight: right,
    paddingX: x,
    paddingY: y,
    padding: p,
    ...props,
  });

  return (
    <div className={className} style={style} {...otherProps}>
      {children}
    </div>
  );
}
