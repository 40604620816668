import { Radio } from '@iheartradio/web.companion';
import type { ReactNode } from 'react';

import { useConfig } from '~app/contexts/config';

export type GenderRadioGroupProps = {
  disabled?: boolean;
  error?: ReactNode;
  hint?: string;
  required?: boolean;
  defaultValue?: string;
  name: string;
  message?: ReactNode;
};

export const GenderRadioGroup = ({
  disabled = false,
  hint,
  required = false,
  defaultValue,
  name,
  message,
}: GenderRadioGroupProps) => {
  const config = useConfig();
  const genders = Object.entries(config.account.registration.genders ?? []).map(
    (entry: [string, { label: string }]) => {
      return {
        value: entry[0],
        label: entry[1].label,
      };
    },
  );

  return (
    <Radio.Group
      defaultValue={defaultValue}
      disabled={disabled}
      hint={hint}
      id="gender"
      label="Gender"
      message={message}
      name={name}
      orientation="horizontal"
      required={required}
    >
      {genders.map(option => {
        return (
          <Radio.Button
            data-test={`gender-${option.value}`}
            disabled={disabled}
            id={`gender-${option.value}`}
            key={option.label}
            value={option.value}
          >
            {option.label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};
