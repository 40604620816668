import * as RadixRadioGroup from '@radix-ui/react-radio-group';

import { styled } from '../../stitches.config.js';
import { RadioButtonCheckedIcon as _RadioButtonCheckedIcon } from '../icons/radio-button-checked-icon.js';
import { RadioButtonIcon as _RadioButtonIcon } from '../icons/radio-button-icon.js';
import { kinds } from '../text/text.js';

export const Colors = {
  darkTheme: '$gray-200',
  lightTheme: '$gray-500',
  darkActive: '$blue-400',
  lightActive: '$blue-500',
};

export const Label = styled('label', {
  paddingLeft: '$4',

  hover: {
    cursor: 'pointer',
  },

  dark: {
    color: Colors.darkTheme,
  },
  light: {
    color: Colors.lightTheme,
  },

  disabled: {
    pointerEvents: 'none',

    dark: {
      color: '$gray-400',
    },
    light: {
      color: '$gray-300',
    },
  },

  '@xsmall': kinds['body-4'],
  '@medium': kinds['body-3'],
});

Label.displayName = 'Radio.Label';

export const StyledItem = styled(RadixRadioGroup.Item, {
  focus: {
    dark: {
      color: Colors.darkActive,
    },
    light: {
      color: Colors.lightActive,
    },
  },

  disabled: {
    pointerEvents: 'none',
  },
});

StyledItem.displayName = 'StyledItem';

export const RadioGroup = styled(RadixRadioGroup.Root, {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  position: 'relative',
});

RadioGroup.displayName = 'Radio.Group';

export const RadioButtonCheckedIcon = styled(_RadioButtonCheckedIcon, {
  /**
   * The classnames Stitches generates for the checked/unchecked icons are the exact same which
   * causes issues. This is included as a way to have Stitches generate unique classnames for each
   * icon but has no other effect.
   */
  state: 'checked',
});

export const RadioButtonIcon = styled(_RadioButtonIcon, {
  /**
   * The classnames Stitches generates for the checked/unchecked icons are the exact same which
   * causes issues. This is included as a way to have Stitches generate unique classnames for each
   * icon but has no other effect.
   */
  state: 'unchecked',
});
