import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SaveSongIcon = forwardRef<SVGSVGElement, IconProps>(
  function SaveSongIcon(
    {
      'aria-label': ariaLabel = 'Save Song Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M7.99996 13.55V5C7.99996 3.9 8.89986 3 9.99996 3H12C13.0998 3 14 3.9 14 5C14 6.1 13.0998 7 12 7H9.99996V16.85C9.99996 18.84 8.60982 20.64 6.64986 20.95C3.91988 21.38 1.59981 19.04 2.06002 16.3C2.30001 14.9 3.33004 13.71 4.66988 13.23C5.89986 12.8 7.06002 13.01 7.99996 13.55Z" />
        <path d="M18 12V15H21C21.55 15 22 15.45 22 16C22 16.55 21.55 17 21 17H18V20C18 20.55 17.55 21 17 21C16.4499 21 16 20.55 16 20V17H13C12.4499 17 12 16.55 12 16C12 15.45 12.4499 15 13 15H16V12C16 11.45 16.4499 11 17 11C17.55 11 18 11.45 18 12Z" />
      </Icon>
    );
  },
) as typeof Icon;
