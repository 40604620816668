// Generated by ts-to-zod
import { z } from 'zod';

const gendersSchema = z.object({
  male: z.object({
    label: z.string(),
  }),
  female: z.object({
    label: z.string(),
  }),
  unspecified: z.object({
    label: z.string(),
  }),
});

export const accountSchema = z.object({
  phone: z
    .object({
      callingCode: z.preprocess(String, z.string()),
      digitRange: z.array(z.number()).optional(),
      format: z.string().optional(),
    })
    .optional(),
  registration: z.object({
    emailUpdatesDefaultUnchecked: z.boolean().optional(),
    genderAllowUnselected: z.boolean(),
    genders: gendersSchema.optional(),
    oauths: z.array(z.string()).optional().nullable(),
    showLoginInNav: z.boolean(),
    usePostal: z.boolean().optional(),
    zipKeyboard: z.string().optional().nullable(),
    zipRegex: z.string().optional().nullable(),
  }),
});

export type Account = z.infer<typeof accountSchema>;
