import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';

import {
  fpHashSchema,
  loginTokenSchema,
  passwordSchema,
  redirectUrlSchema,
  userNameSchema,
} from '~app/constants/zod-validations';

export const validator = withZod(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('credentials'),
      userName: userNameSchema,
      password: passwordSchema,
      fpHash: fpHashSchema,
      redirectUrl: redirectUrlSchema,
    }),
    z.object({
      type: z.literal('token'),
      loginToken: loginTokenSchema,
      redirectUrl: redirectUrlSchema,
      fpHash: fpHashSchema,
    }),
  ]),
);
