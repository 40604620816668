import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FacebookIcon = forwardRef<SVGSVGElement, IconProps>(
  function FacebookIcon(
    {
      'aria-label': ariaLabel = 'Facebook Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M21 12C21 7.02891 16.9711 3 12 3C7.02891 3 3 7.02891 3 12C3 16.493 6.29063 20.216 10.5938 20.891V14.6016H8.30859V12H10.5938V10.0172C10.5938 7.76191 11.9367 6.51562 13.9934 6.51562C14.9777 6.51562 16.0078 6.69141 16.0078 6.69141V8.90625H14.8723C13.7543 8.90625 13.4062 9.60058 13.4062 10.3125V12H15.9023L15.5033 14.6016H13.4062V20.891C17.7094 20.216 21 16.493 21 12Z" />
      </Icon>
    );
  },
) as typeof Icon;
