import type { Dispatch, ReactNode, SetStateAction } from 'react';
import * as React from 'react';

export function createContext<Value>(displayName: string, defaultValue: Value) {
  const Context = React.createContext<[Value, Dispatch<SetStateAction<Value>>]>(
    [defaultValue, () => {}],
  );

  function Provider({
    children,
    value = defaultValue,
  }: {
    children: ReactNode;
    value?: Value;
  }) {
    const [state, setState] = React.useState<Value>(value);

    React.useEffect(() => setState(value), [value]);

    return (
      <Context.Provider value={React.useMemo(() => [state, setState], [state])}>
        {children}
      </Context.Provider>
    );
  }

  Provider.displayName = `${displayName}.Provider`;

  function useContext<T = undefined>() {
    return React.useContext(Context) as unknown as [
      T extends undefined ? Value : T,
      Dispatch<SetStateAction<T extends undefined ? Value : T>>,
    ];
  }

  return {
    defaultValue,
    Provider,
    useContext,
  } as const;
}
