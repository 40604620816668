import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PlaylistIcon = forwardRef<SVGSVGElement, IconProps>(
  function PlaylistIcon(
    {
      'aria-label': ariaLabel = 'Playlist Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.3037 2.94995H4.33037C3.5986 2.94995 3 3.54862 3 4.28032C3 5.01202 3.5986 5.61069 4.33037 5.61069H16.3037C17.0354 5.61069 17.634 5.01202 17.634 4.28032C17.634 3.54862 17.0354 2.94995 16.3037 2.94995Z" />
        <path d="M16.3037 8.27142H4.33037C3.5986 8.27142 3 8.87009 3 9.60179C3 10.3335 3.5986 10.9322 4.33037 10.9322H16.3037C17.0354 10.9322 17.634 10.3335 17.634 9.60179C17.634 8.87009 17.0354 8.27142 16.3037 8.27142Z" />
        <path d="M4.33037 13.5929H10.9822C11.714 13.5929 12.3126 14.1916 12.3126 14.9233C12.3126 15.655 11.714 16.2536 10.9822 16.2536H4.33037C3.5986 16.2536 3 15.655 3 14.9233C3 14.1916 3.5986 13.5929 4.33037 13.5929Z" />
        <path d="M14.9733 20.3911V14.7636C14.9733 14.2448 15.532 13.9255 15.9844 14.1783L20.6806 16.9986C21.1065 17.2647 21.1065 17.89 20.6806 18.1428L15.9844 20.9631C15.532 21.2292 14.9733 20.9099 14.9733 20.3911Z" />
      </Icon>
    );
  },
) as typeof Icon;
