import { getCssText } from '@iheartradio/web.companion';
import { RemixBrowser } from '@remix-run/react';
import React, {
  startTransition,
  StrictMode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { hydrateRoot } from 'react-dom/client';

import ClientStyleContext from './contexts/client-style';

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const { default: whyDidYouRender } = await import(
    '@welldone-software/why-did-you-render'
  );
  whyDidYouRender(React);
}

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [sheet, setSheet] = useState(getCssText());

  const reset = useCallback(() => {
    setSheet(getCssText());
  }, []);

  const value = useMemo(() => ({ reset, sheet }), [reset, sheet]);

  return (
    <ClientStyleContext.Provider
      // memoize the value for this provider to minimize re-renders
      value={value}
    >
      {children}
    </ClientStyleContext.Provider>
  );
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>
    </StrictMode>,
  );
});
