import * as Analytics from '@iheartradio/web.analytics';
import { useEffect } from 'react';

export function useTrackVisibilityChange(
  analytics: Analytics.Analytics.Analytics,
) {
  useEffect(() => {
    function trackVisibility() {
      if (document.visibilityState === 'hidden') {
        analytics.track({
          type: Analytics.eventType.enum.Background,
        });
      } else {
        analytics.track({
          type: Analytics.eventType.enum.Foreground,
        });
      }
    }
    window.document.addEventListener('visibilitychange', trackVisibility);

    return () => {
      window.document.removeEventListener('visibilitychange', trackVisibility);
    };
  }, []);
}
