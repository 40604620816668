import { isObject, isString, isUndefined } from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import jwtDecode from 'jwt-decode';
import { z, ZodError } from 'zod';

import {
  type IdResponse,
  type OauthLinkActionBody,
  type OauthLoginActionBody,
  type RequestCodeResponse,
  IdResponseSchema,
  OauthLinkActionBodySchema,
  OauthLoginActionBodySchema,
  RequestCodeResponseSchema,
} from './types';

const buildGooglePost = async (
  code: string,
  type: 'link' | 'login',
): Promise<OauthLoginActionBody | OauthLinkActionBody> => {
  const tokenFormData = new FormData();
  tokenFormData.append('code', code);

  const tokenRequest = await fetch('/api/v1/google/exchange-code', {
    method: 'post',
    body: tokenFormData,
  });

  if (tokenRequest.ok) {
    const { access_token, id_token }: RequestCodeResponse =
      RequestCodeResponseSchema.parse(await tokenRequest.json());
    const decoded: IdResponse = IdResponseSchema.parse(
      jwtDecode<IdResponse>(id_token),
    );

    if (type === 'login') {
      return {
        type: 'checkAccount',
        accessToken: access_token,
        accessTokenType: 'google',
        oauthUuid: decoded.sub,
        ...(decoded.email ? { userName: decoded.email } : {}),
      } satisfies OauthLoginActionBody;
    } else {
      return {
        intent: 'link',
        accessToken: access_token,
        accessTokenType: 'google',
        oauthUuid: decoded.sub,
      } satisfies OauthLinkActionBody;
    }
  } else {
    const { error } = await tokenRequest
      .json()
      .catch(() => ({ error: 'Unable to login with Google, uknown error' }));
    throw new Error(error);
  }
};

export const googleSdkFactory = ({
  onError = () => {
    console.log('Error with Google SDK');
  },
  onLink,
  onLogin,
  onUnlink,
}: {
  onError?: (initialization?: boolean) => void;
  onLink?: (postBody: OauthLinkActionBody) => void;
  onLogin?: (postBody: OauthLoginActionBody) => void;
  onUnlink?: () => void;
}) => {
  let loginCodeClient: ReturnType<typeof google.accounts.oauth2.initCodeClient>;
  let linkCodeClient: ReturnType<typeof google.accounts.oauth2.initCodeClient>;

  const generateCodeClients = (client_id: string) => {
    if (isUndefined(window?.google)) {
      return;
    }

    loginCodeClient = google.accounts.oauth2.initCodeClient({
      client_id,
      callback: async response => {
        if (!isObject(response) || !isString(response.code)) {
          console.group('loginCodeClient');
          console.error(response.error_description);
          console.error(response.error_uri);
          console.groupEnd();

          onError?.(false);
          return;
        }

        try {
          const postBody: OauthLoginActionBody =
            OauthLoginActionBodySchema.parse(
              await buildGooglePost(response.code, 'login'),
            );

          onLogin?.(postBody);
        } catch (error: unknown) {
          console.group('loginCodeClient');
          console.error(
            error instanceof ZodError ? error.flatten()
            : error instanceof Error ? error.message
            : JSON.stringify(error),
          );
          console.groupEnd();
          onError?.(false);
        }
      },
      error_callback: error => {
        console.group('loginCodeClient');
        console.error(error.message);
        console.groupEnd();

        onError?.(false);
      },
      scope: 'profile email openid',
    });

    linkCodeClient = google?.accounts.oauth2.initCodeClient({
      client_id,
      callback: async response => {
        if (!isObject(response) || !isString(response.code)) {
          console.group('linkCodeClient');
          console.error(response.error_description);
          console.error(response.error_uri);
          console.groupEnd();

          onError?.(false);
          return;
        }

        try {
          const postBody: OauthLinkActionBody = OauthLinkActionBodySchema.parse(
            await buildGooglePost(response.code, 'link'),
          );

          onLink?.(postBody);
        } catch (error: unknown) {
          console.error(
            error instanceof ZodError ? error.flatten()
            : error instanceof Error ? error.message
            : JSON.stringify(error),
          );
          onError?.(false);
        }
      },
      error_callback: error => {
        console.group('linkCodeClient');
        console.error(error.message);
        console.groupEnd();

        onError?.(false);
      },
      scope: 'profile',
    });
  };

  const emitter = createEmitter({
    initialize(appKey_: unknown) {
      return new Promise((resolve, reject) => {
        try {
          const appKey = z.string().parse(appKey_, { path: ['appKey'] });

          if (document.querySelector('script#google-sdk')) {
            generateCodeClients(appKey);
            resolve(true);
            return;
          }

          const googleScript = document.createElement('script');
          googleScript.id = 'google-sdk';
          googleScript.src = 'https://accounts.google.com/gsi/client';
          googleScript.async = true;
          googleScript.defer = true;

          googleScript.addEventListener('error', () => {
            onError?.(true);
            reject(new Error('Error loading Google SDK'));
          });
          googleScript.addEventListener('load', () => {
            generateCodeClients(appKey);
            resolve(true);
          });
          document.body.append(googleScript);
        } catch (error: unknown) {
          onError?.(true);
          if (
            error instanceof ZodError &&
            error.errors
              .reduce(
                (paths, issue) => [...paths, issue.path.join('.')],
                [] as string[],
              )
              .includes('appKey')
          ) {
            reject(new Error('App Key missing for Google SDK'));
          } else {
            reject(new Error('Error loading Google SDK'));
          }
        }
      });
    },
    link() {
      linkCodeClient?.requestCode();
    },
    login() {
      loginCodeClient?.requestCode();
    },
    unlink() {
      onUnlink?.();
    },
    remove() {
      document.querySelector('script#google-sdk')?.remove();
    },
  });

  return emitter;
};
