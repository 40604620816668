import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FavoriteFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function FavoriteFilledIcon(
    {
      'aria-label': ariaLabel = 'Favorite Filled Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M10.3145 19.6805C10.7646 20.1306 11.3638 20.3787 12.0019 20.3787C12.6374 20.378 13.2362 20.1299 13.685 19.6804L20.6993 12.6661C21.6986 11.6668 22.25 10.3363 22.25 8.9225C22.25 5.64449 19.5229 3.62 17 3.62C15.5439 3.62 14.2313 4.15947 13.212 5.17884L12 6.39082L10.788 5.17884C9.76865 4.15947 8.45606 3.62 7 3.62C4.47774 3.62 1.75 5.64382 1.75 8.9225C1.75 10.3363 2.30135 11.6668 3.30072 12.6661L10.3145 19.6805Z" />
      </Icon>
    );
  },
) as typeof Icon;
