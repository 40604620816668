import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ArrowLeftIcon = forwardRef<SVGSVGElement, IconProps>(
  function ArrowLeftIcon(
    {
      'aria-label': ariaLabel = 'Arrow Left Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M18.7925 13H7.62246L12.5025 17.88C12.8925 18.27 12.8925 18.91 12.5025 19.3C12.1125 19.69 11.4825 19.69 11.0925 19.3L4.50246 12.71C4.11246 12.32 4.11246 11.69 4.50246 11.3L11.0825 4.69997C11.4725 4.30997 12.1025 4.30997 12.4925 4.69997C12.8825 5.08997 12.8825 5.71997 12.4925 6.10997L7.62246 11H18.7925C19.3425 11 19.7925 11.45 19.7925 12C19.7925 12.55 19.3425 13 18.7925 13Z" />
      </Icon>
    );
  },
) as typeof Icon;
