import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Marquee } from '../marquee/index.js';
import { Text } from '../text/index.js';
import { kinds } from '../text/text.js';
import { useFullScreen } from './player-root.js';

export const PlayerTitle = forwardRef<
  HTMLParagraphElement,
  { children?: ReactNode }
>(function PlayerTitle({ children }, ref) {
  const [isFullScreen] = useFullScreen();

  if (!children) {
    return null;
  }

  return (
    <Text
      color={{ dark: '$brand-white', light: '$gray-600' }}
      css={{
        padding: '$0 $8 $2 $8',
        ...kinds['caption-1'],

        [`@container ${media.large}`]: {
          padding: isFullScreen ? '$0 $16 $2 $16' : '$0 $8 $2 $8',
        },

        '[data-player-type="fullscreen"] &': {
          padding: '$0 $8 $2 $0',
          ...kinds['h5'],
        },
      }}
      // kind prop is set since it is required by <Text />, but CSS block above still takes priority
      kind="caption-1"
      lines={1}
      ref={ref}
    >
      <Marquee gap="$24">{children}</Marquee>
    </Text>
  );
});

PlayerTitle.displayName = 'Player.Title';
