import type { ReactNode } from 'react';
import type { Merge } from 'type-fest';

import { DialogBody } from './dialog-body.js';
import { DialogButtonContainer } from './dialog-button-container.js';
import { DialogMetadata } from './dialog-metadata.js';
import { type DialogRootProps, DialogRoot } from './dialog-root.js';
import { DialogTitle } from './dialog-title.js';

export type DialogBoxProps = Merge<
  DialogRootProps,
  { title?: string; body?: string; buttons: ReactNode }
>;

export const Dialog = ({
  defaultOpen,
  open,
  trigger,
  showClose = true,
  title,
  body,
  buttons,
  ...props
}: DialogBoxProps) => (
  <DialogRoot
    {...props}
    defaultOpen={defaultOpen}
    open={open}
    showClose={showClose}
    trigger={trigger}
  >
    <DialogMetadata>
      <DialogTitle>{title}</DialogTitle>
      <DialogBody>{body}</DialogBody>
      <DialogButtonContainer>{buttons}</DialogButtonContainer>
    </DialogMetadata>
  </DialogRoot>
);

Dialog.displayName = 'Dialog';
