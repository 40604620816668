import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const TikTokIcon = forwardRef<SVGSVGElement, IconProps>(
  function TikTokIcon(
    {
      'aria-label': ariaLabel = 'TikTok Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M20.75 6.91071C19.533 6.84196 18.3821 6.33443 17.5107 5.48214C17.0477 5.03475 16.6828 4.49596 16.4393 3.9C16.1912 3.30286 16.0696 2.66078 16.0821 2.01428H12.5821V15.0179C12.5821 17.2143 11.275 18.5107 9.65355 18.5107C9.26371 18.5197 8.87603 18.4504 8.51347 18.3068C8.15092 18.1633 7.82085 17.9484 7.54284 17.675C7.26438 17.4005 7.04235 17.0741 6.88926 16.7143C6.73838 16.3534 6.66069 15.9661 6.66069 15.575C6.66069 15.1838 6.73838 14.7966 6.88926 14.4357C7.04235 14.0759 7.26438 13.7495 7.54284 13.475C7.82085 13.2016 8.15092 12.9867 8.51347 12.8432C8.87603 12.6996 9.26371 12.6303 9.65355 12.6393C9.97624 12.6414 10.2962 12.6981 10.6 12.8071V9.23571C10.289 9.169 9.97153 9.13784 9.65355 9.14285C8.3821 9.14285 7.1392 9.51988 6.08203 10.2263C5.02485 10.9326 4.20089 11.9366 3.71432 13.1113C3.22776 14.286 3.10045 15.5786 3.3485 16.8256C3.59655 18.0726 4.20881 19.2181 5.10786 20.1171C6.00692 21.0162 7.15238 21.6284 8.3994 21.8765C9.64642 22.1245 10.939 21.9972 12.1137 21.5107C13.2883 21.0241 14.2923 20.2001 14.9987 19.1429C15.7051 18.0858 16.0821 16.8429 16.0821 15.5714V8.78571C17.4633 9.70742 19.0896 10.1927 20.75 10.1786V6.91071Z" />
      </Icon>
    );
  },
) as typeof Icon;
