import {
  generateMetaKey,
  mergeMeta,
  METADATA_APP_NAME,
  METADATA_DEFAULT_CANONICAL,
  METADATA_DEFAULT_IMAGE,
  METADATA_GLOBAL_KEYWORDS,
  METADATA_LINK_REL_VALUES,
  METADATA_OPENGRAPH_TYPES,
  METADATA_TITLE_SEPARATOR,
  METADATA_TWITTER_CARDS,
  setBasicMetadata,
  setSocialTitle,
} from '@iheartradio/web.remix-shared';

export const meta = mergeMeta(() => {
  return [
    ...setBasicMetadata({
      title: `Login ${METADATA_TITLE_SEPARATOR} ${METADATA_APP_NAME}`,
      description: `Login to your ${METADATA_APP_NAME} Account`,
      keywords: METADATA_GLOBAL_KEYWORDS,
      type: METADATA_OPENGRAPH_TYPES.Website,
      card: METADATA_TWITTER_CARDS.Summary,
      image: METADATA_DEFAULT_IMAGE,
    }),
    ...setSocialTitle(`Login ${METADATA_TITLE_SEPARATOR} ${METADATA_APP_NAME}`),
    {
      rel: METADATA_LINK_REL_VALUES.Canonical,
      href: METADATA_DEFAULT_CANONICAL,
      tagName: 'link',
      key: generateMetaKey('rel', METADATA_LINK_REL_VALUES.Canonical),
    },
    {
      rel: METADATA_LINK_REL_VALUES.ImageSource,
      href: METADATA_DEFAULT_IMAGE,
      tagName: 'link',
      key: generateMetaKey('rel', METADATA_LINK_REL_VALUES.ImageSource),
    },
  ];
});
