import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

import { Box } from '../box/index.js';
import { Flex } from '../flex/index.js';
import {
  FieldContainer,
  FieldLabel,
  FieldMessageContainer,
} from './field.primitive.js';
import { FieldHint } from './hint.js';

export interface FieldProps {
  /** Change the Field styles depending on whether the input is disabled or not */
  disabled?: boolean;

  /** Provide a message to the user to explain the input. */
  message?: ReactNode;

  /**
   * The `id` HTML attribute. Should match the child `input`'s `id` value.
   * `useInputProps` manages this value by default if one is not explicitly provided.
   */
  id?: string;

  /** Whether the field takes the full width of its container. */
  inline?: boolean;

  /** Render the label inline with the form control */
  inlineLabel?: boolean;

  /** Label text for the input. */
  label?: string;

  /** Provide a hint to the user further explaining the input. */
  hint?: ReactNode;

  css?: ComponentProps<typeof FieldContainer>['css'];
}

export const Field = (props: PropsWithChildren<FieldProps>) => {
  const {
    children,
    disabled = false,
    message,
    inline,
    inlineLabel = false,
    hint = '',
    id,
    label,
    css,
    ...restProps
  } = props;

  return (
    <FieldContainer
      css={{
        flexDirection: 'column',
        gap: '$4',
        ...css,
      }}
      inline={inline}
      inlineLabel={inlineLabel}
      {...restProps}
    >
      {label || hint ?
        <Flex alignItems="center" height="2.4rem">
          {label ?
            <FieldLabel
              data-disabled={disabled}
              data-test="field-label"
              htmlFor={id}
            >
              {label}
            </FieldLabel>
          : null}
          {hint ?
            <FieldHint data-test="field-hint-button" hint={hint} />
          : null}
        </Flex>
      : null}
      {children}
      {message ?
        <Box data-test="field-message">
          <FieldMessageContainer>{message}</FieldMessageContainer>
        </Box>
      : null}
    </FieldContainer>
  );
};
