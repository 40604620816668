import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const MusicIcon = forwardRef<SVGSVGElement, IconProps>(
  function MusicIcon(
    {
      'aria-label': ariaLabel = 'Music Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M8.90013 2.02065C8.24493 1.89131 7.63365 2.38595 7.63365 3.04546V14.2374C7.20416 14.0267 6.72006 13.9083 6.20792 13.9083C4.43623 13.9083 3 15.3259 3 17.0746C3 18.8233 4.43623 20.241 6.20792 20.241C7.9796 20.241 9.41584 18.8233 9.41584 17.0746L9.41583 17.0672V5.71684L19.2178 7.58733V15.9964C18.7883 15.7858 18.3042 15.6673 17.7921 15.6673C16.0204 15.6673 14.5842 17.0849 14.5842 18.8337C14.5842 20.5824 16.0204 22 17.7921 22C19.5638 22 21 20.5824 21 18.8337L21 18.8256L21 5.26603C21 4.76785 20.6437 4.33893 20.1488 4.24123L8.90013 2.02065Z" />
      </Icon>
    );
  },
) as typeof Icon;
