export const fontWeights = {
  300: 300, // light
  400: 400, // normal / regular
  500: 500, // medium
  600: 600, // semi-bold
  700: 700, // bold
  800: 800, // extra-bold

  // Shortcuts
  light: 300,
  normal: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
  'extra-bold': 800,
} as const;
