import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SaveAlbumIcon = forwardRef<SVGSVGElement, IconProps>(
  function SaveAlbumIcon(
    {
      'aria-label': ariaLabel = 'Save Album Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M10 9.7C9.56006 9.7 9.19995 10.06 9.19995 10.5C9.19995 10.94 9.56006 11.3 10 11.3C10.4399 11.3 10.8 10.94 10.8 10.5C10.8 10.06 10.4399 9.7 10 9.7Z" />
        <path
          clipRule="evenodd"
          d="M10 2.5C5.58398 2.5 2 6.084 2 10.5C2 14.916 5.58398 18.5 10 18.5C10.6975 18.5 11.3743 18.4106 12.0195 18.2426C11.4116 17.899 11 17.2464 11 16.5C11 15.3977 11.8977 14.5 13 14.5H15V12.5C15 11.3977 15.8977 10.5 17 10.5C17.3621 10.5 17.7021 10.5969 17.9956 10.7661C17.9985 10.6778 18 10.5891 18 10.5C18 6.084 14.416 2.5 10 2.5ZM10 14.1C8.00806 14.1 6.3999 12.492 6.3999 10.5C6.3999 8.508 8.00806 6.9 10 6.9C11.9919 6.9 13.6001 8.508 13.6001 10.5C13.6001 12.492 11.9919 14.1 10 14.1Z"
          fillRule="evenodd"
        />
        <path d="M18 12.5V15.5H21C21.55 15.5 22 15.95 22 16.5C22 17.05 21.55 17.5 21 17.5H18V20.5C18 21.05 17.55 21.5 17 21.5C16.45 21.5 16 21.05 16 20.5V17.5H13C12.45 17.5 12 17.05 12 16.5C12 15.95 12.45 15.5 13 15.5H16V12.5C16 11.95 16.45 11.5 17 11.5C17.55 11.5 18 11.95 18 12.5Z" />
      </Icon>
    );
  },
) as typeof Icon;
