import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const DownloadQueueIcon = forwardRef<SVGSVGElement, IconProps>(
  function DownloadQueueIcon(
    {
      'aria-label': ariaLabel = 'Download Queue Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M18.4167 7.27778C17.823 4.26315 15.1778 2 12 2C9.47698 2 7.28571 3.43304 6.19444 5.53017C3.56666 5.80979 1.5238 8.03799 1.5238 10.738C1.5238 13.6303 3.87222 15.9809 6.7619 15.9809H18.1111C20.5206 15.9809 22.4762 14.0235 22.4762 11.6118C22.4762 9.305 20.6865 7.43506 18.4167 7.27778ZM6.76184 14.2332C4.83247 14.2332 3.26978 12.6691 3.26978 10.738C3.26978 8.80688 4.83247 7.24277 6.76184 7.24277H7.38168C7.95787 5.22429 9.79993 3.74756 11.9999 3.74756C14.6539 3.74756 16.8015 5.89712 16.8015 8.55348V8.99038H18.111C19.5603 8.99038 20.7301 10.1613 20.7301 11.6118C20.7301 13.0623 19.5603 14.2332 18.111 14.2332H6.76184Z" />
        <path d="M12.8544 10.7375C12.8544 11.2201 12.4719 11.6113 12.0001 11.6113C11.5282 11.6113 11.1457 11.2201 11.1457 10.7375C11.1457 10.255 11.5282 9.86377 12.0001 9.86377C12.4719 9.86377 12.8544 10.255 12.8544 10.7375Z" />
        <path d="M10.2914 10.7375C10.2914 11.2201 9.90893 11.6113 9.4371 11.6113C8.96526 11.6113 8.58276 11.2201 8.58276 10.7375C8.58276 10.255 8.96526 9.86379 9.4371 9.86379C9.90893 9.86379 10.2914 10.255 10.2914 10.7375Z" />
        <path d="M14.5631 11.6113C15.0349 11.6113 15.4174 11.2201 15.4174 10.7375C15.4174 10.255 15.0349 9.86377 14.5631 9.86377C14.0913 9.86377 13.7088 10.255 13.7088 10.7375C13.7088 11.2201 14.0913 11.6113 14.5631 11.6113Z" />
      </Icon>
    );
  },
) as typeof Icon;
