import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ShuffleActiveIcon = forwardRef<SVGSVGElement, IconProps>(
  function ShuffleActiveIcon(
    { 'aria-label': ariaLabel = 'Shuffle On Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M18.9458 10.634L21.2261 8.35367C21.4214 8.15841 21.4214 7.84183 21.2261 7.64657L18.9458 5.3662C18.6308 5.05122 18.0922 5.2743 18.0922 5.71975V7.05373C16.6255 7.20458 14.086 8.03837 11.7978 11.4706C9.65782 14.6806 6.45696 15.0253 5.62988 15.0253C5.0776 15.0253 4.62988 15.473 4.62988 16.0253C4.62988 16.5775 5.0776 17.0253 5.62988 17.0253C6.80281 17.0253 10.8019 16.5699 13.4619 12.58C15.254 9.89193 17.096 9.22434 18.0922 9.06928V10.2805C18.0922 10.7259 18.6308 10.949 18.9458 10.634ZM11.2386 9.89059C8.99473 7.41424 6.73374 7.02539 5.62988 7.02539C5.0776 7.02539 4.62988 7.47311 4.62988 8.02539C4.62988 8.57768 5.0776 9.02539 5.62988 9.02539C6.23888 9.02539 8.06461 9.22229 9.98864 11.4989L11.2386 9.89059ZM14.633 13.6896L13.3431 15.2309C15.1654 16.5311 17.0343 16.9096 18.0922 17.0004V18.2806C18.0922 18.7261 18.6308 18.9491 18.9458 18.6342L21.2261 16.3538C21.4214 16.1585 21.4214 15.842 21.2261 15.6467L18.9458 13.3663C18.6308 13.0513 18.0922 13.2744 18.0922 13.7199V14.9917C17.2962 14.9024 15.9637 14.6001 14.633 13.6896ZM12.499 23C13.324 23 13.999 22.325 13.999 21.5C13.999 20.675 13.324 20 12.499 20C11.674 20 10.999 20.675 10.999 21.5C10.999 22.325 11.674 23 12.499 23Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
