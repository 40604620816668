import { Slider as SliderComponent } from './slider.js';
import { Range, Root, Thumb, Track } from './slider.primitive.js';

export const Slider = Object.assign(SliderComponent, {
  Root,
  Range,
  Thumb,
  Track,
}) as typeof SliderComponent & {
  Root: typeof Root;
  Range: typeof Range;
  Thumb: typeof Thumb;
  Track: typeof Track;
};

export type { SliderProps } from './slider.js';
