import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Grid } from '../grid/index.js';

export const PlayerActions = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(function PlayerActions({ children }, ref) {
  return (
    <Grid
      alignItems="center"
      css={{
        [`@container ${media.xsmall}`]: {
          display: 'none',
          backgroundColor: 'transparent',
          borderRadius: '$0',
          justifyContent: 'end',
          padding: '$0 $8',
        },

        [`@container ${media.medium}`]: {
          display: 'grid',
        },

        [`@container ${media.large}`]: {
          padding: '$0 $8 $0 $0',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'grid',
          backgroundColor: '$gray-600',
          borderRadius: '$999',
          justifyContent: 'center',
          placeSelf: 'center',
          padding: '$8 $6',
          width: 'min-content',
          gridTemplateColumns: 'repeat(2, min-content)',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      data-container="actions"
      gridArea="actions"
      ref={ref}
      templateAreas='"playbackSpeed shuffle volume menu expand"'
      templateColumns={{
        [`@container ${media.medium}`]: 'repeat(3, minmax(0, min-content))',
        [`@container ${media.large}`]:
          'repeat(2, minmax(0, min-content)) auto repeat(2, minmax(0, min-content))',
      }}
    >
      {children}
    </Grid>
  );
});

PlayerActions.displayName = 'Player.Actions';
