import { Button, Dialog } from '@iheartradio/web.companion';
import { useFetcher, useNavigation } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import type { ClearCookieAction } from '../../node/clear-cookie.server.js';

type ClearCookieDialogProps = {
  showDialog: boolean;
};

export const ClearCookieDialog = ({
  showDialog = false,
}: ClearCookieDialogProps) => {
  const [open, setOpen] = useState(showDialog);
  const fetcher = useFetcher<ClearCookieAction>();
  const navigation = useNavigation();
  const isSubmitting = fetcher.state === 'submitting';
  const isLoading = fetcher.state === 'loading';
  const isActionRedirect =
    fetcher.state === 'loading' &&
    fetcher.formMethod != null &&
    navigation.formMethod != 'GET' &&
    fetcher.data == null;

  const clearImageCache = useCallback(() => {
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line promise/catch-or-return
      navigator.serviceWorker.ready.then(registration => {
        // eslint-disable-next-line promise/always-return
        registration.active?.postMessage('purge');
      });
    }
  }, []);

  useEffect(() => {
    if (isActionRedirect) {
      setOpen(false);
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [isActionRedirect]);

  return (
    <ClientOnly>
      {() => (
        <Dialog.Root onOpenChange={setOpen} open={open} showClose={false}>
          <Dialog.Metadata>
            <Dialog.Title>Clear Cookies</Dialog.Title>
            <Dialog.Body>
              Are you sure? Clearing cookies will delete all website data and
              preferences.
            </Dialog.Body>
            <Dialog.ButtonContainer flexDirection="column">
              <fetcher.Form action="/clear-cookie" method="post">
                <Button
                  color="red"
                  disabled={isSubmitting || isLoading}
                  kind="primary"
                  onClick={clearImageCache}
                  size={{ '@initial': 'small', '@medium': 'large' }}
                  type="submit"
                >
                  Clear cookies
                </Button>
              </fetcher.Form>
              <Button
                color={{ dark: 'white', light: 'gray' }}
                disabled={isSubmitting || isLoading}
                kind="secondary"
                onClick={() => setOpen(false)}
                size={{ '@initial': 'small', '@medium': 'large' }}
              >
                Cancel
              </Button>
            </Dialog.ButtonContainer>
          </Dialog.Metadata>
        </Dialog.Root>
      )}
    </ClientOnly>
  );
};
