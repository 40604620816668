import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CancelOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function CancelOutlineIcon(
    {
      'aria-label': ariaLabel = 'Cancel Outline Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
          fillRule="evenodd"
        />
        <path d="M15.8225 8.18354C15.5859 7.94691 15.2036 7.94691 14.967 8.18354L12 11.1445L9.03299 8.17747C8.79636 7.94084 8.41411 7.94084 8.17747 8.17747C7.94084 8.41411 7.94084 8.79636 8.17747 9.03299L11.1445 12L8.17747 14.967C7.94084 15.2036 7.94084 15.5859 8.17747 15.8225C8.41411 16.0592 8.79636 16.0592 9.03299 15.8225L12 12.8555L14.967 15.8225C15.2036 16.0592 15.5859 16.0592 15.8225 15.8225C16.0592 15.5859 16.0592 15.2036 15.8225 14.967L12.8555 12L15.8225 9.03299C16.0531 8.80243 16.0531 8.41411 15.8225 8.18354Z" />
        <path
          clipRule="evenodd"
          d="M7.91231 7.91231C8.29539 7.52923 8.91508 7.52923 9.29816 7.91231L12.0003 10.6144L14.7018 7.91838C15.0849 7.53557 15.7047 7.53539 16.0877 7.91838C16.4647 8.29539 16.4647 8.92115 16.0877 9.29816L13.3858 12L16.0877 14.7018C16.4708 15.0849 16.4708 15.7046 16.0877 16.0877C15.7046 16.4708 15.0849 16.4708 14.7018 16.0877L12 13.3858L9.29816 16.0877C8.91508 16.4708 8.29539 16.4708 7.91231 16.0877C7.52923 15.7046 7.52923 15.0849 7.91231 14.7018L10.6142 12L7.91231 9.29816C7.52923 8.91508 7.52923 8.29539 7.91231 7.91231ZM8.76783 8.44264C8.67764 8.35245 8.53283 8.35245 8.44264 8.44264C8.35245 8.53283 8.35245 8.67764 8.44264 8.76783L11.6748 12L8.44264 15.2322C8.35245 15.3224 8.35245 15.4672 8.44264 15.5574C8.53283 15.6475 8.67764 15.6475 8.76783 15.5574L12 12.3252L15.2322 15.5574C15.3224 15.6475 15.4672 15.6475 15.5574 15.5574C15.6475 15.4672 15.6475 15.3224 15.5574 15.2322L12.3252 12L15.5574 8.76783C15.6415 8.68371 15.6415 8.53283 15.5574 8.44871C15.4672 8.35852 15.3224 8.35852 15.2322 8.44871L11.9997 11.6745L8.76783 8.44264Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
