const DEFAULT_REDIRECT = '/';

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect;
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    const url = new URL(to);

    // We consider redirects to iheart domains "safe"
    if (
      url.hostname.endsWith('iheart.com') ||
      url.hostname.endsWith('ihrint.com')
    ) {
      return to;
    }

    return defaultRedirect;
  }

  return to;
}
