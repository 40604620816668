import type { Ads } from '../../schemas/ads.js';
import { base } from './base.js';

const production: Ads = {
  ...base,
  dfpInstanceId: 83_069_739,
  customAds: {
    ...base.customAds,
    enabled: true,
    type: 'Triton',
    url: 'https://crdl.tritondigital.com/api/ads/delivery',
  },
  adswizzCompanionZones: 91,
  adswizzSubdomain: 'nzme',
  adswizzZoneId: 89,
  headerBidding: {
    enabledBidders: [...base.headerBidding.enabledBidders],
  },
};

const staging: Ads = {
  ...production,
};

const pr: Ads = {
  ...production,
};

export default {
  production,
  staging,
  pr,
};
