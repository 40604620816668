import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PreviousIcon = forwardRef<SVGSVGElement, IconProps>(
  function PreviousIcon(
    {
      'aria-label': ariaLabel = 'Previous Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M7 6C7.55 6 8 6.45 8 7V17C8 17.55 7.55 18 7 18C6.45 18 6 17.55 6 17V7C6 6.45 6.45 6 7 6ZM10.66 12.82L16.43 16.89C17.09 17.36 18.01 16.88 18.01 16.07V7.93C18.01 7.12 17.1 6.65 16.43 7.11L10.66 11.18C10.09 11.58 10.09 12.42 10.66 12.82Z" />
      </Icon>
    );
  },
) as typeof Icon;
