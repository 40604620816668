import type { Outbrain } from '../../../schemas/sdks/outbrain.js';

export const stagingWW: Outbrain = {
  enabled: false,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};

export const productionWW: Outbrain = {
  enabled: false,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};
