import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const HistoryIcon = forwardRef<SVGSVGElement, IconProps>(
  function HistoryIcon(
    {
      'aria-label': ariaLabel = 'History Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M13.3836 3.00349C8.3676 2.86352 4.25822 6.95272 4.25822 12.0017H2.49424C2.05079 12.0017 1.83398 12.5416 2.14933 12.8516L4.89877 15.651C5.09587 15.851 5.40136 15.851 5.59845 15.651L8.34789 12.8516C8.65339 12.5416 8.43659 12.0017 7.99313 12.0017H6.22915C6.22915 8.1025 9.36292 4.95311 13.2259 5.0031C16.8919 5.05309 19.9764 8.18249 20.0256 11.9018C20.0749 15.811 16.9707 19.0004 13.1274 19.0004C11.5408 19.0004 10.0725 18.4505 8.90961 17.5207C8.51542 17.2107 7.96356 17.2407 7.6088 17.6007C7.1949 18.0206 7.22447 18.7304 7.68763 19.0904C9.18554 20.2901 11.0678 21 13.1274 21C18.104 21 22.1345 16.8308 21.9966 11.7418C21.8685 7.0527 18.0054 3.13346 13.3836 3.00349ZM12.881 8.00252C12.477 8.00252 12.1419 8.34245 12.1419 8.75237V12.4317C12.1419 12.7816 12.3292 13.1115 12.6248 13.2915L15.6995 15.1411C16.0542 15.3511 16.5075 15.2311 16.7145 14.8812C16.9214 14.5213 16.8032 14.0613 16.4583 13.8514L13.6201 12.1417V8.74238C13.6201 8.34245 13.2851 8.00252 12.881 8.00252Z" />
      </Icon>
    );
  },
) as typeof Icon;
