import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RepeatOneIcon = forwardRef<SVGSVGElement, IconProps>(
  function ReorderIcon(
    { 'aria-label': ariaLabel = 'Repeat One Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M7.0899 19.2136V18.002L17.093 18.002H17.6733C18.7432 18.002 19.4611 17.3452 19.7589 17.0156C20.1964 16.5312 20.2667 16.0736 20.2667 15.2656V8.55129V8.14062C20.2667 7.14062 20.0963 6.74104 19.5263 6.27713C18.841 5.71946 17.9304 5.67188 16.9833 5.67188H7.15922H6.67291C5.72694 5.70994 5.36041 5.79688 4.64166 6.45312C4.19033 6.86521 3.79791 7.50852 3.79791 8.70312V11.6406V12.0547C3.79791 12.2109 3.81745 12.5156 3.93463 12.6953C4.05182 12.875 4.32843 13.1328 4.83697 13.1328C5.19635 13.1328 5.41769 13.0777 5.63525 12.8828C5.79344 12.7411 5.93694 12.5508 5.93694 12.1608L5.93121 8.55129L5.93694 8.45072C5.96624 8.19149 6.10767 7.95086 6.33365 7.77576C6.55963 7.60067 6.85395 7.50365 7.15922 7.50364H16.9833L17.1012 7.50852C17.4051 7.53353 17.6871 7.65419 17.8924 7.84698C18.0976 8.03977 18.2113 8.29086 18.2114 8.55129V14.2734V14.6177V14.9688C18.2114 14.9967 18.2117 15.0263 18.2121 15.0573C18.2154 15.3339 18.2199 15.7169 17.9854 15.9297C17.7246 16.1663 17.5718 16.1914 17.2667 16.1914H7.0899V15.9702V14.7044C7.0899 14.2639 6.58276 14.0434 6.28616 14.3548L4.13887 16.6094C3.95501 16.8025 3.95501 17.1155 4.13887 17.3086L6.28616 19.5632C6.58276 19.8746 7.0899 19.6541 7.0899 19.2136ZM11.5816 15.0938H13.1628V8.58203H11.8043L9.63969 10.328L10.428 11.3034L11.1719 10.6977C11.2461 10.6323 11.3248 10.5596 11.4079 10.4794C11.4911 10.3993 11.5594 10.3265 11.6128 10.2612C11.6069 10.4037 11.6009 10.5641 11.595 10.7422C11.5891 10.9204 11.5846 11.0881 11.5816 11.2455V11.5974V15.0938ZM12.501 23.502C13.326 23.502 14.001 22.827 14.001 22.002C14.001 21.177 13.326 20.502 12.501 20.502C11.676 20.502 11.001 21.177 11.001 22.002C11.001 22.827 11.676 23.502 12.501 23.502Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
