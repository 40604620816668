import { Flex } from '@iheartradio/web.companion';
import type { ReactNode } from 'react';

const AppErrorItem = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      as="main"
      data-test="page-error-item"
      gridArea="content"
      maxWidth="42rem"
      overflowX="hidden"
      overflowY="auto"
    >
      <Flex flexGrow={1} width="100%">
        <Flex height="fit-content" minHeight="100%" width="inherit">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppErrorItem;
