import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { ThumbDownFilledIcon } from '../icons/thumb-down-filled-icon.js';
import { ThumbDownOutlineIcon } from '../icons/thumb-down-outline-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

export type PlayerThumbDownProps = Partial<
  Pick<ButtonProps, 'color' | 'disabled'> & JSX.IntrinsicElements['button']
> & {
  active?: boolean;
};

export const PlayerThumbDown = forwardRef<
  HTMLButtonElement,
  PlayerThumbDownProps
>(function PlayerThumbDown({ active, ...props }, ref) {
  return (
    <Box
      css={{
        display: 'none',
        padding: '$0 $8',

        [`@container ${media.large}`]: {
          display: 'block',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'block',
          padding: '$0 $0 $0 $2',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="thumbDown"
    >
      <Tooltip
        side="top"
        trigger={
          <Button
            color={{ dark: 'white', light: 'gray' }}
            kind="tertiary"
            ref={ref}
            size="icon"
            {...props}
          >
            {active ?
              <ThumbDownFilledIcon
                css={{
                  dark: { fill: '$blue-400 !important' },
                  light: { fill: '$blue-500 !important' },
                }}
                size={32}
              />
            : <ThumbDownOutlineIcon size={32} />}
          </Button>
        }
      >
        <Text
          color={{
            dark: '$brand-black',
            light: '$brand-white',
          }}
          kind="caption-3"
        >
          Thumb Down
        </Text>
      </Tooltip>
    </Box>
  );
});

PlayerThumbDown.displayName = 'Player.ThumbDown';
