import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ProfileSettingsIcon = forwardRef<SVGSVGElement, IconProps>(
  function ProfileSettingsIcon(
    {
      'aria-label': ariaLabel = 'Profile Settings Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M3 7H13C13.55 7 14 7.45 14 8C14 8.55 13.55 9 13 9H3C2.45 9 2 8.55 2 8C2 7.45 2.45 7 3 7ZM3 3H13C13.55 3 14 3.45 14 4C14 4.55 13.55 5 13 5H3C2.45 5 2 4.55 2 4C2 3.45 2.45 3 3 3ZM9 13H3C2.45 13 2 12.55 2 12C2 11.45 2.45 11 3 11H9C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13ZM20.742 15.5C20.742 15.687 20.725 15.863 20.7024 16.039L21.8954 16.9465C22.0029 17.029 22.0311 17.1775 21.9633 17.2985L20.8324 19.2015C20.7646 19.3225 20.6176 19.372 20.4875 19.3225L19.0796 18.7725C18.7856 18.987 18.4689 19.174 18.124 19.3115L17.9092 20.769C17.8922 20.901 17.7735 21 17.6321 21H15.3704C15.229 21 15.1103 20.901 15.0933 20.769L14.8785 19.3115C14.5336 19.174 14.2169 18.9925 13.9229 18.7725L12.515 19.3225C12.3906 19.3665 12.2379 19.3225 12.1701 19.2015L11.0392 17.2985C10.9714 17.1775 10.9996 17.029 11.1071 16.9465L12.3001 16.039C12.2775 15.863 12.2605 15.6815 12.2605 15.5C12.2605 15.3185 12.2775 15.137 12.3001 14.961L11.1071 14.0535C10.9996 13.971 10.9657 13.8225 11.0392 13.7015L12.1701 11.7985C12.2379 11.6775 12.3849 11.628 12.515 11.6775L13.9229 12.2275C14.2169 12.013 14.5336 11.826 14.8785 11.6885L15.0933 10.231C15.1103 10.099 15.229 10 15.3704 10H17.6321C17.7735 10 17.8922 10.099 17.9092 10.231L18.124 11.6885C18.4689 11.826 18.7856 12.0075 19.0796 12.2275L20.4875 11.6775C20.6119 11.6335 20.7646 11.6775 20.8324 11.7985L21.9633 13.7015C22.0311 13.8225 22.0029 13.971 21.8954 14.0535L20.7024 14.961C20.725 15.137 20.742 15.313 20.742 15.5ZM14.5222 15.5C14.5222 16.5615 15.41 17.425 16.5012 17.425C17.5925 17.425 18.4803 16.5615 18.4803 15.5C18.4803 14.4385 17.5925 13.575 16.5012 13.575C15.41 13.575 14.5222 14.4385 14.5222 15.5Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
