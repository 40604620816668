import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SavePlaylistIcon = forwardRef<SVGSVGElement, IconProps>(
  function SavePlaylistIcon(
    {
      'aria-label': ariaLabel = 'Save Playlist Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M13 9H3C2.45 9 2 9.45 2 10C2 10.55 2.45 11 3 11H13C13.55 11 14 10.55 14 10C14 9.45 13.55 9 13 9ZM13 5H3C2.45 5 2 5.45 2 6C2 6.55 2.45 7 3 7H13C13.55 7 14 6.55 14 6C14 5.45 13.55 5 13 5ZM18 13V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V13H13C12.45 13 12 13.45 12 14C12 14.55 12.45 15 13 15H16V18C16 18.55 16.45 19 17 19C17.55 19 18 18.55 18 18V15H21C21.55 15 22 14.55 22 14C22 13.45 21.55 13 21 13H18ZM3 15H9C9.55 15 10 14.55 10 14C10 13.45 9.55 13 9 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15Z" />
      </Icon>
    );
  },
) as typeof Icon;
