import type { Environment } from '../../schemas/environment.js';
import { SUPPORTED_COUNTRIES } from './constants.js';

export const stagingMX: Environment = {
  countryCode: 'MX',
  hostName: 'webapp.MX',
  hosts: {
    account: 'account.web.mx',
    listen: 'listen.web.mx',
  },
  objectDbBucket: 'web.listen.staging',
  markPlayedThreshold: 30,
  supportedCountries: SUPPORTED_COUNTRIES,
  terminalId: 164,
  territoryCode: 'MX',
  websiteUrl: 'https://stage.iheart.com',
};

export const productionMX: Environment = {
  ...stagingMX,
  objectDbBucket: 'web.listen.prod',
  websiteUrl: 'https://www.iheart.com',
};

export const pullRequestMX: Environment = {
  ...productionMX,
  objectDbBucket: 'web.listen.staging',
};
