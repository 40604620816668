import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PinterestIcon = forwardRef<SVGSVGElement, IconProps>(
  function PinterestIcon(
    {
      'aria-label': ariaLabel = 'Pinterest Icon',

      children: _children,
      fill: _fill,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          d="M12 21.9851C17.5228 21.9851 22 17.5117 22 11.9934C22 6.47506 17.5228 2.00159 12 2.00159C6.47715 2.00159 2 6.47506 2 11.9934C2 17.5117 6.47715 21.9851 12 21.9851Z"
          fill="white"
        />
        <path
          d="M12 2.00159C6.47737 2.00159 2 6.47527 2 11.9934C2 16.2286 4.63374 19.847 8.35391 21.3026C8.26337 20.5131 8.1893 19.296 8.38683 18.4325C8.5679 17.6513 9.55556 13.4654 9.55556 13.4654C9.55556 13.4654 9.25926 12.8651 9.25926 11.9851C9.25926 10.5953 10.0658 9.55915 11.07 9.55915C11.9259 9.55915 12.3374 10.2006 12.3374 10.9654C12.3374 11.8207 11.7942 13.1036 11.5062 14.296C11.2675 15.2911 12.0082 16.1052 12.9877 16.1052C14.7654 16.1052 16.1317 14.2302 16.1317 11.5328C16.1317 9.13974 14.4115 7.47034 11.9506 7.47034C9.10288 7.47034 7.4321 9.60027 7.4321 11.8042C7.4321 12.6595 7.76132 13.5805 8.17284 14.0822C8.25514 14.1809 8.26337 14.2713 8.23868 14.37C8.16461 14.6825 7.99177 15.3651 7.95885 15.5049C7.9177 15.6858 7.8107 15.7269 7.6214 15.6365C6.37037 15.0526 5.58848 13.2351 5.58848 11.7631C5.58848 8.61343 7.87654 5.71869 12.1975 5.71869C15.6626 5.71869 18.3621 8.1858 18.3621 11.4917C18.3621 14.9374 16.1893 17.7088 13.177 17.7088C12.1646 17.7088 11.2099 17.1825 10.8889 16.5575C10.8889 16.5575 10.3868 18.4654 10.2634 18.9342C10.0412 19.8059 9.4321 20.8914 9.02058 21.5575C9.95885 21.8453 10.9465 22.0016 11.9835 22.0016C17.5062 22.0016 21.9835 17.5279 21.9835 12.0098C22 6.47527 17.5226 2.00159 12 2.00159Z"
          fill="#27292D"
        />
      </Icon>
    );
  },
) as typeof Icon;
