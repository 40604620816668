import type { ComponentProps } from 'react';

import { styled } from '../../stitches.config.js';
import { Box } from '../box/index.js';
import { kinds } from '../text/text.js';

/**
 * @deprecated Use `Badge` from `@iheartradio/web.accomplice/badge` instead
 */
export const Badge = styled(Box, {
  ...kinds['overline-1'],
  borderRadius: '$2',
  display: 'inline-block',

  variants: {
    size: {
      large: {
        padding: '$2 $6',
      },
      small: {
        padding: '$0 $4',
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});

Badge.displayName = 'Badge';

export type BadgeProps = ComponentProps<typeof Badge>;
