import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ThumbDownOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function ThumbDownOutlineIcon(
    { 'aria-label': ariaLabel = 'Thumb Down Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M10.3008 14.3893L9.61471 17.6972C9.54259 18.0584 9.65085 18.4267 9.91094 18.6867C10.337 19.1056 11.0158 19.1056 11.4348 18.6795L15.4289 14.6782C15.696 14.411 15.8479 14.0426 15.8479 13.6598V6.44451C15.8479 5.65003 15.1977 5 14.4033 5H7.91028C7.33245 5 6.81244 5.34668 6.5885 5.87393L4.23395 11.3703C3.61996 12.8003 4.66737 14.3893 6.22015 14.3893H10.3008ZM12.0757 12.9448L11.2777 16.7922L14.4032 13.6611L14.4033 13.6598V6.44451H7.91555L5.56129 11.9402C5.35602 12.4183 5.70625 12.9448 6.22015 12.9448H12.0757ZM18.5011 5C17.7034 5 17.0566 5.64673 17.0566 6.44451V12.2225C17.0566 13.0203 17.7034 13.6671 18.5011 13.6671C19.2989 13.6671 19.9457 13.0203 19.9457 12.2225V6.44451C19.9457 5.64673 19.2989 5 18.5011 5Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
