import { Slot } from '@radix-ui/react-slot';
import { type ForwardedRef, type ReactNode, forwardRef } from 'react';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import { type LinkVariants, linkStyles } from './link.css.js';

export type LinkProps = LinkVariants & {
  children?: ReactNode;
  to?: string | Record<string, any>;
  css?: RainbowSprinkles;
  preventScrollReset?: boolean;
  asChild?: boolean;
} & ElementProps<'a'>;

function Link(
  { asChild, children, css, ...props }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const { className, style, otherProps } = getRecipeStyleProps(
    linkStyles,
    props,
    css,
  );

  const Component = asChild ? Slot : 'a';

  return (
    <Component
      className={className}
      data-test="link"
      ref={ref}
      style={style}
      {...otherProps}
    >
      {children}
    </Component>
  );
}

const _Link: React.ForwardRefExoticComponent<LinkProps> = forwardRef(Link);
export { _Link as Link };
