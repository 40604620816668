import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CancelIcon = forwardRef<SVGSVGElement, IconProps>(
  function CancelIcon(
    { 'aria-label': ariaLabel = 'Cancel Icon', children: _children, ...props },
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.5149 16.515C16.1054 16.9245 15.4439 16.9245 15.0344 16.515L11.9999 13.4805L8.96536 16.515C8.55586 16.9245 7.89436 16.9245 7.48486 16.515C7.07536 16.1055 7.07536 15.444 7.48486 15.0345L10.5194 12L7.48486 8.96548C7.07536 8.55598 7.07536 7.89448 7.48486 7.48498C7.89436 7.07548 8.55586 7.07548 8.96536 7.48498L11.9999 10.5195L15.0344 7.48498C15.4439 7.07548 16.1054 7.07548 16.5149 7.48498C16.9244 7.89448 16.9244 8.55598 16.5149 8.96548L13.4804 12L16.5149 15.0345C16.9139 15.4335 16.9139 16.1055 16.5149 16.515Z" />
      </Icon>
    );
  },
) as typeof Icon;
