import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { isPlainObject } from 'remeda';

import type { Component, CSSProperties, VariantProps } from '../../types.js';
import type { IconSize } from '../icon/index.js';
import { useTheme } from '../theme/index.js';
import * as Primitives from './logo.primitive.js';

type Fill = VariantProps<typeof Primitives.Logo>['fill'];

export type LogoProps = PropsWithChildren<{
  'aria-label'?: string;
  css?: CSSProperties;
  fill?: Fill | { dark: Fill; light: Fill };
  id?: string;
  size?: IconSize;
  media?: string;
}>;

export const Logo = forwardRef<SVGSVGElement, LogoProps>(function Logo(
  {
    'aria-label': ariaLabel,
    children,
    css,
    fill,
    id,
    size,
    ...props
  }: LogoProps,
  ref,
) {
  const theme = useTheme();

  return (
    <Primitives.Logo
      aria-labelledby={id}
      css={{ ...css }}
      fill={isPlainObject(fill) ? fill[theme] : fill}
      ref={ref}
      role="img"
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={id}>{ariaLabel}</title>
      {children}
    </Primitives.Logo>
  );
}) as Component<'svg', LogoProps>;

export type LogoType = typeof Logo;
