import * as Analytics from '@iheartradio/web.analytics';
import { isNotBlank } from '@iheartradio/web.utilities';
import { useEffect, useRef } from 'react';

import { useAnalytics } from './create-analytics';

export function usePageView(
  pageName: Analytics.Analytics.PageView['pageName'],
  pageData?: Analytics.Analytics.PageView['view'],
) {
  const pageNameRef = useRef<typeof pageName>();
  const pageDataRef = useRef<typeof pageData>();

  const analytics = useAnalytics();

  useEffect(() => {
    if (
      (pageNameRef.current !== pageName ||
        JSON.stringify(pageData) !==
          JSON.stringify(pageDataRef.current ?? {})) &&
      isNotBlank(pageName)
    ) {
      pageNameRef.current = pageName;
      pageDataRef.current = pageData;

      analytics.track({
        type: Analytics.eventType.enum.PageView,
        data: {
          pageName,
          ...(isNotBlank(pageDataRef.current) && { view: pageDataRef.current }),
          window: {
            location: {
              href: window.location.href,
            },
          },
        },
      });
    }
  }, [analytics, pageData, pageName]);
}
