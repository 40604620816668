import { PlayerActions } from './player-actions.js';
import { PlayerAddToPlaylist } from './player-add-to-playlist.js';
import { PlayerArtwork } from './player-artwork.js';
import { PlayerBack15 } from './player-back-15.js';
import { PlayerControls } from './player-controls.js';
import { PlayerDescription } from './player-description.js';
import { PlayerExpand } from './player-expand.js';
import { PlayerForward30 } from './player-forward-30.js';
import { PlayerFullScreen } from './player-full-screen.js';
import { PlayerLink } from './player-link.js';
import { PlayerMenu, PlayerMenuItem } from './player-menu.js';
import { PlayerMetadata } from './player-metadata.js';
import { PlayerMetadataText } from './player-metadata-text.js';
import { PlayerNext } from './player-next.js';
import { PlayerPlay } from './player-play.js';
import { PlayerPlaybackSpeed } from './player-playback-speed.js';
import { PlayerPrevious } from './player-previous.js';
import { PlayerRoot, useFullScreen } from './player-root.js';
import { PlayerShuffle } from './player-shuffle.js';
import { PlayerSubtitle } from './player-subtitle.js';
import { PlayerThumbDown } from './player-thumb-down.js';
import { PlayerThumbUp } from './player-thumb-up.js';
import { PlayerTime } from './player-time.js';
import { PlayerTitle } from './player-title.js';
import { PlayerVolume } from './player-volume.js';
export { FullScreenProvider } from './player-root.js';

export const Player = Object.assign(
  {},
  {
    Actions: PlayerActions,
    Artwork: PlayerArtwork,
    Back15: PlayerBack15,
    Controls: PlayerControls,
    Description: PlayerDescription,
    FullScreen: PlayerFullScreen,
    Expand: PlayerExpand,
    Forward30: PlayerForward30,
    Link: PlayerLink,
    Menu: PlayerMenu,
    MenuItem: PlayerMenuItem,
    Metadata: PlayerMetadata,
    MetadataText: PlayerMetadataText,
    Next: PlayerNext,
    Play: PlayerPlay,
    PlaybackSpeed: PlayerPlaybackSpeed,
    Previous: PlayerPrevious,
    Root: PlayerRoot,
    AddToPlaylist: PlayerAddToPlaylist,
    Shuffle: PlayerShuffle,
    Subtitle: PlayerSubtitle,
    ThumbDown: PlayerThumbDown,
    ThumbUp: PlayerThumbUp,
    Time: PlayerTime,
    Title: PlayerTitle,
    Volume: PlayerVolume,
    useFullScreen,
  },
);
