import { type TextProps, Text } from '../text/index.js';

type DialogTitleProps = Omit<TextProps, 'kind' | 'as'>;

export const DialogTitle = ({ children, ...props }: DialogTitleProps) => (
  <Text
    as="h4"
    kind={{ '@initial': 'h5', '@medium': 'h4' }}
    textAlign="center"
    {...props}
  >
    {children}
  </Text>
);
