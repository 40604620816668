import { forwardRef } from 'react';
import { isNullish } from 'remeda';

import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { NextIcon } from '../icons/next-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerNextProps = JSX.IntrinsicElements['button'] & {
  disabled?: ButtonProps['disabled'];
  skips?: number;
};

export const PlayerNext = forwardRef<HTMLButtonElement, PlayerNextProps>(
  function PlayerNext({ disabled, skips, ...props }, ref) {
    const tooltip =
      isNullish(skips) ? 'Next' : (
        `Next (${skips} ${skips === 1 ? 'Skip' : 'Skips'} Left)`
      );

    return (
      <Box gridArea="next" justifySelf="center" position="relative">
        {isNullish(skips) ? null : (
          <Text
            color={
              disabled ? { dark: '$gray-400', light: '$gray-300' } : undefined
            }
            kind="caption-3"
            left="50%"
            position="absolute"
            top="0"
            transform="translateX(-50%)"
            zIndex="$1"
          >
            {skips}
          </Text>
        )}
        <Tooltip
          side="top"
          trigger={
            <Button
              {...props}
              color={{ dark: 'white', light: 'gray' }}
              disabled={disabled}
              kind="tertiary"
              ref={ref}
              size="icon"
            >
              <NextIcon
                css={{
                  height: '3.2rem',

                  '[data-player-type="fullscreen"] &': {
                    height: '4rem',
                  },
                }}
              />
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            {tooltip}
          </Text>
        </Tooltip>
      </Box>
    );
  },
);

PlayerNext.displayName = 'Player.Next';
