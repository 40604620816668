import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const MaximizeIcon = forwardRef<SVGSVGElement, IconProps>(
  function MaximizeIcon(
    { 'aria-label': ariaLabel = 'Maximize Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props} viewBox="-3 -3 30 30">
        <path d="M11.1478 13.6833L6.51039 18.3242H10.5624C10.8863 18.3242 11.152 18.5901 11.152 18.9142C11.152 19.2382 10.8904 19.5 10.5666 19.5L5.09467 19.5C4.77084 19.5 4.50928 19.2382 4.50928 18.9142L4.50098 13.4381C4.50098 13.1141 4.76253 12.8523 5.08636 12.8523C5.4102 12.8523 5.67175 13.1141 5.67175 13.4381L5.68005 17.4932L10.3175 12.8523C10.5458 12.6238 10.9195 12.6238 11.1478 12.8523C11.3762 13.0808 11.3762 13.4548 11.1478 13.6833Z" />
        <path d="M12.8512 10.3167L17.4886 5.67581H13.4365C13.1127 5.67581 12.847 5.4099 12.847 5.08583C12.847 4.76175 13.1086 4.5 13.4324 4.5L18.9043 4.5C19.2281 4.5 19.4897 4.76175 19.4897 5.08583L19.498 10.5619C19.498 10.8859 19.2364 11.1477 18.9126 11.1477C18.5888 11.1477 18.3272 10.8859 18.3272 10.5619L18.3189 6.50677L13.6815 11.1477C13.4532 11.3762 13.0795 11.3762 12.8512 11.1477C12.6228 10.9192 12.6228 10.5452 12.8512 10.3167Z" />
      </Icon>
    );
  },
) as typeof Icon;
