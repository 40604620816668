export type { FieldProps } from './field.js';
export type { FieldHintProps } from './hint.js';
export type { FieldMessageProps } from './message.js';

import { Field as _Field } from './field.js';
import {
  ErrorIcon,
  FieldContainer,
  FieldLabel,
  FieldMessageContainer,
  FieldMessageContent,
} from './field.primitive.js';
import { FieldHint } from './hint.js';
import { FieldMessage } from './message.js';

export const Field = Object.assign(_Field, {
  ErrorIcon,
  Container: FieldContainer,
  Label: FieldLabel,
  MessageContainer: FieldMessageContainer,
  MessageContent: FieldMessageContent,
  Hint: FieldHint,
  Message: FieldMessage,
}) as typeof _Field & {
  ErrorIcon: typeof ErrorIcon;
  Container: typeof FieldContainer;
  Label: typeof FieldLabel;
  MessageContainer: typeof FieldMessageContainer;
  MessageContent: typeof FieldMessageContent;
  Hint: typeof FieldHint;
  Message: typeof FieldMessage;
};
