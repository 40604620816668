import { shadows } from './shadows.js';

export const filters = {
  'drop-shadow-1': `drop-shadow(${shadows['elevation-1']})`,
  'drop-shadow-4': `drop-shadow(${shadows['elevation-4']})`,
  'drop-shadow-6': `drop-shadow(${shadows['elevation-6']})`,
  'drop-shadow-8': `drop-shadow(${shadows['elevation-8']})`,
  'drop-shadow-up-1': `drop-shadow(${shadows['elevation-up-1']})`,
  'drop-shadow-up-4': `drop-shadow(${shadows['elevation-up-4']})`,
} as const;
