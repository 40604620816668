import { styled } from '../../stitches.config.js';
import { kinds } from '../text/text.js';

export const Button = styled('button', {
  alignItems: 'center',
  appearance: 'none',
  borderRadius: '$999',
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: '0.4rem',
  position: 'relative',
  textDecoration: 'none',
  userSelect: 'none',

  // Prevent other selectors (e.g. parent, sibling, etc.) from overriding the `pointer-events` value for Buttons
  pointerEvents: 'all',

  focusVisible: {
    transition: 'none',

    dark: {
      outline: '0.2rem solid $blue-400',
    },
    light: {
      outline: '0.2rem solid $blue-500',
    },
    outlineOffset: '0.2rem',
  },

  hover: {
    cursor: 'pointer',
  },

  '@motion': {
    transition: 'all 200ms ease',

    pressed: {
      transform: 'scale(0.95)',
    },
  },

  disabled: {
    pointerEvents: 'none',
  },

  svg: {
    dark: {
      fill: 'currentColor',
    },
    light: {
      fill: 'currentColor',
    },
  },

  variants: {
    color: {
      blue: {},
      gray: {},
      green: {},
      magenta: {},
      orange: {},
      purple: {},
      red: {},
      teal: {},
      white: {},
      yellow: {},
    },
    icon: {
      true: {},
    },
    inline: {
      false: {
        display: 'flex',
        width: '100%',
      },
    },
    kind: {
      primary: {
        color: '$brand-white',

        disabled: {
          dark: {
            backgroundColor: '$gray-500',
            color: '$gray-400',
          },
          light: {
            backgroundColor: '$gray-200',
            color: '$gray-300',
          },
        },
      },
      secondary: {
        backgroundColor: 'transparent',
        boxShadow: '0 0 0 0.1rem currentColor inset',

        disabled: {
          dark: {
            backgroundColor: 'transparent',
            color: '$gray-400',
          },
          light: {
            backgroundColor: 'transparent',
            color: '$gray-300',
          },
        },
      },
      tertiary: {
        backgroundColor: 'transparent',

        '&[data-state="open"]': {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        hover: {
          dark: {
            backgroundColor: '$gray-400',
          },
          light: {
            backgroundColor: '$gray-300',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-400',
          },
          light: {
            backgroundColor: '$gray-300',
          },
        },

        disabled: {
          dark: {
            color: '$gray-400',
          },
          light: {
            color: '$gray-300',
          },
        },
      },
    },

    size: {
      icon: {
        height: 'auto',
        padding: '$4',
      },
      large: {
        ...kinds['button-1'],
        height: '4.8rem',
        padding: '$12 $24',
      },
      small: {
        ...kinds['button-2'],
        height: '3.2rem',
        padding: '$8 $16',
      },
    },
  },

  compoundVariants: [
    {
      color: 'blue',
      kind: 'primary',
      css: {
        backgroundColor: '$blue-600',

        hover: {
          backgroundColor: '$blue-500',
        },

        pressed: {
          backgroundColor: '$blue-500',
        },
      },
    },

    {
      color: 'gray',
      kind: 'primary',
      css: {
        backgroundColor: '$gray-600',

        hover: {
          backgroundColor: '$gray-400',
        },

        pressed: {
          backgroundColor: '$gray-400',
        },
      },
    },

    {
      color: 'green',
      kind: 'primary',
      css: {
        backgroundColor: '$green-600',

        hover: {
          backgroundColor: '$green-500',
        },

        pressed: {
          backgroundColor: '$green-500',
        },
      },
    },

    {
      color: 'magenta',
      kind: 'primary',
      css: {
        backgroundColor: '$magenta-650',

        hover: {
          backgroundColor: '$magenta-550',
        },

        pressed: {
          backgroundColor: '$magenta-550',
        },
      },
    },

    {
      color: 'orange',
      kind: 'primary',
      css: {
        backgroundColor: '$orange-650',

        hover: {
          backgroundColor: '$orange-550',
        },

        pressed: {
          backgroundColor: '$orange-550',
        },
      },
    },

    {
      color: 'purple',
      kind: 'primary',
      css: {
        backgroundColor: '$purple-650',

        hover: {
          backgroundColor: '$purple-550',
        },

        pressed: {
          backgroundColor: '$purple-550',
        },
      },
    },

    {
      color: 'red',
      kind: 'primary',
      css: {
        backgroundColor: '$red-550',

        hover: {
          backgroundColor: '$red-400',
        },

        pressed: {
          backgroundColor: '$red-400',
        },
      },
    },

    {
      color: 'teal',
      kind: 'primary',
      css: {
        backgroundColor: '$teal-650',

        hover: {
          backgroundColor: '$teal-550',
        },

        pressed: {
          backgroundColor: '$teal-550',
        },
      },
    },

    {
      color: 'white',
      kind: 'primary',
      css: {
        backgroundColor: '$brand-white',
        color: '$gray-600',

        hover: {
          backgroundColor: '$gray-300',
        },

        pressed: {
          backgroundColor: '$gray-300',
        },
      },
    },

    {
      color: 'yellow',
      kind: 'primary',
      css: {
        backgroundColor: '$yellow-600',
        color: '$gray-600',

        hover: {
          backgroundColor: '$yellow-500',
        },

        pressed: {
          backgroundColor: '$yellow-500',
        },
      },
    },

    {
      color: 'blue',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },

          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$blue-250',
        },

        light: {
          color: '$blue-600',
        },
      },
    },

    {
      color: 'green',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$green-250',
        },

        light: {
          color: '$green-600',
        },
      },
    },

    {
      color: 'gray',
      kind: 'secondary',
      css: {
        color: '$gray-600',

        hover: {
          backgroundColor: '$gray-300',
        },

        pressed: {
          backgroundColor: '$gray-300',
        },
      },
    },

    {
      color: 'magenta',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$magenta-250',
        },

        light: {
          color: '$magenta-650',
        },
      },
    },

    {
      color: 'orange',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$orange-250',
        },

        light: {
          color: '$orange-650',
        },
      },
    },

    {
      color: 'purple',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$purple-250',
        },

        light: {
          color: '$purple-650',
        },
      },
    },

    {
      color: 'teal',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$teal-250',
        },

        light: {
          color: '$teal-650',
        },
      },
    },

    {
      color: 'red',
      kind: 'secondary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$red-300',
        },

        light: {
          color: '$red-600',
        },
      },
    },

    {
      color: 'yellow',
      kind: 'secondary',
      css: {
        // Have to hardcode color value. Stitches doesn't support color variables within box-shadows.
        boxShadow: '0 0 0 0.1rem #CC8838 inset',

        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$yellow-250',
        },

        light: {
          color: '$gray-600',
        },
      },
    },

    {
      color: 'white',
      kind: 'secondary',
      css: {
        color: '$brand-white',

        hover: {
          backgroundColor: '$gray-400',
        },

        pressed: {
          backgroundColor: '$gray-400',
        },
      },
    },

    {
      color: 'blue',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$blue-250',
        },

        light: {
          color: '$blue-600',
        },
      },
    },
    {
      color: 'gray',
      kind: 'tertiary',
      css: {
        color: '$gray-600',

        hover: {
          dark: {
            backgroundColor: '$gray-400',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-400',
          },
        },
      },
    },
    {
      color: 'green',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },

          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$green-250',
        },

        light: {
          color: '$green-600',
        },
      },
    },
    {
      color: 'magenta',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$magenta-250',
        },

        light: {
          color: '$magenta-650',
        },
      },
    },
    {
      color: 'orange',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$orange-250',
        },

        light: {
          color: '$orange-650',
        },
      },
    },
    {
      color: 'purple',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$purple-250',
        },

        light: {
          color: '$purple-650',
        },
      },
    },
    {
      color: 'red',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$red-300',
        },

        light: {
          color: '$red-600',
        },
      },
    },
    {
      color: 'teal',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$teal-250',
        },

        light: {
          color: '$teal-650',
        },
      },
    },
    {
      color: 'white',
      kind: 'tertiary',
      css: {
        color: '$brand-white',

        hover: {
          light: {
            backgroundColor: '$gray-400',
          },
        },

        pressed: {
          light: {
            backgroundColor: '$gray-400',
          },
        },
      },
    },
    {
      color: 'yellow',
      kind: 'tertiary',
      css: {
        hover: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        pressed: {
          dark: {
            backgroundColor: '$gray-500',
          },
          light: {
            backgroundColor: '$gray-200',
          },
        },

        dark: {
          color: '$yellow-250',
        },

        light: {
          color: '$gray-600',
        },
      },
    },
    {
      icon: true,
      size: 'large',
      css: {
        height: 'auto',
        padding: '$12',
      },
    },
    {
      icon: true,
      size: 'small',
      css: {
        height: 'auto',
        padding: '$8',
      },
    },
  ],
});

Button.displayName = 'Button';
