import * as Primitive from 'react-aria-components';

import { styled } from '../../stitches.config.js';
import { inputStyles } from '../input/input.primitive.js';
import { kinds } from '../text/text.js';

export const Root = styled(Primitive.ComboBox);

Root.displayName = 'Combobox';

export const Input = styled(Primitive.Input, {
  ...inputStyles,
  cursor: 'auto',
  textOverflow: 'ellipsis',
});

Input.displayName = 'Combobox.Input';

export const ListBox = Primitive.ListBox;

const selectedOptionStyles = {
  light: {
    backgroundColor: '$gray-250',
  },
  dark: {
    backgroundColor: '$gray-500',
  },
};

export const ListBoxItem = styled(Primitive.ListBoxItem, {
  padding: '$8',
  cursor: 'pointer',
  ...kinds['body-4'],

  // This highlights the selected value, whether using the keyboard or the mouse to select the options
  '&[data-focused]': selectedOptionStyles,

  // Highlights the currently selected option (current input value) when opening the popover
  '&[data-selected]': selectedOptionStyles,

  hover: selectedOptionStyles,
});

ListBoxItem.displayName = 'Combobox.ListBoxItem';

export const ListBoxItemText = styled(Primitive.Text);

ListBoxItemText.displayName = 'Combobox.ListBoxItemText';

export const Popover = styled(Primitive.Popover, {
  borderRadius: '$6',
  boxShadow: '$elevation-4',
  overflowY: 'auto',
  maxHeight: '20rem !important',
  padding: '$12 0',

  '@initial': {
    width: '25rem',
  },

  '@large': {
    width: '29rem',
  },

  dark: {
    color: '$brand-white',
    backgroundColor: '$gray-600',
  },

  light: {
    backgroundColor: '$brand-white',
    color: '$gray-600',
  },
});

Popover.displayName = 'Combobox.Popover';

export const Button = styled(Primitive.Button);

Button.displayName = 'Combobox.Button';
