import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ArrowUpIcon = forwardRef<SVGSVGElement, IconProps>(
  function ArrowUpIcon(
    {
      'aria-label': ariaLabel = 'Arrow Up Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M13.0024 18.7913L13.0024 7.62126L17.8824 12.5013C18.2724 12.8913 18.9124 12.8913 19.3024 12.5013C19.6924 12.1113 19.6924 11.4813 19.3024 11.0913L12.7124 4.50126C12.3224 4.11126 11.6924 4.11126 11.3024 4.50126L4.70241 11.0813C4.31241 11.4713 4.31241 12.1013 4.70241 12.4913C5.09241 12.8813 5.72241 12.8813 6.11241 12.4913L11.0024 7.62126L11.0024 18.7913C11.0024 19.3413 11.4524 19.7913 12.0024 19.7913C12.5524 19.7913 13.0024 19.3413 13.0024 18.7913Z" />
      </Icon>
    );
  },
) as typeof Icon;
