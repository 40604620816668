import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CaretDownFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function CaretDownFilledIcon(
    {
      'aria-label': ariaLabel = 'Caret Down Filled',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.65 14.65L8.86 11.86C8.54 11.54 8.76 11 9.21 11H14.8C15.25 11 15.47 11.54 15.15 11.85L12.36 14.64C12.16 14.84 11.84 14.84 11.65 14.65Z" />
      </Icon>
    );
  },
) as typeof Icon;
