import { forwardRef } from 'react';

import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { Forward30Icon } from '../icons/forward-30-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerForward30Props = JSX.IntrinsicElements['button'] & {
  disabled?: ButtonProps['disabled'];
};

export const PlayerForward30 = forwardRef<
  HTMLButtonElement,
  PlayerForward30Props
>(function PlayerForward30(props, ref) {
  return (
    <Box gridArea="skipForward">
      <Tooltip
        side="top"
        trigger={
          <Button
            {...props}
            color={{ dark: 'white', light: 'gray' }}
            kind="tertiary"
            ref={ref}
            size="icon"
          >
            <Forward30Icon
              css={{
                height: '3.2rem',

                '[data-player-type="fullscreen"] &': {
                  height: '4rem',
                },
              }}
            />
          </Button>
        }
      >
        <Text
          color={{
            dark: '$brand-black',
            light: '$brand-white',
          }}
          kind="caption-3"
        >
          Forward 30 Seconds
        </Text>
      </Tooltip>
    </Box>
  );
});

PlayerForward30.displayName = 'Player.Forward30';
