import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LocationServicesOffIcon = forwardRef<SVGSVGElement, IconProps>(
  function LocationServicesOffIcon(
    {
      'aria-label': ariaLabel = 'Location Services Off Icon',

      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M20.8428 3.1429C21.1417 3.44173 21.2204 3.89616 21.0395 4.27809L13.3252 20.5638C13.1444 20.9455 12.7432 21.1725 12.3229 21.1309C11.9026 21.0892 11.5537 20.788 11.4513 20.3783L9.88252 14.1032L3.60746 12.5344C3.1977 12.432 2.89649 12.0831 2.85487 11.6628C2.81325 11.2425 3.0402 10.8414 3.42191 10.6606L19.7076 2.94627C20.0896 2.76535 20.544 2.84406 20.8428 3.1429ZM6.80335 11.2719L10.9497 12.3084C11.308 12.398 11.5877 12.6778 11.6773 13.036L12.7139 17.1824L18.0333 5.95238L6.80335 11.2719Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
