// Generated by ts-to-zod
import { z } from 'zod';

export const adsSchema = z.object({
  customAds: z.object({
    enabled: z.boolean(),
    partnerIds: z.string(),
    tritonScript: z.string(),
    type: z.string().optional(),
    url: z.string().optional(),
  }),
  adInterval: z.number(),
  dfpInstanceId: z.number().optional().nullable(),
  headerBidding: z.object({
    enabledBidders: z.array(
      z.enum([
        'amazon',
        'indexExchange',
        'moat',
        'rubicon',
        'liveRamp',
      ] as const),
    ),
  }),
  adswizzCompanionZones: z.number().optional(),
  adswizzSubdomain: z.string().optional(),
  adswizzZoneId: z.number().optional(),
  environment: z.string().optional(),
});

export type Ads = z.infer<typeof adsSchema>;
