import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ArtistIcon = forwardRef<SVGSVGElement, IconProps>(
  function ArtistIcon(
    {
      'aria-label': ariaLabel = 'Artist Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M19.4656 1.82197C19.5518 1.81019 19.6451 1.8531 19.6812 1.95069L20.137 3.18232C20.1573 3.23707 20.2005 3.28022 20.2552 3.30049L21.4869 3.75624C21.5233 3.76973 21.5521 3.79127 21.5733 3.81739C21.5884 3.83589 21.5997 3.8567 21.607 3.87867C21.6387 3.9737 21.5987 4.09004 21.4869 4.13136L20.2552 4.58711C20.2005 4.60737 20.1573 4.65052 20.137 4.70527L19.6812 5.9369C19.6168 6.11103 19.3707 6.11103 19.3062 5.9369L18.8504 4.70527C18.8301 4.65052 18.7869 4.60737 18.7322 4.58711L17.5005 4.13136C17.3265 4.06697 17.3265 3.82063 17.5005 3.75624L18.7322 3.30049C18.7869 3.28022 18.8301 3.23707 18.8504 3.18232L19.3062 1.95069C19.3345 1.87416 19.398 1.83125 19.4656 1.82197Z" />
        <path d="M6.18121 2.95069C6.11676 2.77656 5.87067 2.77656 5.80621 2.95069L4.94537 5.27717C4.93512 5.30464 4.91925 5.32923 4.89899 5.34944C4.89166 5.35688 4.88361 5.36372 4.87506 5.36988C4.86066 5.38038 4.84454 5.38899 4.82721 5.39534L2.50055 6.25624C2.32648 6.32063 2.32648 6.56697 2.50055 6.63136L4.82721 7.49226C4.8819 7.51252 4.92511 7.55568 4.94537 7.61042L5.80621 9.9369C5.87067 10.111 6.11676 10.111 6.18121 9.9369L7.04205 7.61042C7.06232 7.55568 7.10553 7.51252 7.16022 7.49226L9.48688 6.63136C9.66095 6.56697 9.66095 6.32063 9.48688 6.25624L7.16022 5.39534C7.10553 5.37507 7.06232 5.33192 7.04205 5.27717L6.18121 2.95069Z" />
        <path d="M12.1409 7.37092C13.7029 5.80885 16.2357 5.80885 17.7977 7.37092C19.3599 8.93299 19.3599 11.4656 17.7977 13.0278L16.367 14.4584C14.805 16.0205 12.2723 16.0205 10.7103 14.4584C9.14825 12.8963 9.14825 10.3637 10.7103 8.80152L12.1409 7.37092Z" />
        <path d="M8.99078 13.4385C9.17145 14.0694 9.51056 14.6646 10.0074 15.1615C10.5042 15.6584 11.0994 15.9973 11.7303 16.1781C11.6876 16.2503 11.64 16.3204 11.5875 16.3879C11.5367 16.4531 11.4815 16.5158 11.4214 16.5758L6.40778 21.5896C5.62677 22.3706 4.36041 22.3706 3.57941 21.5896C2.7984 20.8085 2.7984 19.5421 3.57941 18.7611L8.59308 13.7474C8.71515 13.6254 8.84869 13.5224 8.99078 13.4385Z" />
        <path d="M19.0374 15.8247C18.9471 15.8052 18.8445 15.8472 18.8062 15.9507L18.2154 17.5473C18.2054 17.5743 18.1898 17.5985 18.17 17.6185C18.1497 17.639 18.1248 17.6552 18.0972 17.6654L16.5005 18.2562C16.3265 18.3206 16.3265 18.567 16.5005 18.6314L18.0972 19.2222C18.1234 19.2319 18.1468 19.2468 18.1666 19.2657C18.188 19.2863 18.2049 19.3117 18.2154 19.3403L18.8062 20.9369C18.8707 21.111 19.1168 21.111 19.1812 20.9369L19.772 19.3403C19.7923 19.2856 19.8355 19.2424 19.8902 19.2222L21.4869 18.6314C21.6609 18.567 21.6609 18.3206 21.4869 18.2562L19.8902 17.6654C19.8355 17.6452 19.7923 17.602 19.772 17.5473L19.1812 15.9507C19.1551 15.8801 19.0989 15.8381 19.0374 15.8247Z" />
      </Icon>
    );
  },
) as typeof Icon;
