import * as Dialog from '@radix-ui/react-dialog';

import { keyframes, styled } from '../../stitches.config.js';
import { Flex } from '../flex/index.js';

const sideDrawerStyles = {
  height: '100vh',
  maxHeight: 'unset',
  top: '0',
  width: '42rem',

  '@touch': {
    '@media (orientation: landscape)': {
      width: '100vh',
    },
  },
};

const mobileLandscapeStyles = {
  '@touch': {
    '@media (orientation: landscape)': {
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: '100vh',
    },
  },
};

// Fade in for drawer overlay
const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.8 },
});

// Fade out for drawer overlay
const fadeOut = keyframes({
  '0%': { opacity: 0.8 },
  '100%': { opacity: 0 },
});

// Overlay blurs the background of the app while the drawer is open
export const Overlay = styled(Dialog.Overlay, {
  backgroundColor: '$brand-black',
  height: '100vh',
  left: '0',
  opacity: '0',
  position: 'fixed',
  top: '0',
  width: '100vw',
  zIndex: '$10',

  '&[data-state="open"]': {
    animation: `${fadeIn} 600ms ease-in-out`,
    opacity: 0.8,
  },

  '&[data-state="closed"]': {
    animation: `${fadeOut} 600ms ease-in-out`,
  },
});

Overlay.displayName = 'Drawer.Overlay';

// Header container for the drawer
export const Header = styled(Flex, {
  alignItems: 'center',
  height: '5.6rem',
  justifyContent: 'space-between',
  padding: '$16',
  width: '$12',

  dark: {
    backgroundColor: '$gray-500',
  },

  light: {
    backgroundColor: '$gray-200',
  },

  '@large': {
    padding: '$24',
  },

  variants: {
    placement: {
      top: { borderRadius: '0' },
      bottom: {
        borderRadius: '$6 $6 0 0',
      },
      right: { borderRadius: '0' },
      left: { borderRadius: '0' },
    },
  },
});

Header.displayName = 'Drawer.Header';

// Container to help center the swipe bar
export const SwipeBarContainer = styled('div', {
  alignItems: 'center',
  backgroundColor: '$gray-500',
  borderRadius: '$6 $6 0 0',
  display: 'flex',
  justifyContent: 'center',
  padding: '$8',
  width: '$12',

  dark: {
    backgroundColor: '$gray-500',
  },

  light: {
    backgroundColor: '$gray-200',
  },

  '@large': {
    display: 'none',
  },
});

SwipeBarContainer.displayName = 'Drawer.SwipeBarContainer';

// Small white swipe bar for mobile
export const SwipeBar = styled(Flex, {
  backgroundColor: '$brand-white',
  borderRadius: '$999',
  height: '0.5rem',
  width: '13.4rem',

  dark: {
    backgroundColor: '$brand-white',
  },

  light: {
    backgroundColor: '$brand-black',
  },
});

SwipeBar.displayName = 'Drawer.SwipeBar';

// Content is where any children passed to the Drawer will be displayed
export const Content = styled(Flex, {
  flexDirection: 'column',
  flexGrow: '1',
  overflowY: 'auto',
  padding: '$16',
  // scrollbarWidth and scrollbarColor are styling for FF scroll bar specifically, but only gets shown if your system settings has "Always show scrollbars" turned on.
  scrollbarWidth: 'thin',
  width: '$12',

  dark: {
    backgroundColor: '$gray-600',
    scrollbarColor: '$gray-300 $gray-450',
  },

  light: {
    backgroundColor: '$brand-white',
    scrollbarColor: '$gray-500 $gray-250',
  },

  /* Width */
  '&::-webkit-scrollbar': {
    width: '1.2rem',
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    dark: {
      backgroundColor: '$gray-450',
    },

    light: {
      backgroundColor: '$gray-250',
    },
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundClip: 'content-box',
    borderRadius: '$999',
    border: '2px solid transparent',

    dark: {
      backgroundColor: '$gray-300',
    },

    light: {
      backgroundColor: '$gray-500',
    },
  },

  '@large': {
    padding: '$24',
  },

  variants: {
    placement: {
      top: {
        borderRadius: '0 0 $6 $6',
      },
      bottom: { borderRadius: '0' },
      right: { borderRadius: '0' },
      left: { borderRadius: '0' },
    },
  },
});

Content.displayName = 'Drawer.Content';

// Slide animation for drawer
const slide = (
  startX: number,
  finishX: number,
  startY: number,
  finishY: number,
) =>
  keyframes({
    '0%': { transform: `translate(${startX}%, ${startY}%)` },
    '100%': { transform: `translate(${finishX}%, ${finishY}%)` },
  });

// Body of the drawer that holds the swipe bar, header, and content
export const Body = styled(Dialog.Content, {
  animationDuration: '600ms',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  left: '0',
  maxHeight: 'calc(100vh - 13.6rem)',
  minHeight: '40rem',
  position: 'fixed',
  width: '100%',
  zIndex: '$10',

  '@large': {
    minHeight: '24rem',
    maxHeight: '40rem',
  },

  variants: {
    size: {
      large: {},
      small: {},
    },

    placement: {
      top: {
        top: '0',

        '&[data-state="open"]': {
          animationName: slide(0, 0, -100, 0),
        },

        '&[data-state="closed"]': {
          animationName: slide(0, 0, 0, -100),
        },

        [`${SwipeBarContainer}`]: {
          borderRadius: '0 0 $6 $6',
          order: '3',
        },

        ...mobileLandscapeStyles,
      },

      bottom: {
        bottom: '0',

        '&[data-state="open"]': {
          animationName: slide(0, 0, 100, 0),
        },

        '&[data-state="closed"]': {
          animationName: slide(0, 0, 0, 100),
        },

        ...mobileLandscapeStyles,
      },

      left: {
        left: '0',

        '&[data-state="open"]': {
          animationName: slide(-100, 0, 0, 0),
        },

        '&[data-state="closed"]': {
          animationName: slide(0, -100, 0, 0),
        },

        [`${SwipeBarContainer}`]: {
          display: 'none',
        },

        ...sideDrawerStyles,
      },

      right: {
        left: 'unset',
        right: '0',

        '&[data-state="open"]': {
          animationName: slide(100, 0, 0, 0),
        },

        '&[data-state="closed"]': {
          animationName: slide(0, 100, 0, 0),
        },

        [`${SwipeBarContainer}`]: {
          display: 'none',
        },

        ...sideDrawerStyles,
      },
    },
  },

  // Desktop drawers placed on the sides of the screen (left or right) only allow two sizes: small, and large.
  // This ensures if a drawer is placed right or left, AND is in desktop mode, it is one of those sizes.
  compoundVariants: [
    {
      placement: 'right',
      size: 'small',
      css: {
        '@large': {
          width: '42rem',
        },
      },
    },

    {
      placement: 'right',
      size: 'large',
      css: {
        '@large': {
          width: '60rem',
        },
      },
    },

    {
      placement: 'left',
      size: 'small',
      css: {
        '@large': {
          width: '42rem',
        },
      },
    },

    {
      placement: 'left',
      size: 'large',
      css: {
        '@large': {
          width: '60rem',
        },
      },
    },
  ],
});

Body.displayName = 'Drawer.Body';

export const Root = styled(Dialog.Root);

Root.displayName = 'Drawer.Root';

export const Trigger = styled(Dialog.Trigger, {
  padding: '0',
});

Trigger.displayName = 'Drawer.Trigger';

export const Portal = Dialog.Portal;

Portal.displayName = 'Drawer.Portal';
