import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PlayIcon = forwardRef<SVGSVGElement, IconProps>(function PlayIcon(
  { 'aria-label': ariaLabel = 'Play Icon', ...props }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M9 16.5917V7.40829C9 6.8704 9.6166 6.55554 10.0495 6.88352L16.1762 11.4752C16.5304 11.7376 16.5304 12.2624 16.1762 12.5248L10.0495 17.1165C9.6166 17.4445 9 17.1296 9 16.5917Z" />
    </Icon>
  );
}) as typeof Icon;
