import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ChevronRightIcon = forwardRef<SVGSVGElement, IconProps>(
  function ChevronRightIcon(
    {
      'aria-label': ariaLabel = 'Chevron Right Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M9.00246 6.71002C8.61246 7.10002 8.61246 7.73002 9.00246 8.12002L12.8825 12L9.00246 15.88C8.61246 16.27 8.61246 16.9 9.00246 17.29C9.39246 17.68 10.0225 17.68 10.4125 17.29L15.0025 12.7C15.3925 12.31 15.3925 11.68 15.0025 11.29L10.4125 6.70002C10.0325 6.32002 9.39246 6.32002 9.00246 6.71002Z" />
      </Icon>
    );
  },
) as typeof Icon;
