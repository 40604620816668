import { useUncontrolled } from '@mantine/hooks';
import { forwardRef, useState } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Button } from '../button/index.js';
import { Field } from '../field/index.js';
import { VisibilityOffIcon } from '../icons/visibility-off-icon.js';
import { VisibilityOnIcon } from '../icons/visibility-on-icon.js';
import { type InputProps, Input, useInputProps } from '../input/index.js';
import { InputRightElement } from '../input/input-element.js';
import { InputGroup } from '../input/input-group.js';
import type { InputPropsWithFieldProps } from '../input/use-input-props.js';

export type PasswordInputProps = InputPropsWithFieldProps<
  InputProps & { handlePasswordChange?: (value: string) => void },
  UseUncontrolledInput<string>
>;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props: PasswordInputProps, ref) {
    const { fieldProps, inputProps, handlePasswordChange, ...restProps } =
      useInputProps(props);
    const { value, onChange, defaultValue, disabled, ...restInputProps } =
      inputProps;
    const [show, setShow] = useState(false);

    const [_value, handleChange] = useUncontrolled<string>({
      value,
      onChange,
      defaultValue,
      finalValue: '',
    });

    const handleClick = () => {
      setShow(!show);
    };

    const VisibilityIcon = show ? VisibilityOffIcon : VisibilityOnIcon;

    return (
      <Field {...fieldProps}>
        <InputGroup
          rightElement={
            !disabled && (
              <InputRightElement>
                <Button
                  color={{ dark: 'white', light: 'gray' }}
                  data-test="show-password-button"
                  kind="tertiary"
                  onClick={handleClick}
                  size="icon"
                >
                  <VisibilityIcon size="16" />
                </Button>
              </InputRightElement>
            )
          }
        >
          <Input
            {...restProps}
            {...restInputProps}
            disabled={disabled}
            onChange={event => {
              handlePasswordChange?.(event.currentTarget.value);
              handleChange(event.currentTarget.value);
            }}
            ref={ref}
            type={show ? 'text' : 'password'}
            value={_value}
          />
        </InputGroup>
      </Field>
    );
  },
);
