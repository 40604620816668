import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ThumbDownFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function ThumbDownFilledIcon(
    {
      'aria-label': ariaLabel = 'Thumb Down Selected Icon',
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M10.3008 14.3893L9.61471 17.6972C9.54259 18.0584 9.65085 18.4267 9.91094 18.6867C10.337 19.1056 11.0158 19.1056 11.4348 18.6795L15.4289 14.6782C15.696 14.411 15.8479 14.0426 15.8479 13.6598V6.44451C15.8479 5.65003 15.1977 5 14.4033 5H7.91028C7.33245 5 6.81244 5.34668 6.5885 5.87393L4.23395 11.3703C3.61996 12.8003 4.66737 14.3893 6.22015 14.3893H10.3008Z"
          fillRule="evenodd"
        />
        <path d="M18.5007 5C17.703 5 17.0562 5.64673 17.0562 6.44451V12.2225C17.0562 13.0203 17.703 13.6671 18.5007 13.6671C19.2985 13.6671 19.9453 13.0203 19.9453 12.2225V6.44451C19.9453 5.64673 19.2985 5 18.5007 5Z" />
      </Icon>
    );
  },
) as typeof Icon;
