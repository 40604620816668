import { z } from 'zod';

export const validation: Validation = {
  password: {
    lengthTest: z
      .string()
      // eslint-disable-next-line regexp/match-any
      .regex(/^[\W\w]{8,32}$/, 'Your password must be between 8-32 characters'),
    caseTest: z
      .string()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z]).*$/,
        'Your password must contain one upper case and one lower case letter',
      ),
    digitTest: z
      .string()
      .regex(/\d+/, 'Your password must contain at least one digit'),
  },
};

export type Validation = {
  password: {
    lengthTest: z.ZodString;
    caseTest: z.ZodString;
    digitTest: z.ZodString;
  };
};
