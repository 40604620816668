import { lightDark } from '@iheartradio/web.accomplice';
import { Link } from '@iheartradio/web.accomplice/link';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { LogotypeSecondary } from '@iheartradio/web.companion';
import { Link as RouterLink, useSearchParams } from '@remix-run/react';
import { $path } from 'remix-routes';

type RegistrationHeaderProps = {
  redirectUrl: string;
  mode: 'sign-up' | 'login' | 'social-login' | 'token-exchange';
};

export const RegistrationHeader = ({
  redirectUrl,
  mode,
}: RegistrationHeaderProps) => {
  const [searchParams] = useSearchParams();

  const { title, description, otherFlow, otherCta, dataTest } =
    mode === 'sign-up' ?
      {
        dataTest: 'login-link',
        title: 'Sign up for free',
        description: 'Already have an account?',
        otherFlow: $path('/login', searchParams),
        otherCta: 'Log in',
      }
    : {
        dataTest: 'signup-free-link',
        title: 'Log in',
        description: "Don't have an account?",
        otherFlow: $path('/sign-up', searchParams),
        otherCta: 'Sign up for free',
      };
  const showText = mode !== 'social-login';

  return (
    <Stack
      align="flex-start"
      css={{
        paddingLeft: '$24',
        paddingTop: '$24',
      }}
      gap="$8"
    >
      <Link asChild>
        <RouterLink role="link" to={redirectUrl}>
          <LogotypeSecondary aria-label="iHeart Logo" data-test="logo" />
        </RouterLink>
      </Link>
      {showText ?
        <>
          <Spacer top="$8" />
          <Text as="h2" kind={{ mobile: 'h3', small: 'h3', medium: 'h2' }}>
            {title}
          </Text>
          <Text
            as="p"
            css={{ color: lightDark('$gray500', '$gray200') }}
            kind={{ mobile: 'subtitle-4', medium: 'subtitle-2' }}
          >
            {description}{' '}
            <Link asChild underline="always">
              <RouterLink
                color="primary"
                data-test={dataTest}
                role="link"
                to={otherFlow}
              >
                {otherCta}
              </RouterLink>
            </Link>
          </Text>
        </>
      : null}
    </Stack>
  );
};
