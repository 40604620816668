import type { PartialGlobalConfig } from '../../schemas/index.js';
import type { Sdks } from '../../schemas/sdks/index.js';
import {
  productionAU_AdobeAnalytics,
  productionCA_AdobeAnalytics,
  productionMX_AdobeAnalytics,
  productionNZ_AdobeAnalytics,
  productionUS_AdobeAnalytics,
  productionWW_AdobeAnalytics,
  stagingAU_AdobeAnalytics,
  stagingCA_AdobeAnalytics,
  stagingMX_AdobeAnalytics,
  stagingNZ_AdobeAnalytics,
  stagingUS_AdobeAnalytics,
  stagingWW_AdobeAnalytics,
} from './adobe-analytics/index.js';
import { productionUS_Amazon, stagingUS_Amazon } from './amazon/index.js';
import {
  productionAU_AppBoy,
  productionCA_AppBoy,
  productionMX_AppBoy,
  productionNZ_AppBoy,
  productionUS_AppBoy,
  productionWW_AppBoy,
  stagingAU_AppBoy,
  stagingCA_AppBoy,
  stagingMX_AppBoy,
  stagingNZ_AppBoy,
  stagingUS_AppBoy,
  stagingWW_AppBoy,
} from './app-boy/index.js';
import {
  productionAU_AppsFlyer,
  productionCA_AppsFlyer,
  productionMX_AppsFlyer,
  productionNZ_AppsFlyer,
  productionUS_AppsFlyer,
  productionWW_AppsFlyer,
  stagingAU_AppsFlyer,
  stagingCA_AppsFlyer,
  stagingMX_AppsFlyer,
  stagingNZ_AppsFlyer,
  stagingUS_AppsFlyer,
  stagingWW_AppsFlyer,
} from './appsflyer/index.js';
import {
  productionAU_Braze,
  productionCA_Braze,
  productionMX_Braze,
  productionNZ_Braze,
  productionUS_Braze,
  productionWW_Braze,
  stagingAU_Braze,
  stagingCA_Braze,
  stagingMX_Braze,
  stagingNZ_Braze,
  stagingUS_Braze,
  stagingWW_Braze,
} from './braze/index.js';
import {
  productionAU_ComScore,
  productionCA_ComScore,
  productionMX_ComScore,
  productionNZ_ComScore,
  productionUS_ComScore,
  productionWW_ComScore,
  stagingAU_ComScore,
  stagingCA_ComScore,
  stagingMX_ComScore,
  stagingNZ_ComScore,
  stagingUS_ComScore,
  stagingWW_ComScore,
} from './com-score/index.js';
import {
  productionAU_Facebook,
  productionCA_Facebook,
  productionMX_Facebook,
  productionNZ_Facebook,
  productionUS_Facebook,
  productionWW_Facebook,
  stagingAU_Facebook,
  stagingCA_Facebook,
  stagingMX_Facebook,
  stagingNZ_Facebook,
  stagingUS_Facebook,
  stagingWW_Facebook,
} from './facebook/index.js';
import {
  productionAU_GFKSensic,
  stagingAU_GFKSensic,
} from './gfk-sensic/index.js';
import {
  productionAU_Google,
  productionCA_Google,
  productionMX_Google,
  productionNZ_Google,
  productionUS_Google,
  productionWW_Google,
  stagingAU_Google,
  stagingCA_Google,
  stagingMX_Google,
  stagingNZ_Google,
  stagingUS_Google,
  stagingWW_Google,
} from './google/index.js';
import {
  productionCA_IndexExchange,
  productionUS_IndexExchange,
  stagingCA_IndexExchange,
  stagingUS_IndexExchange,
} from './index-exchange/index.js';
import {
  productionUS_LiveRamp,
  stagingUS_LiveRamp,
} from './live-ramp/index.js';
import {
  productionAU_Lotame,
  productionCA_Lotame,
  productionMX_Lotame,
  productionNZ_Lotame,
  productionUS_Lotame,
  productionWW_Lotame,
  stagingAU_Lotame,
  stagingCA_Lotame,
  stagingMX_Lotame,
  stagingNZ_Lotame,
  stagingUS_Lotame,
  stagingWW_Lotame,
} from './lotame/index.js';
import {
  productionAU_Moat,
  productionCA_Moat,
  productionMX_Moat,
  productionNZ_Moat,
  productionUS_Moat,
  productionWW_Moat,
  stagingAU_Moat,
  stagingCA_Moat,
  stagingMX_Moat,
  stagingNZ_Moat,
  stagingUS_Moat,
  stagingWW_Moat,
} from './moat/index.js';
import {
  productionAU_Outbrain,
  productionCA_Outbrain,
  productionMX_Outbrain,
  productionNZ_Outbrain,
  productionUS_Outbrain,
  productionWW_Outbrain,
  stagingAU_Outbrain,
  stagingCA_Outbrain,
  stagingMX_Outbrain,
  stagingNZ_Outbrain,
  stagingUS_Outbrain,
  stagingWW_Outbrain,
} from './outbrain/index.js';
import {
  productionCA_Permutive,
  stagingCA_Permutive,
} from './permutive/index.js';
import { productionUS_Recurly, stagingUS_Recurly } from './recurly/index.js';
import { productionUS_Rubicon, stagingUS_Rubicon } from './rubicon/index.js';
import {
  productionAU_Snapchat,
  productionCA_Snapchat,
  productionMX_Snapchat,
  productionNZ_Snapchat,
  productionUS_Snapchat,
  productionWW_Snapchat,
  stagingAU_Snapchat,
  stagingCA_Snapchat,
  stagingMX_Snapchat,
  stagingNZ_Snapchat,
  stagingUS_Snapchat,
  stagingWW_Snapchat,
} from './snapchat/index.js';
import {
  productionAU_Triton,
  productionCA_Triton,
  productionMX_Triton,
  productionNZ_Triton,
  productionUS_Triton,
  productionWW_Triton,
  stagingAU_Triton,
  stagingCA_Triton,
  stagingMX_Triton,
  stagingNZ_Triton,
  stagingUS_Triton,
  stagingWW_Triton,
} from './triton/index.js';

const GlobalSdks: PartialGlobalConfig<Sdks> = {
  AU: {
    staging: {
      adobeAnalytics: stagingAU_AdobeAnalytics,
      appBoy: stagingAU_AppBoy,
      appsFlyer: stagingAU_AppsFlyer,
      braze: stagingAU_Braze,
      comScore: stagingAU_ComScore,
      facebook: stagingAU_Facebook,
      gfkSensicSdk: stagingAU_GFKSensic,
      googleAnalytics: stagingAU_Google.googleAnalytics,
      googleCast: stagingAU_Google.googleCast,
      googleFirebase: stagingAU_Google.googleFirebase,
      googlePlus: stagingAU_Google.googlePlus,
      googleRecaptcha: stagingAU_Google.googleRecaptcha,
      lotame: stagingAU_Lotame,
      moat: stagingAU_Moat,
      outbrain: stagingAU_Outbrain,
      snapchat: stagingAU_Snapchat,
      triton: stagingAU_Triton,
    },
    pr: {
      adobeAnalytics: productionAU_AdobeAnalytics,
      appBoy: productionAU_AppBoy,
      appsFlyer: productionAU_AppsFlyer,
      braze: productionAU_Braze,
      comScore: productionAU_ComScore,
      facebook: productionAU_Facebook,
      gfkSensicSdk: productionAU_GFKSensic,
      googleAnalytics: productionAU_Google.googleAnalytics,
      googleCast: productionAU_Google.googleCast,
      googleFirebase: productionAU_Google.googleFirebase,
      googlePlus: productionAU_Google.googlePlus,
      googleRecaptcha: productionAU_Google.googleRecaptcha,
      lotame: productionAU_Lotame,
      moat: productionAU_Moat,
      outbrain: productionAU_Outbrain,
      snapchat: productionAU_Snapchat,
      triton: productionAU_Triton,
    },
    production: {
      adobeAnalytics: productionAU_AdobeAnalytics,
      appBoy: productionAU_AppBoy,
      appsFlyer: productionAU_AppsFlyer,
      braze: productionAU_Braze,
      comScore: productionAU_ComScore,
      facebook: productionAU_Facebook,
      gfkSensicSdk: productionAU_GFKSensic,
      googleAnalytics: productionAU_Google.googleAnalytics,
      googleCast: productionAU_Google.googleCast,
      googleFirebase: productionAU_Google.googleFirebase,
      googlePlus: productionAU_Google.googlePlus,
      googleRecaptcha: productionAU_Google.googleRecaptcha,
      lotame: productionAU_Lotame,
      moat: productionAU_Moat,
      outbrain: productionAU_Outbrain,
      snapchat: productionAU_Snapchat,
      triton: productionAU_Triton,
    },
  },
  CA: {
    staging: {
      adobeAnalytics: stagingCA_AdobeAnalytics,
      appBoy: stagingCA_AppBoy,
      appsFlyer: stagingCA_AppsFlyer,
      braze: stagingCA_Braze,
      comScore: stagingCA_ComScore,
      facebook: stagingCA_Facebook,
      googleAnalytics: stagingCA_Google.googleAnalytics,
      googleCast: stagingCA_Google.googleCast,
      googleFirebase: stagingCA_Google.googleFirebase,
      googlePlus: stagingCA_Google.googlePlus,
      googleRecaptcha: stagingCA_Google.googleRecaptcha,
      indexExchange: stagingCA_IndexExchange,
      lotame: stagingCA_Lotame,
      moat: stagingCA_Moat,
      outbrain: stagingCA_Outbrain,
      permutive: stagingCA_Permutive,
      snapchat: stagingCA_Snapchat,
      triton: stagingCA_Triton,
    },
    pr: {
      adobeAnalytics: productionCA_AdobeAnalytics,
      appBoy: productionCA_AppBoy,
      appsFlyer: productionCA_AppsFlyer,
      braze: productionCA_Braze,
      comScore: productionCA_ComScore,
      facebook: productionCA_Facebook,
      googleAnalytics: productionCA_Google.googleAnalytics,
      googleCast: productionCA_Google.googleCast,
      googleFirebase: productionCA_Google.googleFirebase,
      googlePlus: productionCA_Google.googlePlus,
      googleRecaptcha: productionCA_Google.googleRecaptcha,
      indexExchange: productionCA_IndexExchange,
      lotame: productionCA_Lotame,
      moat: productionCA_Moat,
      outbrain: productionCA_Outbrain,
      permutive: productionCA_Permutive,
      snapchat: productionCA_Snapchat,
      triton: productionCA_Triton,
    },
    production: {
      adobeAnalytics: productionCA_AdobeAnalytics,
      appBoy: productionCA_AppBoy,
      appsFlyer: productionCA_AppsFlyer,
      braze: productionCA_Braze,
      comScore: productionCA_ComScore,
      facebook: productionCA_Facebook,
      googleAnalytics: productionCA_Google.googleAnalytics,
      googleCast: productionCA_Google.googleCast,
      googleFirebase: productionCA_Google.googleFirebase,
      googlePlus: productionCA_Google.googlePlus,
      googleRecaptcha: productionCA_Google.googleRecaptcha,
      indexExchange: productionCA_IndexExchange,
      lotame: productionCA_Lotame,
      moat: productionCA_Moat,
      outbrain: productionCA_Outbrain,
      permutive: productionCA_Permutive,
      snapchat: productionCA_Snapchat,
      triton: productionCA_Triton,
    },
  },
  MX: {
    staging: {
      adobeAnalytics: stagingMX_AdobeAnalytics,
      appBoy: stagingMX_AppBoy,
      appsFlyer: stagingMX_AppsFlyer,
      braze: stagingMX_Braze,
      comScore: stagingMX_ComScore,
      facebook: stagingMX_Facebook,
      googleAnalytics: stagingMX_Google.googleAnalytics,
      googleCast: stagingMX_Google.googleCast,
      googleFirebase: stagingMX_Google.googleFirebase,
      googlePlus: stagingMX_Google.googlePlus,
      googleRecaptcha: stagingMX_Google.googleRecaptcha,
      lotame: stagingMX_Lotame,
      moat: stagingMX_Moat,
      outbrain: stagingMX_Outbrain,
      snapchat: stagingMX_Snapchat,
      triton: stagingMX_Triton,
    },
    pr: {
      adobeAnalytics: productionMX_AdobeAnalytics,
      appBoy: productionMX_AppBoy,
      appsFlyer: productionMX_AppsFlyer,
      braze: productionMX_Braze,
      comScore: productionMX_ComScore,
      facebook: productionMX_Facebook,
      googleAnalytics: productionMX_Google.googleAnalytics,
      googleCast: productionMX_Google.googleCast,
      googleFirebase: productionMX_Google.googleFirebase,
      googlePlus: productionMX_Google.googlePlus,
      googleRecaptcha: productionMX_Google.googleRecaptcha,
      lotame: productionMX_Lotame,
      moat: productionMX_Moat,
      outbrain: productionMX_Outbrain,
      snapchat: productionMX_Snapchat,
      triton: productionMX_Triton,
    },
    production: {
      adobeAnalytics: productionMX_AdobeAnalytics,
      appBoy: productionMX_AppBoy,
      appsFlyer: productionMX_AppsFlyer,
      braze: productionMX_Braze,
      comScore: productionMX_ComScore,
      facebook: productionMX_Facebook,
      googleAnalytics: productionMX_Google.googleAnalytics,
      googleCast: productionMX_Google.googleCast,
      googleFirebase: productionMX_Google.googleFirebase,
      googlePlus: productionMX_Google.googlePlus,
      googleRecaptcha: productionMX_Google.googleRecaptcha,
      lotame: productionMX_Lotame,
      moat: productionMX_Moat,
      outbrain: productionMX_Outbrain,
      snapchat: productionMX_Snapchat,
      triton: productionMX_Triton,
    },
  },
  NZ: {
    staging: {
      adobeAnalytics: stagingNZ_AdobeAnalytics,
      appBoy: stagingNZ_AppBoy,
      appsFlyer: stagingNZ_AppsFlyer,
      braze: stagingNZ_Braze,
      comScore: stagingNZ_ComScore,
      facebook: stagingNZ_Facebook,
      googleAnalytics: stagingNZ_Google.googleAnalytics,
      googleCast: stagingNZ_Google.googleCast,
      googleFirebase: stagingNZ_Google.googleFirebase,
      googlePlus: stagingNZ_Google.googlePlus,
      googleRecaptcha: stagingNZ_Google.googleRecaptcha,
      lotame: stagingNZ_Lotame,
      moat: stagingNZ_Moat,
      outbrain: stagingNZ_Outbrain,
      snapchat: stagingNZ_Snapchat,
      triton: stagingNZ_Triton,
    },
    pr: {
      adobeAnalytics: productionNZ_AdobeAnalytics,
      appBoy: productionNZ_AppBoy,
      appsFlyer: productionNZ_AppsFlyer,
      braze: productionNZ_Braze,
      comScore: productionNZ_ComScore,
      facebook: productionNZ_Facebook,
      googleAnalytics: productionNZ_Google.googleAnalytics,
      googleCast: productionNZ_Google.googleCast,
      googleFirebase: productionNZ_Google.googleFirebase,
      googlePlus: productionNZ_Google.googlePlus,
      googleRecaptcha: productionNZ_Google.googleRecaptcha,
      lotame: productionNZ_Lotame,
      moat: productionNZ_Moat,
      outbrain: productionNZ_Outbrain,
      snapchat: productionNZ_Snapchat,
      triton: productionNZ_Triton,
    },
    production: {
      adobeAnalytics: productionNZ_AdobeAnalytics,
      appBoy: productionNZ_AppBoy,
      appsFlyer: productionNZ_AppsFlyer,
      braze: productionNZ_Braze,
      comScore: productionNZ_ComScore,
      facebook: productionNZ_Facebook,
      googleAnalytics: productionNZ_Google.googleAnalytics,
      googleCast: productionNZ_Google.googleCast,
      googleFirebase: productionNZ_Google.googleFirebase,
      googlePlus: productionNZ_Google.googlePlus,
      googleRecaptcha: productionNZ_Google.googleRecaptcha,
      lotame: productionNZ_Lotame,
      moat: productionNZ_Moat,
      outbrain: productionNZ_Outbrain,
      snapchat: productionNZ_Snapchat,
      triton: productionNZ_Triton,
    },
  },
  US: {
    staging: {
      adobeAnalytics: stagingUS_AdobeAnalytics,
      amazon: stagingUS_Amazon,
      appBoy: stagingUS_AppBoy,
      appsFlyer: stagingUS_AppsFlyer,
      braze: stagingUS_Braze,
      comScore: stagingUS_ComScore,
      facebook: stagingUS_Facebook,
      googleAnalytics: stagingUS_Google.googleAnalytics,
      googleCast: stagingUS_Google.googleCast,
      googleFirebase: stagingUS_Google.googleFirebase,
      googlePlus: stagingUS_Google.googlePlus,
      googleRecaptcha: stagingUS_Google.googleRecaptcha,
      indexExchange: stagingUS_IndexExchange,
      liveramp: stagingUS_LiveRamp,
      lotame: stagingUS_Lotame,
      moat: stagingUS_Moat,
      outbrain: stagingUS_Outbrain,
      recurly: stagingUS_Recurly,
      rubicon: stagingUS_Rubicon,
      snapchat: stagingUS_Snapchat,
      triton: stagingUS_Triton,
    },
    pr: {
      adobeAnalytics: productionUS_AdobeAnalytics,
      amazon: productionUS_Amazon,
      appBoy: productionUS_AppBoy,
      appsFlyer: productionUS_AppsFlyer,
      braze: productionUS_Braze,
      comScore: productionUS_ComScore,
      facebook: productionUS_Facebook,
      googleAnalytics: productionUS_Google.googleAnalytics,
      googleCast: productionUS_Google.googleCast,
      googleFirebase: productionUS_Google.googleFirebase,
      googlePlus: productionUS_Google.googlePlus,
      googleRecaptcha: productionUS_Google.googleRecaptcha,
      indexExchange: productionUS_IndexExchange,
      liveramp: productionUS_LiveRamp,
      lotame: productionUS_Lotame,
      moat: productionUS_Moat,
      outbrain: productionUS_Outbrain,
      recurly: productionUS_Recurly,
      rubicon: productionUS_Rubicon,
      snapchat: productionUS_Snapchat,
      triton: productionUS_Triton,
    },
    production: {
      adobeAnalytics: productionUS_AdobeAnalytics,
      amazon: productionUS_Amazon,
      appBoy: productionUS_AppBoy,
      appsFlyer: productionUS_AppsFlyer,
      braze: productionUS_Braze,
      comScore: productionUS_ComScore,
      facebook: productionUS_Facebook,
      googleAnalytics: productionUS_Google.googleAnalytics,
      googleCast: productionUS_Google.googleCast,
      googleFirebase: productionUS_Google.googleFirebase,
      googlePlus: productionUS_Google.googlePlus,
      googleRecaptcha: productionUS_Google.googleRecaptcha,
      indexExchange: productionUS_IndexExchange,
      liveramp: productionUS_LiveRamp,
      lotame: productionUS_Lotame,
      moat: productionUS_Moat,
      outbrain: productionUS_Outbrain,
      recurly: productionUS_Recurly,
      rubicon: productionUS_Rubicon,
      snapchat: productionUS_Snapchat,
      triton: productionUS_Triton,
    },
  },
  WW: {
    staging: {
      adobeAnalytics: stagingWW_AdobeAnalytics,
      appBoy: stagingWW_AppBoy,
      appsFlyer: stagingWW_AppsFlyer,
      braze: stagingWW_Braze,
      comScore: stagingWW_ComScore,
      facebook: stagingWW_Facebook,
      googleAnalytics: stagingWW_Google.googleAnalytics,
      googleCast: stagingWW_Google.googleCast,
      googleFirebase: stagingWW_Google.googleFirebase,
      googlePlus: stagingWW_Google.googlePlus,
      googleRecaptcha: stagingWW_Google.googleRecaptcha,
      lotame: stagingWW_Lotame,
      moat: stagingWW_Moat,
      outbrain: stagingWW_Outbrain,
      snapchat: stagingWW_Snapchat,
      triton: stagingWW_Triton,
    },
    pr: {
      adobeAnalytics: productionWW_AdobeAnalytics,
      appBoy: productionWW_AppBoy,
      appsFlyer: productionWW_AppsFlyer,
      braze: productionWW_Braze,
      comScore: productionWW_ComScore,
      facebook: productionWW_Facebook,
      googleAnalytics: productionWW_Google.googleAnalytics,
      googleCast: productionWW_Google.googleCast,
      googleFirebase: productionWW_Google.googleFirebase,
      googlePlus: productionWW_Google.googlePlus,
      googleRecaptcha: productionWW_Google.googleRecaptcha,
      lotame: productionWW_Lotame,
      moat: productionWW_Moat,
      outbrain: productionWW_Outbrain,
      snapchat: productionWW_Snapchat,
      triton: productionWW_Triton,
    },
    production: {
      adobeAnalytics: productionWW_AdobeAnalytics,
      appBoy: productionWW_AppBoy,
      appsFlyer: productionWW_AppsFlyer,
      braze: productionWW_Braze,
      comScore: productionWW_ComScore,
      facebook: productionWW_Facebook,
      googleAnalytics: productionWW_Google.googleAnalytics,
      googleCast: productionWW_Google.googleCast,
      googleFirebase: productionWW_Google.googleFirebase,
      googlePlus: productionWW_Google.googlePlus,
      googleRecaptcha: productionWW_Google.googleRecaptcha,
      lotame: productionWW_Lotame,
      moat: productionWW_Moat,
      outbrain: productionWW_Outbrain,
      snapchat: productionWW_Snapchat,
      triton: productionWW_Triton,
    },
  },
};

export { GlobalSdks };
