import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { Menu } from '../menu/index.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerPlaybackSpeedProps = JSX.IntrinsicElements['button'] & {
  disabled?: ButtonProps['disabled'];
  children: ReactNode;
  triggerText: ReactNode;
};

export const PlayerPlaybackSpeed = forwardRef<
  HTMLButtonElement,
  PlayerPlaybackSpeedProps
>(function PlayerPlaybackSpeed({ children, triggerText, ...props }, ref) {
  return (
    <Box
      css={{
        display: 'none',
        padding: '$0 $8',

        [`@container ${media.medium}`]: {
          display: 'block',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'block',
          padding: '$0 $6',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="playbackSpeed"
      justifySelf="center"
    >
      <Menu
        trigger={
          <Box pointerEvents="none">
            <Tooltip
              side="top"
              trigger={
                <Button
                  {...props}
                  color={{ dark: 'white', light: 'gray' }}
                  kind="tertiary"
                  ref={ref}
                  size="icon"
                >
                  <Box
                    height="3.2rem"
                    padding="$8 $0"
                    textAlign="center"
                    width="3.2rem"
                  >
                    <Text
                      as="p"
                      kind={{
                        [`@container ${media.xsmall}`]: 'caption-3',
                        [`@container ${media.large}`]: 'caption-1',
                      }}
                    >
                      {triggerText}
                    </Text>
                  </Box>
                </Button>
              }
            >
              <Text
                color={{
                  dark: '$brand-black',
                  light: '$brand-white',
                }}
                kind="caption-3"
              >
                Change Playback Speed
              </Text>
            </Tooltip>
          </Box>
        }
        zIndex="$10"
      >
        {children}
      </Menu>
    </Box>
  );
});

PlayerPlaybackSpeed.displayName = 'Player.PlaybackSpeed';
