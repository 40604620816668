import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const MuteIcon = forwardRef<SVGSVGElement, IconProps>(function MuteIcon(
  {
    'aria-label': ariaLabel = 'Mute Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M3.62988 5.04003C3.23999 4.65003 3.23999 4.02003 3.62988 3.63003C4.03003 3.24002 4.65991 3.24002 5.05005 3.63003L20.3601 18.95C20.75 19.34 20.75 19.97 20.3601 20.36C19.97 20.75 19.3401 20.75 18.95 20.36L17.6101 19.02C16.9399 19.56 16.1899 20 15.3899 20.33C14.73 20.6 14 20.14 14 19.42C14 19.03 14.22 18.65 14.5801 18.5C15.1599 18.27 15.6899 17.96 16.1799 17.59L12 13.41V17.58C12 18.47 10.9199 18.92 10.29 18.29L7 15H4C3.44995 15 3 14.55 3 14V10C3 9.45003 3.44995 9.00002 4 9.00002H7L7.29004 8.70003L3.62988 5.04003Z" />
      <path d="M18.5901 14.34C18.8501 13.61 19 12.82 19 12C19 9.06002 17.1799 6.54003 14.6101 5.50002C14.25 5.36003 14 5.03002 14 4.65003V4.46002C14 3.83003 14.6299 3.37003 15.22 3.60002C18.6001 4.89002 21 8.17002 21 12C21 13.39 20.6799 14.7 20.1201 15.87L18.5901 14.34Z" />
      <path d="M10.29 5.71002L10.1201 5.88002L12 7.76002V6.41002C12 5.52002 10.9202 5.08003 10.29 5.71002Z" />
      <path d="M14 7.97003C15.48 8.71002 16.5 10.23 16.5 12C16.5 12.0445 16.4968 12.089 16.4924 12.1334C16.4888 12.169 16.4844 12.2045 16.48 12.24L14 9.76003V7.97003Z" />
    </Icon>
  );
}) as typeof Icon;
