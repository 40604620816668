import * as RadixTooltip from '@radix-ui/react-tooltip';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';
import { kinds } from '../text/text.js';

export type TooltipProps = {
  children: ReactNode;
  color?: BoxProps['backgroundColor'];
  delay?: number;
  onChange?: (open: boolean) => void;
  open?: boolean;
  side?: 'bottom' | 'left' | 'right' | 'top';
  trigger: ReactNode;
};

/**
 * @link Accessibility: https://www.w3.org/TR/wai-aria-practices/#tooltip
 * @link Built With: https://www.radix-ui.com/docs/primitives/components/tooltip
 *
 * @remarks The {@link Tooltip \<Tooltip \/\>} is a popup that displays information related to an
 * element when the element receives keyboard focus or the mouse hovers over it.
 *
 * @props
 *
 * {@link TooltipProps.children children}: The content to be displayed within the tooltip.
 *
 * {@link TooltipProps.color color}: The background color of the tooltip.
 *
 * {@link TooltipProps.delay delay}: The amount of time it takes for the tooltip to open.
 *
 * {@link TooltipProps.onChange onChange}: This callback fires when the tooltip open/closes.
 *
 * {@link TooltipProps.open open}: The default open state of the tooltip.
 *
 * {@link TooltipProps.side side}: side The side from which the tooltip should open.
 *
 * {@link TooltipProps.trigger trigger}: This is the element that will open the tooltip when
 * hovered.
 *
 * @example
 *
 * ```tsx
 * <Tooltip
 *   trigger={<Button color="red" kind="primary" size="large">Hover me</Button>}
 * >
 *   👋
 * </Tooltip>
 * ```
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  function Tooltip(
    {
      children,
      color = { dark: '$brand-white', light: '$gray-600' },
      delay = 500,
      onChange = () => {},
      open = false,
      side = 'bottom',
      trigger,
    }: TooltipProps,
    ref,
  ) {
    return (
      <RadixTooltip.Provider>
        <RadixTooltip.Root
          defaultOpen={open}
          delayDuration={delay}
          onOpenChange={onChange}
        >
          <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
          <RadixTooltip.Content
            align="center"
            aria-label="Tooltip Content"
            asChild
            avoidCollisions
            ref={ref}
            side={side}
            sideOffset={3}
          >
            <Box
              {...kinds['overline-2']}
              backgroundColor={color}
              borderRadius="$2"
              color={{ dark: '$gray-600', light: '$brand-white' }}
              padding="$4 $8"
              zIndex="$10"
            >
              {children}
            </Box>
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      </RadixTooltip.Provider>
    );
  },
);
Tooltip.displayName = 'Tooltip';
