import type { Environment } from '../../schemas/environment.js';
import { SUPPORTED_COUNTRIES } from './constants.js';

export const stagingAU: Environment = {
  countryCode: 'AU',
  hostName: 'webapp.AU',
  hosts: {
    account: 'account.web.au',
    listen: 'listen.web.au',
  },
  objectDbBucket: 'web.listen.staging',
  markPlayedThreshold: 30,
  supportedCountries: SUPPORTED_COUNTRIES,
  terminalId: 161,
  territoryCode: 'AU',
  websiteUrl: 'https://stage.iheart.com',
};

export const productionAU: Environment = {
  ...stagingAU,
  objectDbBucket: 'web.listen.prod',
  websiteUrl: 'https://www.iheart.com',
};

export const pullRequestAU: Environment = {
  ...productionAU,
  objectDbBucket: 'web.listen.staging',
};
