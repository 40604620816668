import { Slot } from '@radix-ui/react-slot';
import { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';

export interface FlexProps
  extends PropsWithChildren<ElementProps<'div'> & RainbowSprinkles> {
  asChild?: boolean | undefined;
}

function Flex(
  { asChild, children, ...props }: FlexProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const Component = asChild ? Slot : 'div';
  const { className, style, otherProps } = rainbowSprinkles({
    display: 'flex',
    ...props,
  });

  return (
    <Component className={className} ref={ref} style={style} {...otherProps}>
      {children}
    </Component>
  );
}

const _Flex = forwardRef(Flex);

export { _Flex as Flex };
