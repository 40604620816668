// Generated by ts-to-zod
import { z } from 'zod';

export const apiSchema = z.object({
  apiUrl: z.string(),
  amp: z.object({
    clientEndpoint: z.string(),
    serverEndpoint: z.string(),
  }),
  radioEdit: z.object({
    contentEndpoint: z.string(),
    graphQlEndpoint: z.string(),
    leadsEndpoint: z.string(),
    mediaServerEndpoint: z.string(),
    webGraphQlEndpoint: z.string(),
  }),
});

export type Api = z.infer<typeof apiSchema>;
