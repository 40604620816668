import { useUncontrolled } from '@mantine/hooks';
import type { MouseEvent, MouseEventHandler } from 'react';
import { forwardRef } from 'react';

import type { CSSProperty, UseUncontrolledInput } from '../../types.js';
import { Button } from '../button/index.js';
import { Field } from '../field/index.js';
import { Flex } from '../flex/index.js';
import { CancelFilledIcon } from '../icons/cancel-filled-icon.js';
import { LoadingIcon } from '../icons/loading-icon.js';
import { SearchIcon } from '../icons/search-icon.js';
import {
  type InputProps,
  type InputPropsWithFieldProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useInputProps,
} from '../input/index.js';
import { Spacer } from '../spacer/index.js';

export type SearchInputProps = InputPropsWithFieldProps<
  InputProps,
  UseUncontrolledInput<string> & {
    closeText?: string;
    /** Whether to clear the input when the close button is pressed. */
    clearOnClose?: boolean;

    /** Called when the "Clear" button is clicked */
    onClear?: MouseEventHandler<HTMLButtonElement>;

    /** Called when the "Close" button is clicked */
    onClose?: MouseEventHandler<HTMLButtonElement>;

    isLoading?: boolean;

    width?: CSSProperty<'width'>;
  }
>;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(props: SearchInputProps, ref) {
    const {
      clearOnClose,
      fieldProps,
      inputProps,
      onClear,
      onClose,
      isLoading,
      closeText = 'Close',
      kind = 'filled',
      width = '100%',
      ...restProps
    } = useInputProps(props, { isLoading: false });

    const { disabled, value, onChange, defaultValue } = inputProps;

    const [_value, handleChange] = useUncontrolled<string>({
      value,
      onChange,
      defaultValue,
      finalValue: '',
    });

    const inputHasValue = _value.length > 0;

    return (
      <Field {...fieldProps}>
        <Flex alignItems="center" display="inline-flex" gap="$8" width={width}>
          <InputGroup
            css={{
              flex: '1',

              '@large': {
                // Override the left / right element padding here because both the Input and Icons are larger than the defaults
                $$paddingForSideElement: '$space$56',
              },
            }}
            leftElement={
              <InputLeftElement>
                {isLoading ?
                  <LoadingIcon size={{ '@initial': 24, '@large': 32 }} />
                : <SearchIcon
                    fill={
                      disabled ? { dark: 'gray-400', light: 'gray-300' }
                      : kind === 'filled-profile' ?
                        'brand-white'
                      : undefined
                    }
                    size={{ '@initial': 24, '@large': 32 }}
                  />
                }
              </InputLeftElement>
            }
            rightElement={
              inputHasValue ?
                <InputRightElement>
                  <Spacer right="$4">
                    <Button
                      color={
                        kind === 'filled-profile' ? 'white' : (
                          { dark: 'white', light: 'gray' }
                        )
                      }
                      data-test="clear-input-button"
                      kind="tertiary"
                      onClick={(event: MouseEvent<HTMLButtonElement>) => {
                        handleChange('');

                        // Allow additional functionality via the `onClear` prop if other actions need to be performed besides clearing the input
                        onClear?.(event);
                      }}
                      size="icon"
                    >
                      <CancelFilledIcon
                        size={{ '@initial': 16, '@large': 24 }}
                      />
                    </Button>
                  </Spacer>
                </InputRightElement>
              : null
            }
          >
            <Input
              type="text"
              {...restProps}
              {...inputProps}
              css={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '@large': {
                  padding: '$16 $12',
                },
              }}
              kind={kind}
              onChange={event => handleChange(event.currentTarget.value)}
              ref={ref}
              value={_value}
            />
          </InputGroup>
          {onClose ?
            <Button
              color={{ dark: 'white', light: 'gray' }}
              kind="tertiary"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                if (clearOnClose) {
                  handleChange('');
                }
                onClose(event);
              }}
              size={{ '@initial': 'small', '@large': 'large' }}
            >
              {closeText}
            </Button>
          : null}
        </Flex>
      </Field>
    );
  },
);
