import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Marquee } from '../marquee/index.js';
import { Text } from '../text/index.js';
import { kinds } from '../text/text.js';
import { useFullScreen } from './player-root.js';

export const PlayerDescription = forwardRef<
  HTMLParagraphElement,
  { children?: ReactNode }
>(function PlayerDescription({ children }, ref) {
  const [isFullScreen] = useFullScreen();

  if (!children) {
    return null;
  }

  return (
    <Text
      color={{ dark: '$gray-250', light: '$gray-450' }}
      css={{
        ...kinds['caption-4'],
        padding: '$0 $8 $2 $8',

        [`@container ${media.large}`]: {
          padding: isFullScreen ? '$0 $16 $2 $16' : '$0 $8 $2 $8',
        },

        '[data-player-type="fullscreen"] &': {
          ...kinds['caption-2'],
          display: 'block',
          padding: '$0 $8 $0 $0',
          color: '$brand-white !important',
        },
      }}
      kind="caption-4"
      lines={1}
      ref={ref}
    >
      <Marquee gap="$24">{children}</Marquee>
    </Text>
  );
});

PlayerDescription.displayName = 'Player.Description';
