import * as SwitchPrimitive from '@radix-ui/react-switch';

import { styled } from '../../stitches.config.js';

export const SwitchThumb = styled(SwitchPrimitive.Thumb, {
  position: 'absolute',
  display: 'flex',
  backgroundColor: '$brand-white',
  borderRadius: '$999',
  boxShadow: '$elevation-1',
  transition: 'ease-out 300ms',
  top: '$2',
  left: '$2',

  '&[data-state="checked"]': {
    left: 'calc(100% - 1.8rem)',

    '[aria-labelledby="checkmark-icon"]': {
      display: 'block',
    },

    '[aria-labelledby="cancel-icon"]': {
      display: 'none',
    },
  },

  '&[data-state="unchecked"]': {
    '[aria-labelledby="checkmark-icon"]': {
      display: 'none',
    },

    '[aria-labelledby="cancel-icon"]': {
      display: 'block',
    },
  },
});

SwitchThumb.displayName = 'SwitchThumb';

export const Switch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  backgroundColor: '$gray-350',
  borderRadius: '$999',
  position: 'relative',

  '@xsmall': {
    width: '4.0rem',
    height: '2.0rem',
  },

  '@medium': {
    width: '4.8rem',
    height: '2.4rem',

    '& [data-state="checked"]': {
      left: 'calc(100% - 2.1rem)',
    },
  },

  '&[data-state="checked"]': {
    light: {
      backgroundColor: '$blue-500',
    },
    dark: {
      backgroundColor: '$blue-400',
    },
  },

  disabled: {
    pointerEvents: 'none',

    light: {
      backgroundColor: '$gray-200',
    },
    dark: {
      backgroundColor: '$gray-500',
    },

    [`& ${SwitchThumb}`]: {
      light: {
        backgroundColor: '$gray-300',
      },
      dark: {
        backgroundColor: '$gray-400',
      },
    },
  },

  hover: {
    cursor: 'pointer',
  },
});

Switch.displayName = 'Switch';
