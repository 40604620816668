import { Epsilon as EpsilonAnalytics } from '@iheartradio/web.analytics/react';
import { useEffect } from 'react';

import { useConfig } from '~app/contexts/config';
import { useUser } from '~app/contexts/user';

import { useAnalytics } from '..';

export function Epsilon() {
  const analytics = useAnalytics();
  const config = useConfig();
  const user = useUser();

  useEffect(() => {
    const unsubscribe = analytics.subscribe({
      async track(data) {
        if (!(user?.privacy?.hasOptedOut ?? false)) {
          EpsilonAnalytics.doEpsilonTrack({ data, user, config });
        }
      },
    });

    return unsubscribe;
  });

  return null;
}
