import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { Back15Icon } from '../icons/back-15-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerBack15Props = JSX.IntrinsicElements['button'] & {
  disabled?: ButtonProps['disabled'];
};

export const PlayerBack15 = forwardRef<HTMLButtonElement, PlayerBack15Props>(
  function PlayerBack15(props, ref) {
    return (
      <Box
        css={{
          display: 'none',

          [`@container ${media.medium}`]: {
            display: 'block',
          },

          '[data-player-type="fullscreen"] &': {
            display: 'block',

            [`@container ${media.large}`]: {
              display: 'none',
            },
          },
        }}
        gridArea="skipBack"
        padding="$0 $8"
      >
        <Tooltip
          side="top"
          trigger={
            <Button
              {...props}
              color={{ dark: 'white', light: 'gray' }}
              kind="tertiary"
              ref={ref}
              size="icon"
            >
              <Back15Icon
                css={{
                  height: '3.2rem',

                  '[data-player-type="fullscreen"] &': {
                    height: '4rem',
                  },
                }}
              />
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            Rewind 15 Seconds
          </Text>
        </Tooltip>
      </Box>
    );
  },
);

PlayerBack15.displayName = 'Player.Back15';
