import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const WarningFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function WarningFilledIcon(
    {
      'aria-label': ariaLabel = 'Error Filled Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M11.777 0.465117C11.4317 -0.155038 10.5683 -0.155039 10.223 0.465116L0.121546 18.6047C-0.223802 19.2248 0.207882 20 0.898577 20H21.1014C21.7921 20 22.2238 19.2248 21.8785 18.6047L11.777 0.465117ZM11 12.5C10.45 12.5 10 12.05 10 11.5V7.5C10 6.95 10.45 6.5 11 6.5C11.55 6.5 12 6.95 12 7.5V11.5C12 12.05 11.55 12.5 11 12.5ZM11 16.5C10.4477 16.5 10 16.0523 10 15.5C10 14.9477 10.4477 14.5 11 14.5C11.5523 14.5 12 14.9477 12 15.5C12 16.0523 11.5523 16.5 11 16.5Z" />
      </Icon>
    );
  },
) as typeof Icon;
