import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const InfoIcon = forwardRef<SVGSVGElement, IconProps>(function InfoIcon(
  {
    'aria-label': ariaLabel = 'Info Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M13 12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12L11 16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16L13 12Z" />
      <path d="M13 8C13 7.44771 12.5523 7 12 7C11.4477 7 11 7.44771 11 8C11 8.55229 11.4477 9 12 9C12.5523 9 13 8.55229 13 8Z" />
    </Icon>
  );
}) as typeof Icon;
