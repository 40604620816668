import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PodcastIcon = forwardRef<SVGSVGElement, IconProps>(
  function PodcastIcon(
    {
      'aria-label': ariaLabel = 'Podcast Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M9.83887 19.2631C9.83887 18.6585 10.3271 18.1684 10.9293 18.1684C11.5315 18.1684 12.0197 18.6585 12.0197 19.2631H14.2005C14.8027 19.2631 15.2909 19.7533 15.2909 20.3579V22H6.56768V20.3579C6.56768 19.7533 7.05587 19.2631 7.65808 19.2631H9.83887Z" />
        <path d="M13.9906 2.2025C16.945 2.99118 18.7516 5.85488 18.0252 8.59877L16.6409 13.8285C15.9147 16.5724 12.9305 18.1574 9.9761 17.3687C7.61962 16.7397 5.99359 14.7909 5.80005 12.6357L8.79707 13.4387C9.28937 13.5706 9.7954 13.2785 9.92721 12.7861C10.0592 12.2938 9.76702 11.7878 9.27472 11.6559L5.99314 10.7766L6.46538 8.99224L9.7526 9.87301C10.2449 10.0049 10.7509 9.71276 10.8827 9.22045C11.0148 8.72813 10.7225 8.2221 10.2302 8.09019L6.93785 7.20797L7.3256 5.74271C7.35579 5.62857 7.39004 5.51644 7.42789 5.40641L10.554 6.24404C11.0463 6.37596 11.5523 6.08379 11.6841 5.59148C11.8162 5.09917 11.524 4.59314 11.0317 4.46122L8.39941 3.75597C9.7019 2.30312 11.854 1.63216 13.9906 2.2025Z" />
      </Icon>
    );
  },
) as typeof Icon;
