import * as Tabs from '@radix-ui/react-tabs';

import { styled } from '../../stitches.config.js';
import { kinds } from '../text/text.js';

const scrollSnapX = {
  overflow: 'auto hidden',
  overscrollBehaviorX: 'contain',
  scrollSnapType: 'x proximity',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },

  '@media (prefers-reduced-motion no-preference)': {
    scrollBehavior: 'smooth',
  },
};

export const List = styled(Tabs.List, {
  flexShrink: 0,
  display: 'flex',
  minBlockSize: 'fit-content',
  alignItems: 'baseline',

  ...scrollSnapX,

  borderBottom: '1px solid transparent',

  dark: {
    borderColor: '$gray-500',
  },
  light: {
    borderColor: '$gray-200',
  },
});

List.displayName = 'Tabs.List';

export const Content = styled(Tabs.Content, {
  flexGrow: 1,
  overflow: 'auto',
});

Content.displayName = 'Tabs.Content';

export const Trigger = styled(Tabs.Trigger, {
  all: 'unset', // This is related to the active box shadow to denote the selected tab
  padding: '$8 $16',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  cursor: 'pointer',
  scrollSnapAlign: 'none',
  whiteSpace: 'nowrap',

  $$highlightColor: 'transparent',
  boxShadow: 'inset 0 -2px 0 0 $$highlightColor',

  '&[data-state="active"]': {
    $$highlightColor: '$colors$brand-red',
    scrollSnapAlign: 'center', // This ensures the selected tab is centered in the scroll view.
  },

  // To prevent the hover style from being applied to the active tab, this selector selects non-active Triggers
  '&:hover&[data-state]:not([data-state="active"])': {
    dark: {
      $$highlightColor: '$colors$gray-400',
    },
    light: {
      $$highlightColor: '$colors$gray-300',
    },
  },

  transition: 'all .5s ease-out',

  '@media (prefers-reduced-motion)': {
    transition: 'none',
  },

  disabled: {
    pointerEvents: 'none',

    dark: { color: '$gray-400' },
    light: { color: '$gray-300' },
  },
});

Trigger.displayName = 'Tabs.Trigger';

export const Root = styled(Tabs.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
  overflow: 'hidden',

  dark: {
    color: '$brand-white',
  },
  light: {
    color: '$gray-600',
  },

  [`& ${Trigger}`]: {
    ...kinds['subtitle-3'],
    padding: '$8 $16',
    fontWeight: '$600',
  },

  [`& ${List}`]: {
    gap: '$8',
  },

  '@large': {
    [`& ${Trigger}`]: {
      ...kinds.h4,
      padding: '$8 $24',
      fontWeight: '$700',
    },

    [`& ${List}`]: {
      gap: '$16',
    },
  },
});

Root.displayName = 'Tabs';
