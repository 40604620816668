import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const NewWindowIcon = forwardRef<SVGSVGElement, IconProps>(
  function NewWindowIcon(
    { 'aria-label': ariaLabel = 'New Window Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.6667 17.4444H7.33333C6.90556 17.4444 6.55556 17.0944 6.55556 16.6667V7.33333C6.55556 6.90556 6.90556 6.55556 7.33333 6.55556H11.2222C11.65 6.55556 12 6.20556 12 5.77778C12 5.35 11.65 5 11.2222 5H6.55556C5.69222 5 5 5.7 5 6.55556V17.4444C5 18.3 5.7 19 6.55556 19H17.4444C18.3 19 19 18.3 19 17.4444V12.7778C19 12.35 18.65 12 18.2222 12C17.7944 12 17.4444 12.35 17.4444 12.7778V16.6667C17.4444 17.0944 17.0944 17.4444 16.6667 17.4444ZM13.5556 5.77778C13.5556 6.20556 13.9056 6.55556 14.3333 6.55556H16.3478L9.24667 13.6567C8.94333 13.96 8.94333 14.45 9.24667 14.7533C9.55 15.0567 10.04 15.0567 10.3433 14.7533L17.4444 7.65222V9.66667C17.4444 10.0944 17.7944 10.4444 18.2222 10.4444C18.65 10.4444 19 10.0944 19 9.66667V5.77778C19 5.35 18.65 5 18.2222 5H14.3333C13.9056 5 13.5556 5.35 13.5556 5.77778Z" />
      </Icon>
    );
  },
) as typeof Icon;
