import type { Snapchat } from '../../../schemas/sdks/snapchat.js';

export const stagingUS: Snapchat = {
  pixel: {
    enabled: true,
    id: '015fcbb1-38ea-41dc-ae3e-391a68f31a4b',
  },
};

export const productionUS: Snapchat = {
  pixel: {
    enabled: true,
    id: '015fcbb1-38ea-41dc-ae3e-391a68f31a4b',
  },
};
