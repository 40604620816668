import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const DownloadIcon = forwardRef<SVGSVGElement, IconProps>(
  function DownloadIcon(
    {
      'aria-label': ariaLabel = 'Download Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M18.4167 7.27778C17.823 4.26315 15.1778 2 12 2C9.47698 2 7.28571 3.43304 6.19444 5.53017C3.56666 5.80979 1.5238 8.03799 1.5238 10.738C1.5238 13.6303 3.87222 15.9809 6.7619 15.9809H8.50714C8.98973 15.9809 9.38095 15.5896 9.38095 15.1071C9.38095 14.6245 8.98973 14.2333 8.50714 14.2333H6.7619C4.83253 14.2333 3.26984 12.6691 3.26984 10.738C3.26984 8.80693 4.83253 7.24282 6.7619 7.24282H7.38174C7.95793 5.22434 9.79999 3.74761 12 3.74761C14.654 3.74761 16.8016 5.89717 16.8016 8.55353V8.99043H18.1111C19.5603 8.99043 20.7302 10.1613 20.7302 11.6118C20.7302 13.0624 19.5603 14.2333 18.1111 14.2333H15.4928C15.0103 14.2333 14.619 14.6245 14.619 15.1071C14.619 15.5896 15.0103 15.9809 15.4928 15.9809H18.1111C20.5206 15.9809 22.4762 14.0235 22.4762 11.6118C22.4762 9.305 20.6865 7.43506 18.4167 7.27778ZM12.3651 21.8487C12.1922 22.0489 11.8828 22.0507 11.7078 21.8525L8.73448 18.487C8.40327 18.1122 8.4395 17.5405 8.81526 17.2102C9.19124 16.8798 9.76437 16.9159 10.0956 17.2908L11.127 18.4583L11.127 10.7381C11.127 10.2555 11.5179 9.86428 12 9.86428C12.4821 9.86428 12.873 10.2555 12.873 10.7381L12.873 18.4861L13.8976 17.2988C14.2243 16.9201 14.797 16.8773 15.1766 17.2032C15.5564 17.5291 15.5993 18.1004 15.2725 18.4791L12.3651 21.8487Z" />
      </Icon>
    );
  },
) as typeof Icon;
