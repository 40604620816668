import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LoginIcon = forwardRef<SVGSVGElement, IconProps>(
  function LoginIcon(
    {
      'aria-label': ariaLabel = 'Login Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M3.5 12C3.5 7.032 7.532 3 12.5 3C17.152 3 20.9833 6.53539 21.4519 11.0642C21.1406 11.0219 20.8229 11 20.5 11C18.4363 11 16.5812 11.893 15.3001 13.3137C14.3144 12.9821 13.2501 12.81 12.5 12.81C10.7 12.81 7.127 13.791 7.1 15.582C8.261 17.328 10.25 18.48 12.5 18.48C12.8439 18.48 13.1817 18.4531 13.5113 18.4013C13.5605 19.2718 13.7688 20.0998 14.1075 20.8566C13.5859 20.9508 13.0486 21 12.5 21C7.532 21 3.5 16.968 3.5 12ZM15.2 8.4C15.2 6.906 13.994 5.7 12.5 5.7C11.006 5.7 9.8 6.906 9.8 8.4C9.8 9.894 11.006 11.1 12.5 11.1C13.994 11.1 15.2 9.894 15.2 8.4Z" />
        <path d="M23.5563 18.392C23.5727 18.264 23.5851 18.136 23.5851 18C23.5851 17.864 23.5727 17.736 23.5563 17.608L24.424 16.948C24.5021 16.888 24.5227 16.78 24.4733 16.692L23.6509 15.308C23.6015 15.22 23.4905 15.188 23.4 15.22L22.3761 15.62C22.1622 15.46 21.932 15.328 21.6811 15.228L21.5248 14.168C21.5125 14.072 21.4262 14 21.3234 14H19.6785C19.5757 14 19.4893 14.072 19.477 14.168L19.3207 15.228C19.0699 15.328 18.8396 15.464 18.6257 15.62L17.6018 15.22C17.5072 15.184 17.4003 15.22 17.351 15.308L16.5285 16.692C16.4751 16.78 16.4997 16.888 16.5779 16.948L17.4455 17.608C17.4291 17.736 17.4167 17.868 17.4167 18C17.4167 18.132 17.4291 18.264 17.4455 18.392L16.5779 19.052C16.4997 19.112 16.4792 19.22 16.5285 19.308L17.351 20.692C17.4003 20.78 17.5113 20.812 17.6018 20.78L18.6257 20.38C18.8396 20.54 19.0699 20.672 19.3207 20.772L19.477 21.832C19.4893 21.928 19.5757 22 19.6785 22H21.3234C21.4262 22 21.5125 21.928 21.5248 21.832L21.6811 20.772C21.932 20.672 22.1622 20.536 22.3761 20.38L23.4 20.78C23.4946 20.816 23.6015 20.78 23.6509 20.692L24.4733 19.308C24.5227 19.22 24.5021 19.112 24.424 19.052L23.5563 18.392ZM20.5009 19.4C19.7073 19.4 19.0616 18.772 19.0616 18C19.0616 17.228 19.7073 16.6 20.5009 16.6C21.2946 16.6 21.9402 17.228 21.9402 18C21.9402 18.772 21.2946 19.4 20.5009 19.4Z" />
      </Icon>
    );
  },
) as typeof Icon;
