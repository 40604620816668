import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SaveStationIcon = forwardRef<SVGSVGElement, IconProps>(
  function SaveStationIcon(
    {
      'aria-label': ariaLabel = 'Save Station Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M17.7637 7.5C17.5551 7.5 17.3687 7.37269 17.282 7.18286C16.0216 4.42013 13.2351 2.5 10 2.5C5.58154 2.5 2 6.08172 2 10.5C2 13.0325 3.17668 15.2901 5.0132 16.756C5.24306 16.9395 5.28863 17.2781 5.09815 17.5022C4.93128 17.6985 4.64108 17.736 4.43861 17.5766C2.34463 15.9287 1 13.3714 1 10.5C1 5.52943 5.0293 1.5 10 1.5C13.6626 1.5 16.8141 3.68771 18.2191 6.82764C18.3635 7.15026 18.1172 7.5 17.7637 7.5ZM15.1077 11.5C15.5749 11.5 15.982 11.1622 15.9969 10.6953C15.999 10.6304 16 10.5653 16 10.5C16 7.18629 13.3135 4.5 10 4.5C6.68652 4.5 4 7.18629 4 10.5C4 12.3159 4.80676 13.9434 6.08135 15.0436C6.44773 15.3599 6.99202 15.2741 7.30549 14.9053C7.701 14.44 7.56062 13.7283 7.1345 13.2908C6.43247 12.5701 6 11.5856 6 10.5C6 8.29086 7.79102 6.5 10 6.5C12.209 6.5 14 8.29086 14 10.5C14 11.0119 14.3727 11.5 14.8846 11.5H15.1077ZM12.0001 10.5C12.0001 11.4319 11.3627 12.215 10.5001 12.437V16.5H10.6688C10.8155 16.5 10.9548 16.5644 11.0498 16.6762L15.2998 21.6762C15.5758 22.0009 15.3451 22.5 14.9188 22.5H5.08129C4.65508 22.5 4.42428 22.0009 4.70032 21.6762L8.95032 16.6762C9.04532 16.5644 9.1846 16.5 9.33129 16.5H9.50006V12.437C8.63745 12.215 8.00006 11.4319 8.00006 10.5C8.00006 9.39543 8.8955 8.5 10.0001 8.5C11.1046 8.5 12.0001 9.39543 12.0001 10.5ZM19 14.5V11.5C19 10.95 18.55 10.5 18 10.5C17.45 10.5 17 10.95 17 11.5V14.5H14C13.45 14.5 13 14.95 13 15.5C13 16.05 13.45 16.5 14 16.5H17V19.5C17 20.05 17.45 20.5 18 20.5C18.55 20.5 19 20.05 19 19.5V16.5H22C22.55 16.5 23 16.05 23 15.5C23 14.95 22.55 14.5 22 14.5H19Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
