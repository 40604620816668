import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RewindIcon = forwardRef<SVGSVGElement, IconProps>(
  function RewindIcon(
    {
      'aria-label': ariaLabel = 'Rewind Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M11.3801 16.07V7.93002C11.3801 7.12002 10.4701 6.65002 9.80011 7.11002L4.03011 11.18C3.47011 11.58 3.47011 12.42 4.03011 12.81L9.80011 16.88C10.4701 17.35 11.3801 16.88 11.3801 16.07ZM13.0401 12.82L18.8101 16.89C19.4701 17.36 20.3901 16.88 20.3901 16.07V7.93002C20.3901 7.12002 19.4801 6.65002 18.8101 7.11002L13.0401 11.18C12.4701 11.58 12.4701 12.42 13.0401 12.82Z" />
      </Icon>
    );
  },
) as typeof Icon;
