import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const AttachIcon = forwardRef<SVGSVGElement, IconProps>(
  function AttachIcon(
    {
      'aria-label': ariaLabel = 'Attach Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M17.2539 16H6.67393C4.58393 16 2.72393 14.47 2.52393 12.39C2.29393 10.01 4.16393 8 6.50393 8H18.8639C20.1739 8 21.3639 8.94 21.4939 10.24C21.6439 11.74 20.4739 13 19.0039 13H8.50393C7.95393 13 7.50393 12.55 7.50393 12C7.50393 11.45 7.95393 11 8.50393 11H17.2539C17.6639 11 18.0039 10.66 18.0039 10.25C18.0039 9.84 17.6639 9.5 17.2539 9.5H8.64393C7.33393 9.5 6.14393 10.44 6.01393 11.74C5.86393 13.24 7.03393 14.5 8.50393 14.5H18.8339C20.9239 14.5 22.7839 12.97 22.9839 10.89C23.2139 8.5 21.3439 6.5 19.0039 6.5H6.73393C3.86393 6.5 1.29393 8.6 1.02393 11.46C0.723929 14.75 3.28393 17.5 6.50393 17.5H17.2539C17.6639 17.5 18.0039 17.16 18.0039 16.75C18.0039 16.34 17.6639 16 17.2539 16Z" />
      </Icon>
    );
  },
) as typeof Icon;
