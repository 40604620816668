// Generated by ts-to-zod
import { z } from 'zod';

export const comScoreSchema = z.object({
  customerId: z.string(),
  enabled: z.boolean(),
  publisherSecret: z.string(),
  threshold: z.number(),
});

export type ComScore = z.infer<typeof comScoreSchema>;
