import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SortAlphaAscendingIcon = forwardRef<SVGSVGElement, IconProps>(
  function SortAlphaAscendingIcon(
    {
      'aria-label': ariaLabel = 'Sort Alpha Ascending Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          d="M5.69153 19.8831H9.44948C9.75966 19.8831 10.0136 20.1344 9.99944 20.4485C9.99944 20.7487 9.7526 21 9.44242 21H4.70454C4.14047 21 3.80206 20.3647 4.12635 19.904L8.16625 14.1239H4.57768C4.27438 14.1239 4.02066 13.8726 4.02066 13.5654V13.5585C4.02066 13.2513 4.26733 13 4.57768 13H9.14636C9.71026 13 10.0488 13.6353 9.72437 14.096L5.69153 19.8831Z"
          fill="#A9AFB2"
        />
        <path
          clipRule="evenodd"
          d="M10.3946 11C10.8219 11 11.1138 10.5812 10.9571 10.1902L8.30111 3.53054C8.17282 3.20942 7.85242 3 7.50351 3C7.1546 3 6.8342 3.20942 6.70608 3.53054L4.04293 10.1832C3.88612 10.5742 4.17818 10.993 4.60532 10.993C4.85462 10.993 5.08253 10.8394 5.17502 10.6091L5.68056 9.28272H9.31933L9.82487 10.6091C9.91753 10.8464 10.1453 11 10.3946 11ZM6.115 8.14485L7.49638 4.5288L8.87776 8.14485H6.115Z"
          fillRule="evenodd"
        />
        <path d="M17.9998 17.1814V4.01223C17.9998 3.4555 17.5498 3 16.9998 3C16.4498 3 15.9998 3.4555 15.9998 4.01223V17.1814H14.2098C13.7598 17.1814 13.5398 17.728 13.8598 18.0418L16.6498 20.8558C16.8498 21.0481 17.1598 21.0481 17.3598 20.8558L20.1498 18.0418C20.4698 17.728 20.2398 17.1814 19.7998 17.1814H17.9998Z" />
      </Icon>
    );
  },
) as typeof Icon;
