import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const StopFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function StopFilledIcon(
    {
      'aria-label': ariaLabel = 'Stop Filled Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM14 8.99992H10C9.45002 8.99992 9.00002 9.44992 9.00002 9.99992V13.9999C9.00002 14.5499 9.45002 14.9999 10 14.9999H14C14.55 14.9999 15 14.5499 15 13.9999V9.99992C15 9.44992 14.55 8.99992 14 8.99992Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
