import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ArrowRightIcon = forwardRef<SVGSVGElement, IconProps>(
  function ArrowRightIcon(
    {
      'aria-label': ariaLabel = 'Arrow Right Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M5.20996 13H16.38L11.5 17.88C11.11 18.27 11.11 18.91 11.5 19.3C11.89 19.69 12.52 19.69 12.91 19.3L19.5 12.71C19.89 12.32 19.89 11.69 19.5 11.3L12.92 4.69997C12.53 4.30997 11.9 4.30997 11.51 4.69997C11.12 5.08997 11.12 5.71997 11.51 6.10997L16.38 11H5.20996C4.65996 11 4.20996 11.45 4.20996 12C4.20996 12.55 4.65996 13 5.20996 13Z" />
      </Icon>
    );
  },
) as typeof Icon;
