// Generated by ts-to-zod
import { z } from 'zod';

export const moatSchema = z.object({
  enabled: z.boolean(),
  header: z.string(),
  jwPartnerCode: z.string().optional(),
  jwplayer: z.string().optional(),
  library: z.string(),
  partnerCode: z.string().nullable(),
});

export type Moat = z.infer<typeof moatSchema>;
