import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const DownloadOffIcon = forwardRef<SVGSVGElement, IconProps>(
  function DownloadOffIcon(
    {
      'aria-label': ariaLabel = 'Download Off Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M18.416 7.27242C17.8224 4.26085 15.1774 2 12 2C10.7081 2 9.51221 2.37535 8.49963 3.02131L9.77409 4.29577C10.4375 3.9466 11.1969 3.74583 12 3.74583C14.6537 3.74583 16.8011 5.89321 16.8011 8.54688V8.98333H18.1104C19.5595 8.98333 20.7292 10.153 20.7292 11.6021C20.7292 12.5885 20.1705 13.4439 19.3674 13.8891L20.6332 15.1549C21.7418 14.3605 22.475 13.0686 22.475 11.6021C22.475 9.29758 20.6855 7.42954 18.416 7.27242ZM4.14377 3.1086L6.5443 5.5004C3.75969 5.6226 1.52502 7.90965 1.52502 10.7292C1.52502 13.6185 3.87317 15.9667 6.76252 15.9667H17.0018L18.7477 17.7125L19.8563 16.6039L5.25238 2L4.14377 3.1086ZM8.27267 7.2375L15.256 14.2208H6.76252C4.83338 14.2208 3.27086 12.6583 3.27086 10.7292C3.27086 8.80002 4.83338 7.2375 6.76252 7.2375H8.27267Z" />
      </Icon>
    );
  },
) as typeof Icon;
