import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LockIcon = forwardRef<SVGSVGElement, IconProps>(function LockIcon(
  {
    'aria-label': ariaLabel = 'Lock Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M17.625 8.66667H16.6875V6.7619C16.6875 4.13333 14.5875 2 12 2C9.4125 2 7.3125 4.13333 7.3125 6.7619V8.66667H6.375C5.34375 8.66667 4.5 9.52381 4.5 10.5714V20.0952C4.5 21.1429 5.34375 22 6.375 22H17.625C18.6562 22 19.5 21.1429 19.5 20.0952V10.5714C19.5 9.52381 18.6562 8.66667 17.625 8.66667ZM12 17.2381C10.9688 17.2381 10.125 16.381 10.125 15.3333C10.125 14.2857 10.9688 13.4286 12 13.4286C13.0312 13.4286 13.875 14.2857 13.875 15.3333C13.875 16.381 13.0312 17.2381 12 17.2381ZM9.1875 8.66667V6.7619C9.1875 5.18095 10.4437 3.90476 12 3.90476C13.5562 3.90476 14.8125 5.18095 14.8125 6.7619V8.66667H9.1875Z" />
    </Icon>
  );
}) as typeof Icon;
