import { forwardRef } from 'react';

import { colors } from '../../core/colors.js';
import { Theme } from '../../stitches.config.js';
import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';
import { useTheme } from '../theme/index.js';

export const CopyIcon = forwardRef<SVGSVGElement, IconProps>(function CopyIcon(
  {
    'aria-label': ariaLabel = 'Copy Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  const theme = useTheme();
  const strokeColor =
    theme === Theme.Dark ? colors['brand-white'] : colors['gray-600'];
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props} fill="transparent">
      <>
        <path
          d="M19.5204 9.30005H11.4204C10.4262 9.30005 9.62036 10.1059 9.62036 11.1V19.2C9.62036 20.1942 10.4262 21 11.4204 21H19.5204C20.5145 21 21.3204 20.1942 21.3204 19.2V11.1C21.3204 10.1059 20.5145 9.30005 19.5204 9.30005Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M6.02031 14.7H5.12031C4.64292 14.7 4.18509 14.5104 3.84752 14.1728C3.50995 13.8352 3.32031 13.3774 3.32031 12.9V4.8C3.32031 4.32261 3.50995 3.86477 3.84752 3.52721C4.18509 3.18964 4.64292 3 5.12031 3H13.2203C13.6977 3 14.1555 3.18964 14.4931 3.52721C14.8307 3.86477 15.0203 4.32261 15.0203 4.8V5.7"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </>
    </Icon>
  );
}) as typeof Icon;
