import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const EmailIcon = forwardRef<SVGSVGElement, IconProps>(
  function EmailIcon(
    {
      'aria-label': ariaLabel = 'Email Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM19.6 9.25L12.53 13.67C12.21 13.87 11.79 13.87 11.47 13.67L4.4 9.25C4.15 9.09 4 8.82 4 8.53C4 7.86 4.73 7.46 5.3 7.81L12 12L18.7 7.81C19.27 7.46 20 7.86 20 8.53C20 8.82 19.85 9.09 19.6 9.25Z" />
      </Icon>
    );
  },
) as typeof Icon;
