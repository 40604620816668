import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { ThumbUpFilledIcon } from '../icons/thumb-up-filled-icon.js';
import { ThumbUpOutlineIcon } from '../icons/thumb-up-outline-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

export type PlayerThumbUpProps = Partial<
  Pick<ButtonProps, 'color' | 'disabled'> & JSX.IntrinsicElements['button']
> & {
  active?: boolean;
};

export const PlayerThumbUp = forwardRef<HTMLButtonElement, PlayerThumbUpProps>(
  function PlayerThumbUp({ active, ...props }, ref) {
    return (
      <Box
        css={{
          display: 'none',
          padding: '$0 $8',

          [`@container ${media.medium}`]: {
            display: 'block',
          },

          '[data-player-type="fullscreen"] &': {
            display: 'block',
            padding: '$0 $2',

            [`@container ${media.large}`]: {
              display: 'none',
            },
          },
        }}
        gridArea="thumbUp"
      >
        <Tooltip
          side="top"
          trigger={
            <Button
              color={{ dark: 'white', light: 'gray' }}
              kind="tertiary"
              ref={ref}
              size="icon"
              {...props}
            >
              {active ?
                <ThumbUpFilledIcon
                  css={{
                    dark: { fill: '$blue-400 !important' },
                    light: { fill: '$blue-500 !important' },
                  }}
                  size={32}
                />
              : <ThumbUpOutlineIcon size={32} />}
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            Thumb Up
          </Text>
        </Tooltip>
      </Box>
    );
  },
);

PlayerThumbUp.displayName = 'Player.ThumbUp';
