import type { ComponentProps } from 'react';

import { styled } from '../../stitches.config.js';
import { Button } from '../button/button.primitive.js';
import { kinds } from '../text/text.js';

const activeColor = {
  dark: '$blue-400',
  light: '$blue-500',
};

const disabledColor = {
  dark: '$gray-400',
  light: '$gray-300',
};

const errorColor = {
  dark: '$orange-300',
  light: '$orange-650',
};

export const inputStyles = {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '0',
  width: '100%',

  borderStyle: 'solid',

  dark: {
    color: '$brand-white',
  },
  light: {
    color: '$gray-600',
  },

  hover: {
    dark: {
      borderColor: activeColor.dark,
    },
    light: {
      borderColor: activeColor.light,
    },
  },

  '&::placeholder': {
    dark: {
      color: '$gray-300',
    },
    light: {
      color: '$gray-400',
    },
  },

  focus: {
    outline: 'none',

    '&::placeholder': {
      color: 'transparent',
    },

    [`& ~ ${Button}`]: {
      opacity: 1,
      visibility: 'visible',
    },

    dark: {
      borderColor: activeColor.dark,
    },
    light: {
      borderColor: activeColor.light,
    },
  },

  disabled: {
    pointerEvents: 'none',

    dark: {
      color: disabledColor.dark,
      borderColor: disabledColor.dark,
    },
    light: {
      color: disabledColor.light,
      borderColor: disabledColor.light,
    },

    '&::placeholder': {
      dark: {
        color: disabledColor.dark,
      },
      light: {
        color: disabledColor.light,
      },
    },
  },

  '@xsmall': {
    ...kinds['body-3'],
  },

  '@medium': {
    ...kinds['body-2'],
    lineHeight: '$24',
  },

  defaultVariants: {
    kind: 'flushed',
  },

  variants: {
    kind: {
      outline: {
        padding: '$8 $12',
        borderWidth: '$1',
        borderRadius: '$6',

        dark: {
          borderColor: '$gray-400',
        },
        light: {
          borderColor: '$gray-300',
        },
      },

      filled: {
        padding: '$8 $12',
        borderRadius: '$6',
        borderWidth: '0',

        dark: {
          backgroundColor: '$gray-500',
          color: '$brand-white',
          outlineColor: '$blue-400',
        },
        light: {
          backgroundColor: '$gray-200',
          color: '$gray-600',
          outlineColor: '$blue-500',
        },

        '&:focus, &:focus-visible': {
          dark: {
            boxShadow: '0 0 0 1px $colors$blue-400',
            '-webkit-appearance': 'none',
          },
          light: {
            boxShadow: '0 0 0 1px $colors$blue-500',
            '-webkit-appearance': 'none',
          },
        },

        '&::placeholder': {
          dark: {
            color: '$gray-250',
          },
          light: {
            color: '$gray-450',
          },
        },

        disabled: {
          dark: {
            color: '$gray-400',
            '&::placeholder': {
              color: '$gray-400',
            },
          },
          light: {
            color: '$gray-300',
            '&::placeholder': {
              color: '$gray-300',
            },
          },
        },
      },

      'filled-profile': {
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        borderColor: '$gray-500',
        borderRadius: '$6',
        borderWidth: '1px',
        outlineColor: '$blue-400',
        padding: '$8 $12',
        dark: {
          color: '$brand-white',
        },
        light: {
          color: '$brand-white',
        },

        '&:focus, &:focus-visible': {
          dark: {
            boxShadow: '0 0 0 1px $colors$blue-400',
            '-webkit-appearance': 'none',
          },
          light: {
            boxShadow: '0 0 0 1px $colors$blue-500',
            '-webkit-appearance': 'none',
          },
        },

        '&::placeholder': {
          dark: {
            color: '$gray-250',
          },
          light: {
            color: '$gray-250',
          },
        },

        disabled: {
          dark: {
            color: '$gray-400',
            '&::placeholder': {
              color: '$gray-400',
            },
          },
          light: {
            color: '$gray-300',
            '&::placeholder': {
              color: '$gray-300',
            },
          },
        },
      },

      flushed: {
        dark: {
          borderColor: '$gray-250',
        },
        light: {
          borderColor: '$gray-450',
        },

        padding: '$4 0',
        borderWidth: '0 0 $1 0',
      },

      'flushed-inline': {
        dark: {
          borderColor: '$gray-250',
        },
        light: {
          borderColor: '$gray-450',
        },

        padding: '$4 0',
        borderWidth: '0 0 $1 0',

        '@xsmall': {
          ...kinds['subtitle-4'],
        },

        '@medium': {
          ...kinds['subtitle-2'],
          lineHeight: '$24',
        },
      },

      unstyled: {
        borderWidth: '0',
      },
    },

    isError: {
      true: {
        dark: {
          borderColor: errorColor.dark,
        },
        light: {
          borderColor: errorColor.light,
        },

        hover: {
          dark: {
            borderColor: activeColor.dark,
          },
          light: {
            borderColor: activeColor.light,
          },
        },

        focus: {
          dark: {
            borderColor: activeColor.dark,
          },
          light: {
            borderColor: activeColor.light,
          },
        },
      },
    },
  },
} as const;

export const InputPrimitive = styled('input', inputStyles);

export type InputPrimitiveProps = ComponentProps<typeof InputPrimitive>;
