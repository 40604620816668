export type { ComboboxProps } from './combobox.js';
import { Combobox as _Combobox } from './combobox.js';
import { ListBoxItem, ListBoxItemText } from './combobox.primitive.js';

export const Combobox = Object.assign(_Combobox, {
  ListBoxItem,
  ListBoxItemText,
}) as typeof _Combobox & {
  ListBoxItem: typeof ListBoxItem;
  ListBoxItemText: typeof ListBoxItemText;
};
