import { useMemo } from 'react';

import { Flex } from '../flex/index.js';
import { Spacer } from '../spacer/index.js';
import {
  ErrorIcon,
  FieldMessageContainer,
  FieldMessageContent,
} from './field.primitive.js';

export type FieldMessageProps = {
  errorMessage?: string;
  helperMessage?: string;
  ariaHidden?: boolean;
  disabled?: boolean;
  screenReader?: boolean;
};

export const FieldMessage = ({
  ariaHidden = false,
  disabled,
  errorMessage,
  helperMessage,
  screenReader = false,
  ...props
}: FieldMessageProps) => {
  const iconMap = useMemo(
    () =>
      ({
        error: <ErrorIcon size={{ '@xsmall': '16', '@medium': '18' }} />,
        info: null,
      }) as const,
    [],
  );

  const level = errorMessage ? 'error' : 'info';

  const screenReaderStyles =
    screenReader ?
      {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        position: 'absolute',
        width: '1px',
      }
    : {};

  return (
    <Flex>
      {errorMessage || helperMessage ?
        <Flex alignItems="center" css={screenReaderStyles} width="100%">
          {iconMap[level] || null}
          {iconMap[level] ?
            <Spacer right="4" />
          : null}
          <FieldMessageContainer
            css={{ flexDirection: 'column' }}
            hasIcon={!!iconMap[level]}
          >
            <FieldMessageContent
              aria-hidden={ariaHidden}
              data-disabled={disabled}
              data-test="field-message"
              level={level}
              {...props}
            >
              {errorMessage || helperMessage}
            </FieldMessageContent>
          </FieldMessageContainer>
        </Flex>
      : null}
    </Flex>
  );
};
