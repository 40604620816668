import * as Dropdown from '@radix-ui/react-dropdown-menu';
import {
  type ReactElement,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react';
import { isString } from 'remeda';
import type { SetRequired } from 'type-fest';

import { styled } from '../../stitches.config.js';
import { Box } from '../box/index.js';
import { Text } from '../text/index.js';
import { kinds } from '../text/text.js';

export type MenuItemProps = SetRequired<
  Dropdown.DropdownMenuItemProps,
  'children'
>;

export const LeftSlot = styled('div', {
  display: 'flex',
  alignItems: 'center',

  dark: {
    color: '$brand-white',
  },

  light: {
    color: '$gray-450',
  },

  '& > svg': {
    height: '1.8rem',
    fill: 'currentColor',
  },

  '[data-highlighted] > &': {
    dark: {
      color: '$brand-white',
    },

    light: {
      color: '$gray-450',
    },
  },

  '[data-disabled] &': {
    dark: { color: '$gray-400' },
    light: { color: '$gray-300' },
  },
});

LeftSlot.displayName = 'Menu.Item.LeftSlot';

export const RightSlot = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingLeft: 20,

  dark: {
    color: '$brand-white',
  },

  light: {
    color: '$gray-450',
  },

  '& > svg': {
    height: '1.8rem',
    fill: 'currentColor',
  },

  '[data-highlighted] > &': {
    dark: {
      color: '$brand-white',
    },

    light: {
      color: '$gray-450',
    },
  },
  '[data-disabled] &': {
    dark: { color: '$gray-400' },
    light: { color: '$gray-300' },
  },
});

RightSlot.displayName = 'Menu.Item.RightSlot';

const hoverFocusStyles = {
  outline: 'none',
  dark: { backgroundColor: '$gray-450' },
  light: { backgroundColor: '$gray-250' },
} as const;

export const itemStyles = {
  ...kinds['body-4'],
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  listStyle: 'none',
  padding: '$8',

  dark: {
    color: '$brand-white',
    scrollbarColor: 'initial $gray-600',
  },

  light: {
    color: '$gray-600',
    scrollbarColor: 'initial $gray-200',
  },

  '@small': {
    columnGap: '$4',
  },

  '@medium': {
    columnGap: '$8',
  },

  '@large': {
    ...kinds['body-3'],
  },

  '&[data-highlighted]': {
    ...hoverFocusStyles,
  },

  focus: hoverFocusStyles,
  hover: hoverFocusStyles,

  pressed: {
    dark: { backgroundColor: '$gray-550' },
    light: { backgroundColor: '$gray-150' },
  },

  disabled: {
    pointerEvents: 'none',
    dark: { color: '$gray-400' },
    light: { color: '$gray-300' },
  },

  // https://medium.com/@iamryanyu/how-to-align-last-flex-item-to-right-73512e4e5912
  '& > svg:last-of-type': { marginLeft: 'auto' },
};

/**
 * @link Accessibility: https://www.w3.org/TR/wai-aria-1.2/#menuitem
 * @link Built With: https://www.radix-ui.com/docs/primitives/components/dropdown-menu#item
 *
 * @remarks The {@link MenuItem \<Menu.Item \/\>} is an option in a set of choices contained by a
 * `<Menu />`.
 *
 * @props
 *
 * {@link MenuItemProps.children children} The text to be displayed inside the `<Menu.Item />`. It
 * also can accept up to two icons.
 *
 * {@link MenuItemProps.disabled disabled} You may disable the `<Menu.Item />`, which removes
 * interactivity,
 *
 * {@link MenuItemProps.onSelect onSelect} This handler is fired when a user selects a given item.
 *
 * @example
 *
 * ```tsx
 * <Menu.Item>Option 1</Menu.Item>
 * <Menu.Item disabled>Option 2</Menu.Item>
 * <Menu.Item onSelect={(event: Event) => {}}>Option 3</Menu.Item>
 * ```
 */
export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  function MenuItem(
    { children, disabled, onSelect, onClick, ...props }: MenuItemProps,
    ref,
  ) {
    return (
      <Dropdown.Item
        aria-disabled={disabled}
        asChild
        disabled={disabled}
        onClick={onClick}
        onSelect={onSelect}
        ref={ref}
        {...props}
      >
        <Box css={itemStyles}>
          {Children.map(children, child =>
            isString(child) ?
              <Text
                as="p"
                kind={{ '@initial': 'body-4', '@large': 'body-3' }}
                lines={1}
              >
                {child}
              </Text>
            : isValidElement(child) ?
              cloneElement(child as ReactElement, {
                fill: 'currentColor',
                size: 18,
              })
            : null,
          )}
        </Box>
      </Dropdown.Item>
    );
  },
);

MenuItem.displayName = 'Menu.Item';
