import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CheckOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function CheckOutlineIcon(
    {
      'aria-label': ariaLabel = 'Check Outline Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M17.2975 8.29251C16.9072 7.89941 16.2717 7.89829 15.88 8.29L10 14.17L7.11549 11.2935C6.7257 10.9048 6.09474 10.9053 5.70549 11.2945C5.31586 11.6841 5.31586 12.3159 5.70549 12.7055L9.54545 16.5455C9.79649 16.7965 10.2035 16.7965 10.4545 16.5455L17.295 9.705C17.6848 9.31524 17.6859 8.68365 17.2975 8.29251ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
      </Icon>
    );
  },
) as typeof Icon;
