import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Button } from '../button/index.js';
import { Grid } from '../grid/index.js';
import { VolumeHighIcon } from '../icons/volume-high-icon.js';
import { VolumeLowIcon } from '../icons/volume-low-icon.js';
import { VolumeOffIcon } from '../icons/volume-off-icon.js';
import { type SliderProps, Slider } from '../slider/index.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerVolumeProps = {
  disabled?: SliderProps['disabled'];
  onChange?: SliderProps['onChange'];
  onMute?: JSX.IntrinsicElements['button']['onClick'];
  muted?: boolean;
  volume: number;
};

/**
 * @deprecated
 */
export const PlayerVolume = forwardRef<HTMLButtonElement, PlayerVolumeProps>(
  function PlayerVolume({ disabled, muted, onChange, onMute, volume }, ref) {
    return (
      <Grid
        display={{
          [`@container ${media.xsmall}`]: 'none',
          [`@container ${media.large}`]: 'grid',
        }}
        gridArea="volume"
        width="100%"
      >
        <Grid
          columnGap="$4"
          padding="$0 $8"
          templateColumns="min-content 1fr"
          width="16rem"
        >
          <Tooltip
            side="top"
            trigger={
              <Button
                color={{ dark: 'white', light: 'gray' }}
                kind="tertiary"
                onClick={volume > 0 ? onMute : undefined}
                ref={ref}
                size="icon"
              >
                {(muted || volume === 0) && <VolumeOffIcon size={32} />}
                {!muted && volume > 0 && volume <= 50 && (
                  <VolumeLowIcon size={32} />
                )}
                {!muted && volume > 50 && <VolumeHighIcon size={32} />}
              </Button>
            }
          >
            <Text
              color={{
                dark: '$brand-black',
                light: '$brand-white',
              }}
              kind="caption-3"
            >
              {muted ? 'Unmute' : 'Mute'}
            </Text>
          </Tooltip>
          <Slider
            disabled={disabled}
            max={100}
            min={0}
            onChange={onChange}
            tooltip="Adjust Volume"
            value={volume}
          />
        </Grid>
      </Grid>
    );
  },
);

PlayerVolume.displayName = 'Player.Volume';
