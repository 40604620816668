import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SortAlphaDescendingIcon = forwardRef<SVGSVGElement, IconProps>(
  function SortAlphaDescendingIcon(
    {
      'aria-label': ariaLabel = 'Sort Alpha Descending Icon',

      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M5.69153 9.88307H9.44948C9.75966 9.88307 10.0136 10.1344 9.99944 10.4485C9.99944 10.7487 9.7526 11 9.44242 11H4.70454C4.14047 11 3.80206 10.3647 4.12635 9.90401L8.16625 4.12391H4.57768C4.27438 4.12391 4.02066 3.8726 4.02066 3.56545V3.55846C4.02066 3.25131 4.26733 3 4.57768 3H9.14636C9.71026 3 10.0488 3.63525 9.72437 4.09599L5.69153 9.88307Z" />
        <path
          clipRule="evenodd"
          d="M10.3946 21C10.8219 21 11.1138 20.5812 10.9571 20.1902L8.30111 13.5305C8.17282 13.2094 7.85242 13 7.50351 13C7.1546 13 6.8342 13.2094 6.70608 13.5305L4.04293 20.1832C3.88612 20.5742 4.17818 20.993 4.60532 20.993C4.85462 20.993 5.08253 20.8394 5.17502 20.6091L5.68056 19.2827H9.31933L9.82487 20.6091C9.91753 20.8464 10.1453 21 10.3946 21ZM6.115 18.1449L7.49638 14.5288L8.87776 18.1449H6.115Z"
          fill="#A9AFB2"
          fillRule="evenodd"
        />
        <path d="M17.9998 17.1814V4.01223C17.9998 3.4555 17.5498 3 16.9998 3C16.4498 3 15.9998 3.4555 15.9998 4.01223V17.1814H14.2098C13.7598 17.1814 13.5398 17.728 13.8598 18.0418L16.6498 20.8558C16.8498 21.0481 17.1598 21.0481 17.3598 20.8558L20.1498 18.0418C20.4698 17.728 20.2398 17.1814 19.7998 17.1814H17.9998Z" />
      </Icon>
    );
  },
) as typeof Icon;
