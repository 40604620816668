import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const BrowseIcon = forwardRef<SVGSVGElement, IconProps>(
  function BrowseIcon(
    {
      'aria-label': ariaLabel = 'Browse Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          d="M9.50675 17.1908C10.8807 17.1908 12.1684 16.8174 13.2761 16.1653L17.8534 21.5686C18.292 22.0863 19.0623 22.1462 19.5739 21.7025C20.0855 21.2588 20.1448 20.4794 19.7062 19.9617L15.159 14.5939C16.3137 13.2582 17.0135 11.5095 17.0135 9.59541C17.0135 5.40058 13.6526 2 9.50675 2C5.36088 2 2 5.40058 2 9.59541C2 13.7902 5.36088 17.1908 9.50675 17.1908ZM9.50675 14.2279C12.0353 14.2279 14.0852 12.1539 14.0852 9.59541C14.0852 7.03696 12.0353 4.96292 9.50675 4.96292C6.97816 4.96292 4.92833 7.03696 4.92833 9.59541C4.92833 12.1539 6.97816 14.2279 9.50675 14.2279ZM17.3305 4.46933C17.019 3.94032 16.6607 3.44442 16.2617 2.98787H21.289C21.6817 2.98787 22.0001 3.31951 22.0001 3.7286C22.0001 4.13769 21.6817 4.46933 21.289 4.46933H17.3305ZM18.6933 8.9137C18.6601 8.40842 18.5901 7.91364 18.4862 7.43225H21.289C21.6817 7.43225 22.0001 7.76388 22.0001 8.17298C22.0001 8.58207 21.6817 8.9137 21.289 8.9137H18.6933ZM18.4596 11.8766C18.3429 12.387 18.188 12.882 17.9982 13.3581H21.289C21.6817 13.3581 22.0001 13.0264 22.0001 12.6174C22.0001 12.2083 21.6817 11.8766 21.289 11.8766H18.4596Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
