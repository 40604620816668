import {
  Box,
  Grid,
  LogotypeBeta,
  Navigation,
} from '@iheartradio/web.companion';
import type { ReactNode } from 'react';

import { useErrorContext } from '../hooks/error-context.js';

const AppErrorLayout = ({ children }: { children: ReactNode }) => {
  const { fromRoot } = useErrorContext();

  return fromRoot ?
      <Grid
        gridTemplateAreas={{
          '@initial': `"nav" "content"`,
        }}
        templateColumns={{ '@initial': '1fr' }}
        templateRows={{ '@initial': '4.8rem auto' }}
      >
        <Box data-test="grid area nav" gridArea="nav">
          <Navigation
            css={{
              height: 'auto',
              position: 'relative',
              '@large': { width: '100%' },
            }}
            data-test="navigation-wrapper"
          >
            <Navigation.Header
              css={{
                '@large': {
                  boxShadow: 'inherit',
                  display: 'flex',
                  position: 'fixed',
                  width: 'inherit',
                },
                svg: { '@large': { display: 'flex' } },
              }}
            >
              <LogotypeBeta
                aria-label="iHeart Header Logotype"
                media="mobile"
                size={24}
              />
            </Navigation.Header>
          </Navigation>
        </Box>
        {children}
      </Grid>
    : <>{children}</>;
};

export default AppErrorLayout;
