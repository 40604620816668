import { useUncontrolled } from '@mantine/hooks';
import type { ComponentProps } from '@stitches/react';
import { forwardRef, useMemo } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Field } from '../field/index.js';
import {
  type InputPropsWithFieldProps,
  useInputProps,
} from '../input/index.js';
import { kinds } from '../text/text.js';
import { TextareaPrimitive } from './textarea.primitive.js';

export type TextareaProps = Omit<
  InputPropsWithFieldProps<
    ComponentProps<typeof TextareaPrimitive>,
    UseUncontrolledInput<string>
  >,
  'isError'
> & {
  isError?: boolean;
  maxRows?: number;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(props: TextareaProps, ref) {
    const { inputProps, fieldProps, maxRows, ...restProps } =
      useInputProps<TextareaProps>(props);

    const { value, defaultValue, onChange, disabled, ...restInputProps } =
      inputProps;

    const [_value, handleChange] = useUncontrolled<string>({
      value,
      onChange,
      defaultValue,
      finalValue: '',
    });

    const autoSizeStyles = useMemo(() => {
      // Calculate the height of the textarea based on the number of rows and the line height
      const xSmallStyles = {
        maxHeight: `calc(${maxRows}*$lineHeights${kinds['body-3'].lineHeight})`,
        minHeight: `$lineHeights${kinds['body-3'].lineHeight}`,
      };

      const mediumStyles = {
        maxHeight: `calc(${maxRows}*$lineHeights${kinds['body-2'].lineHeight})`,
        minHeight: `$lineHeights${kinds['body-2'].lineHeight}`,
      };

      return {
        gridArea: '1 / 1 / 2 / 2',
        width: '100%',
        wordWrap: 'anywhere',
        '@xsmall': {
          ...xSmallStyles,
        },
        '@medium': {
          ...mediumStyles,
        },
      };
    }, [maxRows]);

    return (
      <Field {...fieldProps}>
        <TextareaPrimitive
          {...restProps}
          {...restInputProps}
          aria-disabled={disabled}
          css={{
            ...autoSizeStyles,
            // TODO: This is a hack to remove the extra space added by the textarea.
            height: _value && _value.length > 0 ? 'auto' : 'calc(100% - 1rem)',
            minHeight: 'min-content',
            resize: 'vertical',
          }}
          disabled={disabled}
          kind="flushed"
          onChange={event => handleChange(event.currentTarget.value)}
          ref={ref}
          rows={1}
          value={_value}
        />
      </Field>
    );
  },
);
