import type { Features } from '../../schemas/features.js';

export const featuresBase: Features = {
  showPodcastTranscriptions: true,
  useAmpTranscription: false,
  showHomepageHero: false,
  showPodcastSearch: false,
  podcastTritonTokenEnabled: true,
  showUserEducationTour: false,
};
