import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const InfoOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function InfoOutlineIcon(
    {
      'aria-label': ariaLabel = 'Info Outline Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
        <path d="M13 12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12L11 16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16L13 12Z" />
        <path d="M13 8C13 7.44771 12.5523 7 12 7C11.4477 7 11 7.44771 11 8C11 8.55229 11.4477 9 12 9C12.5523 9 13 8.55229 13 8Z" />
      </Icon>
    );
  },
) as typeof Icon;
