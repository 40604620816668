export const lineHeights = {
  6: '0.6rem',
  8: '0.8rem',
  10: '1rem',
  14: '1.4rem',
  15: '1.5rem',
  16: '1.6rem',
  18: '1.8rem',
  19: '1.9rem',
  20: '2.0rem',
  21: '2.1rem',
  22: '2.2rem',
  24: '2.4rem',
  26: '2.6rem',
  28: '2.8rem',
  30: '3.0rem',
  38: '3.8rem',
  40: '4.0rem',
  45: '4.5rem',
  48: '4.8rem',
} as const;
