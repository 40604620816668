import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const YourLibraryIcon = forwardRef<SVGSVGElement, IconProps>(
  function YourLibraryIcon(
    {
      'aria-label': ariaLabel = 'Your Library Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M7.5 1.5C7.22386 1.5 7 1.72386 7 2C7 2.27614 7.22386 2.5 7.5 2.5H16.5C16.7761 2.5 17 2.27614 17 2C17 1.72386 16.7761 1.5 16.5 1.5H7.5Z" />
        <path d="M5.00012 5.49817C5.00113 4.94588 5.44967 4.49899 6.00195 4.5L18.0019 4.52197C18.5542 4.52298 19.0011 4.97152 19.0001 5.5238C18.9991 6.07609 18.5505 6.52298 17.9982 6.52197L5.99829 6.5C5.44601 6.49899 4.99911 6.05045 5.00012 5.49817Z" />
        <path
          clipRule="evenodd"
          d="M4.11653 22.5H19.884C20.5002 22.5 21 22.1117 21 21.632V9.36798C21 8.88834 20.5002 8.5 19.884 8.5H4.11653C3.49985 8.5 3 8.88834 3 9.36798V21.632C3 22.1117 3.49985 22.5 4.11653 22.5ZM10.9286 12.0714C10.9286 11.481 11.4096 11 12 11C12.5904 11 13.0714 11.481 13.0714 12.0714V14.4286H15.4286C16.019 14.4286 16.5 14.9096 16.5 15.5C16.5 16.0904 16.019 16.5714 15.4286 16.5714H13.0714V18.9286C13.0714 19.519 12.5904 20 12 20C11.4096 20 10.9286 19.519 10.9286 18.9286V16.5714H8.57143C7.981 16.5714 7.5 16.0904 7.5 15.5C7.5 14.9096 7.981 14.4286 8.57143 14.4286H10.9286V12.0714Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
