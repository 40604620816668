import { forwardRef } from 'react';

import type { Component } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export type FlexProps = BoxProps & {
  alignContent?: BoxProps['alignContent'];
  alignItems?: BoxProps['alignItems'];
  basis?: BoxProps['flexBasis'];
  columnGap?: BoxProps['columnGap'];
  direction?: BoxProps['flexDirection'];
  display?: BoxProps['display'];
  gap?: BoxProps['gap'];
  grow?: BoxProps['flexGrow'];
  justifyContent?: BoxProps['justifyContent'];
  justifyItems?: BoxProps['justifyItems'];
  rowGap?: BoxProps['rowGap'];
  shrink?: BoxProps['flexShrink'];
  wrap?: BoxProps['flexWrap'];
};

/**
 * @deprecated
 */
export const Flex = forwardRef<HTMLDivElement, FlexProps>(function Flex(
  {
    alignContent,
    alignItems,
    basis,
    children,
    columnGap,
    direction,
    display = 'flex',
    gap,
    grow,
    justifyContent,
    justifyItems,
    rowGap,
    shrink,
    wrap,
    ...props
  }: FlexProps,
  ref,
) {
  return (
    <Box
      alignContent={alignContent}
      alignItems={alignItems}
      columnGap={columnGap}
      display={display}
      flexBasis={basis}
      flexDirection={direction}
      flexGrow={grow}
      flexShrink={shrink}
      flexWrap={wrap}
      gap={gap}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      ref={ref}
      rowGap={rowGap}
      {...props}
    >
      {children}
    </Box>
  );
}) as Component<'div', FlexProps>;
