import type { ComponentProps } from '@stitches/react';
import type { Merge } from 'type-fest';

import { styled } from '../../stitches.config.js';
import type { Input } from './input.js';
import type { InputLeftElement, InputRightElement } from './input-element.js';

const InputGroupPrimitive = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  $$paddingForSideElement: '$space$48',

  variants: {
    hasLeftElement: {
      true: {
        [`& input`]: {
          paddingLeft: '$$paddingForSideElement',
        },
      },
      false: {},
    },

    hasRightElement: {
      true: {
        [`& input`]: {
          paddingRight: '$$paddingForSideElement',
        },
      },
      false: {},
    },
  },
});

export type InputGroupProps = Merge<
  Omit<
    ComponentProps<typeof InputGroupPrimitive>,
    'hasLeftElement' | 'hasRightElement' | 'inlist'
  >,
  {
    leftElement?: ReturnType<typeof InputLeftElement>;

    children: ReturnType<typeof Input>;

    rightElement?: ReturnType<typeof InputRightElement>;
  }
>;

export const InputGroup = (props: InputGroupProps) => {
  const {
    rightElement = null,
    leftElement = null,
    children,
    ...restProps
  } = props;

  const hasLeftElement = !!leftElement;
  const hasRightElement = !!rightElement;

  return (
    <InputGroupPrimitive
      hasLeftElement={hasLeftElement}
      hasRightElement={hasRightElement}
      {...restProps}
    >
      {leftElement}
      {children}
      {rightElement}
    </InputGroupPrimitive>
  );
};

InputGroup.toString = () => '.input-group';
InputGroup.displayName = 'InputGroup';
