import * as Analytics from '@iheartradio/web.analytics';
import { useEffect } from 'react';

export function useAppOpenClose(
  analytics: Analytics.Analytics.Analytics,
  appName: string,
  appVersion: string,
  pageName: string,
) {
  const startTime = Date.now();

  useEffect(() => {
    function onAppOpen({
      initializationTime,
      appVersion,
    }: {
      initializationTime: number;
      appVersion: string;
    }) {
      analytics.track({
        type: Analytics.eventType.enum.AppOpen,
        data: {
          remote: {
            location: `web|listen|${appVersion}`,
          },
          session: {
            initializationTime,
          },
        },
      });
    }

    function fireAppOpenEvent() {
      onAppOpen({
        appVersion: appVersion ?? '',
        initializationTime: Date.now() - startTime,
      });
    }

    // If load event already happened then just need to fire app open event
    if (window.document.readyState === 'complete') {
      fireAppOpenEvent();
    } else {
      window.addEventListener('load', () => fireAppOpenEvent(), { once: true });
    }
  }, []);

  // Added beforeunLoad event listener to check app load time
  useEffect(() => {
    function onAppClose(pageName: string) {
      analytics.track({
        type: Analytics.eventType.enum.AppClose,
        data: {
          event: {
            location: `web|${appName}|${pageName}|app_close|exit_app`,
          },
        },
      });
    }

    function fireOnUnload() {
      onAppClose(pageName);
    }

    window.addEventListener('beforeunload', fireOnUnload);

    return () => window.removeEventListener('beforeunload', fireOnUnload);
  }, []);
}
