export const space = {
  0: '0rem',
  1: '0.1rem',
  2: '0.2rem',
  4: '0.4rem',
  6: '0.6rem',
  8: '0.8rem',
  12: '1.2rem',
  16: '1.6rem',
  18: '1.8rem',
  24: '2.4rem',
  32: '3.2rem',
  36: '3.6rem',
  40: '4.0rem',
  48: '4.8rem',
  56: '5.6rem',
  64: '6.4rem',
  128: '12.8rem',
} as const;
