// Generated by ts-to-zod
import { z } from 'zod';

export const kruxSchema = z.object({
  dev: z.object({
    id: z.string(),
  }),
  en: z.object({
    appOpen: z.string(),
    id: z.string(),
  }),
  fr: z.object({
    appOpen: z.string(),
    id: z.string(),
  }),
});

export type Krux = z.infer<typeof kruxSchema>;
