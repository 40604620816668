import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FeaturedIcon = forwardRef<SVGSVGElement, IconProps>(
  function FeaturedIcon(
    {
      'aria-label': ariaLabel = 'Featured Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M12.5302 2.33346C12.3166 1.88885 11.6835 1.88885 11.4699 2.33346L8.73568 8.02487C8.64995 8.20334 8.48017 8.32669 8.28394 8.35308L2.02617 9.19474C1.53731 9.26049 1.34167 9.86259 1.69852 10.2031L6.26645 14.5623C6.40969 14.699 6.47454 14.8985 6.439 15.0933L5.30571 21.3049C5.21717 21.7902 5.72935 22.1623 6.1635 21.9281L11.7209 18.9308C11.8951 18.8368 12.105 18.8368 12.2792 18.9308L17.8366 21.9281C18.2707 22.1623 18.7829 21.7902 18.6944 21.3049L17.5611 15.0933C17.5256 14.8985 17.5904 14.699 17.7336 14.5623L22.3016 10.2031C22.6584 9.86259 22.4628 9.26049 21.9739 9.19474L15.7162 8.35308C15.5199 8.32669 15.3501 8.20334 15.2644 8.02487L12.5302 2.33346Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
