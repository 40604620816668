import { type ReactNode, useMemo } from 'react';
import { type ToasterProps, Toaster } from 'react-hot-toast';

import { css } from '../../stitches.config.js';
import type { CSSProperty } from '../../types.js';
import { makeImportant } from '../../utilities/make-important.js';
import { useFormatCss } from '../box/index.js';

export type ToastProviderProps = {
  children?: ReactNode;
  position?: ToasterProps['position'];
  gap?: ToasterProps['gutter'];
  insetX?: CSSProperty<'inset'>;
  insetY?: CSSProperty<'inset'>;
  reverse?: ToasterProps['reverseOrder'];
};

export function ToastProvider({
  children,
  gap = 10,
  insetX = '$16',
  insetY = '$16',
  position = 'bottom-center',
  reverse = false,
}: ToastProviderProps) {
  const x = makeImportant(insetX);
  const y = makeImportant(insetY);

  const styles = useFormatCss({
    bottom: y,
    left: x,
    right: x,
    top: y,
  });

  const className = useMemo(() => css(styles), [styles]);

  return (
    <>
      <Toaster
        containerClassName={className()}
        gutter={gap}
        position={position}
        reverseOrder={reverse}
      />
      {children}
    </>
  );
}
