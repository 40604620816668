import { isPlainObject } from 'remeda';

import { globalCss } from '../../stitches.config.js';
import { normalize } from './normalize.js';

const baseStyles = {
  boxSizing: 'border-box',
  padding: 0,
};

const resetStyles = {
  '*': baseStyles,

  'html, body': {
    fontSize: '10px',
    fontFamily: `'Open Sans', sans-serif`,
  },

  'pre, code, kbd, samp': {
    fontFamily:
      'ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, monospace',
  },

  'a, button': {
    all: 'unset',
    ...baseStyles,
  },

  '.triton-pixel': {
    position: 'absolute',
    width: '1px',
    height: '1px',
  },
};

export const globalStyles = {
  ...normalize,
  ...resetStyles,
};

export function Reset() {
  const injectGlobalStyles = globalCss(globalStyles);
  injectGlobalStyles();
  return null;
}

export const resetCssText = () => {
  return stringifyCss(resetStyles);
};

function cssBlock(s: string) {
  return `{\n${s}}\n`;
}

function kebabCase(s: string) {
  return s
    .replaceAll(/([a-z])([A-Z])/g, '$1-$2')
    .replaceAll(/[\s_]+/g, '-')
    .toLowerCase();
}

function stringifyValue(v: string) {
  return (
    isPlainObject(v) ?
      cssBlock(
        Object.entries(v)
          .map(([k, v]) => {
            return `  ${kebabCase(k)}: ${v}`;
          })
          .filter(s => s.trim().length > 0)
          .join(';\n') + ';\n',
      )
    : typeof v === 'string' ? `"${v.trim()}";\n`
    : `${v};\n`
  );
}

function stringifyCss(cssObj: object) {
  let result = '';

  for (const [k, v] of Object.entries(cssObj)) {
    result += `${k.trim()} ${stringifyValue(v)}`;
  }

  return result;
}
