import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { SavePlaylistIcon } from '../icons/save-playlist-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

export const PlayerAddToPlaylist = forwardRef<
  HTMLButtonElement,
  { onClick?: () => void }
>(function PlayerAddToPlaylist(props, ref) {
  return (
    <Box
      css={{
        display: 'none',
        padding: '$0 $8',

        [`@container ${media.large}`]: {
          display: 'block',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'block',
          padding: '$0 $2',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="addToPlaylist"
    >
      <Tooltip
        side="top"
        trigger={
          <Button
            {...props}
            color={{ dark: 'white', light: 'gray' }}
            kind="tertiary"
            ref={ref}
            size="icon"
          >
            <SavePlaylistIcon size={32} />
          </Button>
        }
      >
        <Text
          color={{
            dark: '$brand-black',
            light: '$brand-white',
          }}
          kind="caption-3"
        >
          Add to Playlist
        </Text>
      </Tooltip>
    </Box>
  );
});

PlayerAddToPlaylist.displayName = 'Player.AddToPlaylist';
