import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FacebookColorIcon = forwardRef<SVGSVGElement, IconProps>(
  function FacebookColorIcon(
    {
      'aria-label': ariaLabel = 'Facebook Color Icon',

      children: _children,
      fill: _fill,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          d="M21 12C21 7.02891 16.9711 3 12 3C7.02891 3 3 7.02891 3 12C3 16.493 6.29062 20.216 10.5938 20.891V14.6016H8.30859V12H10.5938V10.0172C10.5938 7.76191 11.9367 6.51562 13.9934 6.51562C14.9777 6.51562 16.0078 6.69141 16.0078 6.69141V8.90625H14.8723C13.7543 8.90625 13.4062 9.60059 13.4062 10.3125V12H15.9023L15.5033 14.6016H13.4062V20.891C17.7094 20.216 21 16.493 21 12Z"
          fill="#1877F2"
        />
        <path
          d="M15.5033 14.6016L15.9023 12H13.4062V10.3125C13.4062 9.60059 13.7543 8.90625 14.8723 8.90625H16.0078V6.69141C16.0078 6.69141 14.9777 6.51562 13.9934 6.51562C11.9367 6.51562 10.5938 7.76191 10.5938 10.0172V12H8.30859V14.6016H10.5938V20.891C11.0525 20.9631 11.5219 21 12 21C12.4781 21 12.9475 20.9631 13.4062 20.891V14.6016H15.5033Z"
          fill="white"
        />
      </Icon>
    );
  },
) as typeof Icon;
