import {
  Header,
  Item,
  List,
  Logo,
  Navigation as _Navigation,
} from './navigation.primitive.js';

export const Navigation = Object.assign(_Navigation, {
  Header,
  Item,
  List,
  Logo,
}) as typeof _Navigation & {
  Header: typeof Header;
  Item: typeof Item;
  List: typeof List;
  Logo: typeof Logo;
};
