// Generated by ts-to-zod
import { z } from 'zod';

export const appBoySchema = z.object({
  appKey: z.string(),
  baseUrl: z.string(),
  enabled: z.boolean(),
  threshold: z.number(),
  groupKey: z.string().optional(),
});

export type AppBoy = z.infer<typeof appBoySchema>;
