import { Flex } from '@iheartradio/web.companion';
import type { ReactNode } from 'react';

import { useErrorContext } from '../hooks/error-context.js';

const AppErrorContainer = ({ children }: { children: ReactNode }) => {
  const { fromRoot } = useErrorContext();
  return (
    <Flex
      backgroundColor={{ light: '$gray-100', dark: '$brand-black' }}
      data-test="page-error-container"
      height={fromRoot ? '100vh' : '100%'}
      justifyContent="center"
    >
      {children}
    </Flex>
  );
};

export default AppErrorContainer;
