import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RepeatOffIcon = forwardRef<SVGSVGElement, IconProps>(
  function ReorderIcon(
    { 'aria-label': ariaLabel = 'Repeat Off Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M7.08887 18.002V19.2136C7.08887 19.6541 6.58172 19.8746 6.28512 19.5632L4.13784 17.3086C3.95398 17.1155 3.95398 16.8025 4.13784 16.6094L6.28513 14.3548C6.58173 14.0434 7.08887 14.2639 7.08887 14.7044V15.9702V16.1914H17.2656C17.5708 16.1914 17.7236 16.1663 17.9844 15.9297C18.2189 15.7169 18.2143 15.3339 18.211 15.0573C18.2107 15.0263 18.2103 14.9967 18.2103 14.9688V14.6177V14.2734V8.55129C18.2103 8.29086 18.0966 8.03977 17.8914 7.84698C17.6861 7.65419 17.4041 7.53353 17.1002 7.50852L16.9823 7.50364H7.15819C6.85292 7.50365 6.5586 7.60066 6.33262 7.77576C6.10664 7.95086 5.96521 8.19149 5.9359 8.45072L5.93017 8.55129L5.9359 12.1608C5.9359 12.5508 5.7924 12.7411 5.63422 12.8828C5.41666 13.0777 5.19532 13.1328 4.83594 13.1328C4.3274 13.1328 4.05079 12.875 3.9336 12.6953C3.81641 12.5156 3.79688 12.2109 3.79688 12.0547V11.6406V8.70312C3.79688 7.50852 4.1893 6.86521 4.64063 6.45312C5.35938 5.79688 5.7259 5.70994 6.67188 5.67188H7.15819H16.9823C17.9293 5.67188 18.84 5.71946 19.5252 6.27713C20.0952 6.74104 20.2656 7.14062 20.2656 8.14062V8.55129V15.2656C20.2656 16.0736 20.1953 16.5312 19.7578 17.0156C19.4601 17.3452 18.7422 18.002 17.6722 18.002H17.092L7.08887 18.002Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
