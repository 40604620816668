import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const SongIcon = forwardRef<SVGSVGElement, IconProps>(function SongIcon(
  {
    'aria-label': ariaLabel = 'Song Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M12 5V13.55C11.06 13.01 9.89997 12.8 8.66997 13.23C7.32997 13.71 6.29997 14.9 6.05997 16.3C5.59997 19.04 7.91997 21.38 10.65 20.95C12.61 20.64 14 18.84 14 16.85V7H16C17.1 7 18 6.1 18 5C18 3.9 17.1 3 16 3H14C12.9 3 12 3.9 12 5Z" />
    </Icon>
  );
}) as typeof Icon;
