import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { ShuffleActiveIcon } from '../icons/shuffle-active-icon.js';
import { ShuffleIcon } from '../icons/shuffle-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerShuffleProps = Partial<
  Pick<ButtonProps, 'disabled'> & JSX.IntrinsicElements['button']
> & {
  active?: boolean;
};

export const PlayerShuffle = forwardRef<HTMLButtonElement, PlayerShuffleProps>(
  function PlayerShuffle({ active = false, ...props }, ref) {
    return (
      <Box
        css={{
          display: 'none',
          padding: '$0 $8',

          [`@container ${media.medium}`]: {
            display: 'block',
          },

          '[data-player-type="fullscreen"] &': {
            display: 'block',
            padding: '$0 $6',

            [`@container ${media.large}`]: {
              display: 'none',
            },
          },
        }}
        gridArea="shuffle"
        justifySelf="center"
      >
        <Tooltip
          side="top"
          trigger={
            <Button
              {...props}
              color={{
                dark: 'white',
                light: 'gray',
              }}
              kind="tertiary"
              ref={ref}
              size="icon"
            >
              {active ?
                <ShuffleActiveIcon
                  css={{
                    dark: { fill: '$blue-400 !important' },
                    light: { fill: '$blue-500 !important' },
                  }}
                  size={32}
                />
              : <ShuffleIcon size={32} />}
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            {active ? 'Shuffle Off' : 'Shuffle'}
          </Text>
        </Tooltip>
      </Box>
    );
  },
);

PlayerShuffle.displayName = 'Player.Shuffle';
