import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ReplayIcon = forwardRef<SVGSVGElement, IconProps>(
  function ReplayIcon(
    {
      'aria-label': ariaLabel = 'Replay Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M11.9619 5.74069V2.99379C11.9619 2.55074 11.4197 2.33414 11.1084 2.64919L7.29246 6.38065C7.09162 6.57756 7.09162 6.88277 7.29246 7.07968L11.0983 10.8111C11.4197 11.1163 11.9619 10.8997 11.9619 10.4567V7.7098C15.7075 7.7098 18.6699 11.077 17.8464 14.8872C17.3745 17.1221 15.5268 18.9239 13.2573 19.3866C9.67238 20.125 6.47907 17.7129 5.99706 14.454C5.92677 13.9814 5.50501 13.6171 5.01296 13.6171C4.41045 13.6171 3.92844 14.1389 4.00878 14.7297C4.63137 19.0518 8.82886 22.2516 13.5787 21.3459C16.7117 20.7453 19.2322 18.274 19.8448 15.2022C20.8389 10.1515 16.9226 5.74069 11.9619 5.74069Z" />
      </Icon>
    );
  },
) as typeof Icon;
