import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const HomeIcon = forwardRef<SVGSVGElement, IconProps>(function HomeIcon(
  {
    'aria-label': ariaLabel = 'Home Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M21.7297 10.0454L12.5405 2.17199C12.2702 1.94267 11.7297 1.94267 11.4594 2.17199L2.27027 10.0454C2.09009 10.1982 2 10.3511 2 10.504V21.3884C2 21.6942 2.36036 22 2.81081 22H8.57656H15.4234H21.1891C21.6396 22 22 21.6942 22 21.3884V10.504C22 10.3511 21.9099 10.1982 21.7297 10.0454Z" />
    </Icon>
  );
}) as typeof Icon;
