import { borderWidths } from './border-widths.js';
import { colors } from './colors.js';
import { filters } from './filters.js';
import { fontSizes } from './font-sizes.js';
import { fontWeights } from './font-weights.js';
import { letterSpacings } from './letter-spacings.js';
import { lineHeights } from './line-heights.js';
import { radii } from './radii.js';
import { shadows } from './shadows.js';
import { sizes } from './sizes.js';
import { space } from './space.js';
import { zIndices } from './z-indices.js';

export const core = {
  borderWidths,
  colors,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  filters,
  shadows,
  sizes,
  space,
  zIndices,
} as const;

export { media } from './media.js';
