import { useMemo } from 'react';
import { isNullish, isPlainObject } from 'remeda';

import type { CSSProperties, MediaValue } from '../../types.js';

/**
 * @description
 * This function properly maps media props into valid css properties.
 *
 * @param mediaValue {MediaValue<any> | any} - This is the prop that needs to be transformed into
 * valid css.
 * @param mapFn {(value: any) => CSSProperties} - This is the function we use to map the prop into
 * valid css.
 *
 * @returns CSSProperties
 *
 * @example
 * const style = useMapMediaProp({ '@initial': 1, '@medium': 2 }, (value) => ({
 *   lineHeight: value
 * }));
 *
 * // "style" is then equal to the following css:
 * {
 *   lineHeight: 1,
 *   '@medium': {
 *     lineHeight: 2
 *   }
 * }
 */
export function useMapMediaProp<Value>(
  mediaValue: MediaValue<Value> | Value,
  mapFn: (value: Value) => CSSProperties,
) {
  return useMemo(() => {
    if (isNullish(mediaValue)) {
      return {};
    }

    return isPlainObject(mediaValue) ?
        Object.entries(mediaValue).reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            ...(key === '@initial' ? mapFn(value) : { [key]: mapFn(value) }),
          }),
          {},
        )
      : mapFn(mediaValue);
  }, [mapFn, mediaValue]);
}
