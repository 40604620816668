import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const VolumeOffIcon = forwardRef<SVGSVGElement, IconProps>(
  function VolumeOffIcon(
    {
      'aria-label': ariaLabel = 'Volume Off Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M3.62988 5.00255C3.23999 4.61255 3.23999 3.98255 3.62988 3.59255C4.03003 3.20255 4.65991 3.20255 5.05005 3.59255L20.3601 18.9126C20.75 19.3026 20.75 19.9326 20.3601 20.3225C19.97 20.7125 19.3401 20.7125 18.95 20.3225L17.6101 18.9826C16.9399 19.5225 16.1899 19.9625 15.3899 20.2926C14.73 20.5626 14 20.1025 14 19.3825C14 18.9925 14.22 18.6126 14.5801 18.4625C15.1599 18.2326 15.6899 17.9225 16.1799 17.5526L12 13.3726V17.5426C12 18.4326 10.9199 18.8825 10.29 18.2526L7 14.9625H4C3.44995 14.9625 3 14.5126 3 13.9625V9.96255C3 9.41255 3.44995 8.96255 4 8.96255H7L7.29004 8.66255L3.62988 5.00255Z" />
        <path d="M18.5901 14.3026C18.8501 13.5725 19 12.7825 19 11.9625C19 9.02255 17.1799 6.50255 14.6101 5.46255C14.25 5.32255 14 4.99255 14 4.61255V4.42255C14 3.79255 14.6299 3.33255 15.22 3.56255C18.6001 4.85255 21 8.13255 21 11.9625C21 13.3525 20.6799 14.6625 20.1201 15.8326L18.5901 14.3026Z" />
        <path d="M10.29 5.67255L10.1201 5.84255L12 7.72254V6.37255C12 5.48255 10.9202 5.04255 10.29 5.67255Z" />
        <path d="M14 7.93255C15.48 8.67255 16.5 10.1926 16.5 11.9625C16.5 12.007 16.4968 12.0515 16.4924 12.0959C16.4888 12.1315 16.4844 12.167 16.48 12.2025L14 9.72255V7.93255Z" />
      </Icon>
    );
  },
) as typeof Icon;
