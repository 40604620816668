import { lightDark } from '@iheartradio/web.accomplice';
import { Group } from '@iheartradio/web.accomplice/group';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { Box, HelperMessage } from '@iheartradio/web.companion';
import { isNotBlank, isNull } from '@iheartradio/web.utilities';
import type { ReactNode } from 'react';

import type {
  PasswordValidationKey,
  PasswordValidationResults,
} from '~app/hooks';

const textColor = lightDark('$gray450', '$gray250');
const disabledColor = lightDark('$gray300', '$gray400');

type PasswordChecksProps = {
  passwordValidationResults: PasswordValidationResults;
  serverValidationError?: string | ReactNode | null;
  disabled?: boolean;
};

const PasswordCheckTestTexts: { [k in PasswordValidationKey]: string } = {
  lengthTest: '8 to 32 characters',
  caseTest: 'an upper case and lower case letter',
  digitTest: 'a number (0-9)',
} as const;

export const PasswordChecks = ({
  passwordValidationResults,
  serverValidationError,
  disabled = false,
}: PasswordChecksProps) => {
  return (
    <Box>
      {isNotBlank(serverValidationError) ?
        typeof serverValidationError === 'string' ?
          <>
            <HelperMessage hasIcon kind="error">
              {serverValidationError}
            </HelperMessage>
            <Spacer bottom="$4" />
          </>
        : serverValidationError
      : null}
      <Text
        as="p"
        css={{
          color: disabled ? disabledColor : textColor,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        kind={{ mobile: 'caption-4', large: 'body-4' }}
      >
        Password must have:
      </Text>
      <Stack css={{ paddingTop: '$2' }}>
        {Object.keys(passwordValidationResults).map((key: string) => {
          const dasherized = key.replaceAll(
            /[A-Z]/g,
            s => '-' + s.toLowerCase(),
          );
          const validationKey = key as PasswordValidationKey;
          const status = passwordValidationResults[validationKey];
          return (
            <Group
              align="center"
              css={{
                paddingTop: '$2',
              }}
              data-test={
                isNull(status) ?
                  disabled ?
                    `${dasherized}-disabled`
                  : `${dasherized}-neutral`
                : status ?
                  `${dasherized}-success`
                : `${dasherized}-error`
              }
              key={key}
            >
              <HelperMessage
                hasIcon
                kind={
                  disabled ? 'disabled'
                  : isNull(status) ?
                    disabled ?
                      'disabled'
                    : 'neutral'
                  : status ?
                    'success'
                  : 'error'
                }
              >
                {PasswordCheckTestTexts[validationKey]}
              </HelperMessage>
            </Group>
          );
        })}
      </Stack>
    </Box>
  );
};
