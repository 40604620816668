import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ThumbUpFilledIcon = forwardRef<SVGSVGElement, IconProps>(
  function ThumbUpFilledIcon(
    { 'aria-label': ariaLabel = 'Thumb Up Selected Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M13.6992 9.6107L14.3853 6.30277C14.4574 5.94164 14.3491 5.57329 14.0891 5.31328C13.663 4.89438 12.9842 4.89438 12.5652 5.3205L8.57111 9.32179C8.30397 9.58903 8.15215 9.95738 8.15215 10.3402V17.5555C8.15215 18.35 8.80228 19 9.59666 19H16.0897C16.6676 19 17.1876 18.6533 17.4115 18.1261L19.7661 12.6297C20.38 11.1997 19.3326 9.6107 17.7799 9.6107H13.6992Z"
          fillRule="evenodd"
        />
        <path d="M5.49925 19C6.29703 19 6.94376 18.3533 6.94376 17.5555V11.7775C6.94376 10.9797 6.29703 10.3329 5.49925 10.3329C4.70147 10.3329 4.05474 10.9797 4.05474 11.7775V17.5555C4.05474 18.3533 4.70147 19 5.49925 19Z" />
      </Icon>
    );
  },
) as typeof Icon;
