import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ScanIcon = forwardRef<SVGSVGElement, IconProps>(function ScanIcon(
  {
    'aria-label': ariaLabel = 'Scan Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path
        clipRule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.55344 21.1214 7.31209 19.6625 5.57425L20.1154 5.12136C20.5059 4.73084 20.5059 4.09767 20.1154 3.70715C19.7249 3.31662 19.0917 3.31662 18.7012 3.70715L12.5595 9.84882C12.3808 9.80246 12.1933 9.77779 12 9.77779C10.7727 9.77779 9.77778 10.7727 9.77778 12C9.77778 13.2273 10.7727 14.2222 12 14.2222C13.2273 14.2222 14.2222 13.2273 14.2222 12C14.2222 11.7079 14.1659 11.4289 14.0634 11.1734L16.0997 9.13705C16.6671 9.94802 17 10.9351 17 12C17 14.7614 14.7614 17 12 17C9.23855 17 6.99997 14.7614 6.99997 12C6.99997 9.23855 9.23855 6.99997 12 6.99997H12V5.99997H12C8.68626 5.99997 5.99997 8.68626 5.99997 12C5.99997 15.3137 8.68626 18 12 18C15.3137 18 18 15.3137 18 12C18 10.6586 17.5598 9.42 16.816 8.42079L18.2415 6.99523C19.3418 8.36556 20 10.1059 20 12Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}) as typeof Icon;
