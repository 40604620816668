import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FastForwardIcon = forwardRef<SVGSVGElement, IconProps>(
  function FastForwardIcon(
    {
      'aria-label': ariaLabel = 'Fast Forward Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M5.19011 16.89L10.9601 12.82C11.5201 12.42 11.5201 11.58 10.9601 11.19L5.19011 7.11002C4.52011 6.65002 3.61011 7.12002 3.61011 7.93002V16.07C3.61011 16.88 4.52011 17.35 5.19011 16.89ZM12.6101 7.93002V16.07C12.6101 16.88 13.5201 17.35 14.1901 16.89L19.9601 12.82C20.5201 12.42 20.5201 11.58 19.9601 11.19L14.1901 7.12002C13.5201 6.65002 12.6101 7.12002 12.6101 7.93002Z" />
      </Icon>
    );
  },
) as typeof Icon;
