export enum HttpMethods {
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export enum ContentType {
  FormUrlEncoded = 'application/x-www-form-urlencoded',
  FormData = 'multipart/form-data',
  Json = 'application/json',
  Html = 'text/html',
  PlainText = 'text/plain',
}
