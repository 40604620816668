// Generated by ts-to-zod
import { z } from 'zod';

export const googleAnalyticsSchema = z.object({
  account: z.string(),
  domain: z.string(),
  enabled: z.boolean(),
  threshold: z.number(),
});

export type GoogleAnalytics = z.infer<typeof googleAnalyticsSchema>;

export const googleCastSchema = z.object({
  appKey: z.string(),
  enabled: z.boolean(),
  threshold: z.number(),
});

export type GoogleCast = z.infer<typeof googleCastSchema>;

export const googleFirebaseSchema = z.object({
  account: z.string(),
});

export type GoogleFirebase = z.infer<typeof googleFirebaseSchema>;

export const googlePlusSchema = z.object({
  appKey: z.string(),
  token: z.string(),
  enabled: z.boolean(),
  threshold: z.number(),
});

export type GooglePlus = z.infer<typeof googlePlusSchema>;

export const googleRecaptchaSchema = z.object({
  sitekey: z.string(),
  v3SiteKey: z.string(),
  enterpriseRecaptchaApiKey: z.string(),
});

export type GoogleRecaptcha = z.infer<typeof googleRecaptchaSchema>;
