import { useUncontrolled } from '@mantine/hooks';
import type { MouseEvent, MouseEventHandler } from 'react';
import { forwardRef } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Button } from '../button/index.js';
import { Field } from '../field/index.js';
import { CancelFilledIcon } from '../icons/cancel-filled-icon.js';
import {
  type InputProps,
  type InputPropsWithFieldProps,
  Input,
  InputGroup,
  InputRightElement,
  useInputProps,
} from '../input/index.js';

export type TextInputProps = InputPropsWithFieldProps<
  InputProps,
  UseUncontrolledInput<string> & {
    /** Includes a "Clear" button for resetting the input value when clicked. */
    clearable?: boolean;

    /** Called when the "Clear" button is clicked (only when `clearable` is `true`) */
    onClear?: MouseEventHandler<HTMLButtonElement>;
  }
>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props: TextInputProps, ref) {
    const { fieldProps, inputProps, clearable, onClear, ...restProps } =
      useInputProps(props);
    const { value, disabled, onChange, defaultValue, ...restInputProps } =
      inputProps;

    const [_value, handleChange] = useUncontrolled<string>({
      value,
      onChange,
      defaultValue,
      finalValue: '',
    });

    const showClearButton =
      clearable && typeof _value === 'string' && _value.length > 0 && !disabled;

    return (
      <Field {...fieldProps}>
        <InputGroup
          rightElement={
            showClearButton ?
              <InputRightElement>
                <Button
                  color={{ dark: 'white', light: 'gray' }}
                  data-test="clear-input-button"
                  kind="tertiary"
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    handleChange('');

                    // Allow additional functionality via the `onClear` prop if other actions need to be performed besides clearing the input
                    onClear?.(event);
                  }}
                  size="icon"
                >
                  <CancelFilledIcon size="16" />
                </Button>
              </InputRightElement>
            : null
          }
        >
          <Input
            type="text"
            {...restProps}
            {...restInputProps}
            onChange={event => handleChange(event.currentTarget.value)}
            ref={ref}
            value={_value}
          />
        </InputGroup>
      </Field>
    );
  },
);
