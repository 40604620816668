import { isUndefined } from '@iheartradio/web.utilities';

import type { ErrorFactory } from '../types/error-config.js';

export const newRelicNotifier = (fact: ErrorFactory) => {
  if (isUndefined(globalThis?.window)) {
    return;
  }

  if (isUndefined(globalThis?.window?.newrelic)) {
    console.log('New Relic Browser Agent Not Initialized');
    return;
  }

  globalThis?.window.newrelic.noticeError(fact.errorSource.payload as Error);
};
