import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const StopIcon = forwardRef<SVGSVGElement, IconProps>(function StopIcon(
  { 'aria-label': ariaLabel = 'Stop Icon', ...props }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M9 7.5H15C15.825 7.5 16.5 8.175 16.5 9V15C16.5 15.825 15.825 16.5 15 16.5H9C8.175 16.5 7.5 15.825 7.5 15V9C7.5 8.175 8.175 7.5 9 7.5Z" />
    </Icon>
  );
}) as typeof Icon;
