import {
  Button,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components';

import { css, styled } from '../../stitches.config.js';
import { inputStyles } from '../input/input.primitive.js';
import { kinds } from '../text/text.js';

export const StyledSelect = styled(Select, {
  ...inputStyles,

  focus: {
    outline: 'none',
    outlineColor: '$brand-white',
  },
  cursor: 'pointer',
});

StyledSelect.displayName = 'StyledSelect';

export const StyledButton = styled(Button, {
  display: 'flex',
  alignItems: 'center',
  gap: '$16',
  justifyContent: 'space-between',
  width: '100%',
});

StyledButton.displayName = 'StyledButton';

export const StyledSelectValue = styled(SelectValue, {
  '-webkit-box-orient': 'vertical',
  '-webkit-box-pack': 'end',
  '-webkit-line-clamp': 1,
  boxOrient: 'vertical',
  display: '-webkit-box',
  lineClamp: 1,
  lineHeight: 'normal',
  overflow: 'hidden',
  wordBreak: 'break-word',
});

StyledSelectValue.displayName = 'StyledSelectValue';

export const StyledPopover = styled(Popover, {
  // This ensures the popover width is the same as the Select element
  '&[data-trigger=Select]': {
    width: 'var(--trigger-width)',
  },
});

StyledPopover.displayName = 'StyledPopover';

export const listBoxStyles = css({
  borderRadius: '$6',
  boxShadow: '$elevation-4',
  overflowY: 'auto',
  width: '100%',
  maxHeight: '20rem',
  cursor: 'pointer',

  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: '$1',

  focus: {
    outline: 'none',
    dark: {
      borderColor: '$brand-white',
    },
    light: {
      borderColor: '$gray-450',
    },
  },
  dark: {
    scrollbarColor: 'initial $gray-600',
    backgroundColor: '$gray-600',
    color: '$brand-white',
  },
  light: {
    scrollbarColor: 'initial $gray-200',
    backgroundColor: '$brand-white',
    color: '$gray-450',
  },
});

export const StyledListBoxItem = styled(ListBoxItem, {
  ...kinds['body-4'],
  padding: '$8',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&[data-hovered], &[data-focused]': {
    dark: {
      backgroundColor: '$gray-450',
    },
    light: {
      backgroundColor: '$gray-250',
    },
  },

  '&.selected': {
    backgroundColor: 'none',

    '&::before': {
      content: '✓',
      alt: ' ',
      position: 'absolute',
      top: '8px',
      right: '4px',
    },
  },

  focus: {
    outline: 'none',
  },
});

StyledListBoxItem.displayName = 'StyledListBoxItem';
