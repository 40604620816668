const wordSeparators =
  // eslint-disable-next-line regexp/no-dupe-characters-character-class
  /[\s!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\u2000-\u206F\u2E00-\u2E7F-]+/;
// eslint-disable-next-line regexp/no-obscure-range
const capital_plus_lower = /[A-Z\u00C0-Ý][a-zà-ÿ]/g;
// eslint-disable-next-line regexp/no-obscure-range
const capitals = /[A-Z\u00C0-Ý]+/g;

export function kebabCase(str: string) {
  // replace word starts with space + lower case equivalent for later parsing
  // 1) treat cap + lower as start of new word
  str = str.replaceAll(capital_plus_lower, function (match) {
    // match is one caps followed by one non-cap
    return ' ' + (match[0].toLowerCase() || match[0]) + match[1];
  });
  // 2) treat all remaining capitals as words
  str = str.replaceAll(capitals, function (match) {
    // match is a series of caps
    return ' ' + match.toLowerCase();
  });
  return str
    .trim()
    .split(wordSeparators)
    .join('-')
    .replace(/^-/, '')
    .replace(/-\s*$/, '');
}
