import { useEffect, useMemo } from 'react';

import { getErrorConfig } from '../get-error-config.js';
import type { ErrorConfigProps } from '../types/error-config.js';

export function useErrorConfig({
  root,
  error,
  pathname,
  templates,
}: ErrorConfigProps) {
  const errorConfig = useMemo(
    () =>
      getErrorConfig({
        root,
        error,
        pathname,
        templates,
      }),
    [pathname, error, root, templates],
  );

  useEffect(() => {
    errorConfig.emit();
  }, [errorConfig]);

  return errorConfig;
}
