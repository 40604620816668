import { isFunction } from 'remeda';

import { core } from '../core/index.js';
import type { CSSProperties } from '../types.js';

export const createThemeVariant = <Key extends keyof typeof core>(
  key: Key,
  property: string | ((token: string) => CSSProperties),
) =>
  Object.keys(core[key]).reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current]:
        isFunction(property) ?
          property(current)
        : { [property]: `$${current}` },
    }),
    {},
  ) as Record<keyof (typeof core)[Key], CSSProperties>;

export const colorVariant = createThemeVariant('colors', token => ({
  dark: {
    color: `$${token}`,
  },
  light: {
    color: `$${token}`,
  },
}));
