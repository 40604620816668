import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { Theme } from '../../stitches.config.js';
import { createContext } from '../../utilities/create-context.js';

export const ThemeContext = createContext<Theme>('ThemeContext', Theme.Light);

export interface ThemeProviderProps {
  children?: ReactNode;
  controlled?: boolean;
  /**
   * The element is the entity that we will attach the "data-theme" attribute to as a side effect
   * of theme initialization and changes. If an element is not explicitly passed in, then theme
   * changes will still be exposed to `useTheme`, but nothing will be attached to an element.
   */
  element?: HTMLElement | null;
  value?: Theme | undefined;
}

export const ThemeProvider = ({
  children,
  controlled = false,
  element = null,
  value,
}: ThemeProviderProps) => {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const [theme, setTheme] = useState<Theme>(
    value ?? (prefersDark ? Theme.Dark : Theme.Light),
  );

  useEffect(() => {
    if (controlled) return;
    setTheme(prefersDark ? Theme.Dark : Theme.Light);
  }, [controlled, prefersDark]);

  useEffect(() => {
    if (!controlled || !value) return;
    setTheme(value);
  }, [controlled, value]);

  useEffect(() => {
    element?.setAttribute('data-theme', theme);
  }, [element, theme]);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};
