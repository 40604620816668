import {
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from 'react';

import { media } from '../../core/index.js';
import { Theme } from '../../stitches.config.js';
import { type BoxProps, Box } from '../box/index.js';
import { Grid } from '../grid/index.js';
import { ThemeProvider, useTheme } from '../theme/index.js';

export const FullScreenContext = createContext<
  Readonly<[boolean, Dispatch<SetStateAction<boolean>>]>
>([false, () => {}]);

export const useFullScreen = () => useContext(FullScreenContext);

export interface PlayerRootProps extends BoxProps {
  children: ReactNode;
  disabled?: boolean;
  fullscreen?: boolean;
  defaultFullScreen?: boolean;
}

export type FullScreenProviderProps = {
  children: ReactNode;
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
};

export const FullScreenProvider = ({
  isFullScreen,
  setIsFullScreen,
  children,
}: FullScreenProviderProps) => {
  return (
    <FullScreenContext.Provider
      value={useMemo(() => [isFullScreen, setIsFullScreen], [isFullScreen])}
    >
      {children}
    </FullScreenContext.Provider>
  );
};

export const PlayerRoot = forwardRef<HTMLDivElement, PlayerRootProps>(
  function PlayerRoot({ children, ...props }, ref) {
    const defaultTheme = useTheme();
    const [isFullScreen, setIsFullScreen] = useFullScreen();
    const theme = isFullScreen ? Theme.Dark : defaultTheme;

    return (
      <ThemeProvider controlled value={theme}>
        <Box
          css={{
            containerType: 'inline-size',

            ':is(a, button) *': {
              pointerEvents: 'none',
            },
          }}
          data-theme={theme}
          onClick={(event: MouseEvent<HTMLDivElement>) => {
            const target = event.target as HTMLDivElement;

            // Stopping event propagation so clicking specifically on a button within the miniplayer doesn't cause it to open the FSP
            // Only empty white space in the miniplayer should cause the FSP to open
            if (
              target instanceof HTMLAnchorElement ||
              target instanceof HTMLButtonElement
            ) {
              event.stopPropagation();
              return;
            }
            if (window.innerWidth < 769) {
              setIsFullScreen(true);
            }
          }}
          position="relative"
          {...props}
        >
          <Grid
            background={{ dark: '$gray-600', light: '$brand-white' }}
            filter="$drop-shadow-up-4"
            height={{
              [`@container ${media.xsmall}`]: '6.4rem',
              [`@container ${media.large}`]: '8.8rem',
            }}
            ref={ref}
            templateAreas={{
              [`@container ${media.xsmall}`]: `
                "metadata controls"
                "time time"
              `,

              [`@container ${media.medium}`]: `
                "metadata controls actions"
                "time time time"
              `,

              [`@container ${media.large}`]: `
                "metadata controls actions"
                "metadata time actions"
              `,
            }}
            templateColumns={{
              [`@container ${media.xsmall}`]: '1fr max-content',
              [`@container ${media.medium}`]: 'repeat(3, 1fr)',
            }}
            templateRows="1fr minmax(0, min-content)"
          >
            {children}
          </Grid>
        </Box>
      </ThemeProvider>
    );
  },
);

PlayerRoot.displayName = 'Player.Root';
