import { colors } from './colors.js';

export const shadows = {
  'elevation-1': '0 1px 3px rgba(0, 0, 0, 0.15)',
  'elevation-4': '0 3px 5px rgba(0, 0, 0, 0.15)',
  'elevation-6': '0 5px 8px rgba(0, 0, 0, 0.15)',
  'elevation-8': '0 8px 24px rgba(0, 0, 0, 0.15)',
  'elevation-up-1': '0 -1px 3px rgba(0, 0, 0, 0.15)',
  'elevation-up-4': '0 -3px 6px rgba(0, 0, 0, 0.15)',
  'focus-outline-dark': `0 0 0 0.2rem ${colors['brand-white']}`,
  'focus-outline-light': `0 0 0 0.2rem ${colors['brand-black']}`,
} as const;
