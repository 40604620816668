import {
  type Icon,
  Button,
  ErrorOutlineIcon,
  Flex,
} from '@iheartradio/web.companion';
import { isArray, isUndefined } from '@iheartradio/web.utilities';
import { Link } from '@remix-run/react';
import { type ReactNode, createElement } from 'react';

import type {
  CTAFactProps,
  CTAIconItem,
  CTAItem,
  dialogClick,
} from '../types/cta-props.js';

export const ErrorReloadButton = ({ kind, color }: CTAFactProps) => (
  <Button
    color={color}
    data-test="route-error-reload"
    kind={kind}
    onClick={() => window.location.reload()}
    size="large"
  >
    Reload
  </Button>
);

export const ErrorActionButton = ({
  color,
  kind,
  path,
  text,
}: CTAFactProps) => (
  <Button
    as={Link}
    color={color}
    data-test="route-error-action"
    kind={kind}
    prefetch="render"
    size="large"
    to={path}
  >
    {text}
  </Button>
);

export const ErrorClearButton = ({
  color,
  handleDialogClick,
  kind,
}: CTAFactProps) => (
  <Button
    color={color}
    data-test="route-error-clear"
    kind={kind}
    onClick={handleDialogClick}
    size="large"
  >
    Clear cookies
  </Button>
);

export const ErrorCTA = ({ children }: { children: ReactNode }) => (
  <Flex data-test="route-error-cta" flexDirection="column" gap="$16">
    {children}
  </Flex>
);

const ErrorCTAMap = {
  action: ErrorActionButton,
  reload: ErrorReloadButton,
  clear: ErrorClearButton,
} as const;

const ErrorIconMap: Record<string, typeof Icon> = {
  'error-outline': ErrorOutlineIcon,
} as const;

type ErrorIconValue = (typeof ErrorIconMap)[keyof typeof ErrorIconMap];

export const getErrorIcon = (icon: CTAIconItem): ErrorIconValue =>
  ErrorIconMap[icon];

export const getErrorCTA = (
  blocks: CTAItem[],
  handleDialogClick: dialogClick,
) => {
  if (isArray(blocks) && blocks.length > 0) {
    return blocks.map((item: CTAItem, index: number) => {
      if (!isUndefined(ErrorCTAMap[item.block])) {
        return createElement(ErrorCTAMap[item.block], {
          ...item.props,
          handleDialogClick,
          key: index,
        });
      }
      console.debug('This cta block component has not been mapped.');
      return null;
    });
  }
  return null;
};
