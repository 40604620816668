import { styled } from '../../stitches.config.js';
import { kinds } from '../text/text.js';

const colors = {
  error: {
    dark: '$orange-300',
    light: '$orange-650',
  },
  disabled: {
    dark: '$gray-400',
    light: '$gray-300',
  },
  neutral: {
    dark: '$gray-250',
    light: '$gray-450',
  },
  success: {
    dark: '$green-400',
    light: '$green-600',
  },
};

export const HelperMessageText = styled('span', {
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'nowrap',

  '@xsmall': {
    ...kinds['caption-4'],
  },

  '@medium': {
    ...kinds['caption-2'],
  },

  variants: {
    kind: {
      error: {
        dark: {
          color: colors.error.dark,
        },
        light: {
          color: colors.error.light,
        },
        '& > .icon-wrapper svg': {
          dark: {
            fill: colors.error.dark,
          },
          light: {
            fill: colors.error.light,
          },
        },
      },
      disabled: {
        dark: {
          color: colors.disabled.dark,
        },
        light: {
          color: colors.disabled.light,
        },
        '& > .icon-wrapper svg': {
          dark: {
            fill: colors.disabled.dark,
          },
          light: {
            fill: colors.disabled.light,
          },
        },
      },
      neutral: {
        dark: {
          color: colors.neutral.dark,
        },
        light: {
          color: colors.neutral.light,
        },
        '& > svg': {
          dark: {
            fill: colors.neutral.dark,
          },
          light: {
            fill: colors.neutral.light,
          },
        },
      },
      success: {
        dark: {
          color: colors.success.dark,
        },
        light: {
          color: colors.success.light,
        },
        '& > .icon-wrapper svg': {
          dark: {
            fill: colors.success.dark,
          },
          light: {
            fill: colors.success.light,
          },
        },
      },
    },
  },
  defaultVariants: {
    kind: 'neutral',
  },
});
