import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LocationServicesOnIcon = forwardRef<SVGSVGElement, IconProps>(
  function LocationServicesOnIcon(
    {
      'aria-label': ariaLabel = 'Location Services On Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M21.0395 4.27809C21.2204 3.89616 21.1417 3.44173 20.8428 3.1429C20.544 2.84406 20.0896 2.76535 19.7076 2.94627L3.42191 10.6606C3.0402 10.8414 2.81325 11.2425 2.85487 11.6628C2.89649 12.0831 3.1977 12.432 3.60746 12.5344L9.88252 14.1032L11.4513 20.3783C11.5537 20.788 11.9026 21.0892 12.3229 21.1309C12.7432 21.1725 13.1444 20.9455 13.3252 20.5638L21.0395 4.27809Z" />
      </Icon>
    );
  },
) as typeof Icon;
