import type { CSSProperties } from 'react';

import type { IconProps } from '../icon/index.js';

type ErrorFilledIconProps = IconProps & {
  style?: CSSProperties;
};

export function ErrorFilledIcon({
  'aria-label': ariaLabel = 'Error Filled Icon',
  children: _children,
  style,
}: ErrorFilledIconProps) {
  return (
    <svg
      aria-label={ariaLabel}
      fill="#CC032E"
      role="img"
      style={style}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" />
    </svg>
  );
}
