import type { GlobalConfig } from '../schemas/index.js';
import { GlobalAccount } from './account/index.js';
import { GlobalAds } from './ads/index.js';
import { GlobalApi } from './api/index.js';
import { GlobalApp } from './app/index.js';
import { GlobalEnvironment } from './environment/index.js';
import { GlobalFeatures } from './features/index.js';
import { GlobalSdks } from './sdks/index.js';
import { GlobalUrls } from './urls/index.js';

export const globalConfig: GlobalConfig = {
  AU: {
    staging: {
      account: { ...GlobalAccount.AU.staging },
      api: { ...GlobalApi.AU.staging },
      environment: { ...GlobalEnvironment.AU.staging },
      features: { ...GlobalFeatures.AU.staging },
      sdks: { ...GlobalSdks.AU.staging },
      urls: { ...GlobalUrls.AU.staging },
      app: { ...GlobalApp.AU.staging },
      ads: { ...GlobalAds.AU.staging },
    },
    pr: {
      account: { ...GlobalAccount.AU.pr },
      api: { ...GlobalApi.AU.pr },
      environment: { ...GlobalEnvironment.AU.pr },
      features: { ...GlobalFeatures.AU.pr },
      sdks: { ...GlobalSdks.AU.pr },
      urls: { ...GlobalUrls.AU.pr },
      app: { ...GlobalApp.AU.pr },
      ads: { ...GlobalAds.AU.pr },
    },
    production: {
      account: { ...GlobalAccount.AU.production },
      api: { ...GlobalApi.AU.production },
      environment: { ...GlobalEnvironment.AU.production },
      features: { ...GlobalFeatures.AU.production },
      sdks: { ...GlobalSdks.AU.production },
      urls: { ...GlobalUrls.AU.production },
      app: { ...GlobalApp.AU.production },
      ads: { ...GlobalAds.AU.production },
    },
  },
  CA: {
    staging: {
      account: { ...GlobalAccount.CA.staging },
      api: { ...GlobalApi.CA.staging },
      environment: { ...GlobalEnvironment.CA.staging },
      features: { ...GlobalFeatures.CA.staging },
      sdks: { ...GlobalSdks.CA.staging },
      urls: { ...GlobalUrls.CA.staging },
      app: { ...GlobalApp.CA.staging },
      ads: { ...GlobalAds.CA.staging },
    },
    pr: {
      account: { ...GlobalAccount.CA.pr },
      api: { ...GlobalApi.CA.pr },
      environment: { ...GlobalEnvironment.CA.pr },
      features: { ...GlobalFeatures.CA.pr },
      sdks: { ...GlobalSdks.CA.pr },
      urls: { ...GlobalUrls.CA.pr },
      app: { ...GlobalApp.CA.pr },
      ads: { ...GlobalAds.CA.pr },
    },
    production: {
      account: { ...GlobalAccount.CA.production },
      api: { ...GlobalApi.CA.production },
      environment: { ...GlobalEnvironment.CA.production },
      features: { ...GlobalFeatures.CA.production },
      sdks: { ...GlobalSdks.CA.production },
      urls: { ...GlobalUrls.CA.production },
      app: { ...GlobalApp.CA.production },
      ads: { ...GlobalAds.CA.production },
    },
  },
  MX: {
    staging: {
      account: { ...GlobalAccount.MX.staging },
      api: { ...GlobalApi.MX.staging },
      environment: { ...GlobalEnvironment.MX.staging },
      features: { ...GlobalFeatures.MX.staging },
      sdks: { ...GlobalSdks.MX.staging },
      urls: { ...GlobalUrls.MX.staging },
      app: { ...GlobalApp.MX.staging },
      ads: { ...GlobalAds.MX.staging },
    },
    pr: {
      account: { ...GlobalAccount.MX.pr },
      api: { ...GlobalApi.MX.pr },
      environment: { ...GlobalEnvironment.MX.pr },
      features: { ...GlobalFeatures.MX.pr },
      sdks: { ...GlobalSdks.MX.pr },
      urls: { ...GlobalUrls.MX.pr },
      app: { ...GlobalApp.MX.pr },
      ads: { ...GlobalAds.MX.pr },
    },
    production: {
      account: { ...GlobalAccount.MX.production },
      api: { ...GlobalApi.MX.production },
      environment: { ...GlobalEnvironment.MX.production },
      features: { ...GlobalFeatures.MX.production },
      sdks: { ...GlobalSdks.MX.production },
      urls: { ...GlobalUrls.MX.production },
      app: { ...GlobalApp.MX.production },
      ads: { ...GlobalAds.MX.production },
    },
  },
  NZ: {
    staging: {
      account: { ...GlobalAccount.NZ.staging },
      api: { ...GlobalApi.NZ.staging },
      environment: { ...GlobalEnvironment.NZ.staging },
      features: { ...GlobalFeatures.NZ.staging },
      sdks: { ...GlobalSdks.NZ.staging },
      urls: { ...GlobalUrls.NZ.staging },
      app: { ...GlobalApp.NZ.staging },
      ads: { ...GlobalAds.NZ.staging },
    },
    pr: {
      account: { ...GlobalAccount.NZ.pr },
      api: { ...GlobalApi.NZ.pr },
      environment: { ...GlobalEnvironment.NZ.pr },
      features: { ...GlobalFeatures.NZ.pr },
      sdks: { ...GlobalSdks.NZ.pr },
      urls: { ...GlobalUrls.NZ.pr },
      app: { ...GlobalApp.NZ.pr },
      ads: { ...GlobalAds.NZ.pr },
    },
    production: {
      account: { ...GlobalAccount.NZ.production },
      api: { ...GlobalApi.NZ.production },
      environment: { ...GlobalEnvironment.NZ.production },
      features: { ...GlobalFeatures.NZ.production },
      sdks: { ...GlobalSdks.NZ.production },
      urls: { ...GlobalUrls.NZ.production },
      app: { ...GlobalApp.NZ.production },
      ads: { ...GlobalAds.NZ.production },
    },
  },
  US: {
    staging: {
      account: { ...GlobalAccount.US.staging },
      api: { ...GlobalApi.US.staging },
      environment: { ...GlobalEnvironment.US.staging },
      features: { ...GlobalFeatures.US.staging },
      sdks: { ...GlobalSdks.US.staging },
      urls: { ...GlobalUrls.US.staging },
      app: { ...GlobalApp.US.staging },
      ads: { ...GlobalAds.US.staging },
    },
    pr: {
      account: { ...GlobalAccount.US.pr },
      api: { ...GlobalApi.US.pr },
      environment: { ...GlobalEnvironment.US.pr },
      features: { ...GlobalFeatures.US.pr },
      sdks: { ...GlobalSdks.US.pr },
      urls: { ...GlobalUrls.US.pr },
      app: { ...GlobalApp.US.pr },
      ads: { ...GlobalAds.US.pr },
    },
    production: {
      account: { ...GlobalAccount.US.production },
      api: { ...GlobalApi.US.production },
      environment: { ...GlobalEnvironment.US.production },
      features: { ...GlobalFeatures.US.production },
      sdks: { ...GlobalSdks.US.production },
      urls: { ...GlobalUrls.US.production },
      app: { ...GlobalApp.US.production },
      ads: { ...GlobalAds.US.production },
    },
  },
  WW: {
    staging: {
      account: { ...GlobalAccount.WW.staging },
      api: { ...GlobalApi.WW.staging },
      environment: { ...GlobalEnvironment.WW.staging },
      features: { ...GlobalFeatures.WW.staging },
      sdks: { ...GlobalSdks.WW.staging },
      urls: { ...GlobalUrls.WW.staging },
      app: { ...GlobalApp.WW.staging },
      ads: { ...GlobalAds.WW.staging },
    },
    pr: {
      account: { ...GlobalAccount.WW.pr },
      api: { ...GlobalApi.WW.pr },
      environment: { ...GlobalEnvironment.WW.pr },
      features: { ...GlobalFeatures.WW.pr },
      sdks: { ...GlobalSdks.WW.pr },
      urls: { ...GlobalUrls.WW.pr },
      app: { ...GlobalApp.WW.pr },
      ads: { ...GlobalAds.WW.pr },
    },
    production: {
      account: { ...GlobalAccount.WW.production },
      api: { ...GlobalApi.WW.production },
      environment: { ...GlobalEnvironment.WW.production },
      features: { ...GlobalFeatures.WW.production },
      sdks: { ...GlobalSdks.WW.production },
      urls: { ...GlobalUrls.WW.production },
      app: { ...GlobalApp.WW.production },
      ads: { ...GlobalAds.WW.production },
    },
  },
};
