import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const PauseIcon = forwardRef<SVGSVGElement, IconProps>(
  function PauseIcon(
    { 'aria-label': ariaLabel = 'Pause Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M8.0625 15.9375C8.0625 16.6594 8.65312 17.25 9.375 17.25C10.0969 17.25 10.6875 16.6594 10.6875 15.9375V8.0625C10.6875 7.34062 10.0969 6.75 9.375 6.75C8.65312 6.75 8.0625 7.34062 8.0625 8.0625V15.9375Z" />
        <path d="M13.3125 15.9375C13.3125 16.6594 13.9031 17.25 14.625 17.25C15.3469 17.25 15.9375 16.6594 15.9375 15.9375V8.0625C15.9375 7.34062 15.3469 6.75 14.625 6.75C13.9031 6.75 13.3125 7.34062 13.3125 8.0625V15.9375Z" />
      </Icon>
    );
  },
) as typeof Icon;
