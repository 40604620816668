import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ArrowDownIcon = forwardRef<SVGSVGElement, IconProps>(
  function ArrowDownIcon(
    {
      'aria-label': ariaLabel = 'Arrow Down Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M13.0024 5.20876L13.0024 16.3788L17.8824 11.4988C18.2724 11.1088 18.9124 11.1088 19.3024 11.4988C19.6924 11.8888 19.6924 12.5188 19.3024 12.9088L12.7124 19.4988C12.3224 19.8888 11.6924 19.8888 11.3024 19.4988L4.70241 12.9188C4.31241 12.5288 4.31241 11.8988 4.70241 11.5088C5.09241 11.1188 5.72241 11.1188 6.11241 11.5088L11.0024 16.3788L11.0024 5.20876C11.0024 4.65876 11.4524 4.20876 12.0024 4.20876C12.5524 4.20876 13.0024 4.65876 13.0024 5.20876Z" />
      </Icon>
    );
  },
) as typeof Icon;
