import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const TrendingUpIcon = forwardRef<SVGSVGElement, IconProps>(
  function TrendingUpIcon(
    {
      'aria-label': ariaLabel = 'Trending Up Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M16.6425 7.04995L18.0825 8.48995L13.2025 13.37L9.91252 10.08C9.52252 9.68995 8.89252 9.68995 8.50252 10.08L2.50252 16.09C2.11252 16.48 2.11252 17.11 2.50252 17.5C2.89252 17.89 3.52252 17.89 3.91252 17.5L9.20252 12.2L12.4925 15.49C12.8825 15.88 13.5125 15.88 13.9025 15.49L19.4925 9.90995L20.9325 11.35C21.2425 11.66 21.7825 11.44 21.7825 11V6.69995C21.7925 6.41995 21.5725 6.19995 21.2925 6.19995H17.0025C16.5525 6.19995 16.3325 6.73995 16.6425 7.04995Z" />
      </Icon>
    );
  },
) as typeof Icon;
