import type { CSS } from '@stitches/react';
import { createStitches, defaultThemeMap } from '@stitches/react';

import { core, media } from './core/index.js';

export const Theme = {
  Dark: 'dark',
  Light: 'light',
} as const;

export type Theme = (typeof Theme)[keyof typeof Theme];

export const config = {
  media,
  prefix: `companion`,
  theme: core,
  themeMap: {
    ...defaultThemeMap,
    filter: 'filters',
    outlineOffset: 'space',
    scrollbarColor: 'colors',
  },
} as const;

export type CSSProperties = CSS<typeof config>;

export type ColorStyleProperties = Pick<
  CSSProperties,
  | 'backgroundColor'
  | 'borderColor'
  | 'color'
  | 'fill'
  | 'filter'
  | 'outlineColor'
  | 'scrollbarColor'
>;

export const {
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
  reset,
} = createStitches({
  ...config,
  utils: {
    dark: (props: ColorStyleProperties) => ({
      [`&[data-theme="${Theme.Dark}"]`]: props,
      [`[data-theme="${Theme.Dark}"] &`]: props,
      [`[data-theme] &[data-theme="${Theme.Dark}"]`]: props,
      [`[data-theme] [data-theme="${Theme.Dark}"] &`]: props,
    }),

    light: (props: ColorStyleProperties) => ({
      [`&[data-theme="${Theme.Light}"]`]: props,
      [`[data-theme="${Theme.Light}"] &`]: props,
      [`[data-theme] &[data-theme="${Theme.Light}"]`]: props,
      [`[data-theme] [data-theme="${Theme.Light}"] &`]: props,
    }),

    disabled: (props: CSSProperties) => ({
      '&[aria-disabled="true"], &[disabled], &[data-disabled="true"]': props,
    }),

    // This utility is used to apply hover styles on non-touch devices.
    // It should be used in place of `&:hover` wherever possible.
    hover: (props: CSSProperties) => ({
      '@pointer': {
        '&:hover': props,
      },
    }),

    pressed: (props: CSSProperties) => ({
      '&:active': props,
    }),

    focus: (props: CSSProperties) => ({
      '&:focus, &:focus-visible': props,
    }),

    focusVisible: (props: CSSProperties) => ({
      '&:focus-visible': props,
    }),

    p: (props: Pick<CSSProperties, 'padding'>) => ({
      padding: props,
    }),

    py: (props: Pick<CSSProperties, 'paddingTop' | 'paddingBottom'>) => ({
      paddingTop: props,
      paddingBottom: props,
    }),

    paddingY: (props: Pick<CSSProperties, 'paddingTop' | 'paddingBottom'>) => ({
      paddingTop: props,
      paddingBottom: props,
    }),

    px: (props: Pick<CSSProperties, 'paddingLeft' | 'paddingRight'>) => ({
      paddingLeft: props,
      paddingRight: props,
    }),

    paddingX: (props: Pick<CSSProperties, 'paddingLeft' | 'paddingRight'>) => ({
      paddingLeft: props,
      paddingRight: props,
    }),
  },
});

export const themes = {
  dark: createTheme(Theme.Dark, core),
  light: createTheme(Theme.Light, core),
} as const;
