import { forwardRef } from 'react';

import { media } from '../../core/media.js';
import { Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { PreviousIcon } from '../icons/previous-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerPreviousProps = JSX.IntrinsicElements['button'] & {
  disabled?: ButtonProps['disabled'];
};

export const PlayerPrevious = forwardRef<
  HTMLButtonElement,
  PlayerPreviousProps
>(function PlayerPrevious(props: PlayerPreviousProps, ref) {
  return (
    <Box
      css={{
        display: 'none',

        [`@container ${media.medium}`]: {
          display: 'block',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'block',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="previous"
      justifySelf="center"
    >
      <Tooltip
        side="top"
        trigger={
          <Button
            {...props}
            color={{ dark: 'white', light: 'gray' }}
            kind="tertiary"
            ref={ref}
            size="icon"
          >
            <PreviousIcon
              css={{
                height: '3.2rem',

                '[data-player-type="fullscreen"] &': {
                  height: '4rem',
                },
              }}
            />
          </Button>
        }
      >
        <Text
          color={{
            dark: '$brand-black',
            light: '$brand-white',
          }}
          kind="caption-3"
        >
          Previous
        </Text>
      </Tooltip>
    </Box>
  );
});

PlayerPrevious.displayName = 'Player.Previous';
