import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const CaretDownIcon = forwardRef<SVGSVGElement, IconProps>(
  function CaretDownIcon(
    {
      'aria-label': ariaLabel = 'Caret Down Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M6.49547 11.8577L10.8239 16.1861C11.4756 16.8379 12.5285 16.8379 13.1803 16.1861L17.5086 11.8577C18.5615 10.8049 17.8095 9 16.3221 9H7.66531C6.17794 9 5.44262 10.8049 6.49547 11.8577Z" />
      </Icon>
    );
  },
) as typeof Icon;
