import type { Environment } from '../../schemas/environment.js';
import { SUPPORTED_COUNTRIES } from './constants.js';

export const stagingWW: Environment = {
  countryCode: 'WW',
  hostName: 'webapp.WW',
  hosts: {
    account: 'account.web.ww',
    listen: 'listen.web.ww',
  },
  objectDbBucket: 'web.listen.staging',
  markPlayedThreshold: 30,
  supportedCountries: SUPPORTED_COUNTRIES,
  terminalId: 165,
  territoryCode: 'WW',
  websiteUrl: 'https://stage.iheart.com',
};

export const productionWW: Environment = {
  ...stagingWW,
  objectDbBucket: 'web.listen.prod',
  websiteUrl: 'https://www.iheart.com',
};

export const pullRequestWW: Environment = {
  ...productionWW,
  objectDbBucket: 'web.listen.staging',
};
