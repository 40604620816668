import type { Outbrain } from '../../../schemas/sdks/outbrain.js';

export const stagingAU: Outbrain = {
  enabled: false,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};

export const productionAU: Outbrain = {
  enabled: false,
  pixelId: '004d2133385513d6a3dbfd9993b43474ab',
};
