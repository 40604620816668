import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Marquee } from '../marquee/index.js';
import { kinds, Text } from '../text/index.js';
import { useFullScreen } from './player-root.js';

export const PlayerSubtitle = forwardRef<
  HTMLParagraphElement,
  { children?: ReactNode }
>(function PlayerSubtitle({ children }, ref) {
  const [isFullScreen] = useFullScreen();

  if (!children) {
    return null;
  }

  return (
    <Text
      color={{ dark: '$brand-white', light: '$gray-600' }}
      css={{
        ...kinds['caption-4'],
        padding: '$0 $8 $2 $8',
        display: 'none',

        [`@container ${media.large}`]: {
          padding: isFullScreen ? '$0 $16 $2 $16' : '$0 $8 $2 $8',
          display: isFullScreen ? 'none' : 'block',
        },
      }}
      kind="caption-4"
      ref={ref}
    >
      <Marquee gap="$24">{children}</Marquee>
    </Text>
  );
});

PlayerSubtitle.displayName = 'Player.Subtitle';
