import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ChevronDownIcon = forwardRef<SVGSVGElement, IconProps>(
  function ChevronDownIcon(
    {
      'aria-label': ariaLabel = 'Chevron Down Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M15.88 9.00246L12 12.8825L8.11998 9.00246C7.72998 8.61246 7.09998 8.61246 6.70998 9.00246C6.31998 9.39246 6.31998 10.0225 6.70998 10.4125L11.3 15.0025C11.69 15.3925 12.32 15.3925 12.71 15.0025L17.3 10.4125C17.69 10.0225 17.69 9.39246 17.3 9.00246C16.91 8.62246 16.27 8.61246 15.88 9.00246Z" />
      </Icon>
    );
  },
) as typeof Icon;
