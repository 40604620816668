import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const RadioIcon = forwardRef<SVGSVGElement, IconProps>(
  function RadioIcon(
    {
      'aria-label': ariaLabel = 'Radio Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M17.2925 17.505C17.0804 17.652 16.7863 17.6016 16.6289 17.4018C16.4552 17.1812 16.5159 16.8674 16.7488 16.7036C18.7211 15.316 20 13.0859 20 10.5714C20 6.36354 16.4185 2.95238 12 2.95238C7.58154 2.95238 4 6.36354 4 10.5714C4 12.9833 5.17668 15.1335 7.0132 16.5295C7.24306 16.7042 7.28863 17.0267 7.09815 17.2402C6.93128 17.4271 6.64108 17.4628 6.43861 17.3111C4.34463 15.7416 3 13.3061 3 10.5714C3 5.83756 7.0293 2 12 2C16.9707 2 21 5.83756 21 10.5714C21 13.4214 19.5396 15.9465 17.2925 17.505Z" />
        <path d="M15.8213 14.977C15.4353 15.2811 14.8735 15.1727 14.5729 14.791C14.2152 14.3368 14.3766 13.6878 14.8021 13.29C15.5414 12.5987 16 11.6361 16 10.5714C16 8.46749 14.209 6.7619 12 6.7619C9.79102 6.7619 8 8.46749 8 10.5714C8 11.6053 8.43247 12.543 9.1345 13.2293C9.56062 13.646 9.701 14.3238 9.30549 14.767C8.99202 15.1182 8.44773 15.1999 8.08135 14.8987C6.80676 13.8508 6 12.3009 6 10.5714C6 7.41552 8.68652 4.85714 12 4.85714C15.3135 4.85714 18 7.41552 18 10.5714C18 12.3445 17.152 13.9289 15.8213 14.977Z" />
        <path d="M14.0001 10.5714C14.0001 11.459 13.3627 12.2047 12.5001 12.4162V16.2857H12.6688C12.8155 16.2857 12.9548 16.3471 13.0498 16.4535L17.2998 21.2154C17.5758 21.5247 17.3451 22 16.9188 22H7.08129C6.65508 22 6.42428 21.5247 6.70032 21.2154L10.9503 16.4535C11.0453 16.3471 11.1846 16.2857 11.3313 16.2857H11.5001V12.4162C10.6375 12.2047 10.0001 11.459 10.0001 10.5714C10.0001 9.51946 10.8955 8.66667 12.0001 8.66667C13.1046 8.66667 14.0001 9.51946 14.0001 10.5714Z" />
      </Icon>
    );
  },
) as typeof Icon;
