import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ContextMenuIcon = forwardRef<SVGSVGElement, IconProps>(
  function ContextMenuIcon(
    {
      'aria-label': ariaLabel = 'Context Menu Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M4 4C3.44772 4 3 4.44772 3 5V9C3 9.55228 3.44772 10 4 10H8C8.55228 10 9 9.55228 9 9V5C9 4.44772 8.55228 4 8 4H4ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6H20C20.5523 6 21 5.55228 21 5C21 4.44772 20.5523 4 20 4H12ZM11 9C11 8.44772 11.4477 8 12 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H12C11.4477 10 11 9.55228 11 9ZM4 14C3.44772 14 3 14.4477 3 15V19C3 19.5523 3.44772 20 4 20H8C8.55228 20 9 19.5523 9 19V15C9 14.4477 8.55228 14 8 14H4ZM12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16H20C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14H12ZM11 19C11 18.4477 11.4477 18 12 18H16C16.5523 18 17 18.4477 17 19C17 19.5523 16.5523 20 16 20H12C11.4477 20 11 19.5523 11 19Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
