import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const MinimizeIcon = forwardRef<SVGSVGElement, IconProps>(
  function MinimizeIcon(
    { 'aria-label': ariaLabel = 'Minimize Icon', ...props }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M4.67143 18.4974L9.31343 13.8554H5.25739C4.93324 13.8554 4.66727 13.5894 4.66727 13.2653C4.66727 12.9411 4.92908 12.6793 5.25323 12.6793H10.7305C11.0547 12.6793 11.3165 12.9411 11.3165 13.2653L11.3248 18.7426C11.3248 19.0668 11.063 19.3286 10.7389 19.3286C10.4147 19.3286 10.1529 19.0668 10.1529 18.7426L10.1446 14.6866L5.50258 19.3286C5.27401 19.5571 4.89999 19.5571 4.67143 19.3286C4.44286 19.1 4.44286 18.726 4.67143 18.4974Z" />
        <path d="M19.328 5.50258L14.686 10.1446H18.742C19.0662 10.1446 19.3322 10.4106 19.3322 10.7347C19.3322 11.0589 19.0703 11.3207 18.7462 11.3207H13.2689C12.9447 11.3207 12.6829 11.0589 12.6829 10.7347L12.6746 5.25739C12.6746 4.93324 12.9364 4.67143 13.2606 4.67143C13.5847 4.67143 13.8465 4.93324 13.8465 5.25739L13.8548 9.31343L18.4968 4.67143C18.7254 4.44286 19.0994 4.44286 19.328 4.67143C19.5566 4.89999 19.5566 5.27401 19.328 5.50258Z" />
      </Icon>
    );
  },
) as typeof Icon;
