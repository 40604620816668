import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const VolumeMuteIcon = forwardRef<SVGSVGElement, IconProps>(
  function VolumeMuteIcon(
    {
      'aria-label': ariaLabel = 'Volume Mute Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M3 10V14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58V6.41002C12 5.52002 10.92 5.07002 10.29 5.70002L7 9.00002H4C3.45 9.00002 3 9.45002 3 10Z" />
      </Icon>
    );
  },
) as typeof Icon;
