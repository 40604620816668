import { isBlank } from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';

import type {
  FbMeResponse,
  OauthLinkActionBody,
  OauthLoginActionBody,
} from './types';

export const facebookSdkFactory = ({
  onError = () => {
    console.log('Error with Facebook SDK');
  },
  onLink,
  onLogin,
  onUnlink,
}: {
  onError?: (initialization?: boolean) => void;
  onLink?: (postBody: OauthLinkActionBody) => void;
  onLogin?: (postBody: OauthLoginActionBody) => void;
  onUnlink?: () => void;
}) => {
  const emitter = createEmitter({
    initialize(appId?: string) {
      return new Promise<true>((resolve, reject) => {
        if (isBlank(appId)) {
          onError?.(true);
          reject(new Error('AppId missing for FB SDK'));
          return;
        }
        if (document.querySelector('script#fb-sdk')) {
          resolve(true);
          return;
        }

        const fbScript = document.createElement('script');
        fbScript.id = 'fb-sdk';
        fbScript.src = 'https://connect.facebook.net/en_US/sdk.js';
        fbScript.async = true;
        fbScript.defer = true;
        fbScript.crossOrigin = 'anonymous';
        fbScript.addEventListener('error', () => {
          onError?.(true);
          reject(new Error('Error loading the Facebook SDK'));
        });
        window.fbAsyncInit = () => {
          FB.init({
            appId,
            autoLogAppEvents: true,
            xfbml: false,
            version: 'v14.0',
          });
          resolve(true);
        };
        document.body.append(fbScript);
      });
    },
    link() {
      FB.login(function (response) {
        if (response.status === 'connected') {
          const postBody: OauthLinkActionBody = {
            intent: 'link',
          };
          postBody.accessToken = response.authResponse.accessToken;
          postBody.accessTokenType = 'fb';
          postBody.oauthUuid = response.authResponse.userID;
          onLink?.(postBody);
        } else {
          onError?.(false);
        }
      });
    },
    login() {
      FB.login(
        function (response) {
          if (response.status === 'connected') {
            const postBody: OauthLoginActionBody = {
              type: 'checkAccount',
            };
            postBody.accessToken = response.authResponse.accessToken;
            postBody.accessTokenType = 'fb';
            postBody.oauthUuid = response.authResponse.userID;
            FB.api('/me', { fields: 'email' }, (response: FbMeResponse) => {
              if (response.email) {
                postBody.userName = response.email;
              }
              onLogin?.(postBody);
            });
          } else {
            onError?.(false);
          }
        },
        { scope: 'public_profile,email' },
      );
    },
    unlink() {
      onUnlink?.();
    },
    remove() {
      document.querySelector('script#fb-sdk')?.remove();
    },
  });

  return emitter;
};
