import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const FavoriteOutlineIcon = forwardRef<SVGSVGElement, IconProps>(
  function FavoriteOutlineIcon(
    {
      'aria-label': ariaLabel = 'Favorite Outline Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M10.3145 19.6805C10.7646 20.1306 11.3638 20.3787 12.0019 20.3787C12.6374 20.378 13.2362 20.1299 13.685 19.6804L20.6993 12.6661C21.6986 11.6668 22.25 10.3363 22.25 8.9225C22.25 5.64449 19.5229 3.62 17 3.62C15.5439 3.62 14.2313 4.15947 13.212 5.17884L12 6.39082L10.788 5.17884C9.76865 4.15947 8.45606 3.62 7 3.62C4.47774 3.62 1.75 5.64382 1.75 8.9225C1.75 10.3363 2.30135 11.6668 3.30072 12.6661L10.3145 19.6805ZM12.4478 18.4431C12.2104 18.68 11.7874 18.6787 11.5526 18.4434L4.53803 11.4288C3.86805 10.7589 3.5 9.86997 3.5 8.9225C3.5 6.73036 5.31502 5.37 7 5.37C7.69037 5.37 8.68603 5.55208 9.55072 6.41677L11.3813 8.2474C11.7234 8.5894 12.2766 8.5894 12.6187 8.2474L14.4493 6.41671C15.3134 5.55211 16.3096 5.37 17 5.37C18.685 5.37 20.5 6.73036 20.5 8.9225C20.5 9.86984 20.1313 10.7589 19.4619 11.4289L12.4478 18.4431Z"
          fillRule="evenodd"
        />
      </Icon>
    );
  },
) as typeof Icon;
