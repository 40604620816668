import { parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';

import { ThemeFormSchema } from '../../schemas.js';
import { useHints } from './client-hints.js';
import { useRequestInfo } from './request-info.js';

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme(rootError = false) {
  const hints = useHints(rootError);
  const requestInfo = useRequestInfo(rootError);
  const optimisticMode = useOptimisticThemeMode();

  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }

  return requestInfo.userPrefs.theme ?? hints.theme;
}

export const SET_THEME_FETCHER_KEY = 'SET_THEME';

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const themeFetcher = useFetcher({ key: SET_THEME_FETCHER_KEY });

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema,
    });

    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}
