import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const NextIcon = forwardRef<SVGSVGElement, IconProps>(function NextIcon(
  {
    'aria-label': ariaLabel = 'Next Icon',
    children: _children,
    ...props
  }: IconProps,
  ref,
) {
  return (
    <Icon aria-label={ariaLabel} ref={ref} {...props}>
      <path d="M7.58 16.89L13.35 12.82C13.91 12.42 13.91 11.58 13.35 11.19L7.58 7.11C6.91 6.65 6 7.12 6 7.93V16.07C6 16.88 6.91 17.35 7.58 16.89ZM16 7V17C16 17.55 16.45 18 17 18C17.55 18 18 17.55 18 17V7C18 6.45 17.55 6 17 6C16.45 6 16 6.45 16 7Z" />
    </Icon>
  );
}) as typeof Icon;
