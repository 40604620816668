import { forwardRef } from 'react';
import { isPlainObject } from 'remeda';

import { keyframes } from '../../stitches.config.js';
import { Box } from '../box/index.js';
import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

const rotate = keyframes({
  from: { transform: 'rotateZ(0deg)' },
  to: { transform: 'rotateZ(360deg)' },
});

const loading = keyframes({
  '0%, 25%': {
    strokeDashoffset: 68,
    transform: 'rotateZ(0deg)',
  },
  '50%, 75%': {
    strokeDashoffset: 19,
    transform: 'rotateZ(45deg)',
  },
  '100%': {
    strokeDashoffset: 68,
    transform: 'rotateZ(360deg)',
  },
});

export const LoadingIcon = forwardRef<SVGSVGElement, IconProps>(
  function LoadingIcon(
    {
      'aria-label': ariaLabel = 'Loading Icon',
      children: _children,
      css,
      fill = { dark: 'brand-white', light: 'gray-600' },
      ...props
    },
    ref,
  ) {
    const stroke =
      isPlainObject(fill) ?
        {
          dark: { stroke: `$${fill.dark}` },
          light: { stroke: `$${fill.light}` },
        }
      : { stroke: `$${fill}` };

    return (
      <Icon
        aria-label={ariaLabel}
        css={{
          ...css,
          animation: `${rotate} 2000ms linear infinite`,
          borderRadius: '100%',
          transform: 'rotateZ(0)',
          willChange: 'transform',
        }}
        fill="none"
        ref={ref}
        {...props}
      >
        <Box
          animation={`${loading} 2000ms ease-in-out both infinite`}
          as="circle"
          css={{
            ...stroke,
            strokeDasharray: 70,
            strokeLinecap: 'round',
            strokeWidth: 2,
          }}
          cx="50%"
          cy="50%"
          fill="transparent"
          r="46%"
          transformOrigin="50% 50%"
          willChange="strokeDashoffset, transform"
        />
      </Icon>
    );
  },
) as typeof Icon;
