// Shared Messages
const UNKNOWN =
  'Something went wrong but we are working on it. Please try again.';
const EMAIL_NOT_FOUND = 'Please enter your email address';
const EMAIL_NOT_VALID = 'Please enter a valid email address';
const EMAIL_ERROR =
  'There may already be a user with this email, try logging in';
const INCORRECT_PASSWORD = 'Current password doesn’t match our records';
const AMP_INCORRECT_PASSWORD = 'Please check your current password';
const PASSWORD_NOT_FOUND = 'Please enter your password';
const PASSWORD_LENGTH =
  'Please choose a password between 8 and 32 characters long';
const AMP_PASSWORD_LENGTH = 'Please check the password character length';
const PASSWORD_ERROR =
  'Password must have 8-32 characters, an upper and lower case letter, and a digit';
const CURRENT_PASSWORD_NOT_FOUND = 'Please enter your current password';
const NEW_PASSWORD_NOT_FOUND = 'Please enter your new password';
const PASSWORD_CASE =
  'Password must contain an upper case and lower case letter';
const PASSWORD_DIGIT = 'Password must contain a number (0-9)';
const PASSWORD_INVALID_CHARACTERS =
  'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \' " ` |  { } < >';
const AMP_PASSWORD_INVALID_CHARACTERS = 'Invalid character in password';
const PASSWORD_INVALID_CHARACTERS_PHONETIC =
  'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign.';
const PASSWORD_TOO_WEAK =
  'Password must have 8-32 characters, an upper and lower case letter, and a digit';
const AMP_PASSWORD_TOO_WEAK = 'Password is not strong enough';
const PASSWORD_TOO_COMMON_TITLE = 'Password is too common';
const PASSWORD_TOO_COMMON = 'Password shouldn’t have common words or passwords';
const AMP_PASSWORD_TOO_COMMON_TITLE = 'Password is too common';
const AMP_PASSWORD_TOO_COMMON =
  'Choose a password that’s harder for people to guess. Don’t use passwords like ‘Password 123!’';
const PASSWORDS_DO_NOT_MATCH = "Uh-oh passwords don't match";
const BILLING_ZIPCODE_NOT_FOUND = 'Please enter the zip code';
const ZIPCODE_NOT_FOUND = 'Please enter your zip code';
const ZIPCODE_INVALID = 'Please enter a valid zip code';
const BIRTHYEAR_NOT_FOUND = 'Please enter your birth year';
const BIRTHYEAR_FUTURE =
  'You must be older than 13 to sign up for an iHeart account';
const BIRTHYEAR_INVALID = 'Please enter a valid birth year';
const GENDER_NOT_FOUND = 'Please select your gender';
const UNSUPPORTED_COUNTRY = 'Sorry, your region is not valid for registration';
const PASSWORD_SAME_AS_CURRENT =
  'Your new password cannot be the same as your current password';
const PASSWORD_CONFIRMATION_NOT_FOUND = 'Please confirm your password';
const AMP_PASSWORD_SAME_AS_CURRENT = 'Please choose a different password';
const ERROR_CREATING_ACCOUNT = 'There was an error creating the account';
const SOCIAL_CREDS = 'Social username or password incorrect';
const LOGIN_TOKEN = 'The login token is invalid';
// -- end Shared Messages

export const AUTHORIZE = {
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  EMAIL_ERROR,
  EMAIL_OAUTH: 'This email address is associated with a social provider',
  PASSWORD_NOT_FOUND,
  PASSWORD_NOT_VALID: "Password doesn't match our records",
  TOKEN_EXPIRED: 'The login link has expired',
  TOKEN_ERROR: 'The login link is invalid',
  TOO_MANY_LOGIN_ATTEMPTS_TITLE: 'Too many repeated login attempts',
  TOO_MANY_LOGIN_ATTEMPTS:
    'Your account is locked temporarily. Please wait and try again later',
  UNKNOWN,
  REDIRECT_URI: 'The redirect URI is misconfigured',
  CLIENT_ID: 'The client ID is misconfigured',
  GENERIC_OAUTH: 'There was an error with the social provider',
  ZIPCODE_NOT_FOUND,
  ZIPCODE_INVALID,
  BIRTHYEAR_NOT_FOUND,
  BIRTHYEAR_NAN: 'You must enter a four digit number for birth year',
  BIRTHYEAR_FUTURE,
  BIRTHYEAR_INVALID,
  GENDER_NOT_FOUND,
  ERROR_CREATING_ACCOUNT,
  SOCIAL_CREDS,
  LOGIN_TOKEN,
} as const;

export const EXCHANGE_CODE = {
  NO_SECRET: 'No client secret configured in environment',
  NO_CODE: 'No authorization code provided',
  NO_APP_KEY: 'No Google appKey',
  NO_REDIRECT_URI: 'No valid redirect_uri available',
  DISCOVERY_FAILED: 'OpenID discovery failed',
  GENERIC: 'There was an error',
} as const;

export const GENRE_PICKER = {
  INVALID_SESSION: 'Session invalid, please log in again',
} as const;

export const LOGIN = {
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  EMAIL_ERROR: "We couldn't find an account associated with this email",
  EMAIL_OAUTH: 'This email address is associated with a social provider',
  FP_HASH_NOT_FOUND: 'Device fingerprint is missing',
  PASSWORD_NOT_FOUND,
  PASSWORD_NOT_VALID: "Password doesn't match our records",
  TOKEN_EXPIRED: 'The login link has expired',
  TOKEN_ERROR: 'The login link is invalid',
  TOO_MANY_LOGIN_ATTEMPTS_TITLE: 'Too many repeated log in attempts',
  TOO_MANY_LOGIN_ATTEMPTS:
    'Your account is locked temporarily. Please wait and try again later.',
  UNKNOWN,
} as const;

export const EDIT_EMAIL = {
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  USERNAME_ALREADY_TAKEN: 'An account with this email address already exists',
  AMP_USERNAME_ALREADY_TAKEN: 'Please enter a new email address',
  EMAIL_MISMATCH: 'Uh-oh email addresses don’t match',
  PASSWORD_NOT_FOUND,
  INCORRECT_PASSWORD,
  SESSION_TIMED_OUT_TITLE: 'Session expired',
  SESSION_TIMED_OUT: 'Please log in and try again',
  UNKNOWN,
} as const;

export const EDIT_INFO = {
  BIRTH_DATE_PART: 'You must supply a valid number',
  BIRTH_DAY_INVALID: 'Please enter a valid birth day',
  BIRTH_MONTH_INVALID: 'Please enter a valid birth month',
  SESSION_TIMED_OUT: 'Please log in and try again',
  INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
  ZIPCODE_INVALID,
  ZIPCODE_NOT_FOUND,

  UNKNOWN,
} as const;

export const EDIT_PASSWORD = {
  PASSWORD_NOT_FOUND,
  CURRENT_PASSWORD_NOT_FOUND,
  NEW_PASSWORD_NOT_FOUND,
  AMP_INCORRECT_PASSWORD,
  NEW_PASSWORD_CONFIRM_NOT_FOUND: PASSWORD_CONFIRMATION_NOT_FOUND,
  AMP_PASSWORD_LENGTH,
  AMP_PASSWORD_INVALID_CHARACTERS,
  AMP_PASSWORD_TOO_WEAK,
  AMP_PASSWORD_TOO_COMMON,
  AMP_PASSWORD_TOO_COMMON_TITLE,
  PASSWORD_LENGTH,
  PASSWORD_CASE,
  PASSWORD_DIGIT,
  PASSWORD_SPECIAL: PASSWORD_INVALID_CHARACTERS,
  PASSWORD_INVALID_CHARACTERS_PHONETIC,
  PASSWORD_TOO_WEAK,
  PASSWORD_TOO_COMMON,
  PASSWORD_SAME_AS_CURRENT,
  AMP_PASSWORD_SAME_AS_CURRENT,
  PASSWORDS_DO_NOT_MATCH,
  INCORRECT_PASSWORD,
  SESSION_TIMED_OUT: 'Please log in to update your password',
  CURRENT_PASSWORD_HELPER_TEXT:
    'For security purposes, enter your current password to save changes',
  UNKNOWN,
} as const;

export const SET_CREDENTIALS = {
  ...EDIT_EMAIL,
  ...EDIT_PASSWORD,
  EMAIL_ERROR,
  INELIGIBLE_SET_CREDENTIALS: 'You are not eligible to set your credentials',
} as const;

export const SIGNUP_ROOT = {
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  EMAIL_ERROR,
  PASSWORD_NOT_FOUND,
  PASSWORD_LENGTH,
  ZIPCODE_NOT_FOUND,
  ZIPCODE_INVALID,
  BIRTHYEAR_NOT_FOUND,
  BIRTHYEAR_NAN: BIRTHYEAR_FUTURE,
  BIRTHYEAR_INVALID,
  GENDER_NOT_FOUND,
  UNSUPPORTED_COUNTRY,
} as const;

export const SIGNUP_EMAIL = {
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  EMAIL_ERROR,
  PASSWORD_NOT_FOUND,
  PASSWORD_LENGTH,
  AMP_PASSWORD_LENGTH,
  PASSWORD_CASE,
  PASSWORD_DIGIT,
  PASSWORD_ERROR,
  PASSWORD_INVALID_CHARACTERS,
  AMP_PASSWORD_INVALID_CHARACTERS,
  PASSWORD_INVALID_CHARACTERS_PHONETIC,
  PASSWORD_TOO_WEAK,
  AMP_PASSWORD_TOO_WEAK,
  PASSWORD_TOO_COMMON_TITLE,
  PASSWORD_TOO_COMMON,
  AMP_PASSWORD_TOO_COMMON,
  AMP_PASSWORD_TOO_COMMON_TITLE,
  ZIPCODE_NOT_FOUND,
  BILLING_ZIPCODE_NOT_FOUND,
  ZIPCODE_INVALID,
  BIRTHYEAR_NOT_FOUND,
  BIRTHYEAR_FUTURE,
  BIRTHYEAR_INVALID,
  GENDER_NOT_FOUND,
  UNSUPPORTED_COUNTRY,
  UNKNOWN,
} as const;

export const SOCIAL_LINK = {
  ACCESS_TOKEN_MISSING: 'Missing access token from Oauth provider',
  ACCESS_TOKEN_TYPE_MISSING: 'Missing access token type',
  OAUTH_UUID_MISSING: 'Missing uniqe id from Oauth provider',
  UNKNOWN,
} as const;

export const SOCIAL_LOGIN = {
  GENERIC: 'There was an error with the social provider',
  SOCIAL_CREDS: 'Social username or password incorrect',
  ERROR_CREATING_ACCOUNT,
  UNKNOWN,
  EMAIL_NOT_FOUND,
  EMAIL_NOT_VALID,
  ZIPCODE_NOT_FOUND,
  ZIPCODE_INVALID,
  BIRTHYEAR_NOT_FOUND,
  BIRTHYEAR_NAN: BIRTHYEAR_FUTURE,
  BIRTHYEAR_INVALID,
  GENDER_NOT_FOUND,
} as const;

export const RESET_PASSWORD = {
  ACCESS_TOKEN_EXPIRED: 'The password reset link was invalid.',
  PROFILE_ID: 'Profile ID invalid',
  PASSWORD_NOT_FOUND,
  PASSWORD_CONFIRMATION_NOT_FOUND,
  PASSWORD_LENGTH,
  AMP_PASSWORD_LENGTH,
  PASSWORD_CASE,
  PASSWORD_DIGIT,
  PASSWORD_SPECIAL: PASSWORD_INVALID_CHARACTERS,
  PASSWORD_INVALID_CHARACTERS_PHONETIC,
  PASSWORD_TOO_WEAK,
  AMP_PASSWORD_TOO_WEAK,
  PASSWORD_TOO_COMMON,
  PASSWORD_SAME_AS_CURRENT,
  AMP_PASSWORD_SAME_AS_CURRENT,
  AMP_PASSWORD_INVALID_CHARACTERS,
  PASSWORDS_DO_NOT_MATCH,
  AMP_PASSWORD_TOO_COMMON,
  AMP_PASSWORD_TOO_COMMON_TITLE,
  UNKNOWN:
    'There was an error while attempting to change your password. Please try again later',
} as const;
