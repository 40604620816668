import { Provider, Root, Trigger } from '@radix-ui/react-tooltip';

import { Tooltip as Component } from './tooltip.js';

export const Tooltip = Object.assign(Component, {
  Root,
  Provider,
  Trigger,
}) as typeof Component & {
  Root: typeof Root;
  Provider: typeof Provider;
  Trigger: typeof Trigger;
};

export type { TooltipProps } from './tooltip.js';
