import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const LocationIcon = forwardRef<SVGSVGElement, IconProps>(
  function LocationIcon(
    {
      'aria-label': ariaLabel = 'Location Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M17.85 9.33414C17.85 5.83655 15.2321 3 12 3C8.7679 3 6.15002 5.83655 6.15002 9.33414C6.15002 14.6069 12 21 12 21C12 21 17.85 14.6069 17.85 9.33414ZM9.22127 9.05172C9.22127 7.42241 10.4644 6.10345 12 6.10345C13.5356 6.10345 14.7788 7.42241 14.7788 9.05172C14.7788 10.681 13.5356 12 12 12C10.4644 12 9.22127 10.681 9.22127 9.05172Z" />
      </Icon>
    );
  },
) as typeof Icon;
