// Generated by ts-to-zod
import { z } from 'zod';

export const urlsSchema = z.object({
  about: z.string().optional(),
  account: z.string().optional(),
  adChoices: z.string().optional(),
  advertise: z.string().optional(),
  apps: z.string().optional(),
  appsAuto: z.string().optional(),
  appsHome: z.string().optional(),
  appsMobile: z.string().optional(),
  appsWear: z.string().optional(),
  blog: z.string().optional(),
  brand: z.string().optional(),
  content: z.string().optional(),
  contestrules: z.string().optional(),
  contests: z.string().optional(),
  customradio: z.string().optional(),
  embeddedNews: z.string().optional(),
  epsilon: z.string().optional(),
  events: z.string().optional(),
  features: z.string().optional(),
  forYou: z.string().optional(),
  genres: z.string().optional(),
  getTheAppLink: z.string().optional(),
  help: z.string().optional(),
  helpResettingPassword: z.string().optional(),
  helpSkipLimit: z.string().optional(),
  helpSocialSignIn: z.string().optional(),
  helpVerifyingEmail: z.string().optional(),
  heroTheme: z.string().optional(),
  holidayHat: z.string().optional(),
  home: z.string().optional(),
  iglooUrl: z.string().optional(),
  jobs: z.string().optional(),
  listen: z.string().optional(),
  liveradio: z.string().optional(),
  mymusic: z.string().optional(),
  mystations: z.string().optional(),
  news: z.string().optional(),
  ondemand: z.string().optional(),
  optout: z.string().optional(),
  photos: z.string().optional(),
  playlistDirectoryMain: z.string().optional(),
  playlists: z.string().optional(),
  podcasts: z.string().optional(),
  privacy: z.string().optional(),
  subscriptionoptions: z.string().optional(),
  terms: z.string().optional(),
  tlnkApps: z.string().optional(),
  upgrade: z.string().optional(),
  yourLibrary: z.string().optional(),
});

export type Urls = z.infer<typeof urlsSchema>;
