import { useMemo } from 'react';

import type { PropertyKey } from './use-format-css.js';
import { properties } from './use-format-css.js';

export function useStyleProps<Props extends Record<string, any>>(props: Props) {
  return useMemo(() => {
    return Object.entries(props).reduce(
      (accumulator, [key, value]) => {
        return properties.has(key as PropertyKey) ?
            {
              ...accumulator,
              cssProperties: { ...accumulator.cssProperties, [key]: value },
            }
          : {
              ...accumulator,
              htmlAttributes: { ...accumulator.htmlAttributes, [key]: value },
            };
      },
      { cssProperties: {}, htmlAttributes: {} },
    );
  }, [props]);
}
