import { useSearchParams } from '@remix-run/react';

import { useConfig } from '~app/contexts/config';

export const useRedirectUrl = (url?: string) => {
  const config = useConfig();
  const [searchParams] = useSearchParams();

  const defaultUrl = url ?? config.urls.listen;
  const redirectUrl = searchParams.get('redirectUrl') || defaultUrl || '/';

  return { redirectUrl };
};
