import { forwardRef } from 'react';

import type { IconProps } from '../icon/index.js';
import { Icon } from '../icon/index.js';

export const ChevronLeftIcon = forwardRef<SVGSVGElement, IconProps>(
  function ChevronLeftIcon(
    {
      'aria-label': ariaLabel = 'Chevron Left Icon',
      children: _children,
      ...props
    }: IconProps,
    ref,
  ) {
    return (
      <Icon aria-label={ariaLabel} ref={ref} {...props}>
        <path d="M15.0025 6.70998C14.6125 6.31998 13.9825 6.31998 13.5925 6.70998L9.00246 11.3C8.61246 11.69 8.61246 12.32 9.00246 12.71L13.5925 17.3C13.9825 17.69 14.6125 17.69 15.0025 17.3C15.3925 16.91 15.3925 16.28 15.0025 15.89L11.1225 12L15.0025 8.11998C15.3825 7.72998 15.3825 7.08998 15.0025 6.70998Z" />
      </Icon>
    );
  },
) as typeof Icon;
