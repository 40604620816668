import {
  type CTAItem,
  type UNKNOWN_ERROR_FACT,
  primaryReload,
  tertiaryClear,
} from '@iheartradio/web.remix-shared';

export const UNKNOWN_ERROR: UNKNOWN_ERROR_FACT = ({ root, pathname }) => ({
  TITLE: 'Something Went Wrong',
  DESCRIPTION: 'Try reloading the page or check back later.',
  ICON: 'error-outline',
  CTA: (({ root, pathname }) => {
    const defaultUnknownError: CTAItem[] = [primaryReload, tertiaryClear];
    if (root) {
      switch (pathname) {
        default: {
          return [...defaultUnknownError];
        }
      }
    } else {
      switch (pathname) {
        default: {
          return [...defaultUnknownError];
        }
      }
    }
  })({ root, pathname }),
});
