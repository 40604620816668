import { ValidatedForm } from 'remix-validated-form';

import { SignUpLoginContainer } from '~app/components/signup-login-container';

export { action } from './.server/action';
export { loader } from './.server/loader';
export { meta } from './meta';

import { Notification, useToast } from '@iheartradio/web.companion';
import { isNotBlank } from '@iheartradio/web.utilities';
import {
  isRouteErrorResponse,
  useRouteError,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';
import { useEffect } from 'react';
import { pathOr } from 'remeda';

import { usePageView } from '~app/analytics/use-page-view';
import { useFingerprint } from '~app/hooks';
import type {
  LoginPageErrors,
  LoginPageProps,
} from '~app/pages/login-page/login-page';
import LoginPage from '~app/pages/login-page/login-page';

import { validator } from './utils';

type ToastMessage = { title: string; description?: string };
type LoginComponentProps = Omit<
  LoginPageProps,
  'fpHash' | 'showSocial' | 'errors'
>;

export const getToastMessage = (messageKey: string | null): ToastMessage => {
  if (messageKey === 'passwordUpdated') {
    return { title: 'Updated password', description: 'Please log in again' };
  }
  if (messageKey === 'loggedOut') {
    return { title: 'You have been logged out', description: '' };
  }
  return { title: '', description: '' };
};

export function ErrorBoundary() {
  const routeError = useRouteError();

  const { errors, ...rest } =
    isRouteErrorResponse(routeError) ?
      pathOr(routeError, ['data'], null)
    : null;

  return <Login errors={errors} {...rest} />;
}

export default function Login({
  errors = null,
  loginToken,
  redirectUrl,
  ...rest
}: {
  errors?: LoginPageErrors | null;
} & LoginComponentProps) {
  const submit = useSubmit();
  const toast = useToast();

  const { fpHash } = useFingerprint();

  const [searchParams] = useSearchParams();
  const loginToken_ = searchParams.get('loginToken');
  const redirectUrl_ = searchParams.get('redirectUrl');
  const messageKey = searchParams.get('message');

  usePageView('login');

  useEffect(() => {
    if (isNotBlank(messageKey)) {
      const message = getToastMessage(messageKey);

      if (isNotBlank(message)) {
        toast(dismiss => (
          <Notification
            data-test="success-notification"
            kind="success"
            onDismiss={dismiss}
            title={message.title}
          >
            {message.description}
          </Notification>
        ));
      }
    }
  }, [messageKey, toast]);

  useEffect(() => {
    if (loginToken && fpHash) {
      const formData = new FormData();
      formData.append('loginToken', loginToken);
      formData.append('fpHash', fpHash);
      submit(formData, { method: 'post' });
    }
  }, [fpHash, loginToken, submit]);

  return (
    <SignUpLoginContainer mode="login">
      <ValidatedForm id="login-form" method="POST" validator={validator}>
        <LoginPage
          errors={errors}
          fpHash={fpHash}
          loginToken={loginToken ?? loginToken_}
          redirectUrl={redirectUrl ?? redirectUrl_}
          showSocial
          {...rest}
        />
      </ValidatedForm>
    </SignUpLoginContainer>
  );
}
