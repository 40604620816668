import type { ReactNode } from 'react';

import { type FlexProps, Flex } from '../flex/index.js';
import type { Icon } from '../icon/index.js';
import { Text } from '../text/index.js';

export interface EmptyStateProps extends FlexProps {
  description: string;
  title: string;
  icon: typeof Icon;
  /** Any Call-To-Action content */
  cta?: ReactNode;
}

export const EmptyState = ({
  description,
  title,
  icon: IconComponent,
  cta,
  ...props
}: EmptyStateProps) => {
  return (
    <Flex
      alignItems="center"
      alignSelf="center"
      flexDirection="column"
      gap={{ '@initial': '$16', '@large': '$24' }}
      justifyContent="center"
      padding={{ '@initial': '$24', '@large': '8rem $24' }}
      {...props}
    >
      <IconComponent
        data-test="empty-state-icon"
        fill={{ light: 'gray-300', dark: 'gray-400' }}
        size={{ '@initial': 96, '@large': 124 }}
      />
      <Flex flexDirection="column" gap="$8">
        <Text
          as="h4"
          color={{ light: '$gray-600', dark: '$brand-white' }}
          css={{ textAlign: 'center' }}
          data-test="empty-state-title"
          kind={{
            '@initial': 'h4',
            '@large': 'h3',
          }}
        >
          {title}
        </Text>
        <Text
          as="p"
          color={{ light: '$gray-450', dark: '$gray-250' }}
          css={{ textAlign: 'center' }}
          data-test="empty-state-description"
          kind={{
            '@initial': 'body-3',
            '@large': 'body-2',
          }}
        >
          {description}
        </Text>
      </Flex>
      {cta}
    </Flex>
  );
};
