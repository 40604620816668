import { uuid } from '@iheartradio/web.utilities';
import type { JwtPayload } from 'jwt-decode';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import { z } from 'zod';

import {
  EDIT_INFO as EDIT_INFO_VALIDATION_ERRORS,
  EDIT_PASSWORD as EDIT_PASSWORD_VALIDATION_ERRORS,
  LOGIN as LOGIN_VALIDATION_ERRORS,
  RESET_PASSWORD as RESET_PASSWORD_VALIDATION_ERRORS,
  SIGNUP_EMAIL as SIGNUP_EMAIL_VALIDATION_ERRORS,
} from '~app/constants/validation-errors';
import { safeRedirect } from '~app/utilities/redirect';

const currentYear = new Date().getFullYear();

export const userNameSchema = z
  .string()
  .min(1, { message: LOGIN_VALIDATION_ERRORS.EMAIL_NOT_FOUND })
  .email(LOGIN_VALIDATION_ERRORS.EMAIL_NOT_VALID);

export const passwordSchema = z
  .string({ required_error: LOGIN_VALIDATION_ERRORS.PASSWORD_NOT_FOUND })
  .min(4, { message: LOGIN_VALIDATION_ERRORS.PASSWORD_NOT_VALID });

export const fpHashSchema = z
  .string()
  .optional()
  .transform(fpHash => fpHash ?? uuid());

export const redirectUrlSchema = z
  .string()
  .optional()
  .transform(redirectUrl => safeRedirect(redirectUrl, '/settings'));

export const loginTokenSchema = z.string().superRefine((loginToken, ctx) => {
  try {
    const decoded = jwt_decode<JwtPayload>(loginToken);
    const expiration = decoded.exp ?? 0;
    const nowSeconds = Math.floor(Date.now() / 1000);

    if (expiration - nowSeconds < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: LOGIN_VALIDATION_ERRORS.TOKEN_EXPIRED,
        fatal: true,
      });
      return z.NEVER;
    }
  } catch (error: unknown) {
    if (error instanceof InvalidTokenError) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: LOGIN_VALIDATION_ERRORS.TOKEN_ERROR,
        fatal: true,
      });
      return z.NEVER;
    }
  }
});

export const currentPasswordSchema = z.string().min(1, {
  message: EDIT_PASSWORD_VALIDATION_ERRORS.CURRENT_PASSWORD_NOT_FOUND,
});

export const newPasswordSchema = z
  .string()
  .min(1, { message: EDIT_PASSWORD_VALIDATION_ERRORS.NEW_PASSWORD_NOT_FOUND });

export const confirmPasswordSchema = z.string().min(1, {
  message: RESET_PASSWORD_VALIDATION_ERRORS.PASSWORD_CONFIRMATION_NOT_FOUND,
});

export const birthYearSchema = z.coerce
  .number({
    invalid_type_error: SIGNUP_EMAIL_VALIDATION_ERRORS.BIRTHYEAR_INVALID,
  })
  .min(1, { message: SIGNUP_EMAIL_VALIDATION_ERRORS.BIRTHYEAR_NOT_FOUND })
  .refine(
    value => {
      if (Number(value) < 1900 || Number(value) > currentYear - 14) {
        return false;
      }
      return true;
    },
    { message: SIGNUP_EMAIL_VALIDATION_ERRORS.BIRTHYEAR_INVALID },
  );

export const phoneNumberSchema = z.string().refine(
  value => {
    if (value && value.length !== 10) {
      return false;
    }
    return true;
  },
  { message: EDIT_INFO_VALIDATION_ERRORS.INVALID_PHONE_NUMBER },
);

export const birthDaySchema = z.coerce.number().refine(
  value => {
    if (value && (value < 1 || value > 31 || String(value) === '0')) {
      return false;
    }
    return true;
  },
  { message: EDIT_INFO_VALIDATION_ERRORS.BIRTH_DAY_INVALID },
);

export const birthMonthSchema = z.coerce.number().refine(
  value => {
    if (value && (value < 1 || value > 12)) {
      return false;
    }
    return true;
  },
  { message: EDIT_INFO_VALIDATION_ERRORS.BIRTH_MONTH_INVALID },
);

export const getZipCodeSchema = (regex: string, isBillingZip = false) => {
  return z
    .string({
      required_error:
        isBillingZip ?
          SIGNUP_EMAIL_VALIDATION_ERRORS.BILLING_ZIPCODE_NOT_FOUND
        : SIGNUP_EMAIL_VALIDATION_ERRORS.ZIPCODE_NOT_FOUND,
    })
    .min(1, {
      message:
        isBillingZip ?
          SIGNUP_EMAIL_VALIDATION_ERRORS.BILLING_ZIPCODE_NOT_FOUND
        : SIGNUP_EMAIL_VALIDATION_ERRORS.ZIPCODE_NOT_FOUND,
    })
    .regex(new RegExp(regex), {
      message: SIGNUP_EMAIL_VALIDATION_ERRORS.ZIPCODE_INVALID,
    });
};

export const genderSchema = z.string({
  required_error: SIGNUP_EMAIL_VALIDATION_ERRORS.GENDER_NOT_FOUND,
});
