import { Link } from '@iheartradio/web.accomplice/link';
import { Text } from '@iheartradio/web.accomplice/text';

import { useConfig } from '~app/contexts/config';

import { DoNotSellMyPersonalInformation } from './do-not-sell';

export const PrivacyTermsOptout = () => {
  const config = useConfig();

  return (
    <Text kind="caption-4">
      By creating an account, you also agree to our{' '}
      <Link
        color="primary"
        data-test="terms-of-service"
        href={config.urls.terms}
        role="link"
        target="_blank"
        underline="hover"
      >
        Terms of Use
      </Link>{' '}
      and{' '}
      <Link
        color="primary"
        data-test="privacy-policy"
        href={config.urls.privacy}
        role="link"
        target="_blank"
        underline="hover"
      >
        Privacy Policy.
      </Link>{' '}
      <DoNotSellMyPersonalInformation color="primary" underline="hover" />
    </Text>
  );
};
