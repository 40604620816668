import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { type GridProps, Grid } from '../grid/index.js';
import { useFullScreen } from './player-root.js';

export type PlayerMetadataProps = GridProps & {
  children: ReactNode;
};

export const PlayerMetadata = forwardRef<HTMLDivElement, PlayerMetadataProps>(
  function PlayerMetadata({ children, ...props }, ref) {
    const [isFullScreen] = useFullScreen();

    return (
      <Grid
        alignItems="center"
        css={{
          [`@container ${media.xsmall}`]: {
            padding: '$0 $8',
            gridTemplateAreas: '"text"',
            gridTemplateColumns: 'minmax(10rem, auto)',
          },

          [`@container ${media.small}`]: {
            padding: '$0',
            gridTemplateAreas: '"artwork text"',
            gridTemplateColumns:
              'max-content minmax(10rem, auto) max-content max-content max-content',
          },

          [`@container ${media.medium}`]: {
            gridTemplateAreas:
              isFullScreen ? '"text thumbUp"' : '"artwork text thumbUp"',
          },

          [`@container ${media.large}`]: {
            gridTemplateAreas:
              isFullScreen ?
                '"text addToPlaylist thumbUp thumbDown"'
              : '"artwork text addToPlaylist thumbUp thumbDown"',
            gridTemplateColumns: isFullScreen && 'minmax(10rem, auto)',
          },

          '[data-player-type="fullscreen"] &': {
            [`@container ${media.xsmall}`]: {
              padding: '$0 $0 $4 $0',
              gridTemplateAreas: '"text addToPlaylist thumbUp thumbDown"',
              gridTemplateColumns:
                'minmax(10rem, 1fr) min-content min-content min-content',
            },

            [`@container ${media.small}`]: {
              justifySelf: 'center',
              width: '80%',
            },

            [`@container ${media.medium}`]: {
              width: '70%',
            },

            [`@container ${media.large}`]: {
              display: 'none',
            },

            '& > :last-child': {
              paddingRight: '$0',
            },
          },
        }}
        gridArea="metadata"
        justifyContent="start"
        ref={ref}
        {...props}
      >
        {children}
      </Grid>
    );
  },
);

PlayerMetadata.displayName = 'Player.Metadata';
