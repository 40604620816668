import { type ClientHintsValue, getHintUtils } from '@epic-web/client-hints';
import {
  clientHint as colorSchemeHint,
  subscribeToSchemeChange,
} from '@epic-web/client-hints/color-scheme';
import { clientHint as reducedMotionHint } from '@epic-web/client-hints/reduced-motion';
import { clientHint as timeZoneHint } from '@epic-web/client-hints/time-zone';
import { useRevalidator } from '@remix-run/react';
import { useEffect } from 'react';

import { CHPrefersColorScheme } from './server/constants.js';

export type { ClientHintsValue } from '@epic-web/client-hints';

const hintsUtils = getHintUtils({
  theme: {
    ...colorSchemeHint,
    cookieName: CHPrefersColorScheme,
  },
  timeZone: timeZoneHint,
  reducedMotion: reducedMotionHint,
});

const getHints: (request?: Request) => ClientHintsValue<{
  theme: typeof colorSchemeHint;
  timeZone: typeof timeZoneHint;
  reducedMotion: typeof reducedMotionHint;
}> = hintsUtils.getHints;

// This component gets the client hints from the browser, and if they change will cause a root
// loader revalidation
export function ClientHintCheck() {
  const { revalidate } = useRevalidator();

  useEffect(() => subscribeToSchemeChange(() => revalidate()), [revalidate]);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
      suppressHydrationWarning={true}
    />
  );
}

export { getHints };
