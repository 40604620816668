import type { ReactNode, Ref } from 'react';
import { forwardRef } from 'react';
import { isPlainObject } from 'remeda';

import type { Component, CSSProperties, VariantProps } from '../../types.js';
import { useTheme } from '../theme/index.js';
import { Icon as Primitive } from './icon.primitive.js';

export type Fill = VariantProps<typeof Primitive>['fill'];

export type IconSize = VariantProps<typeof Primitive>['size'];

export type IconProps = {
  'aria-label'?: string;
  children?: ReactNode;
  css?: CSSProperties;
  fill?: Fill | { dark: Fill; light: Fill };
  id?: string;
  size?: IconSize;
};

export const Icon = forwardRef(function Icon(
  {
    children,
    fill = {
      dark: 'brand-white',
      light: 'gray-600',
    },
    id,
    size = 24,
    ...props
  }: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <Primitive
      aria-labelledby={id}
      data-test={id}
      fill={isPlainObject(fill) ? fill[theme] : fill}
      ref={ref}
      role="img"
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </Primitive>
  );
}) as Component<'svg', IconProps>;

export type IconType = typeof Icon;
